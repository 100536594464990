import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import ProductTypesViewModel from '../../core/features/productTypes/ProductTypesViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'ProductType',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                description:'',
                parentProductTypeId:'',
                measureUnitId:'',
                status: '',
            },
            measureUnits: [],
            products: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR TIPOS DE PRODUCTOS',
                onUploadImport: 'on-upload-import-product-types',
                onCancelImport: 'on-cancel-import-product-types'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-product-types', this.onSearch)
        this.onMountedEvent('on-upload-import-product-types', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-product-types', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-product-types', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-product-types', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-product-types', this.onCancelImport);
    },
    created() {
        this.viewModel = new ProductTypesViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
           this.onSearch() 
        },
        onSelectMeasureUnit() { },
        onCreate(){
            this.item = this.viewModel.create({
                isActive: true,
                length:0,
                width:0,
                height:0,
                weight: 0,
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        searchParentProductTypeIdFilter(data){
            if(data) {
                this.addFilter('ParentProductTypeId', data.id)
            } else {
                this.removeFilter('ParentProductTypeId')
            }
            this.onSearch()
        },
        searchMeasureUnitIdFilter(data){
            if(data) {
                this.addFilter('MeasureUnitId', data.id)
            } else {
                this.removeFilter('MeasureUnitId')
            }
            this.onSearch()
        },
        onFindParentProductType(data) {
            if(data) {
                this.viewModel.findParentProductTypes(`Name=${data}`)
            } else {
                this.products = []
            }
        },
        onFindParentProductTypesResponse(response) {
            this.products = response.data
        },
        onFindMeasureUnit(data){
            if(data) {
                this.viewModel.findMeasureUnits(`Name=${data}`)
            } else {
                this.measureUnits = []
            }
        },
        onFindMeasureUnitsResponse(response){
            this.measureUnits = response.data
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        
    }
}