import BaseForm from '../infraestructure/BaseForm'
import ServiceCostViewModel from '../../core/features/serviceCosts/ServiceCostViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'ServiceDeliveryPrice',
            viewModel: null,
            rules: {
                weightBase: [{
                    required: true,
                    message: 'Peso base requerido.',
                    trigger: 'blur'
                }],
                extraWeightCost: [{
                    required: true,
                    message: 'Costo extra peso requerido.',
                    trigger: 'blur'
                }],
                extraWeightTax: [{
                    required: true,
                    message: 'I.V.A. extra peso requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ServiceCostViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.config.isLoading = true
                    this.viewModel.saveWeighCost(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.notifySuccess(response.message)
            setTimeout(() => {
                this.config.isLoading = false
                this.config.isVisible = false
            }, 800);
        }
    }
}