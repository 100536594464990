import BasePage from "../infraestructure/BasePage";


export default {
  extends: BasePage,
  created() {},
  data: function() {
    return {
      fullscreenLoading: false,
      formData: {
        email: "",
        password: "",
      },
      rules:{
        password: [
          {
              required: true,
              message: 'La contraseña es requerida.',
              trigger: 'blur'
          },
          { validator: this.onValidatePassword, trigger: 'blur' }
          ],
      passwordConfirmation: [
          {
              required: true,
              message: 'La confirmación de la contraseña es requerida.',
              trigger: 'blur'
          },
          { validator: this.onValidatePasswordConfirmation, trigger: 'blur' }
      ]
      }
    };
  },

  methods: {
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid)=>{
        if(isValid){
          this.fullscreenLoading = true;
          this.formData.token = this.$route.query.token
          this.formData.email = this.$route.query.email
          this.viewModel.passwordRecoveryToken(this.formData)
          return true   
        }
        return false
      })
    },

    onSendPasswordRecoveryResponse(response) {

        this.fullscreenLoading = !response.data;
        this.$router.push({ name: "login" });
    },

    onValidatePassword(rule, value, callback){
      if (value === '') {
        callback(new Error('La contraseña es requerida.'));
      } else {
        if (this.formData.passwordConfirmation !== '') {
          this.$refs.formData.validateField('passwordConfirmation');
        }
        callback();
      }
    },
    onValidatePasswordConfirmation(rule, value, callback){
      if (value === '') {
        callback(new Error('Ingrese la contraseña nuevamente'));
      } else if (value !== this.formData.password) {
        callback(new Error('La contraseña y confirmación de contraseña no coinciden'));
      } else {
        callback();
      }
  }
  },
};