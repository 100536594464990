import BaseApi from '../../infraestructure/BaseApi'

export default class ApiTracking extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findTrackingNumber(trackingNumber) {
        return this.client.get(`${this.endPoint}/tracking-number/${trackingNumber}`)
    }

    findMultipleTrackingNumber(trackingNumber) {
        return this.client.get(`${this.endPoint}/multipletracking-number/${trackingNumber}`);
    }
}
