import BaseForm from '../infraestructure/BaseForm'
import PrepaidPlanViewModel from '../../core/features/prepaidPlans/PrepaidPlanViewModel'

export default {
    extends: BaseForm,
    data() {
        var formatPrice = (rule, value, callback) => {
            if(!value){
                return callback(new Error('El costo requerido.'));
            }else if (!Number.parseFloat(value)) {
                callback(new Error('Ingrese dígitos'));
            }else {
                callback();
           } 
        }
        return {
            modulePermissions: 'PrepaidPlan',
            viewModel: null,
            rules:{
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                quantity: [{
                    required: true,
                    message: 'Cantidad requerido.',
                    trigger: 'blur'
                }],
                amount: [{
                    validator: formatPrice, trigger: 'blur', required: true
                }],
                amountReception: [{
                    validator: formatPrice, trigger: 'blur', required: true
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new PrepaidPlanViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-prepaid-plan')
        }
    }
}