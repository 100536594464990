<script>
import CustomerBoxComponent from "./CustomerBoxComponent";
// import AddressBoxView from '../../../shared/boxes/AddressBoxView';
import MyLocationsBoxView from '../../../shared/boxes/myLocationsBox/BoxView'
// import MyContactsBoxView from '../../../shared/boxes/myContactsBox/BoxView'

export default {
  components: {
    // AddressBoxView,
    MyLocationsBoxView,
    // MyContactsBoxView
  },
  extends: CustomerBoxComponent,
};
</script>

<template>
  <div
    style="margin-top:20px"
    class="request-quote__shipment"
  >
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
      >
        <small class="notification-warning">
          <i class="el-icon-warning" /> Si no cuenta con numero de cliente, ingresar sus datos por primera vez. 
          Si actualmente cuenta con numero de cliente, favor de ingresarlo en el siguiente campo de busqueda.
        </small>
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        <el-form-item prop="customerNumber">
          <el-input
            v-model="formData.customerNumber"
            placeholder="Buscar por numero de cliente"
            autocomplete="off"
            size="medium"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onFindCustomerByCode"
            >
              Buscar
            </el-button>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="8"
      >
        <el-form-item
          label="Nombre"
          prop="customer.contactName"
        >
          <el-input
            v-model="formData.customer.contactName"
            autocomplete="off"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
      >
        <el-form-item
          label="Correo Electrónico"
          prop="customer.contactEmail"
        >
          <el-input
            v-model="formData.customer.contactEmail"
            autocomplete="off"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
      >
        <el-form-item
          label="Tel. de Contacto"
          prop="customer.contactPhone"
        >
          <el-input
            v-model="formData.customer.contactPhone"
            autocomplete="off"
            size="medium"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <!-- <el-row class="row-bg" :gutter="20" style="margin-bottom:20px">
      <el-col :xs="24" :sm="24" align="center">
        <small class="notification-warning">
          <i class="el-icon-warning" /> Si desea facturar capturar sus datos de Raz&oacute;n Social y RFC.
          </small>
      </el-col>
    </el-row>-->
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="16"
      >
        <el-form-item
          label="Razón Social"
          prop="customer.fiscalName"
        >
          <el-input
            v-model="formData.customer.fiscalName"
            autocomplete="off"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
      >
        <el-form-item
          v-loading="isPostalCodeLoading"
          label="RFC"
          prop="customer.fiscalCode"
        >
          <el-input
            v-model="formData.customer.fiscalCode"
            autocomplete="off"
            size="medium"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-tabs
      v-model="activeName"
      type="border-card"
    >
      <el-tab-pane
        label="Mis Ubicaciones"
        name="my-locations"
      >
        <my-locations-box-view
          :form-data="formData.customer"
          :is-location-selected="true"
        />
        <!-- <address-box-view :form-data="formData.customer" prop-name="customer" orientation="vertical" /> -->
      </el-tab-pane>
      <!-- <el-tab-pane label="Mis Contactos" name="my-contacts">
        <my-contacts-box-view :form-data="formData.customer" />
        <address-box-view :form-data="formData.customer" prop-name="customer" orientation="vertical" /> 
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>