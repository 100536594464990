import BaseComponent from "../../infraestructure/BaseComponent";
import MainMenuViewModel from "../../../core/features/shared/MainMenuViewModel";

export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null,
      activeLink: null,
    };
  },
  mounted() {
    this.activeLink = this.$route.path;
  },
  created() {
    this.viewModel = new MainMenuViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.setupScripts();
    },
    toggleSideBarNavbar() {
      var item = document.getElementsByTagName("BODY")[0];
      item.classList.remove("sidebar-enable");
    },
    allowSubAccounts() {
      return this.$store.getters["admin/getUserActive"].allowSubAccounts
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters["admin/isAuth"];
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.activeLink = to.path;
      }
    },
  },
};
