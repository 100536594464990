import BaseViewModel from '../../infraestructure/BaseViewModel'
import Country from '../countries/Country'
import District from '../districts/District'
import Municipality from '../municipalities/Municipality'
import PostalCode from '../postalCodes/PostalCode'
import Neighborhood from './Neighborhood'

export default class NeighborhoodsViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }

    create(data) {
        return new Neighborhood(data, 'create')
    }

    edit(data) {
        return new Neighborhood(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.neighborhoods.create(data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
            
        } else {
            this.api.neighborhoods.update(data.id, data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.neighborhoods.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.neighborhoods.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.neighborhoods.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm(formData) {
        if(formData.state == 'update'){
            this.view.postalCodes = (formData.postalCode) ? [new PostalCode(formData.postalCode)] : []
        }
    }

    import(file) {
        this.api.neighborhoods.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            country: '',
            district: '',
            municipality: '',
            place: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('CountryId');
        this.view.removeFilter('DistrictId');
        this.view.removeFilter('MunicipalityId');
        this.view.removeFilter('CityId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    bindList() {
        this.api.countries.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.countries = this.mapCountry(response.data.data)
                setTimeout(() => {
                    this.view.onSearch();
                    this.view.isListLoading = false;
                }, 400);
            })
            .catch(this.view.onError);
    }

    //#region 
    
    mapCollection(collection){
        return collection.map((item) => new Neighborhood(item))
    }

    mapCountry(collection) {
        return collection.map((item) => new Country(item))
    }

    mapDistricts(collection) {
        return collection.map((item) => new District(item))
    }

    mapMunicipality(collection) {
        return collection.map((item) => new Municipality(item))
    }

    mapPostalCodes(collection) {
        return collection.map((item) => new PostalCode(item))
    }

    //#endregion

    findPostalCodes(query) {
        this.api.postalCodes.find(query)
            .then((response) => this.view.onFindPostalCodeResponse(response.data))
            .catch(this.view.onError)
    }

    findMunicipalities(query) {
        this.api.municipalities.find(query)
            .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
            .catch(this.view.onError)
    }

    findCities(query) {
        this.api.places.find(query)
            .then((response) => this.view.onFindCitiesResponse(response.data))
            .catch(this.view.onError)
    }
}