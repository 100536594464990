<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleSaleComponent from './ModuleSaleComponent'
import ListSaleView from './ListSaleView'
import ListSaleBillGroupView from './billingGroup/ListView.vue'

export default {
  components: {
    'admin-menu-view': AdminMenuView,
    'admin-header-view': AdminHeaderView,
    'admin-footer-view': AdminFooterView,
    'list-sale-view': ListSaleView,
    'list-sale-bill-group-view': ListSaleBillGroupView,
  },
  extends: ModuleSaleComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-shopping-cart module-header-icon" /> Ventas y Carta Porte
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="activeName">
                    <el-tab-pane label="Ventas y Cartas Porte" name="sales">
                      <list-sale-view v-if="activeName === 'sales'" />
                    </el-tab-pane>
                    <el-tab-pane label="Grupos de Facturación" name="sale-bill-groups">
                      <list-sale-bill-group-view v-if="activeName === 'sale-bill-groups'" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>