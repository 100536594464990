import Vue from "vue";
import App from "./App.vue";
import store from "./pages/dashboard/Store/store";
import moment from "moment";
import { MXN } from "./core/helpers/MoneyHelper";
import Interceptor from "./core/helpers/InterceptorHelper.cs"; 
import router from "./router";
import jQuery from "jquery";
global.jQuery = jQuery;
global.$ = jQuery;
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/es";
import sitehelper from "./core/helpers/SiteLoaderHelper";
import VueApexCharts from 'vue-apexcharts'
import VueSimplebar from 'vue-simplebar'
import 'vue-simplebar/dist/vue-simplebar.min.css'
import 'leaflet/dist/leaflet.css'

ElementUI.Dropdown.methods.hide = function hide() {
  var _this2 = this;

  if (!this.triggerElm || this.triggerElm.disabled) return;
  this.removeTabindex();
  if (this.tabindex >= 0) {
    this.resetTabindex(this.triggerElm);
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(function () {
    _this2.visible = false;
  }, this.trigger === 'click' ? 0 : this.hideTimeout);
}

Vue.use(VueSimplebar)
Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)
Vue.use(ElementUI, { locale });
Vue.use(sitehelper);
Vue.filter("formatDate", (value) => moment(String(value), moment.ISO_8601).format("DD MMM YYYY"));
moment.locale("es");
Vue.filter("formatDate", (value) => moment(String(value), moment.ISO_8601).format("DD MMM YYYY"));
Vue.filter("formatDWithTime", (value) => moment(String(value), moment.ISO_8601).format("DD MMM YYYY HH:mm:ss"));
Vue.filter("formatMXNCurrency", (value) => `${MXN(value).format()} MXN`);
Vue.filter("formatCurrency", (value) => `${MXN(value).format()}`);
Vue.filter("3Digits", (value) => value.toFixed(3));
Interceptor();
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

