import BaseForm from '../../../../components/infraestructure/BaseForm'
import PackageBoxViewModel from './PackageBoxViewModel'


export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
        }
    },
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new PackageBoxViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() { 
            console.log('on active')
            this.onNotifyEvent('is-form-loading', false)
        },
        onAddPackage() {
            this.formData.packages.push({
                description: null,
                quantity: 1.0,
                width: 1.0,
                length: 1.0,
                height: 1.0,
                weight: 1.0
            })
        },
        onDeletePackage(index) {
            this.formData.packages.splice(index, 1)
        }
    }
}