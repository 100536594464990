import BaseViewModel from '../../infraestructure/BaseViewModel'


export default class DashboardViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }
    
    getDataResumen() {
        this.api.dashboard.getData()
            .then((response) => this.view.onSearchResponse(response.data))
            .catch(this.view.onError)
    }
}
