import BasePage from "../infraestructure/BasePage";


export default {
  extends: BasePage,
  created() {},
  data: function() {
    return {
      fullscreenLoading: false,
      message: '',
      typeAlert: 'info',
      formData: {
        email: ""
      },
      rules:{
        email: [{
            required: true,
            message: 'Correo requerido.',
            trigger: 'blur'
        }],
      }
    };
  },
mounted() {
  this.message = 'Se le enviarán las instrucciones a su correo electrónico'
},
  methods: {
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid)=>{
        if(isValid){
          this.fullscreenLoading = true;
          this.viewModel.sendEmailRecovery(this.formData)
          return true   
        }
        return false
      })
    },
    onSendRecoveryResponse(response) {
      this.typeAlert = 'success'
      this.message= response.message
      this.fullscreenLoading = false;
    },

    onLoginError(error){
      this.fullscreenLoading = false;
      this.onError(error)
    }
  },
};