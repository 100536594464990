<script>
import ModalBankAccountComponent from './ModalBankAccountComponent'

export default {
    extends: ModalBankAccountComponent,
}
</script>

<template>
  <el-dialog
    custom-class="dialog-bank-account"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    width="25%"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-panel-add" /> Cuenta de Banco
    </span>
    
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              ref="code"
              v-model="formData.code"
              autocomplete="off"
              autofocus="true"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Nombre Banco"
            prop="bankName"
          >
            <el-input
              v-model="formData.bankName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Numero de Cuenta"
            prop="accountNumber"
          >
            <el-input
              v-model="formData.accountNumber"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Estado"
            align="center"
          >
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="¿Es Default?"
            align="center"
          >
            <el-switch
              v-model="formData.isDefault"
              active-text="Si"
              inactive-text="No"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>