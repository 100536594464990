<script>
import StepTwoComponent from "./StepTwoComponent";
import ModalReceptionPackageView from "../ModalReceptionPackageView";

export default {
  components: {
    "modal-reception-package-view": ModalReceptionPackageView,
  },
  extends: StepTwoComponent,
};
</script>

<template>
  <el-row
    type="flex"
    class="row-bg reception-steps-wrapper"
    :gutter="20"
  >
    <el-col :span="24">
      <!-- <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :span="24"
          align="center"
        >
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <h4>Tipo de Recepción</h4>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="24"
              align="center"
            >
              <el-form-item
                prop="receptionType"
                class="field-reception-type"
              >
                <el-radio-group
                  v-model="formData.receptionType"
                  @change="onChangeReceptionType"
                >
                  <el-radio
                    v-for="item in receptionTypes"
                    :key="item.id"
                    :label="item.id"
                  >
                    <i :class="item.icon" /> {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>-->
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <h4>Flete (Salidas/Viajes)</h4>
        </el-col>
      </el-row>
      <el-row
        v-if="formData.solvencyType !== 5"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item prop="serviceCost">
            <el-select
              v-model="formData.serviceCost"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              value-key="id"
              size="large"
              @change="onChangeServiceCost"
            >
              <el-option
                v-for="item in serviceCosts"
                :key="item.id"
                :label="onFormatServiceCost(item)"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <h4>Lista de Mercancia</h4>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-button
                type="primary"
                size="small"
                @click="onCreateReceptionPackage"
              >
                <i class="fas fa-plus-circle" /> Agregar Paquete
              </el-button>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                prop="receptionPackages"
                class="field-reception-packages"
              >
                <el-table
                  v-loading="isTableLoading"
                  show-summary
                  sum-text="Total"
                  :summary-method="getSummaries"
                  :data="formData.receptionPackages"
                  style="width: 100%"
                >
                  <el-table-column
                    label="Indice"
                    width="100"
                    align="center"
                  >
                    <template #default="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="Descripción Mercancia"
                  />
                  <el-table-column
                    label="Tipo"
                    width="280"
                    align="center"
                  >
                    <template #default="scope">
                      {{ scope.row.productType.name }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="U.M."
                    width="100"
                  >
                    <template #default="scope">
                      {{ scope.row.measureUnit.name }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    label="Cantidad"
                    width="100"
                    align="center"
                  />
                  <el-table-column
                    prop="finalWeight"
                    label="Peso"
                    width="110"
                    align="center"
                  />
                  <el-table-column
                    prop="extraWeight"
                    label="Sobre Peso"
                    width="110"
                    align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.extraWeight }}
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                    prop="weight"
                    label="Peso Fis. KG"
                    width="110"
                    align="center"
                  />
                  <el-table-column
                    prop="volumetricWeight"
                    label="Peso Vol. KG"
                    width="110"
                    align="center"
                  /> -->
                  <!-- <el-table-column
                    prop="subtotal"
                    label="Costo"
                    width="140"
                    align="center"
                  >
                    <template #default="scope">
                      {{ scope.row.subtotal | formatMXNCurrency }}
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    label="Opciones"
                    width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                    <template #default="scope">
                      <el-dropdown @command="onChangeOptionReceptionPackage">
                        <el-button size="small">
                          <i class="el-icon-more" />
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              :command="{
                                command: 'edit',
                                item: scope.row,
                                index: scope.$index,
                              }"
                            >
                              <i class="el-icon-edit" /> Mostrar
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="{
                                command: 'delete',
                                item: scope.row,
                                index: scope.$index,
                              }"
                            >
                              <i class="el-icon-delete" /> Eliminar
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
          <modal-reception-package-view
            v-if="modalReceptionPackageConfig.isVisible"
            :form-data="itemReceptionPackage"
            :config="modalReceptionPackageConfig"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
