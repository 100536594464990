export default class CustomerAccount {
  constructor(options, state) {
    if(state){
      this.state = state
    }
    Object.assign(this, options)
  }

  getFullName(){
    return `${this.firstName}  ${this.lastName}`
  }

  getAccountName(){
    return `${this.accountName}`
  }

  getUserNameEmail() {
    return `${this.userName} | ${this.email}`
  }

  getResponsibleFullName() {
    return this.name
  }
  
  toSendCustomerAccounts() {
    return {
      userName: this.userName.trim(),
      accountName: this.accountName,
      accountPhone: this.accountPhone,
      password: this.password,
      passwordConfirmation: this.password,
      email: this.email.trim(),
      //roleName: (this.roleName) ? this.roleName : null,
      roleName: 'cliente subcuenta',
      customerId: this.customerId,
      isActive: this.isActive
    }
  }
}