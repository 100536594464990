<script>
import ShipmentBoxComponent from "./ShipmentBoxComponent";
import AddressBoxView from "../../../shared/boxes/addressBox/AddressBoxView";
import ContactsBoxView from "../../../shared/boxes/myContactsBox/BoxView";

export default {
  components: {
    AddressBoxView,
    ContactsBoxView,
  },
  extends: ShipmentBoxComponent,
};
</script>
<template>
  <div class="request-quote__shipment">
    <el-form-item
      label="Servicio Origen/Destino"
      prop="serviceCost"
    >
      <el-select
        v-model="formData.serviceCost"
        placeholder="Seleccionar Servicio Origen/Destino"
        filterable
        clearable
        debounce
        value-key="id"
        size="large"
        @change="onChangeServiceCost"
      >
        <el-option
          v-for="item in serviceCosts"
          :key="item.id"
          :label="item.getFullDescriptionNoPrice()"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        <el-form-item
          label="Seleccionar una opción para asignar responsable de pago."
          prop="payerResponsible"
        >
          <el-radio-group
            v-model="formData.payerResponsible"
            size="small"
          >
            <el-radio-button label="sender">
              Remitente
            </el-radio-button>
            <el-radio-button label="receiver">
              Destinatario
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-collapse
      v-model="shipmentTabActive"
      accordion
    >
      <el-collapse-item
        v-loading="isSenderBoxLoading"
        title="Remitente"
        name="1"
      >
        <el-tabs type="border-card">
          <el-tab-pane label="Información de Contacto">
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
              >
                <el-form-item
                  label="Seleccionar una opción si desea usar los datos de cliente o utilizar contactos"
                >
                  <el-radio-group
                    v-model="formData.senderType"
                    size="small"
                    @change="onSenderTypeChange"
                  >
                    <el-radio-button label="customer">
                      Usar Cliente
                    </el-radio-button>
                    <el-radio-button label="contacts">
                      Usar Contactos
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="formData.senderType === 'contacts'"
              class="row-bg"
              :gutter="20"
            >
              <el-col :xs="24">
                <contacts-box-view
                  :customer="formData.customer"
                  :form-data="formData.sender"
                  prop-name="sender"
                  type="sender"
                />
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="16"
              >
                <el-form-item
                  label="Nombre"
                  required
                  :prop="`sender.contactName`"
                >
                  <el-input
                    v-model="formData.sender.contactName"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="Teléfono"
                  :prop="`sender.contactPhone`"
                  required
                >
                  <el-input
                    v-model="formData.sender.contactPhone"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
              >
                <el-form-item
                  label="Correo Electrónico"
                  :prop="`sender.contactEmail`"
                >
                  <el-input
                    v-model="formData.sender.contactEmail"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
              >
                <el-form-item
                  label="Referencia/Observación"
                  :prop="`sender.observation`"
                >
                  <el-input
                    v-model="formData.sender.observation"
                    autocomplete="off"
                    autofocus="true"
                    type="textarea"
                    size="medium"
                    :rows="4"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Dirección">
            <address-box-view
              :form-data="formData.sender.customerLocation"
              orientation="vertical"
              prop-name="sender.customerLocation"
            />
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>
      <el-collapse-item
        v-loading="isReceiverBoxLoading"
        title="Destinatario"
        name="2"
      >
        <el-tabs type="border-card">
          <el-tab-pane label="Información de Contacto">
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
              >
                <el-form-item
                  label="Seleccionar una opción si desea usar los datos de cliente o utilizar contactos"
                >
                  <el-radio-group
                    v-model="formData.receiverType"
                    size="small"
                    @change="onReceiverTypeChange"
                  >
                    <el-radio-button label="customer">
                      Usar Cliente
                    </el-radio-button>
                    <el-radio-button label="contacts">
                      Usar Contactos
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="formData.receiverType === 'contacts'"
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
              >
                <contacts-box-view
                  :customer="formData.customer"
                  :form-data="formData.receiver"
                  type="receiver"
                />
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="16"
              >
                <el-form-item
                  label="Nombre"
                  required
                  :prop="`receiver.contactName`"
                >
                  <el-input
                    v-model="formData.receiver.contactName"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="Teléfono"
                  :prop="`receiver.contactPhone`"
                  required
                >
                  <el-input
                    v-model="formData.receiver.contactPhone"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-form-item
                  label="Correo Electrónico"
                  :prop="`receiver.contactEmail`"
                >
                  <el-input
                    v-model="formData.receiver.contactEmail"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-form-item
                  label="Referencia/Observación"
                  :prop="`receiver.observation`"
                >
                  <el-input
                    v-model="formData.receiver.observation"
                    autocomplete="off"
                    autofocus="true"
                    type="textarea"
                    size="medium"
                    :rows="4"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Dirección">
            <address-box-view
              :form-data="formData.receiver.customerLocation"
              orientation="vertical"
              prop-name="receiver.customerLocation"
            />
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>

      <el-collapse-item
        v-loading="isFiscalBoxLoading"
        title="Facturación"
        name="3"
      >
        <el-tabs type="border-card">
          <el-tab-pane label="Datos Fiscales">
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-form-item
                  label="Seleccionar una opción si desea usar los datos de remitente o destinatario"
                >
                  <el-radio-group
                    v-model="formData.orderFiscal.type"
                    size="small"
                    @change="onFiscalOptionChange"
                  >
                    <el-radio-button label="none">
                      Ningúno
                    </el-radio-button>
                    <el-radio-button label="sender">
                      Remitente
                    </el-radio-button>
                    <el-radio-button label="receiver">
                      Destinatario
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="16"
              >
                <el-form-item
                  label="Razón Social"
                  prop="orderFiscal.fiscalName"
                >
                  <el-input
                    v-model="formData.orderFiscal.fiscalName"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    :disabled="!isOrderFiscalEditable()"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  v-loading="isPostalCodeLoading"
                  label="R.F.C."
                  prop="orderFiscal.fiscalCode"
                >
                  <el-input
                    v-model="formData.orderFiscal.fiscalCode"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    :disabled="!isOrderFiscalEditable()"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-form-item
                  label="Correo Electrónico"
                  prop="orderFiscal.fiscalEmail"
                >
                  <el-input
                    v-model="formData.orderFiscal.fiscalEmail"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    :disabled="!isOrderFiscalEditable()"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Dirección">
            <address-box-view
              :form-data="formData.orderFiscal.location"
              orientation="vertical"
              prop-name="orderFiscal.location"
            />
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
