<script>
import ModalReceptionsCustomerComponent from "./ModalReceptionsCustomerComponent";

export default {
  extends: ModalReceptionsCustomerComponent,
};
</script>

<template>
  <el-dialog
    width="60%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
    custom-class="dialog"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-comment-notes" /> Cliente Recepción
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="18">
          <el-form-item
            label="Descripción/Alias de Cliente"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="Descuento (%)"
            prop="discount"
          >
            <el-input-number
              ref="discount"
              v-model="formData.discountPercentage"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :step="5"
              :min="0"
              :max="100"
              step-strictly
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="9">
          <el-form-item
            label="Nombre de Contacto"
            prop="contactName"
          >
            <el-input
              v-model="formData.contactName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            label="Correo Electrónico de Contacto"
            prop="contactEmail"
          >
            <el-input
              v-model="formData.contactEmail"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item
            label="Teléfono de Contacto"
            prop="contactPhone"
          >
            <el-input
              v-model="formData.contactPhone"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="Datos Fiscales"
          name="first"
        >
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="18">
              <el-form-item
                label="Razón Social"
                prop="fiscalName"
              >
                <el-input
                  v-model="formData.fiscalName"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="RFC"
                prop="fiscalCode"
              >
                <el-input
                  v-model="formData.fiscalCode"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          label="Datos de Ubicación"
          name="second"
        >
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                label="Descripción/Alias de Ubicación"
                prop="descriptionLocation"
              >
                <el-input
                  v-model="formData.descriptionLocation"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="18">
              <el-form-item
                label="Calle Principal"
                prop="mainStreet"
              >
                <el-input
                  ref="mainStreet"
                  v-model="formData.mainStreet"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="Numero"
                prop="extNumber"
              >
                <el-input
                  ref="extNumber"
                  v-model="formData.extNumber"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="18">
              <el-form-item
                label="Entre Calles"
                prop="secondaryStreet"
              >
                <el-input
                  ref="secondaryStreet"
                  v-model="formData.secondaryStreet"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="Código Postal"
                prop="postalCode"
              >
                <el-input
                  ref="postalCode"
                  v-model="formData.postalCode"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                label="Ciudad/Destino"
                prop="city"
              >
                <el-select
                  v-model="formData.city"
                  placeholder="Seleccionar Ciudad"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  remote
                  size="medium"
                  :remote-method="onFindDestinations"
                >
                  <el-option
                    v-for="item in destinations"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                label="Colonia"
                prop="neighborhood"
              >
                <el-input
                  v-model="formData.neighborhoodName"
                  placeholder="Ingresar colonia nueva"
                  class="input-with-select"
                >
                  <el-select
                    ref="neighborhood"
                    slot="prepend"
                    v-model="formData.neighborhood"
                    placeholder="Seleccionar Colonia"
                    filterable
                    clearable
                    debounce
                    value-key="id"
                    remote
                    size="medium"
                    class="select-neighborhood"
                    :remote-method="onFindNeighborhoods"
                  >
                    <el-option
                      v-for="item in neighborhoods"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                  <el-button
                    slot="append"
                    icon="el-icon-circle-plus-outline"
                    @click="onAddNeighborhood"
                  >
                    Agregar
                  </el-button>
                </el-input>
                <!--  <el-input
              ref="neighborhood"
              v-model="formData.neighborhood"
              autocomplete="off"
              autofocus="true"
              size="medium"
            /> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <!-- <el-button
       
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button> -->
    </span>
  </el-dialog>
</template>
