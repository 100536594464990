// import _ from 'lodash'
import BaseForm from "../../../../components/infraestructure/BaseForm";
import ShipmentBoxViewModel from "./ShipmentBoxViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      shipmentTabActive: "1",
      isCustomerContactInfo: false,
      isSenderBoxLoading: false,
      isReceiverBoxLoading: false,
      isFiscalBoxLoading: false,
      serviceCosts: []
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    isPostalCodeLoading: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.viewModel = new ShipmentBoxViewModel(this);
    this.onActive();
  },
  mounted() {
    this.onMountedEvent("sender-contact-change", this.onSenderContactChange);
    this.onMountedEvent(
      "sender-contact-location-change",
      this.onSenderContactLocationChange
    );

    this.onMountedEvent(
      "receiver-contact-change",
      this.onReceiverContactChange
    );
    this.onMountedEvent(
      "receiver-contact-location-change",
      this.onReceiverContactLocationChange
    );
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "sender-contact-change",
      this.onSenderContactChange
    );
    this.onBeforeDestroyEvent(
      "sender-contact-location-change",
      this.onSenderContactLocationChange
    );

    this.onBeforeDestroyEvent(
      "receiver-contact-change",
      this.onReceiverContactChange
    );
    this.onBeforeDestroyEvent(
      "receiver-contact-location-change",
      this.onReceiverContactLocationChange
    );
  },
  methods: {
    onActive() {
      this.viewModel.bindBoxView();
      this.onFiscalOptionChange(this.formData.orderFiscal.type)
      if (!this.formData.sender.observation) this.onSetContact(this.formData.customer, "sender");
    },
    onSetContact(data, type) {
      setTimeout(() => {
        this.$set(this.formData, type, JSON.parse(JSON.stringify(data)));
        this.isSenderBoxLoading = false;
        this.isReceiverBoxLoading = false;
      }, 400);
    },
    onResetContact(type) {
      setTimeout(() => {
        this.onNotifyEvent(`reset-${type}-form`, null);
        this.isSenderBoxLoading = false;
        this.isReceiverBoxLoading = false;
      }, 400);
    },
    onChangeServiceCost() {
      this.$forceUpdate();
    },
    onFormatServiceCost(item) {
      return this.viewModel.formatMoney(item);
    },
    onServiceCostResponse(response) {
      this.serviceCosts = this.viewModel.mapServiceCost(response.data);
      this.onNotifyEvent("is-form-loading", false);
    },
    onFiscalOptionChange(type) {
      this.isFiscalBoxLoading = true;
      setTimeout(() => {
        switch (type) {
          case "none":
            this.setFiscalData({
              fiscalName: "PUBLICO EN GENRAL",
              fiscalCode: "XAXX010101000",
              fiscalEmail: "",
            });
            break;
          case "sender":
          case "receiver":
            console.log(this.formData, type)
            this.setFiscalData({
              fiscalName: this.viewModel.getItemData(this.formData[type], 'fiscalName', 'selectedContact.fiscalName'),
              fiscalCode: this.viewModel.getItemData(this.formData[type], 'fiscalCode', 'selectedContact.fiscalCode'),
              fiscalEmail: this.viewModel.getItemData(this.formData[type], 'contactEmail', 'email'),
              mainStreet: this.viewModel.getItemData(this.formData[type], 'customerLocation.mainStreet'),
              secondaryStreet: this.viewModel.getItemData(this.formData[type], 'customerLocation.secondaryStreet'),
              intNumber: this.viewModel.getItemData(this.formData[type], 'customerLocation.intNumber'),
              extNumber: this.viewModel.getItemData(this.formData[type], 'customerLocation.extNumber'),
              postalCode: this.viewModel.getItemData(this.formData[type], 'customerLocation.postalCode'),
              city: this.viewModel.getItemData(this.formData[type], 'customerLocation.city'),
              municipality: this.viewModel.getItemData(this.formData[type], 'customerLocation.municipality'),
              district: this.viewModel.getItemData(this.formData[type], 'customerLocation.district'),
              country: this.viewModel.getItemData(this.formData[type], 'customerLocation.country'),
              neighborhood: this.viewModel.getItemData(this.formData[type], 'customerLocation.neighborhood'),
            });
            break;
        }
        this.isFiscalBoxLoading = false;
      }, 400);
    },
    setFiscalData(data) {
      this.$set(this.formData.orderFiscal, "fiscalName", data.fiscalName);
      this.$set(this.formData.orderFiscal, "fiscalCode", data.fiscalCode);
      this.$set(this.formData.orderFiscal, "fiscalEmail", data.fiscalEmail);
      this.$set(
        this.formData.orderFiscal.location,
        "mainStreet",
        data.mainStreet
      );
      this.$set(
        this.formData.orderFiscal.location,
        "secondaryStreet",
        data.secondaryStreet
      );
      this.$set(
        this.formData.orderFiscal.location,
        "intNumber",
        data.intNumber
      );
      this.$set(
        this.formData.orderFiscal.location,
        "extNumber",
        data.extNumber
      );
      this.$set(
        this.formData.orderFiscal.location,
        "postalCode",
        data.postalCode
      );
      this.$set(this.formData.orderFiscal.location, "country", data.country);
      this.$set(this.formData.orderFiscal.location, "district", data.district);
      this.$set(
        this.formData.orderFiscal.location,
        "municipality",
        data.municipality
      );
      this.$set(this.formData.orderFiscal.location, "city", data.city);
      this.$set(
        this.formData.orderFiscal.location,
        "neighborhood",
        data.neighborhood
      );

      this.$forceUpdate();
    },
    isOrderFiscalEditable() {
      return this.formData.orderFiscal.type !== "none";
    },
    onSenderTypeChange(type) {
      switch (type) {
        case "customer":
          this.isSenderBoxLoading = true;
          this.onSetContact(this.formData.customer, "sender");
          break;
        case "contacts":
          this.isSenderBoxLoading = true;
          this.onResetContact("sender");
          break;
      }
    },
    onSenderContactChange(data) {
      this.$set(
        this.formData.sender,
        "contactName",
        data ? data.contactName : ""
      );
      this.$set(
        this.formData.sender,
        "contactPhone",
        data ? data.contactPhone : ""
      );
      this.$set(
        this.formData.sender,
        "contactEmail",
        data ? data.contactEmail : ""
      );
    },
    onSenderContactLocationChange(data) {
      this.$set(
        this.formData.sender,
        "customerLocation",
        data ? JSON.parse(JSON.stringify(data)) : {}
      );
      this.$forceUpdate();
    },
    onReceiverTypeChange(type) {
      switch (type) {
        case "customer":
          this.isReceiverBoxLoading = true;
          this.onSetContact(this.formData.customer, "receiver");
          break;
        case "contacts":
          this.isReceiverBoxLoading = true;
          this.onResetContact("receiver");
          break;
      }
    },
    onReceiverContactChange(data) {
      this.$set(
        this.formData.receiver,
        "contactName",
        data ? data.contactName : ""
      );
      this.$set(
        this.formData.receiver,
        "contactPhone",
        data ? data.contactPhone : ""
      );
      this.$set(
        this.formData.receiver,
        "contactEmail",
        data ? data.contactEmail : ""
      );
      this.$forceUpdate();
    },
    onReceiverContactLocationChange(data) {
      console.log(data)
      this.$set(
        this.formData.receiver,
        "customerLocation",
        data ? JSON.parse(JSON.stringify(data)) : {}
      );
      this.$forceUpdate();
    },
  },
};
