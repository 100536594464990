export default class Employee {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  getFullName() {
    return `${this.firstName}  ${this.lastName}`;
  }

  getUserNameEmail() {
    return `${this.userName} | ${this.email}`;
  }

  getResponsibleFullName() {
    return this.name;
  }

  toSend() {
    return {
      id: this.id,
      userName: this.userName,
      password: this.password,
      passwordConfirmation: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      roleId: this.role ? this.role.id : null,
      roleName: this.role ? this.role.name : null,
      email: this.email,
      status: this.status,
      isActive: this.isActive,
      isTransportFigure: this.isTransportFigure,
      identificationNumber: this.identificationNumber,
      federalTaxPayerRegistration: this.federalTaxPayerRegistration,
      licenseExpiration: this.licenseExpiration,
      licenseNumber: this.licenseNumber,
      branchOfficeId: this.branchOffice ? this.branchOffice.id : null,
      figureTransportType: (this.figureTransportType) ? this.figureTransportType : null,
      address: this.mapAddressToSend(),
    };
  }

  mapAddressToSend() {
    return {
      mainStreet: this.address.mainStreet,
      secondaryStreet: this.address.secondaryStreet,
      intNumber: this.address.intNumber,
      extNumber: this.address.extNumber,
      postalCodeId: (this.address.postalCode) ? this.address.postalCode.id : null,
      neighborhoodId: (this.address.neighborhood) ? this.address.neighborhood.id : null,
      cityId: (this.address.city) ? this.address.city.id : null,
      municipalityId: (this.address.municipality) ? this.address.municipality.id : null,
      districtId: (this.address.district) ? this.address.district.id : null,
      countryId: (this.address.country) ? this.address.country.id : null,
    };
  }
}
