import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import PostalCodeViewModel from "../../core/features/postalCodes/PostalCodeViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: "PostalCode",
            viewModel: null,
            batchGroups: [],
            batchPosition: 0,
            totalRegistersByBatch: 2000,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                country: '',
                district: '',
                municipality: '',
                place: '',
                status: '',
            },
            countries: [],
            districts: [],
            municipalities: [],
            places: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR CÓDIGOS POSTALES",
                onUploadImport: "on-upload-import-postal-codes",
                onCancelImport: "on-cancel-import-postal-codes",
            },
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-postal-code", this.onSearch);
        this.onMountedEvent("on-upload-import-postal-codes", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-postal-codes", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-postal-code", this.onSearch);
        this.onBeforeDestroyEvent(
            "on-upload-import-postal-codes",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-postal-codes",
            this.onCancelImport
        );
    },
    created() {
        this.viewModel = new PostalCodeViewModel(this);
        this.onActive();
    },

    methods: {
        onActive() {
            this.viewModel.bindList();
        },
        onSelectPostalCode() { },
        onCreate() {
            this.item = this.viewModel.create({ isActive: true });
            this.modalConfig.isVisible = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        onSearchByDestinationCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('DestinationCode', text)
            } else {
                this.removeFilter('DestinationCode')
            }
            this.onSearch();
        }, 500),
        onCountryChange(countryId) {
            this.districts = []
            this.municipalities = []
            this.places = []
            this.filtersSelected.district = ''
            this.filtersSelected.municipality = ''
            this.filtersSelected.place = ''
            if(countryId) {
                this.viewModel.findDistricts(`CountryId=${countryId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (countryId) ? this.addFilter('CountryId', countryId) : this.removeFilter('CountryId')
            this.onSearch();
        },
        onFindDistrictsResponse(response) {
            this.districts = this.viewModel.mapDistricts(response.data)
        },
        onDistrictChange(districtId) {
            this.municipalities = []
            if (!districtId) this.filtersSelected.municipality = '';
            if (districtId) {
                this.viewModel.findMunicipalities(`DistrictId=${districtId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (districtId) ? this.addFilter('DistrictId', districtId) : this.removeFilter('DistrictId')
            this.onSearch();
        },
        onFindMunicipalitiesResponse(response) {
            this.municipalities = this.viewModel.mapMunicipality(response.data)
        },
        onMunicipalityChange(municipalityId) {
            this.places = []
            if(municipalityId) {
                this.viewModel.findCities(`MunicipalityId=${municipalityId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (municipalityId) ? this.addFilter('MunicipalityId', municipalityId) : this.removeFilter('MunicipalityId')
            this.onSearch();
        },
        onFindCitiesResponse(response) {
            this.places = this.viewModel.mapPlace(response.data)
        },
        onPlaceChange(placeId) {
            (placeId) ? this.addFilter('CityId', placeId) : this.removeFilter('CityId')
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onUploadImport(file) {
            this.modalImport.isLoading = true;
            this.viewModel.import(file);
        },
        onImportResponse(response) {
            this.batchGroups = this.viewModel.getPostalCodesByChunks(
                response.data,
                this.totalRegistersByBatch
            );
            if (this.batchGroups.length > 0) {
                this.viewModel.batchPostalCodesByChunks(
                    this.batchGroups[this.batchPosition]
                );
            } else {
                this.modalImport.isLoading = false;
                this.notifyWarning(response.message);
            }
        },
        async onBatchResponse() {
            this.batchPosition += 1;
            if (this.batchGroups.length <= this.batchPosition) {
                this.batchPosition = 0;
                this.modalImport.isLoading = false;
                this.modalImport.isVisible = false;
                this.notifySuccess("Códigos postales importados.");
                this.onSearch();
            } else {
                this.viewModel.batchPostalCodesByChunks(
                    this.batchGroups[this.batchPosition]
                );
            }
        },
    },
};
