<script>
import ModalOrderComponent from "./ModalOrderComponent";
import ModalOrderCustomerView from './ModalOrderCustomerView'
import CustomerBoxView from '../receptions/boxes/CustomerBoxView'

export default {
  components: {
    ModalOrderCustomerView,
    CustomerBoxView
  },
  extends: ModalOrderComponent,
};
</script>

<template>
  <div>
    <el-dialog
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
      width="70%"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-location-pin-alt" /> Orden de Recolección<span v-if="formData.orderNumber">: {{
          formData.orderNumber }}</span>
      </span>
      <div v-loading="config.isLoading">
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col>
            <el-steps
              :active="stepActive"
              align-center
            >
              <el-step
                title="Cliente"
                description="Tipo de Servicio y Datos de Envío"
              />
              <el-step
                title="Paquetes"
                description="Información de Mercancía"
              />
              <el-step
                title="Finalizar"
                description="Guía de Envío"
              />
            </el-steps>
          </el-col>
        </el-row>
        <div
          v-if="stepActive === 0"
          class="reception-steps-wrapper"
        >
          <el-form
            ref="formDataStep0"
            :model="formData"
            :rules="rules0"
            :label-position="'top'"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="12">
                <h4>Tipo de Servicio</h4>
                <el-row
                  type="flex"
                  class="row-bg "
                  :gutter="20"
                >
                  <el-col
                    :span="24"
                    align="center"
                  >
                    <el-form-item
                      prop="receptionServiceType"
                      class="field-client-type"
                    >
                      <el-radio-group
                        v-model="formData.receptionServiceType"
                        @change="onChangeReceptionServiceType"
                      >
                        <el-radio
                          v-for="item in receptionServiceTypes"
                          :key="item.id"
                          :label="item.id"
                        >
                          <i :class="item.icon" /> {{ item.name }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <h4>Responsable de Pago</h4>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col
                    :span="24"
                    align="center"
                  >
                    <el-form-item
                      prop="payerResponsible"
                      class="field-client-type"
                    >
                      <el-radio-group
                        v-model="formData.payerResponsible"
                        @change="onChangePayerResponsible"
                      >
                        <el-radio
                          v-for="item in payerResponsibles"
                          :key="item.id"
                          :label="item.id"
                        >
                          <i :class="item.icon" /> {{ item.name }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
              style="margin-top: 10px;"
            >
              <el-col :span="24">
                <el-collapse accordion>
                  <el-collapse-item
                    v-loading="isSenderBoxLoading"
                    title="REMITENTE / DESTINATARIO"
                    name="1"
                  >
                    <el-row
                      class="row-bg"
                      :gutter="20"
                      style="margin-top: 10px;"
                    >
                      <el-col :span="24">
                        <el-alert
                          title="Se requiere registro de clientes como Remitentes y Destinatario."
                          type="warning"
                          :closable="false"
                          show-icon
                        />
                      </el-col>
                    </el-row>
                    <br>
                    <el-row
                      type="flex"
                      class="row-bg"
                      :gutter="20"
                    >
                      <el-col :span="12">
                        <CustomerBoxView
                          :form-data="formData.sender"
                          prop-name="sender"
                          title="Remitente"
                          :reception="formData"
                        />
                      </el-col>
                      <el-col :span="12">
                        <CustomerBoxView
                          :form-data="formData.receiver"
                          prop-name="receiver"
                          title="Destinatario"
                          :reception="formData"
                        />
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                  <el-collapse-item
                    title="DATOS FISCALES"
                    name="2"
                  >
                    <el-tabs
                      v-loading="isFiscalLoading"
                      type="border-card"
                    >
                      <el-tab-pane label="Información de Facturación">
                        <el-row
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col
                            :span="24"
                            align="right"
                          >
                            <el-alert
                              title="Buscar y seleccionar el cliente previamente registrado para poder facturar. Si el cliente no requiere factura, seleccionar PUBLICO EN GENERAl."
                              type="warning"
                              :closable="false"
                              show-icon
                            />
                          </el-col>
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col :span="24">
                            <el-form-item
                              label="Opciones de Facturación"
                              prop="orderFiscal.type"
                            >
                              <el-radio-group
                                v-model="formData.orderFiscal.type"
                                size="small"
                                @change="onFiscalOptionChange"
                              >
                                <el-radio-button label="none">
                                  Sin factura
                                </el-radio-button>
                                <el-radio-button label="my-account">
                                  Facturar
                                </el-radio-button>
                              </el-radio-group>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row
                          v-if="formData.orderFiscal.type && formData.orderFiscal.type !== 'none'"
                          type="flex"
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col :span="24">
                            <el-form-item
                              label="Cliente"
                              prop="orderFiscal.customer"
                            >
                              <el-select
                                v-model="formData.orderFiscal.customer"
                                placeholder="Seleccionar"
                                filterable
                                clearable
                                debounce
                                value-key="id"
                                remote
                                size="medium"
                                :remote-method="onFindCustomers"
                                autocomplete="off"
                                @change="onChangeCustomer"
                              >
                                <el-option
                                  v-for="item in customers"
                                  :key="item.id"
                                  :label="item.description"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col :span="24">
                            <el-row
                              v-if="formData.orderFiscal.type"
                              class="row-bg"
                              :gutter="20"
                            >
                              <el-col
                                :xs="24"
                                :sm="12"
                              >
                                <el-form-item
                                  label="Razón Social"
                                  prop="orderFiscal.fiscalName"
                                >
                                  <el-input
                                    v-model="formData.orderFiscal.fiscalName"
                                    autocomplete="off"
                                    size="medium"
                                    :disabled="true"
                                    class="fiscal-data__input-display"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="24"
                                :sm="5"
                              >
                                <el-form-item
                                  label="RFC"
                                  prop="orderFiscal.fiscalCode"
                                >
                                  <el-input
                                    v-model="formData.orderFiscal.fiscalCode"
                                    autocomplete="off"
                                    size="medium"
                                    :disabled="true"
                                    class="fiscal-data__input-display"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="24"
                                :sm="7"
                              >
                                <el-form-item
                                  label="Correo Electrónico"
                                  prop="orderFiscal.fiscalEmail"
                                >
                                  <el-input
                                    v-model="formData.orderFiscal.fiscalEmail"
                                    autocomplete="off"
                                    size="medium"
                                    :disabled="true"
                                    class="fiscal-data__input-display"
                                  />
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row
                              v-if="formData.orderFiscal.type"
                              class="row-bg"
                              :gutter="20"
                            >
                              <el-col :span="16">
                                <el-form-item
                                  label="Regimen Fiscal"
                                  prop="orderFiscal.fiscalRegime"
                                >
                                  <el-select
                                    v-model="formData.orderFiscal.fiscalRegime"
                                    placeholder="Seleccionar"
                                    filterable
                                    clearable
                                    debounce
                                    value-key="id"
                                    size="large"
                                    disabled
                                    class="fiscal-data__input-display"
                                  >
                                    <el-option
                                      v-for="item in taxRegimes"
                                      :key="item.id"
                                      :label="item.getFullDescription()"
                                      :value="item"
                                    />
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="8">
                                <el-form-item
                                  label="Código Postal"
                                  prop="orderFiscal.postalCode"
                                >
                                  <el-select
                                    v-model="formData.orderFiscal.postalCode"
                                    placeholder="Seleccionar"
                                    value-key="id"
                                    size="medium"
                                    disabled
                                    class="fiscal-data__input-display"
                                  >
                                    <el-option
                                      v-for="item in postalCodes"
                                      :key="item.id"
                                      :label="item.code"
                                      :value="item"
                                    />
                                  </el-select>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-row>
                        <el-row v-if="formData.orderFiscal.type" type="flex" class="row-bg" :gutter="20">
                            <el-col :span="7">
                              <el-form-item label="Uso de CFDI" prop="orderFiscal.billUsingType">
                                <el-select v-model="formData.orderFiscal.billUsingType" placeholder="Seleccionar"
                                  clearable debounce value-key="id" size="medium" filterable>
                                  <el-option v-for="item in billUsingTypes" :key="item.id" :label="item.getFullName()"
                                    :value="item" />
                                </el-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="7">
                              <el-form-item label="Metodo de Pago" prop="orderFiscal.paymentMethod">
                                <el-select v-model="formData.orderFiscal.paymentMethod" placeholder="Seleccionar" clearable
                                  debounce value-key="id" size="medium">
                                  <el-option v-for="item in paymentMethods" :key="item.id" :label="item.getFullName()"
                                    :value="item" />
                                </el-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="7" align="center">
                              <el-form-item label="¿Timbrado Automático?">
                                <el-checkbox v-model="formData.isAutoDigitalInvoice"> Si </el-checkbox>
                              </el-form-item>
                            </el-col>
                          </el-row>
                      </el-tab-pane>
                      <!-- <el-tab-pane label="Dirección">
                      <address-box-view v-if="formData.orderFiscal.location" :form-data="formData.orderFiscal.location"
                        orientation="vertical" prop-name="orderFiscal.location" />
                    </el-tab-pane>-->
                    </el-tabs>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="stepActive === 1">
          <el-form
            ref="formDataStep1"
            :model="formData"
            :rules="rules1"
            :label-position="'top'"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-form-item
                  label="Flete (Salidas/Viajes)"
                  prop="serviceCost"
                >
                  <el-select
                    v-model="formData.serviceCost"
                    placeholder="Seleccionar"
                    filterable
                    clearable
                    value-key="id"
                    size="medium"
                    @change="onChangeServiceCost"
                  >
                    <el-option
                      v-for="item in serviceCosts"
                      :key="item.id"
                      :label="onFormatServiceCost(item)"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-tabs type="border-card">
              <el-tab-pane label="Paquetes">
                <el-row
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col
                    :span="24"
                    align="right"
                  >
                    <el-button
                      type="primary"
                      size="medium"
                      @click="onAddPackage"
                    >
                      <i class="fas fa-plus-circle" /> Agregar Paquete
                    </el-button>
                  </el-col>
                </el-row>
                <el-row
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <el-form-item
                      prop="traces"
                      align="center"
                    >
                      <el-table
                        :data="formData.traces"
                        style="width: 100%"
                        default-expand-all
                      >
                        <el-table-column
                          type="index"
                          width="20"
                        />
                        <el-table-column type="expand">
                          <template slot-scope="props">
                            <el-row
                              class="row-bg"
                              :gutter="20"
                            >
                              <el-col
                                :span="24"
                                align="right"
                              >
                                <el-alert
                                  title="Las unidades de medida como Largo, Alto y Ancho son en centimetros(cm). La unidad de medidad para peso en Kilogramos."
                                  type="warning"
                                  :closable="false"
                                />
                              </el-col>
                            </el-row>
                            <el-row
                              class="row-bg"
                              :gutter="20"
                            >
                              <el-col
                                :xs="12"
                                :sm="4"
                                align="center"
                              >
                                <el-form-item
                                  label="Cantidad"
                                  :prop="'traces.' + props.$index + '.quantity'"
                                  :rules="[
                                    {
                                      required: true,
                                      message: 'Cantidad requerido.',
                                      trigger: 'blur, change',
                                    },
                                    {
                                      validator: onPackageQuantityValidator,
                                      trigger: 'blur, change',
                                    }
                                  ]"
                                >
                                  <el-input-number
                                    v-model="props.row.quantity"
                                    size="small"
                                    :min="0"
                                    controls-position="right"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="12"
                                :sm="4"
                                align="center"
                              >
                                <el-form-item
                                  label="Largo (cm)"
                                  :prop="'traces.' + props.$index + '.length'"
                                  :rules="[
                                    {
                                      required: true,
                                      message: 'Largo requerido.',
                                      trigger: 'blur, change',
                                    },
                                  ]"
                                >
                                  <el-input-number
                                    v-model="props.row.length"
                                    size="small"
                                    :min="0"
                                    controls-position="right"
                                    :precision="2"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="12"
                                :sm="4"
                                align="center"
                              >
                                <el-form-item
                                  label="Alto (cm)"
                                  :prop="'traces.' + props.$index + '.height'"
                                  :rules="[
                                    {
                                      required: true,
                                      message: 'Alto requerido.',
                                      trigger: 'blur, change',
                                    },
                                  ]"
                                >
                                  <el-input-number
                                    v-model="props.row.height"
                                    size="small"
                                    :min="0"
                                    controls-position="right"
                                    :precision="2"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="12"
                                :sm="4"
                                align="center"
                              >
                                <el-form-item
                                  label="Ancho (cm)"
                                  :prop="'traces.' + props.$index + '.width'"
                                  :rules="[
                                    {
                                      required: true,
                                      message: 'Ancho requerido.',
                                      trigger: 'blur, change',
                                    },
                                  ]"
                                >
                                  <el-input-number
                                    v-model="props.row.width"
                                    size="small"
                                    :min="0"
                                    controls-position="right"
                                    :precision="2"
                                  />
                                </el-form-item>
                              </el-col>
                              <el-col
                                :xs="12"
                                :sm="4"
                                align="center"
                              >
                                <el-form-item
                                  label="Peso (kg)"
                                  :prop="'traces.' + props.$index + '.weight'"
                                  :rules="[
                                    {
                                      required: true,
                                      message: 'Peso requerido.',
                                      trigger: 'blur, change',
                                    },
                                  ]"
                                >
                                  <el-input-number
                                    v-model="props.row.weight"
                                    size="small"
                                    :min="0"
                                    controls-position="right"
                                    :precision="2"
                                  />
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>

                        <el-table-column
                          prop="description"
                          label="Descripción"
                        >
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="'traces.' + scope.$index + '.description'"
                              :rules="[
                                {
                                  required: true,
                                  message: `Descripción de Paquete ${scope.$index + 1} requerido.`,
                                  trigger: 'blur',
                                },
                              ]"
                            >
                              <el-input
                                v-model="scope.row.description"
                                size="small"
                                type="textarea"
                              />
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Opciones"
                          width="100"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="default"
                              icon="el-icon-delete-solid"
                              size="small"
                              @click="onDeletePackage(scope.$index)"
                            />
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col
            align="center"
            class="reception-steps-navigation"
          >
            <el-button
              v-if="!isFirstStep() && stepActive !== 2"
              type="primary"
              size="small"
              @click="onPrevStep"
            >
              Anterior
            </el-button>
            <el-button
              v-if="stepActive !== 2"
              type="primary"
              size="small"
              @click="onNextStep"
            >
              Siguiente
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div
        v-if="false"
        v-loading="config.isLoading"
      >
        <el-row>
          <el-col :span="24">
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
              style="padding-top:15px;"
            >
              <el-col
                :span="6"
                align="center"
              >
                <h4>GUÍA DE ENVÍO</h4>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h3 class="reception-resume-header">
                      <i class="uil-qrcode-scan" />
                      {{ formData.trackingNumber }}
                    </h3>
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                :span="6"
                align="center"
              >
                <h4>FECHA DE RECEPCIÓN</h4>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h3 class="reception-resume-header">
                      <i class="uil-calendar-alt" />
                      {{ formData.receptionDate | formatDate }}
                    </h3>
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                :span="6"
                align="center"
              >
                <h4>TIPO DE RECEPCIÓN</h4>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h3 class="reception-resume-header">
                      {{ onGetNameReceptionType(formData.receptionType) }}
                    </h3>
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                :span="6"
                align="center"
              >
                <h4>SEGUIMIENTO</h4>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h3 class="reception-resume-header">
                      <i :class="onGetIconTrackingStatus(formData.status)" />
                      {{ onGetNameTrackingStatus(formData.status) }}
                    </h3>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-tabs v-model="tabActive">
              <el-tab-pane
                label="Detalles"
                name="reception-details"
              >
                <el-row
                  type="flex"
                  class="row-bg reception-info-wrapper"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <el-row
                      type="flex"
                      class="row-bg reception-info-details"
                      :gutter="20"
                    >
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix"
                          >
                            <h5>
                              <i class="uil-map-marker" /> Remitente |
                              {{ formData.sender.name }}
                            </h5>
                          </div>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Cliente:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>
                                {{ formData.sender.name }}
                              </p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Dirección:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>
                                {{
                                  formData.getDescriptionFullAddressByContractKey("sender")
                                }}
                              </p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Nombre:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>{{ formData.sender.name }}</p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="8">
                              <h5 class="field-details">
                                Teléfono:
                              </h5>
                            </el-col>
                            <el-col :span="16">
                              <h5 class="field-details">
                                Correo:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="8">
                              <p>{{ formData.sender.phone }}</p>
                            </el-col>
                            <el-col :span="16">
                              <p>{{ formData.sender.email }}</p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Referencia:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>{{ formData.sender.observation }}</p>
                            </el-col>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix"
                          >
                            <h5>
                              <i class="uil-map-marker" /> Destinatario |
                              {{ formData.receiver.name }}
                            </h5>
                          </div>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Cliente:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>
                                {{ formData.receiver.name }}
                              </p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Dirección:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>
                                {{
                                  formData.getDescriptionFullAddressByContractKey("receiver")
                                }}
                              </p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Nombre:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>{{ formData.receiver.name }}</p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="8">
                              <h5 class="field-details">
                                Teléfono:
                              </h5>
                            </el-col>
                            <el-col :span="16">
                              <h5 class="field-details">
                                Correo:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="8">
                              <p>{{ formData.receiver.phone }}</p>
                            </el-col>
                            <el-col :span="16">
                              <p>{{ formData.receiver.email }}</p>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <h5 class="field-details">
                                Referencia:
                              </h5>
                            </el-col>
                          </el-row>
                          <el-row
                            type="flex"
                            class="row-bg"
                            :gutter="20"
                          >
                            <el-col :span="24">
                              <p>{{ formData.receiver.observation }}</p>
                            </el-col>
                          </el-row>
                        </el-card>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane
                label="Paquetes"
                name="packages"
              >
                <el-table
                  :data="formData.traces"
                  show-summary
                  sum-text="Total"
                  :summary-method="getSummaries"
                  style="width: 100%"
                >
                  <el-table-column
                    label="Indice"
                    width="100"
                    align="center"
                  >
                    <template #default="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="Descripción Mercancia"
                  />
                  <el-table-column
                    label="Tipo Producto"
                    width="150"
                  >
                    <template #default="scope">
                      <div v-if="scope.row.productType">
                        {{ scope.row.productType.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="U.M."
                    width="100"
                  >
                    <template #default="scope">
                      <div v-if="scope.row.measureUnit">
                        {{ scope.row.measureUnit.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    label="Cantidad"
                    width="100"
                    align="center"
                  />
                  <el-table-column
                    prop="weight"
                    label="Peso Fis. KG"
                    width="110"
                    align="center"
                  />
                  <el-table-column
                    prop="volumetricWeight"
                    label="Peso Vol. KG"
                    width="110"
                    align="center"
                  />
                  <el-table-column
                    label="Dimensiones"
                    width="200"
                  >
                    <template #default="scope">
                      {{ scope.row.length }}cm x {{ scope.row.height }}cm x {{ scope.row.height }}cm
                    </template>
                  </el-table-column>
                  <!--               <el-table-column
                prop="subtotal"
                label="Costo"
                width="140"
                align="center"
              >
                <template #default="scope">
                  {{ scope.row.subtotal | formatMXNCurrency }}
                </template>
              </el-table-column> -->
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="onCancel"
        >Cancelar</el-button>
        <!-- <el-button type="primary" size="small" :disabled="!onCanSaveOrUpdate()"
          @click="onSubmitForm('formData')">Guardar</el-button>-->
      </span>
    </el-dialog>
    <ModalOrderCustomerView
      v-if="modalCustomerConfig.isVisible"
      :form-data="itemCustomer"
      :config="modalCustomerConfig"
    />
  </div>
</template>
