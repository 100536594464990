<script>
import ModalReceptionPackageComponent from "./ModalReceptionPackageComponent";

export default {
  extends: ModalReceptionPackageComponent,
};
</script>

<template>
  <el-dialog
    width="35%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-clipboard-alt" /> Paquete
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              ref="description"
              v-model="formData.description"
              autocomplete="off"
              autofocus="true"
              size="medium"
              type="textarea"
              :rows="2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="Cantidad"
            prop="quantity"
          >
            <el-input-number
              ref="quantity"
              v-model="formData.quantity"
              autocomplete="off"
              autofocus="true"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Max. Peso Total (kg)"
            prop="weight"
          >
            <el-input-number
              ref="weight"
              v-model="formData.weight"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :precision="3"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="8">
          <el-form-item
            label="Max. Longitud (cm)"
            prop="length"
          >
            <el-input-number
              ref="length"
              v-model="formData.length"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :precision="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Máx. Ancho (cm)"
            prop="width"
          >
            <el-input-number
              ref="width"
              v-model="formData.width"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :precision="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Máx. Alto (cm)"
            prop="height"
          >
            <el-input-number
              ref="height"
              v-model="formData.height"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :precision="3"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
