import BaseViewModel from "../../infraestructure/BaseViewModel";
//import Customer from "./Customer";
import CustomerLocation from "./CustomerLocation";
import Neighborhood from "../neighborhoods/Neighborhood";

export default class CustomerLocationViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    //#region MODULO CLIENTES

    create(data) {
        return new CustomerLocation(data, "create");
    }

    edit(data, customerId) {
        let item = new CustomerLocation(data, "update");
        item.customerId = customerId;
        return item;
    }

    save(data) {
        if (data.state === "create") {
            this.api.customerLocation
                .create(data.customerId, data.toSendCustomerLocation())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.customerLocation
                .update(data.customerId, data.id, data.toSendCustomerLocation())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(customerId, id) {
        this.api.customerLocation
            .delete(customerId, id)
            .then((response) => this.view.onDeleteResponseCustomerLocation(response.data, customerId))
            .catch(this.view.onError);
    }

    findCollection(customerId, filters) {
        this.api.customerLocation
            .find(customerId, this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError);
    }

    findItem(customerId, id) {
        this.api.customerLocation
            .findOne(customerId, id)
            .then((response) => this.view.onFindItemResponseCustomerLocation(response.data, customerId))
            .catch(this.view.onError);
    }
    findItemLocation(customerId, id) {
        this.api.customerLocation
            .findOne(id)
            .then((response) => this.view.onSearchResponse(response.data))
            .catch(this.view.onError);
    }
    bindForm(formData) {
        if (formData.state === "update") {
            this.view.destinations = formData.place ? [formData.place] : [];
            this.view.neighborhoods = formData.neighborhood ? [formData.neighborhood] : [];
            this.view.destinations = formData.city ? [formData.city] : [];
        }
    }

    import(file) {
        this.api.customerLocation
            .import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    mapCollection(collection) {
        return collection.map((item) => new CustomerLocation(item));
    }

    //#endregion

    //#region UBICACIÓN DE CLIENTES

    /*   createCustomerLocation(data) {
      //console.log(data)
      return new CustomerLocation(data, "create");
    }
  
    editCustomerLocation(data) {
      return new CustomerLocation(data.toSend(), "update");
    }
  
    deleteCustomerLocation(collection, index) {
      collection.splice(index, 1);
    }
  
    upsertCustomerLocation(collection, data, customerLocationSelectedIndex) {
      if (data.state === "update") {
        this.view.$set(collection, customerLocationSelectedIndex, data);
      } else {
        collection.push(data);
      }
      customerLocationSelectedIndex = null;
    } */

    findDestinations(criteria) {
        this.api.destinations
            .find(criteria)
            .then((response) => this.view.onFindDestinationsResponse(response.data))
            .catch(this.view.onError);
    }

    findNeighborhoods(criteria) {
        this.api.neighborhoods
            .find(criteria)
            .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
            .catch(this.view.onError);
    }

    addNeighborhood(data) {
        return new Neighborhood(data);
    }

    createNeighborhood(data) {
        this.api.neighborhoods
            .create(data.toSend())
            .then((response) => {
                return this.api.neighborhoods.findOne(response.data.id);
            })
            .then((response) => this.view.onCreateNeighborhoodResponse(response.data))
            .catch(this.view.onError);
    }

    mapNeighborhoods(collection) {
        return collection.map((item) => new Neighborhood(item));
    }

    //#endregion

    export() {
        this.api.customerLocation
            .exportToExcel(this.view.$store.getters["admin/getUserActive"].id)
            .then((response) => this.view.onExportExcelResponse(response.data))
            .catch(this.view.onError);
    }
}
