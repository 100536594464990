import { _ } from "core-js";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import CustomerAccount from "../customerAccount/CustomerAccount";
import CustomerVerificationCode from "./CustomerVerificationCode";

export default class CustomerVerificationCodeViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  //#region MODULO CLIENTES

  create(data) {
    return new CustomerVerificationCode(data, "create");
  }

  edit(data, customerId) {
    let item = new CustomerVerificationCode(data, "update");
    item.customerId = customerId
    return item;
  }

  save(data) {
    if (data.state === "create") {
      this.api.customerVerificationCodes
        .create(data.customerId, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.customerVerificationCodes
        .update(data.customerId, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(customerId, id) {
    this.api.customerVerificationCodes
      .delete(customerId, id)
      .then((response) => this.view.onDeleteResponseCustomerVerificationCode(response.data, customerId))
      .catch(this.view.onError);
  }

  findCollection(customerId, filters) {
    this.api.customerVerificationCodes
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(customerId, id) {
    this.api.customerVerificationCodes
      .findOne(customerId, id)
      .then((response) =>
        this.view.onFindItemResponseCustomerVerificationCode(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  async bindListAsync(customerAccount) {
    try {
      const customerResponse = await this.api.customers.getByUserAccount(`Account=${customerAccount}`)
      this.view.customerId = customerResponse.data.data
      this.view.onSearch(this.view.customerId)
    } catch (error) {
      this.view.onError(error)
    }
  }

  bindForm(formData) {
    if (formData.state === "update") {
      if(formData.accountResponsible) this.view.responsibles = [new CustomerAccount(formData.accountResponsible)]
    }
  }

  import(file) {
    this.api.customerVerificationCodes
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new CustomerVerificationCode(item));
  }

  generateVerificationCode(customerId) {
    this.api.customerVerificationCodes.generateVerificationCode(customerId)
      .then((response) => this.view.onGenerateVerificationResponse(response.data))
      .catch(this.view.onGenerateVerificationError)
  }

  getVerificationCodeQr(customerId, id) {
    this.api.customerVerificationCodes.getVerificationCodeQr(customerId, id)
      .then((response) => this.view.onGetVerificationCodeQrResponse(response.data))
      .catch(this.view.onGetVerificationCodeQrError)
  }

  findResponsibles(customerId, criteria) {
    this.api.customersAccount
      .find(customerId, criteria)
      .then((response) => this.view.onFindResponsiblesResponse(response.data))
      .catch(this.view.onError);
  }

  mapResponsibles(collection) {
    return _.map(collection, (item) => new CustomerAccount(item))
  }
}