import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import MunicipalityViewModel from '../../core/features/municipalities/MunicipalityViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Municipality',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                country: '',
                district: '',
                status: '',
            },
            countries: [],
            districts: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR MUNICIPIOS',
                onUploadImport: 'on-upload-import-municipalities',
                onCancelImport: 'on-cancel-import-municipalities'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-municipality', this.onSearch)
        this.onMountedEvent('on-upload-import-municipalities', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-municipalities', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-municipality', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-municipalities', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-municipalities', this.onCancelImport);
    },
    created() {
        this.viewModel = new MunicipalityViewModel(this)
        this.onActive()
    },

    methods: {
        onActive() {
            this.viewModel.bindList();
        },
        onSelectMunicipality() { },
        onCreate() {
            this.item = this.viewModel.create({ isActive: true })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        onCountryChange(countryId) {
            this.districts = []
            this.filtersSelected.district = ''
            if(countryId) {
                this.viewModel.findDistricts(`CountryId=${countryId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (countryId) ? this.addFilter('CountryId', countryId) : this.removeFilter('CountryId')
            this.onSearch();
        },
        onFindDistrictsResponse(response) {
            this.districts = this.viewModel.mapDistricts(response.data)
        },
        onDistrictChange(districtId) {
            (districtId) ? this.addFilter('DistrictId', districtId) : this.removeFilter('DistrictId')
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}