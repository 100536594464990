import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      receptionTypes: ReceptionsViewModel.receptionTypes,
      itemReceptionPackage: null,
      serviceCosts: [],
      modalReceptionPackageConfig: {
        isVisible: false,
        isLoading: false,
      },
      totalOverWeight: 0,
      weightCostConfig: null,
      isTableLoading: false,
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  mounted() {
    this.onMountedEvent(
      "on-submited-reception-packages",
      this.onUpsertReceptionPackage
    );
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "on-submited-reception-packages",
      this.onUpsertReceptionPackage
    );
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onActive() {
      this.viewModel.bindStepTwoFormView(this.formData);
    },
    onUpsertReceptionPackage(data) {
      this.isTableLoading = true;
      setTimeout(() => {
        this.viewModel.upsertReceptionPackage(this.formData.receptionPackages, data, this.receptionPackageSelectedIndex);
        this.formData.consignmentNote.hasDangerousMaterial = this.viewModel.hasDangerousMaterials(this.formData.receptionPackages)
        this.onCalculatePackages()
        this.isTableLoading = false
      }, 400);
    },
    onChangeServiceCost(serviceCost) {
      if (serviceCost) {
        let serviceDeliveryPrice = this.viewModel.getServiceDeliveryPrice(this.formData, serviceCost.amount);
        this.$set(this.formData, "serviceDeliveryPrice", serviceDeliveryPrice);
        this.onCalculatePackages()
      } else {
        this.formData.serviceDeliveryPrice = 0;
      }
    },
    onFormatServiceCost(serviceCost) {
      return this.viewModel.formatServiceCost(
        serviceCost,
        this.formData.receptionServiceType,
        this.formData.serviceDeliveryPrice
      );
    },
    onCreateReceptionPackage() {
      try {
        if (!this.viewModel.isReceptionTypeSelected(this.formData))
          throw new Error("Tipo de Recepción requerido");

        if (!this.viewModel.isServiceCostSelected(this.formData))
          throw new Error("Flete requerido");

        this.itemReceptionPackage = this.viewModel.createReceptionPackage({
          isActive: true,
          quantity: 1,
          weight: 1,
          width: 1,
          height: 1,
          length: 1,
          serviceDeliveryPrice: this.formData.serviceDeliveryPrice,
          merchandiseValue: 1,
        });
        this.modalReceptionPackageConfig.isVisible = true;
      } catch (error) {
        this.notifyWarning(error.message);
      }
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    onChangeOptionReceptionPackage(event) {
      if (event.command === "edit") this.onEditReceptionPackage(event);
      if (event.command === "delete") this.onDeleteReceptionPackage(event);
    },
    onEditReceptionPackage(event) {
      this.isTableLoading = true;
      setTimeout(() => {
        this.itemReceptionPackage = this.viewModel.editReceptionPackage(event.item);
        this.itemReceptionPackage.serviceDeliveryPrice = this.formData.serviceDeliveryPrice;
        this.receptionPackageSelectedIndex = event.index;
        this.modalReceptionPackageConfig.isVisible = true;
        this.isTableLoading = false;
      }, 400);
    },
    onDeleteReceptionPackage(event) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isTableLoading = true;
          setTimeout(() => {
            this.viewModel.deleteReceptionPackage(
              this.formData.receptionPackages,
              event.index
            );
            this.isTableLoading = false;
          }, 400);
        })
        .catch(() => {});
    },
    onCalculatePackages() {
      this.formData.receptionPackages = this.viewModel.calculatePackages(this.formData.receptionPackages, this.weightCostConfig);
      this.formData.totalOverWeight = this.viewModel.calculateTotalOverWeight(this.formData.receptionPackages);
      this.$forceUpdate();
    }
  },
};
