<script>
import ModalSolvencyComponent from "./ModalSolvencyComponent";

export default {
  extends: ModalSolvencyComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-solvency"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-ticket" /> Créditos y Prepagos
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              v-model="formData.code"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="Tipo"
            prop="type"
          >
            <el-select
              v-model="formData.type"
              placeholder="Seleccionar"
              clearable
              value-key="id"
              size="medium"
            >
              <el-option
                v-for="item in solvencyTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Cantidad de Guías"
            prop="quantity"
          >
            <el-input-number
              v-model="formData.quantity"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="isPrepaid()"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Plan de Pago"
            prop="customer"
          >
            <el-select
              v-model="formData.prepaidPlan"
              placeholder="Seleccionar"
              filterable
              clearable
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindPrepaidPlans"
              @change="onChangePrepaidPlan"
            >
              <el-option
                v-for="item in prepaidPlans"
                :key="item.id"
                :label="item.description"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      />
      
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Cliente"
            prop="customer"
          >
            <el-select
              v-model="formData.customer"
              placeholder="Seleccionar"
              filterable
              clearable
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindCustomers"
            >
              <el-option
                v-for="item in customers"
                :key="item.id"
                :label="item.description"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
