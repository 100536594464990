export default class ReceptionTraceStatus {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            traceStatus: this.traceStatus || 0,
            traceStatusReasonId: (this.traceStatusReason) ? this.traceStatusReason.id : null,
            comments: this.comments,
            receptionId: this.receptionId
        }
    }

}