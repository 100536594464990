import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import EmployeesViewModel from '../../core/features/employees/EmployeesViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Employee',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                employeeCode: '',
                name: '',
                userName: '',
                email: '',
                roleName: '',
                status: '',
            },
            roles:[],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR EMPLEADOS',
                onUploadImport: 'on-upload-import-employees',
                onCancelImport: 'on-cancel-import-employees'
            }

        }
    },
    mounted() {
        this.onMountedEvent('on-submited-employee', this.onSearch);
        this.onMountedEvent('on-upload-import-employees', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-employees', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-employee', this.onSearch);
        this.onBeforeDestroyEvent('on-upload-import-employees', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-employees', this.onCancelImport);
    },
    created() {
        this.viewModel = new EmployeesViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
            this.onSearch();
        },
        onSelectUser() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
                address: {}
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByEmployeeCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('EmployeeCode', text)
            } else {
                this.removeFilter('EmployeeCode')
            }
            this.onSearch();
        }, 500),
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByUserName: _.debounce(function (text) {
            if (text) {
                this.addFilter('UserName', text)
            } else {
                this.removeFilter('UserName')
            }
            this.onSearch();
        }, 500),
        onSearchByEmail: _.debounce(function (text) {
            if (text) {
                this.addFilter('Email', text)
            } else {
                this.removeFilter('Email')
            }
            this.onSearch();
        }, 500),
        onRoleChange(roleName) {
            (roleName) ? this.addFilter('RoleName', roleName) : this.removeFilter('RoleName')
            this.onSearch()
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },

       
    }
}
