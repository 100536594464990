import BaseModule from '../infraestructure/BaseModule'
import ReceptionsViewModel from '../../core/features/receptions/ReceptionsViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            tabActive: 'receptions'
        }
    },
    created() {
        this.viewModel = new ReceptionsViewModel(this)
    },
    methods: {
        onActive() {
            
        }
    }
}