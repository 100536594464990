import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Shipment from "./Shipment";
import moment from "moment";
import User from "../users/User";
import Vehicle from "../vehicles/Vehicle";
import { MXN } from "../../helpers/MoneyHelper";
import Destination from "../destinations/Destination";

export default class ShipmentViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    static shipmentStatuses = [
        {
            id: 1,
            name: "En Espera de Carga",
        },
        {
            id: 2,
            name: "Cargando",
        },
        {
            id: 3,
            name: "En Ruta",
        },
        {
            id: 4,
            name: "Finalizado",
        },
    ];
    static UpdateState = {
        Update: 0,
        Add: 1,
        Remove: 2,
    };

    create(data) {
        return new Shipment(data, "create");
    }

    createItemGuide(data) {
        return data;
    }

    edit(data) {
        return new Shipment(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.shipments
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.shipments
                .update(data.id, data.toSendUpdate())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.shipments
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.shipments
            .find(this.getQueryParameters(filters))
            .then((response) =>
                this.view.onSearchResponse(response.data, response.headers)
            )
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.shipments
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            shipmentNumber: '',
            beginingDate: '',
            destinyName: '',
            responsible: '',
            operator: '',
            shipmentStatus: '',
        };

        this.view.removeFilter('ShipmentNumber');
        this.view.removeFilter('BeginningDate');
        this.view.removeFilter('EndingDate');
        this.view.removeFilter('DestinationPlaceId');
        this.view.removeFilter('ResponsibleId');
        this.view.removeFilter('OperatorId');
        this.view.removeFilter('ShipmentStatus');
        this.view.onSearch();
    }

    bindList() {
        this.api.destinations.find(`Skip=0&Limit=1000000&IsDestination=true&IsActive=true`)
            .then((response) => {
                this.view.destinations = this.mapDestinations(response.data.data)
                return this.api.users.find(`skip=0&limit=50000&IsActive=true`);
            })
            .then((response) => {
                this.view.operators = this.mapUsers(response.data.data)
                this.view.responsibles = this.mapUsers(response.data.data)
                setTimeout(() => {
                    this.view.isListLoading = false;
                    this.view.onSearch()
                }, 400);
            })
            .catch(this.view.onError);
    }

    findDestinations(criteria) {
        this.api.destinations
            .find(criteria)
            .then((response) => this.view.onFindDestinationsResponse(response.data))
            .catch(this.view.onError);
    }

    findReceptionsByDestinations(destinations) {
        this.api.receptions
            .getByDestionations(destinations)
            .then((response) =>
                this.view.onFindReceptionDestinationsResponse(response.data)
            )
            .catch(this.view.onError);
    }

    bindShipmentReceptions(currentCollection, newCollection) {
        currentCollection.forEach((shipment) => {
            let currentShipmentReception = newCollection.find((item) => item.destination.id == shipment.destination.id)
            if (currentShipmentReception) {
                shipment.receptions = currentShipmentReception.receptions
            }
        })

        /* const currentMap = new Map(
            currentCollection.map((item) => [item.destination.id, item])
        );
        let collection = _.isEmpty(newCollection)
            ? currentCollection
            : newCollection;
        for (const newShipmentReception of collection) {
            const existenceShipmentReception = currentMap.get(
                newShipmentReception.destination.id
            );
            if (!existenceShipmentReception) {
                this.setReceptionsState(
                    newShipmentReception,
                    ShipmentViewModel.UpdateState.Add
                );
                currentCollection.push(newShipmentReception);
            } else if (
                newShipmentReception.updateState == ShipmentViewModel.UpdateState.Remove
            ) {
                this.setReceptionsState(
                    newShipmentReception,
                    ShipmentViewModel.UpdateState.Update
                );
            }
        }*/
    }

    setReceptionsState(shipmentReception, updateState) {
        shipmentReception.updateState = updateState;
        shipmentReception.receptions.forEach(
            (item) => (item.updateState = updateState)
        );
    }

    findvehicles(criteria) {
        this.api.vehicles
            .find(criteria)
            .then((response) => this.view.onFindVehiclesResponse(response.data))
            .catch(this.view.onError);
    }

    findOperator(criteria) {
        this.api.users
            .find(criteria)
            .then((response) => this.view.onFindOperatorResponse(response.data))
            .catch(this.view.onError);
    }

    findResponsible(criteria) {
        this.api.users
            .find(criteria)
            .then((response) => this.view.onFindResponsibleResponse(response.data))
            .catch(this.view.onError);
    }

    findReceiver(criteria) {
        this.api.users
            .find(criteria)
            .then((response) => this.view.onFindonReceiversResponse(response.data))
            .catch(this.view.onError);
    }

    /**
     * Metodo para mapear el formulario principal
     * @param {*} formData
     */
    bindForm(formData) {
        this.api.users
            .getUserCurrentApi()
            .then((response) => {
                this.view.responsibles = [new User(response.data)];
                return this.api.vehicles.find(`Skip=0&Limit=100000&IsActive=true`);
            })
            .then((response) => {
                this.view.vehicles = this.mapVehicles(response.data.data);
                if (formData.state === "create") {
                    formData.responsible = this.view.responsibles[0];
                } else {
                    this.view.operators = [new User(formData.operator)];
                    this.view.receivers = formData.receiverEmployee
                        ? [new User(formData.receiverEmployee)]
                        : [];
                    formData.destination = this.mapShipmentReceptionsToDestinations(
                        formData.shipmentReceptions
                    );
                    this.view.destinations = formData.destination;
                }
            })
            .catch(this.view.onError);
    }

    mapShipmentReceptionsToDestinations(collection) {
        return _.map(collection, (item) => item.destination);
    }

    findItemTrackingNumber(trackingNumber) {
        this.api.trackings
            .findTrackingNumber(trackingNumber)
            .then((response) =>
                this.view.onFindItemTrackingNumberResponse(response.data)
            )
            .catch(this.view.onError);
    }

    findItemReceptionNumber(receptionNumber, receptionsUpdate, destinationId) {
        this.api.receptions
            .getReceptionNumber(receptionNumber, destinationId)
            .then((response) => {
                var result = _.concat(
                    response.data.data,
                    _.filter(receptionsUpdate, { state: 0 })
                );
                this.view.onFindItemReceptionNumberResponse({
                    data: result,
                });
            })
            .catch(this.view.onError);
    }

    onUpsertShipmentGuide(collection, data) {
        let existenceReception = _.find(data.receptionsUpdate, {
            id: data.reception.id,
        });
        if (existenceReception) {
            existenceReception.state = existenceReception.state === 0 ? 1 : 0;
        } else {
            var item = data.reception;
            item.state = 1;
            data.receptionsUpdate.push(item);
        }
        collection.push(data.reception);
    }

    existReception(collection, selectedReception) {
        return _.find(collection, { id: selectedReception.id });
    }

    /**
     * Metodo para eliminar todas las guias de un destino
     * @param {ShipmentReception} collection Colección de destinos
     * @param {Number} shipmentReceptionIndex Posicionamiento del destino a eliminar
     */
    deleteShipmentReception(collection, shipmentReceptionIndex) {
        let shipmentReception = collection[shipmentReceptionIndex];
        if (shipmentReception.id) {
            shipmentReception.updateState = ShipmentViewModel.UpdateState.Remove;
            shipmentReception.receptions.forEach(
                (reception) =>
                    (reception.updateState = ShipmentViewModel.UpdateState.Remove)
            );
        } else {
            collection.splice(shipmentReceptionIndex, 1);
        }
    }

    deleteDestinationByShipmentReception(destinations, shipmentReception) {
        const index = destinations.indexOf(shipmentReception.destination)
        if(index != -1){
            destinations.splice(index, 1)
        }
    }

    /**
     * Metodo para eliminar una guia de una colección de guias de un destino.
     * @param {ShipmentReception} collection Colección de destinos
     * @param {Number} shipmentReceptionIndex Posicionamiento de destino en donde se encuentra la guia a eliminar
     * @param {Number} receptionIndex Posicionamiento de la guia a eliminar
     */
    deleteReception(collection, shipmentReceptionIndex, receptionIndex) {
        var shipmentReception = collection[shipmentReceptionIndex];
        if (shipmentReception.id) {
            var receptions = _.filter(
                collection[shipmentReceptionIndex].receptions,
                (item) => item.updateState !== ShipmentViewModel.UpdateState.Remove
            );
            receptions[receptionIndex].updateState =
                ShipmentViewModel.UpdateState.Remove;
        } else {
            collection[shipmentReceptionIndex].receptions.splice(receptionIndex, 1);
        }
    }

    filterByUpdateState(collection) {
        return _.filter(
            collection,
            (item) => item.updateState !== ShipmentViewModel.UpdateState.Remove
        );
    }

    printManifest(shipmentId) {
        this.api.shipments
            .printManifest(shipmentId)
            .then((response) => this.view.onPrintManifestResponse(response.data))
            .catch(this.view.onPrintManifestError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Shipment(item));
    }

    mapUsers(collection) {
        return collection.map((item) => new User(item));
    }

    mapDestinations(collection) {
        return collection.map((item) => new Destination(item));
    }

    mapDestinationsIds(collection) {
        return collection.map((item) => item.id).join(",");
    }

    mapVehicles(collection) {
        return collection.map((item) => new Vehicle(item));
    }

    calculateTotalPackages(collection) {
        return _.sumBy(collection, (item) => {
            return _.sumBy(item.receptions, (p) => {
                return p.updateState != ShipmentViewModel.UpdateState.Remove ? p.packages : 0
            });
        });
    }

    calculateTotalCods(collection) {
        return _.sumBy(collection, (item) => {
            let paymentAgainstDelivery = _.sumBy(item.receptions, (p) => {
                return p.updateState != ShipmentViewModel.UpdateState.Remove ? p.paymentAgainstDelivery : 0
            });
            return paymentAgainstDelivery ? paymentAgainstDelivery : 0
        });
    }

    calculateTotalShippingQuote(collection) {

        return _.sumBy(collection, (item) => {
            return _.sumBy(item.receptions, (p) => {
                return !p.freightPayable && p.updateState != ShipmentViewModel.UpdateState.Remove ? p.sale.total : 0
            });
        });
    }

    //#endregion

    //#region  validations

    isValidSelectedDate(selectedDate) {
        var today = moment().format("YYYY-MM-DD");
        var currentSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
        return moment(currentSelectedDate).isSameOrAfter(today);
    }

    isEmpty(collection) {
        return _.isEmpty(collection);
    }

    isShipmentReceptionsEmmpty(collection) {
        return (
            _.isEmpty(collection) ||
            _.countBy(
                collection,
                (item) => item.updateState !== ShipmentViewModel.UpdateState.Remove
            ) > 0
        );
    }

    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = "";
                return;
            }
            const values = data.map((item) => item[column.property]);
            if (column.property) {
                if (column.property === "paymentAgainstDelivery") {
                    this.getReduceAmount(sums, index, values);
                }
                else if (column.property === "totalPackages") {
                    this.getTotalPackages(sums, index, values);
                }
                else if (column.property === "shippingQuote") {
                    var dataFreightPayable = data.map((item) => item["freightPayable"]);
                    this.getReduceShippingAmount(sums, index, values, dataFreightPayable);
                } else if (column.property === "packages") {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                } else if (column.property === "total") {
                    this.getTotalFreight(sums, index, values)
                }

            } else {
                sums[index] = "";
            }
        });

        return sums;
    }

    getReduceAmount(sums, index, values) {
        let result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
                return prev + curr;
            } else {
                return prev;
            }
        }, 0);
        sums[index] = `${MXN(result).format()} MXN`;
    }

    getTotalFreight(sums, index, values) {
        let result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
                return prev + value;
            } else {
                return prev;
            }
        }, 0);
        sums[index] = `${MXN(result).format()} MXN`;
    }

    getTotalPackages(sums, index, values) {
        let result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
                return prev + value;
            } else {
                return prev;
            }
        }, 0);
        sums[index] = `${result}`;
    }

    getReduceShippingAmount(sums, index, values, dataFreightPayable) {
        let result = values.reduce((prev, curr, currentIndex) => {
            if (!dataFreightPayable[currentIndex]) {
                const value = Number(curr);
                if (!isNaN(value)) {
                    return prev + curr;
                } else {
                    return prev;
                }
            } else {
                return prev + 0;
            }
        }, 0);
        sums[index] = `${MXN(result).format()} MXN`;
    }

    getTotalReceptions(collection) {
        return _.sumBy(collection, (item) => {
            return _.size(
                _.filter(item.receptions, (reception) => reception.updateState !== 2)
            );
        });
    }

    mapTraceStatus(item) {
        var result = [];

        if(item) {
            result.push(item.traceStatusName);

            if (item.traceStatusReason) {
                result.push(`Motivo: ${item.traceStatusReason}`);
            }
    
            if (item.comments) {
                result.push(`Observaciones: ${item.comments}`);
            }
        }
        

        return result.join(" | ");
    }

    processDestinations(destinations, shipmentReceptions) {

        return destinations.reduce((result, destination) => {
            const filteredShipmentReceptions = this.getFilteredShipmentReceptions(destination, shipmentReceptions);
    
            if (_.isEmpty(filteredShipmentReceptions)) {
                result.push({ destination: destination, receptions: [] });
            } else {
                result = result.concat(filteredShipmentReceptions);
            }
    
            return result;
        }, []);
    }

    getFilteredShipmentReceptions = (destination, shipmentReceptions) => {
        return _.filter(shipmentReceptions, shipmentReception => 
            shipmentReception.destination.id === destination.id
        );
    }

    //#endregion
}
