import BaseApi from '../../infraestructure/BaseApi'

export default class SaleApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doBill(id){
        return this.client.get(`${this.endPoint}/do-bill/${id}`)
    }

    doBillCancellation(id){
        return this.client.get(`${this.endPoint}/do-bill-cancellation/${id}`)
    }

    all(requests){
        return this.all(requests)
    }

    updateRestructureLocations(){
        return this.client.put(`${this.endPoint}/restructure-locations`, {})
    }

    doBillGroup(data){
        return this.client.post(`${this.endPoint}/bill-group`, data)
    }

    findGroupSaleBills(criteria){
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/group-sale-bills${parameters}`)
    }

    generateExcelSalesBySaleGroup(data){
        return this.client.post(`${this.endPoint}/generate-excel-sale-group`, data)
    }
}