<script>
import RestorePasswordComponent from "./RestorePasswordComponent";
export default {
  name: "RestorePasswordView",
  extends: RestorePasswordComponent,
};
</script>
<template>
  <div class="authentication-bg">
    <div class="account-pages">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5 text-center">
            <div class="login-logo" />
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Restablecer Contraseña !
                  </h5>
                  <p class="text-muted">
                    Ingresa tu nueva contraseña.
                  </p>
                </div>
                <div class="p-2 mt-4">
                  <el-form
                    ref="formData"
                    :model="formData"
                    :rules="rules"
                  >
                    <div class="mb-3">
                      <el-form-item
                        label="Nueva Contraseña"
                        prop="password"
                      >
                        <el-input
                          id="userpassword"
                          v-model="formData.password"
                          type="password"
                          class="form-control"
                          placeholder="Ingresar nueva contraseña"
                          @keyup.enter.native="onSubmitForm('formData')"
                        />
                      </el-form-item>
                    </div>

                    <div class="mb-3">
                      <el-form-item
                        label="Vuelva a ingresar la Contraseña"
                        prop="passwordConfirmation"
                      >
                        <el-input
                          v-model="formData.passwordConfirmation"
                          autocomplete="off"
                          name="passwordConfirmation"
                          size="medium"
                          show-password
                          placeholder="Vuelva a ingresar la nueva contraseña"
                        />
                      </el-form-item>
                    </div>


                    <div class="mt-3 text-end">
                      <el-button
                        v-loading.fullscreen.lock="fullscreenLoading"
                        type="primary"
                        class="w-100"
                        @click="onSubmitForm('formData')"
                      >
                        Confirmar
                      </el-button>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>

            <div class="mt-5 text-center">
              <!-- <p>
                ©
                Minible. Crafted with
                <i class="mdi mdi-heart text-danger" /> by Themesbrand
              </p> -->
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
