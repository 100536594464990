<script>
import ModalVehiclesComponent from "./ModalVehiclesComponent";

export default {
  extends: ModalVehiclesComponent,
};
</script>

<template>
  <el-dialog custom-class="dialog-vehicle" :visible.sync="config.isVisible" :before-close="onCancel"
    :close-on-click-modal="false">
    <span slot="title" class="dialog-title">
      <i class="uil-truck" /> Vehículos
    </span>
    <el-form ref="formData" v-loading="config.isLoading" :model="formData" :rules="rules" :label-position="'top'">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="8">
          <el-form-item label="Número de equipo" prop="equipmentNumber">
            <el-input v-model="formData.equipmentNumber" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Número de placas" prop="plateNumber">
            <el-input v-model="formData.plateNumber" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Peso Bruto Vehícular (Tn)" prop="grossWeight">
            <el-tooltip class="item" effect="dark" content="Peso en Toneladas" placement="top">
              <el-input-number v-model="formData.grossWeight" autocomplete="off" size="medium" controls-position="right"
                :min="0.1" :presicion="2" />
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="16">
          <el-form-item label="Aseguradora" prop="insuranceCarrier">
            <el-input v-model="formData.insuranceCarrier" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Número de Poliza" prop="policyNumber">
            <el-input v-model="formData.policyNumber" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="16">
          <el-form-item label="Aseguradora Ambiental" prop="insuranceEnvironment">
            <el-input v-model="formData.insuranceEnvironment" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Número de Poliza Ambiental" prop="policyEnvironment">
            <el-input v-model="formData.policyEnvironment" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Descripción" prop="description">
            <el-input v-model="formData.description" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Tipo de Vehículo" prop="vehicleType">
            <el-select v-model="formData.vehicleType" placeholder="Seleccionar Tipo de Vehículo" filterable clearable
              value-key="id" size="medium">
              <el-option v-for="item in vehicleTypes" :key="item.id" :label="item.name" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Tipo Permiso SCT" prop="sctPermissionType">
            <el-select v-model="formData.sctPermissionType" placeholder="Buscar y Seleccionar..." filterable clearable
              value-key="id" size="medium">
              <el-option v-for="item in sctPermissionTypes" :key="item.id" :label="item.code + ' - ' + item.name"
                :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Descripción" prop="description">
            <el-input v-model="formData.description" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="12">
          <el-form-item label="Num. Permiso SCT" prop="permissionNumber" required>
            <el-input v-model="formData.permissionNumber" type="text" placeholder="Número de permiso" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Año de Modelo" prop="modelYear" required>
            <el-input-number v-model="formData.modelYear" type="text" placeholder="Año de modelo" size="medium" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Sucursal" prop="branchOffice">
            <el-select v-model="formData.branchOffice" placeholder="Seleccionar Sucursal" filterable clearable
              value-key="id" size="medium">
              <el-option v-for="item in branchOffices" :key="item.id" :label="item.name" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="Estado" align="center">
            <el-switch v-model="formData.isActive" active-text="Activo" inactive-text="Inactivo" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button type="primary" size="small" :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')">Guardar</el-button>
    </span>
  </el-dialog>
</template>
