import BaseViewModel from '../../infraestructure/BaseViewModel'
import CollectionRoute from './CollectionRoute'
import Employee from '../employees/Employee'
import WorkRoute from '../workRoutes/WorkRoute'
import _ from 'lodash'

export default class CollectionRouteViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static UpdateState = {
        Update: 0,
        Add: 1,
        Remove: 2
    };

    create(data) {
        return new CollectionRoute(data, 'create')
    }

    edit(data) {
        return new CollectionRoute(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.collectionRoutes.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)

        } else {
            this.api.collectionRoutes.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.collectionRoutes.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.collectionRoutes.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.collectionRoutes.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm(formData) {
        if (formData.state === 'update') {
            this.view.employees = (formData.employeeProfile) ? [new Employee(formData.employeeProfile)] : []
        }
    }

    import(file) {
        this.api.collectionRoutes.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindList() {
        this.api.employees.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.employees = this.mapEmployees(response.data.data)
                setTimeout(() => {
                    this.view.isListLoading = false;
                    this.view.onSearch()
                }, 400);
            })
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            employeeProfile: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('EmployeeProfileId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    findEmployees(criteria) {
        this.api.employees.find(criteria)
            .then((response) => this.view.onFindEmployeesResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findWorkRoutes(criteria) {
        this.api.workroutes.find(criteria)
            .then((response) => this.view.onFindWorkRoutesResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new CollectionRoute(item))
    }

    mapEmployees(collection) {
        return collection.map((item) => new Employee(item))
    }

    mapWorkRoutes(collection) {
        return collection.map((item) => new WorkRoute(item))
    }

    addWorkRoute(collection, data) {
        data.updateState = CollectionRouteViewModel.UpdateState.Add
        collection.push(data)
        this.view.$forceUpdate()
    }

    deleteWorkRoute(workRoutes, index) {
        var item = workRoutes[index]
        item.updateState = CollectionRouteViewModel.UpdateState.Remove
        this.view.$set(workRoutes, index, item)
        this.view.$forceUpdate()
    }

    filterWorkRoutes(collection) {
        return _.filter(collection, item => item.updateState !== CollectionRouteViewModel.UpdateState.Remove)
    }

    verifyWorkRouteExistence(workRoutes, data) {
        var item = _.find(workRoutes, { id: data.id })
        return !_.isNil(item)
    }



    //#endregion
}