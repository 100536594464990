import BaseForm from "../../../../components/infraestructure/BaseForm";
import OrderDetailViewModel from "./OrderDetailViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      order: null,
      orderPdf: null,
      isDetailLoading: false,
      isFirstLoading: false,
    };
  },
  props: {
    orderNumber: {
      type: String,
      required: true,
    },
  },
  created() {
    this.viewModel = new OrderDetailViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.isDetailLoading = true;
      this.isFirstLoading = true;
      this.viewModel.bindOrderDetailView(this.orderNumber);
    },
    onFindPickingOrderResponse(response) {
      setTimeout(() => {
        this.orderPdf = response;
        this.isDetailLoading = false;
        this.isFirstLoading = false;
      }, 400);
    },
    onFindPickingOrderError(error) {
      this.onError(error);
    },
    onFindOrderDetailResponse(response) {
      this.order = response.data;
    },
    bindOrderDetailViewError(error) {
      this.onError(error);
    },
    onDownloadDocument() {
      this.isDetailLoading = true;
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          this.orderPdf.data,
          this.orderPdf.fileName,
          "application/pdf",
          () => {
            this.notifySuccess("Información Descargada.");
            this.isDetailLoading = false;
          }
        );
      }, 400);
    },
    onDownloadVerificationCode() {
      this.isDetailLoading = true;
      if (this.order.customer.verificationCode)
        this.viewModel.getVerificationCodeQr(
          this.order.customer.customerId,
          this.order.customer.verificationCode
        );
    },
    onGetVerificationCodeQrResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          response.data,
          response.fileName,
          "application/pdf",
          () => {
            this.notifySuccess("Qr de Verificación Generado.");
            this.isDetailLoading = false;
          }
        );
      }, 400);
    },
    onGetVerificationCodeQrError(error) {
      this.isDetailLoading = false;
      this.onError(error);
    },
  },
};
