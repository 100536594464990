<script>
import AboutPageComponent from "./AboutPageComponent";
import HeroSection from "../components/HeroSection";
import AboutSection from "./sections/AboutSection";
import CallToActionSection from "./sections/CallToActionSection";
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView"
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView"
export default {
  name: "AboutPageView",
  components: {
    "hero-section": HeroSection,
    "about-section": AboutSection,
    "call-to-action-section": CallToActionSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  },
  extends: AboutPageComponent,
};
</script>
<template>
  <div>
    <website-header-view />
    <hero-section
      :is-home="false"
      url="/website/images/sliders/3.jpg"
      title-sub-heading="Personal capacitado y comprometido"
      title-heading="En Paquetería Y Mensajería Somos Una Excelente Solución"
      :breadcrumb-item="[
        {
          href: 'index.html',
          name: 'Inicio',
        },
        {
          href: '',
          name: 'Empresa',
        },
        {
          href: '',
          name: 'Acerca de nosotros',
        },
      ]"
    />
    <!-- End #page-title-->
    <!--
      ============================
      About #4 Section
      ============================
      -->
    <about-section />
    <!--
      ============================
      CTA #3 Section
      ============================
      -->
    <call-to-action-section />
    <!--
      ============================
      Cases & Clients #2 Section
      ============================
      -->
    <section
      id="cases-clients-2"
      class="cases-clients cases-clients-2 bg-parllax"
    >
      <div class="cases-standard">
        <div class="container">
          <div class="heading text-center">
            <p class="heading-subtitle">
              No compre riesgos, nosotros somos su solución
            </p>
            <h2 class="heading-title">
              Nos enorgullecemos de ofrecer las mejores servicios en mensajería,
              paquetería y carga express
            </h2>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="carousel owl-carousel carousel-navs carousel-dots"
                data-slide="3"
                data-slide-rs="1"
                data-autoplay="true"
                data-nav="true"
                data-dots="false"
                data-space="30"
                data-loop="true"
                data-speed="3000"
              >
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/1.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >floride chemical factory</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">analytics</a><a href="javascript:void(0)">optimization</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          We understand that data is the greatest asset when it
                          comes to analyzing and optimizing your supply chain
                          performance.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/2.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >warehouse inventory</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">warehousing</a><a href="javascript:void(0)">distrbution</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          Cost savings is crucial, innovative technology
                          minimizes your overall spend by utilizing an schedule.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/3.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >Minimize Manufacturing</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">logistics</a><a href="javascript:void(0)">analytics</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          Our Group has been building relationships and projects
                          that last. Serving an impressive list of long-term.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/1.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >floride chemical factory</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">analytics</a><a href="javascript:void(0)">optimization</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          We understand that data is the greatest asset when it
                          comes to analyzing and optimizing your supply chain
                          performance.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/2.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >warehouse inventory</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">warehousing</a><a href="javascript:void(0)">distrbution</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          Cost savings is crucial, innovative technology
                          minimizes your overall spend by utilizing an schedule.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
                <div class="case-item">
                  <div class="case-item-warp">
                    <div class="case-img">
                      <img
                        src="assets/images/work/full/3.jpg"
                        alt="work Item Image"
                      >
                    </div>
                    <!-- End .work-img-->
                    <div class="case-content">
                      <div class="case-title">
                        <h4>
                          <a
                            href="case-study-single.html"
                          >Minimize Manufacturing</a>
                        </h4>
                      </div>
                      <div class="case-cat">
                        <a href="javascript:void(0)">logistics</a><a href="javascript:void(0)">analytics</a>
                      </div>
                      <div class="case-desc">
                        <p>
                          Our Group has been building relationships and projects
                          that last. Serving an impressive list of long-term.
                        </p>
                      </div>
                      <div class="case-more">
                        <a
                          href="case-study-single.html"
                        ><i class="icon-arrow-right" /> explore case study</a>
                      </div>
                    </div>
                    <!-- End .work-content-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End .row-->
        </div>
      </div>
    </section>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <website-footer-view />
  </div>
</template>
