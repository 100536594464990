<script>
import TransportationFigureComponent from "./TransportationFigureComponent";

export default {
  extends: TransportationFigureComponent,
};
</script>
<template>
  <el-collapse-item
    title="FIGURA DE TRANSPORTE"
    name="transportFigure"
  >
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-form-item
          label="Clave Transporte"
          :prop="`${propName}.transportationType`"
          required
        >
          <el-select
            v-model="formData.transportationType"
            placeholder="Seleccionar clave de transporte"
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in transportationTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="right"
      >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-circle-plus"
          @click="onAddDriver"
        >
          Agregar Figura
        </el-button>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="center"
      >
        <el-form-item :prop="`${propName}.transportationFigures`">
          <el-table
            v-loading="isDriverTableLoading"
            :data="formData.getTransportFigures()"
            style="width: 100%"
          >
            <el-table-column
              prop="staff"
              label="Operador"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.staff"
                  placeholder="Seleccionar operador"
                  value-key="id"
                  size="medium"
                  filterable
                  @change="onChangeDriver(scope.$index, scope.row.staff)"
                >
                  <el-option
                    v-for="item in drivers"
                    :key="item.id"
                    :label="item.getFullName()"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="INE"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.identificationNumber }}
              </template>
            </el-table-column>
            <el-table-column
              label="RFC Operador"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.federalTaxPayerRegistration }}
              </template>
            </el-table-column>
            <el-table-column
              prop="licenseNumber"
              label="No. Licencia"
              align="center"
              width="130"
            />
            <el-table-column
              label="Vigencia Licencia"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.licenseExpiration">
                  {{ scope.row.licenseExpiration | formatDate }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Dirección"
              prop="address"
            />
            <el-table-column
              label="Opciones"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <el-dropdown @command="onChangeOption">
                  <el-button size="small">
                    <i class="el-icon-more" />
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="{
                          command: 'delete',
                          item: scope.row,
                          index: scope.$index,
                        }"
                      >
                        <i class="el-icon-delete" /> Eliminar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
  </el-collapse-item>
</template>
