import BaseForm from "../infraestructure/BaseForm";
import RolesViewModel from "../../core/features/roles/RolesViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: 'Role',
      viewModel: null,
      permissionsTabActive: "permissions",
      permissionsList: [],
      checkAllPermissions: false,
      rules: {
        name: [
          {
            required: true,
            message: "Nombre requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur",
          },
        ],
        isActive: [
          {
            required: true,
            message: "El Estado es requerido.",
            trigger: "blur",
          },
        ],
      },
      modules: [],
      multipleSelection: [],
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new RolesViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse() {
      var data = this.viewModel.mapRolePermission(
        this.formData.name,
        this.modules
      );
      this.viewModel.saveRolePermissions(data);
    },
    onSavePermissionsResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-role");
    },
    onChangePermission(module, permission, index) {
      if (permission.isActive) {
        module.selectedPermissions.push(permission.codePermission);
        module.permissions[index].isActive = true;
      } else {
        let permissionIndex = module.selectedPermissions.indexOf(
          permission.codePermission
        );
        if (permissionIndex !== -1) {
          this.$delete(module.selectedPermissions, permissionIndex);
        }
        module.permissions[index].isActive = false;
      }
      this.$forceUpdate();
    },

    selectionAllOrClear(module, estado) {
      let permissions = [...module.permissions];
      if (estado) {
        module.selectedPermissions = [];
        permissions.forEach((row) => {
          row.isActive = true;
          module.selectedPermissions.push(row.codePermission);
        });
      } else {
        permissions.forEach((row) => {
          row.isActive = false;
          let permissionIndex = module.selectedPermissions.indexOf(
            row.codePermission
          );
          this.$delete(module.selectedPermissions, permissionIndex);
        });
      }
      //
      module.permissions = permissions;
      this.$forceUpdate();
    },
    onCheckAllPermissions(isAllChecked) {
      this.modules.forEach((module) => {
        this.selectionAllOrClear(module, isAllChecked)
      })
    }
  },
};
