import TokenStorageHelper from "../../../../core/helpers/TokenStorageHelper";

const admin = {
  namespaced: true,
  state: {
    Permissions: [],
    userCurrent: [],
  },
  getters: {
    getUserPermission: (state) => (code) => {
      if (state.Permissions.find((Permission) => Permission.code === code)) {
        return true;
      } else {
        return false;
      }
    },
    getUserActive:(state) =>{
      return state.userCurrent;
    }
  },
  mutations: {
    AUTH_USER(state, payload) {
      state.Permissions = payload.permissions
      state.isAuth = true
    },
    ON_END_SESSION(state) { (state.isAuth = false), (state.Permissions = []),
        TokenStorageHelper.removeToken();
    },
    SET_ROLE_PERMISSION(state, payload) {
      state.Permissions = payload.permissions;
    },
    SET_USER_CURRENT(state, user) {
      state.userCurrent = user;
    },
  },
  actions: {
    signIn({ commit }, payload) {
      commit("AUTH_USER", payload);
    },
    setCurrentUser({ commit }, payload) {
      commit("SET_USER_CURRENT", payload);
    },
    setRolePermissions({ commit }, payload) {
      commit("SET_ROLE_PERMISSION", payload);
    }
  },
};

export default admin;