import BaseApi from '../../infraestructure/BaseApi'

export default class ApiSolvency extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findAvailableSolvencyByCustomer(customerId, solvencyType) {
        return this.client.get(`${this.endPoint}/availability-by-customer/${customerId}/solvency-type/${solvencyType}`)
    }

    findAvailableSolvencyByCustomerAccount(solvencyType) {
        return this.client.get(`${this.endPoint}/availability-by-customer-account/solvency-type/${solvencyType}`)
    }

    findAvailableReceptionsByCustomerAccount(){
        return this.client.get(`${this.endPoint}/availability-receptions-by-customer-account`)
    }

    getAssignedReceptions(customerSolvencyId) {
        return this.client.get(`${this.endPoint}/assigned-receptions/${customerSolvencyId}`)
    }
}