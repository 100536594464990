import BaseModule from '../infraestructure/BaseModule'
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomerReceptionsViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}
