import _ from 'lodash'
import BaseViewModel from "../../infraestructure/BaseViewModel";
//import Customer from "./Customer";
import CustomerAccount from "./CustomerAccount";

export default class CustomerAccountViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  //#region MODULO CLIENTES

  create(data) {
    return new CustomerAccount(data, "create");
  }

  edit(data, customerId) {
    let item = new CustomerAccount(data, "update");
    item.customerId = customerId;
    return item;
  }

  save(data) {
    if (data.state === "create") {
      this.api.customersAccount
        .create(data.toSendCustomerAccounts())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    } else {
      this.api.customersAccount
        .update(data.customerId, data.id, data.toSendCustomerAccounts())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    }
  }

  delete(customerId, id) {
    this.api.customersAccount
      .delete(customerId, id)
      .then((response) =>
        this.view.onDeleteResponseCustomerAccounts(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  findCollection(customerId, filters) {
    this.api.customersAccount
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(customerId, id) {
    this.api.customersAccount
      .findOne(customerId, id)
      .then((response) =>
        this.view.onFindItemResponseCustomerAccounts(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  findItemAccounts(customerId, id) {
    this.api.customersAccount
      .findOne(id)
      .then((response) => this.view.onSearchResponse(response.data))
      .catch(this.view.onError);
  }

  async bindFormAsync(customerAccount) {
    try {
      const customerResponse = await this.api.customers.getByUserAccount(`Account=${customerAccount}`)
      this.view.customerId = customerResponse.data.data
      this.view.onSearch();
    } catch (error) {
      this.view.onError(error)
    }
  }

  import(file) {
    this.api.customersAccount
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new CustomerAccount(item));
  }

  //#endregion

  //#region UBICACIÓN DE CLIENTES

  /**
   * Metodo para verificar las cuentas de usuario(maestras y subcuentas)de un cliente,
   * se puede envíar un correo o un nombre de usuario
   */
  async verifyUserName(customerId, value, callback) {
    try {
      if (_.isNil(value) || _.isEmpty(value)) {
        callback(new Error("Usuario requerido"));
      } else {
        const response = await this.api.customers.verifyCustomerAccount({
          accountName: value,
          customerId: customerId,
        });
        const verificationResult = response.data.data;
        if (verificationResult.isValid) {
          callback();
        } else {
          callback(new Error(verificationResult.message));
        }
      }
    } catch (error) {
      this.view.onError(error);
    }
  }

  /**
   * Metodo para verificar las cuentas de usuario(maestras y subcuentas)de un cliente,
   * se puede envíar un correo o un nombre de usuario
   */
  async verifyEmail(customerId, value, callback) {
    try {
      if (_.isNil(value) || _.isEmpty(value)) {
        callback(new Error("Correo requerido"));
      } else {
        const response = await this.api.customers.verifyCustomerAccount({
          accountEmail: value,
          customerId: customerId,
        });
        const verificationResult = response.data.data;
        if (verificationResult.isValid) {
          callback();
        } else {
          callback(new Error(verificationResult.message));
        }
      }
    } catch (error) {
      this.view.onError(error);
    }
  }

  verifyPassword(value, callback) {
    if (_.isNil(value) || _.isEmpty(value)) {
      callback(new Error("Contraseña requerida."));
    }

    if (!(_.isNil(value) & _.isEmpty(value))) {
      this.view.$refs.formData.validateField("passwordConfirmation");
    }

    callback();
  }

  verifyPasswordConfirmation(formData, value, callback) {
    if (_.isNil(value) || _.isEmpty(value)) {
      callback(new Error("Verificación de contraseña requerida."));
    }

    if (!_.isEqual(value, formData.password)) {
      callback(new Error("Confirmación contraseña no coincide."));
    } else {
      callback();
    }
  }
  //#endregion
}
