import BaseApi from '../../infraestructure/BaseApi'

export default class TaxRegimeApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    importRelationsWithBillUsingTypes(file) {
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${this.endPoint}/import-relation-bill-using-types`, fd)
    }
}