<script>
import ModalTrackingComponent from "./ModalTrackingComponent";

export default {
  extends: ModalTrackingComponent,
};
</script>

<template>
  <el-dialog
    width="50%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-truck" /> Trazabilidad
    </span>
    <div class="reception-info-wrapper">
      <el-row
        type="flex"
        class="row-bg mb-3"
        :gutter="20"
      >
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-discover fs-2" /><br>
                Número de Guía
              </h6>
            </div>
            <div class="text-center">
              {{ formData.trackingNumber }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-map-location fs-2" /><br>
                Origen
              </h6>
            </div>
            <div class="text-center">
              {{ formData.origin }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-location fs-2" /><br>
                Destino
              </h6>
            </div>
            <div class="text-center">
              {{ formData.destiny }}
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg "
        :gutter="20"
      >
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-date fs-2" /><br>
                Fecha de Recepción
              </h6>
            </div>
            <div class="text-center">
              {{ formData.receptionDate | formatDate }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-user-solid fs-2" /><br>
                Remitente | Teléfono
              </h6>
            </div>
            <div class="text-center">
              {{ formData.senderName }} | {{ formData.senderPhone }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card">
            <div
              slot="header"
              class="clearfix text-center"
            >
              <h6>
                <i class="el-icon-user fs-2" /><br>
                Destinatario | Teléfono
              </h6>
            </div>
            <div class="text-center">
              {{ formData.receiverName }} | {{ formData.receiverPhone }}
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-tabs
      v-model="activeTabName"
      @tab-click="onTabChange"
    >
      <el-tab-pane
        label="Historial"
        name="historyTrace"
      >
        <el-timeline class="time-line-reception-trace">
          <el-timeline-item
            v-for="(item, index) in onGetOrderDescending(formData.historyTrace)"
            :key="index"
            :icon="onGetIconTrackingStatus(item.status)"
            :color="onGetColorTrackingStatus(item.status)"
            :timestamp="item.date | formatDWithTime"
          >
            {{ onGetTraceStatusDescription(item) }}
          </el-timeline-item>
        </el-timeline>
      </el-tab-pane>
      <el-tab-pane
        v-if="formData.isDelivered"
        label="Ubicación de Entrega"
        name="deliveryLocation"
      >
        <div
          id="map"
          v-loading="isMapLoading"
          style="height: 450px;"
        />
      </el-tab-pane>
    </el-tabs>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Aceptar</el-button>
    </span>
  </el-dialog>
</template>
