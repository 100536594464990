<script>
import TransportPageComponent from "./TransportPageComponent"
import HeroSection from "../components/HeroSection"
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView"
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView"
export default {
  name: "TransportPageView",
  components: {
    "hero-section": HeroSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  },
  extends: TransportPageComponent,
};
</script>
<template>
  <div>
    <!-- Start .header-1-->
    <website-header-view />
    <!-- Start #page-title-->
    <hero-section
      :is-home="false"
      url="/website/images/sliders/4.jpg"
      title-sub-heading="Carga Express"
      title-heading="trabajamos con rapidez y contamos con una excelente infraestructura"
      :breadcrumb-item="[
        {
          href: 'index.html',
          name: 'Inicio',
        },
        {
          href: '',
          name: 'Servcios',
        },
        {
          href: '',
          name: 'Transporte',
        },
      ]"
    />
    <!-- End #page-title-->
    <!--
      ============================
      Services Single Section
      ============================
      -->
    <section
      id="service-single"
      class="service-single case-study case-study-2 pb-70"
    >
      <div class="container">
        <div class="row">
          <!-- 
            ============================
            Sidebar Area
            ============================
            -->
          <div class="col-sm-12 col-md-12 col-lg-4 order-2 order-lg-0">
            <div class="sidebar sidebar-case-study">
              <!-- Start .widget-categories-->
              <div class="widget widget-categories">
                <div class="widget-title">
                  <h5>Servicios</h5>
                </div>
                <div class="widget-content">
                  <ul class="list-unstyled">
                    <li><a href="javascript:void(0)">Mensajería</a></li>
                    <li><a href="javascript:void(0)">Guías pre-pagadas</a></li>
                    <li>
                      <a href="javascript:void(0)">Crédito empresarial</a>
                    </li>
                    <li><a href="javascript:void(0)">Recolección</a></li>
                    <li>
                      <a
                        href="javascript:void(0)"
                      >Recolección Aeromexpress carga</a>
                    </li>
                    <li><a href="javascript:void(0)">Flete por cobrar</a></li>
                  </ul>
                </div>
              </div>
              <!-- End .widget-categories -->
              <!-- Start .widget-reservation-->
              <div class="widget widget-reservation">
                <img
                  src="assets/images/blog/sidebar/reservation.jpg"
                  alt="Background Image"
                >
                <div class="widget-content">
                  <h5>
                    Servicio de mensajería y paquetería en todo el estado.
                  </h5>
                  <p>
                    Es una empresa dedicada, desde hace más de 21 años, a
                    brindar servicio de mensajería y paquetería en todo el
                    estado. Contamos con infraestructura adecuada y personal
                    capacitado para realizar entregas puntuales.
                  </p>
                  <a
                    class="btn btn--transparent btn--inverse btn--block"
                    href="javascript:void(0)"
                  >Cotización</a>
                </div>
              </div>
              <!-- End .widget-reservation-->
              <!-- Start .widget-download-->

              <!-- End .widget-download-->
            </div>
          </div>
          <!-- End .col-lg-4 -->
          <div class="col-sm-12 col-md-12 col-lg-8">
            <!-- Start .case-study-entry-->
            <div class="case-study-entry">
              <div class="entry-content">
                <div class="entry-bio">
                  <h5>Información general</h5>
                  <p>
                    Nuestro personal de transporte aéreo concede gran
                    importancia a la personalización del proceso de reserva para
                    nuestros clientes. Es por eso que nos esforzamos por
                    encontrar la solución de transporte aéreo que mejor se
                    adapte a sus necesidades. Le preguntaremos cuándo estará
                    disponible el flete, cuál es la fecha de entrega requerida y
                    si existe la posibilidad de ahorrar tiempo o costos. Sus
                    respuestas a estas y otras preguntas nos ayudarán a decidir
                    si debe reservar el flete aéreo directamente. También
                    veremos si nuestro servicio marítimo-aéreo es una mejor
                    solución para usted.
                  </p>
                  <p>
                    Contamos con más de 21 años de experiencia. Durante ese
                    tiempo, nos convertimos en expertos en transporte de carga
                    por aire y todos sus servicios relacionados. Trabajamos en
                    estrecha colaboración con las principales aerolíneas del
                    mundo. Las negociaciones en curso garantizan que siempre
                    tengamos el espacio de carga que necesitamos y la capacidad
                    de ofrecerle tarifas competitivas, incluso durante la
                    temporada alta.
                  </p>
                  <p>
                    Siempre que sea posible, montaremos y desmontaremos los
                    dispositivos de carga unitaria (ULD), lo que reduce
                    significativamente el riesgo de daños a su envío y le ahorra
                    tiempo y gastos. Podemos hacer esto porque muchas de
                    nuestras estaciones de carga tienen su propio transporte
                    terrestre en o alrededor del aeropuerto.
                  </p>
                </div>
                <div class="entry-bio enty-bio-3">
                  <h5>¿Cómo Trabajamos?</h5>
                  <p>
                    En Logista RAM contamos con una flotilla de camionetas
                    distribuidos en la ciudad, con ello conseguimos que cada
                    usuario acceda a nuestro servicio de mensajería de manera
                    rápida y segura.
                  </p>
                </div>
                <div
                  id="video1"
                  class="video video-1 bg-overlay bg-overlay-dark"
                >
                  <div class="bg-section">
                    <img
                      src="/website/images/sliders/serviciotransporte.jpg"
                      alt="background"
                    >
                  </div>
                </div>
                <div class="entry-why">
                  <div class="entry-bio">
                    <h5>¡Por qué nosotros!</h5>
                    <p>
                      Continuamos persiguiendo esa misma visión en el complejo e
                      incierto mundo de hoy, ¡trabajando todos los días para
                      ganarnos la confianza de nuestros clientes! Durante ese
                      tiempo, nos convertimos en expertos en transporte de carga
                      por aire y todos sus servicios relacionados. Trabajamos en
                      estrecha colaboración con las principales aerolíneas del
                      mundo.
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="entry-bio entry-topic">
                        <i class="fas fa-check" />
                        <div class="entry-topic-body">
                          <h5>Sistema de control de calidad</h5>
                          <p>
                            En Mensajería Paquetería y Carga Express Ram estamos
                            comprometidos con satisfacer los requerimientos de
                            envío de nuestros clientes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="entry-bio entry-topic">
                        <i class="fas fa-check" />
                        <div class="entry-topic-body">
                          <h5>Personal altamente profesional</h5>
                          <p>
                            En Mensajería Paquetería y Carga Express Ram estamos
                            comprometidos con satisfacer los requerimientos de
                            envío de nuestros clientes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="entry-bio entry-topic">
                        <i class="fas fa-check" />
                        <div class="entry-topic-body">
                          <h5>Satisfacción garantizada</h5>
                          <p>
                            En Mensajería Paquetería y Carga Express Ram estamos
                            comprometidos con satisfacer los requerimientos de
                            envío de nuestros clientes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="entry-bio entry-topic">
                        <i class="fas fa-check" />
                        <div class="entry-topic-body">
                          <h5>Procesos precisos</h5>
                          <p>
                            En Mensajería Paquetería y Carga Express Ram estamos
                            comprometidos con satisfacer los requerimientos de
                            envío de nuestros clientes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="accordion01"
                  class="accordion accordion-2"
                >
                  <div class="heading">
                    <h4 class="heading-title">
                      beneficios clave
                    </h4>
                  </div>
                  <div class="card">
                    <div class="card-heading">
                      <a
                        class="card-link collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion01"
                        href="#collapse01-1"
                      >¿Qué plan es el adecuado para mí?
                      </a>
                    </div>
                    <div
                      id="collapse01-1"
                      class="collapse"
                      data-parent="#accordion01"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet consectetur adipiscing, elit
                        sem magna interdum tristique himenaeos, arcu eros
                        cubilia habitasse risus.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-heading">
                      <a
                        class="card-link collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion01"
                        href="#collapse01-2"
                      >¿Tengo que comprometerme con un contrato?</a>
                    </div>
                    <div
                      id="collapse01-2"
                      class="collapse"
                      data-parent="#accordion01"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet consectetur adipiscing, elit
                        sem magna interdum tristique himenaeos, arcu eros
                        cubilia habitasse risus.
                      </div>
                    </div>
                  </div>
                  <div class="card active-acc">
                    <div class="card-heading">
                      <a
                        class="card-link collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion01"
                        href="#collapse01-3"
                      >¿Qué métodos de pago están disponibles?
                      </a>
                    </div>
                    <div
                      id="collapse01-3"
                      class="collapse show"
                      data-parent="#accordion01"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet consectetur adipiscing, elit
                        sem magna interdum tristique himenaeos, arcu eros
                        cubilia habitasse risus.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End .case-study-entry-->
          </div>
          <!-- End .col-lg-8-->
        </div>
        <!-- End .row-->
      </div>
      <!-- End .container-->
    </section>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <website-footer-view />
  </div>
</template>
