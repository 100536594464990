export default class BranchOffice {
  constructor(options, state) {
    if (state) {
      this.state = state
    }
    Object.assign(this, options)
  }

  toSend() {
    return {
      id: this.id,
      code: this.code.trim(),
      name: this.name.trim(),
      description: this.description,
      isActive: this.isActive
    }
  }
}