import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomersLocation extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }
 
    create(customerId, data) {
        return this.client.post(`${this.endPoint}/${customerId}/locations`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/locations/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/locations/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/locations${parameters}`)
    }

    findOne(customerId, id) {
          return this.client.get(`${this.endPoint}/${customerId}/locations/${id}`)
    }

    import(customerId, file) {
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${this.endPoint}/${customerId}/locations/import`, fd)
    }

    exportToExcel(customerId) {
        return this.client.get(`${this.endPoint}/${customerId}/locations/export-excel`)
    }
}