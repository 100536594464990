import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      paymentTypes: [],
      paymentMethods: [],
      billUsingTypes: [],
      billTypes: [],
      currencies: [],
      weightUnits: [],
      useCustomerData: "none",
      isLoading: false,
      fiscalName: "",
      fiscalCode: "",
      fiscalEmail: "",
      consignmentNotesVersions: [
        /* {
          id: "2.0",
          name: "2.0",
        },*/
        {
          id: "3.1",
          name: "3.1",
        },
      ],
      internationalTransports: [
        {
          id: "Si",
          name: "Si",
        },
        {
          id: "No",
          name: "No",
        },
      ],
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    weightCostConfig: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onActive() {
      this.viewModel.bindStepThreeView(this.formData);
    },
    isFreighPayableDisabled() {
      return this.formData.solvencyType === ReceptionsViewModel.SolvencyType.Credit
    },
    onDiscountChange(data){
      this.onNotifyEvent('on-discount-update', data || 0)
    }
  },
  watch: {
    
  },
};
