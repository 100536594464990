export default class CustomerContact {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        id: this.id,
        description: this.description,
        fiscalName: this.fiscalName,
        fiscalCode: this.fiscalCode,
        contactName: this.name || this.contactName || "",
        contactEmail: this.email || this.contactEmail || "",
        contactPhone: this.phone || this.contactPhone || "",
      }
    }
  }