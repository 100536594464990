import BaseApi from '../../infraestructure/BaseApi'

export default class SystemSettings extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    updateAutoDigitalInvoice(data) {
        return this.client.put(`${this.endPoint}/auto-digital-invoice`, data)
    }

    getDefaultSettings() {
        return this.client.get(`${this.endPoint}/default-settings`)
    }
}