import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomers extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    register(data){
        return this.client.post(`${this.endPoint}/register`, data)
    }

    findByCode(code){
        return this.client.get(`${this.endPoint}/by-code/${code}`)
    }

    findByFiscal(criteria){
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-fiscal${parameters}`)
    }

    verify(criteria){
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/verify${parameters}`)
    }

    verifyCustomerAccount(data){
        return this.client.post(`${this.endPoint}/verify-customer-account`, data)
    }

    updateRestructureLocations(){
        return this.client.put(`${this.endPoint}/restructure-locations`, {})
    }

    getByUserAccount(criteria){
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-user-account${parameters}`)
    }
}