<script>
import AdminMenuCustomerView from '../shared/menus/AdminMenuCustomerView.vue'
import AdminHeaderCustomerView from '../shared/headers/AdminHeaderCustomerView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleCustomerLocationComponent from './ModuleCustomerLocationComponent'
import ListCustomerLocationView from './ListCustomerLocationView'

export default {
    components: {
        'admin-menu-customer-view': AdminMenuCustomerView,
        'admin-header-customer-view': AdminHeaderCustomerView,
        'admin-footer-view': AdminFooterView,
        'list-customers-location-view': ListCustomerLocationView
    },
    extends: ModuleCustomerLocationComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-customer-view />
    <admin-menu-customer-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-map-marker module-header-icon" /> Ubicaciones
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <list-customers-location-view />
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>