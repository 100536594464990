import BaseViewModel from '../../infraestructure/BaseViewModel'
import Country from '../countries/Country'
import District from '../districts/District'
import Municipality from './Municipality'

export default class MunicipalityViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new Municipality(data, 'create')
    }

    edit(data) {
        return new Municipality(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.municipalities.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.municipalities.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.municipalities.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.municipalities.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.municipalities.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm(formData) {
        this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`)
            .then((response) => {
                this.view.countries = response.data.data
                if(formData.state === 'update') {
                    if(formData.country) return this.api.districts.find(`CountryId=${formData.country.id}&Skip=0&Limit=10000&IsActive=true`)
                }
            })
            .then((response) => {
                if(response) {
                    this.view.districts = response.data.data
                }
            })
            .catch(this.view.onError)
    }

    import(file) {
        this.api.municipalities.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            country: '',
            district: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('CountryId');
        this.view.removeFilter('DistrictId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    bindList() {
        this.api.countries.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.countries = this.mapCountry(response.data.data)
                setTimeout(() => {
                    this.view.onSearch();
                    this.view.isListLoading = false;
                }, 400);
            })
            .catch(this.view.onError);
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new Municipality(item))
    }

    mapCountry(collection) {
        return collection.map((item) => new Country(item))
    }

    mapDistricts(collection) {
        return collection.map((item) => new District(item))
    }

    //#endregion

    findDistricts(query) {
        this.api.districts.find(query)
            .then((response) => this.view.onFindDistrictsResponse(response.data))
            .catch(this.view.onError)
    }
}