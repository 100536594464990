import BaseForm from '../../../infraestructure/BaseForm'
import BoxViewModel from './BoxViewModel'
export default {
    extends: BaseForm,
    data() {
        return {
            isLoading: false,
            viewModel: null,
            rules: {
                description: [
                    { required: true, message:"Alias de ubicación requerido.", trigger: 'blur' }
                ],
                postalCode: [
                    { required: true, message:"Código postal requerido.", trigger: 'blur' }
                ],
                country: [
                    { required: true, message:"País requerido.", trigger: 'blur' }
                ],
                district: [
                    { required: true, message:"Estado requerido.", trigger: 'blur' }
                ],
                municipality: [
                    { required: true, message:"Municipio requerido.", trigger: 'blur' }
                ],
                city: [
                    { required: true, message:"Ciudad requerido.", trigger: 'blur' }
                ],
                neighborhood: [
                    { required: true, message:"Colonia requerido.", trigger: 'blur' }
                ],
                mainStreet: [
                    { required: true, message:"Calle principal requerido.", trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        this.viewModel = new BoxViewModel(this)
        this.onActive()
    },
    props: {
        formData: {
            type: Object,
            require: true
        },
        config: {
            type: Object,
            require: true
        }
    },
    methods: {
        onActive() {
            console.log(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onNotifyEvent('on-submited-customer', response)
        },
        onSaveResponseError(error) {
            this.onError(error)
        },
        onCustomerLocationResponse(response){
            this.onNotifyEvent('on-submited-location', response)
        }
    }
}