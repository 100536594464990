<script>
import ListShipmentComponent from "./ListShipmentComponent";
import ModalShipmentView from "./ModalShipmentView";
import ModalMultipleShipmentView from "./ModalMultipleShipmentView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-shipment-view": ModalShipmentView,
    "modal-multiple-shipment-view": ModalMultipleShipmentView,
    "modal-import-view": ModalImportView,
  },
  extends: ListShipmentComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div class="col-3">
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-6 me-2" />
                <div class="d-flex justify-content-between">
                  <div
                    id="buttonlist"
                    class="mb-3"
                  >
                    <el-button
                      type="danger"
                      class="filter-btn"
                      icon="fa fa-filter"
                      size="medium"
                      @click="onMoreFilters"
                    >
                      Filtrar por:
                    </el-button>
                    <el-button
                      v-if="onUserPermission('Permission.Delivery.Create')"
                      type="primary"
                      size="medium"
                      class="main-action"
                      :disabled="!onCanCreate(modulePermissions)"
                      @click="onCreate"
                    >
                      <i class="fas fa-plus-circle" /> Crear Manifiesto
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.Delivery.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectShipment"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="shipmentNumber"
                  label="No. Manifiesto"
                  width="140"
                  align="center"
                />

                <el-table-column
                  property="vechicle"
                  label="No. Vehículo"
                  width="120"
                  align="center"
                />

                <el-table-column
                  label="Fecha de Envio"
                  property="shipmentDate"
                  show-overflow-tooltip
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-tag
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-date" />
                      <span class="mx-2">
                        {{ scope.row.shipmentDate | formatDate }}
                      </span>
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  property="destination"
                  label="Destino(s)"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  property="operator"
                  label="Operador"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="responsible"
                  label="Responsable"
                  show-overflow-tooltip
                />

                <el-table-column
                  property="receiverEmployee"
                  label="Recibio"
                  show-overflow-tooltip
                />

                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="170"
                >
                  <template #default="scope">
                    <el-tag
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-truck" /> {{ scope.row.shipmentStatusName }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="onUserPermission('Permission.Delivery.Update') || onUserPermission('Permission.Delivery.Delete')"
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="{
                              command: 'print-manifest',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Generar
                            Manifiesto
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.Delivery.Update')
                            "
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'edit', item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.Delivery.Delete')
                            "
                            :disabled="!onCanDelete(modulePermissions)"
                            :command="{ command: 'delete', item: scope.row }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-shipment-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <modal-multiple-shipment-view
      v-if="modalViewModalDestinations.isActive"
      :form-data="itemDestination"
      :config="modalViewModalDestinations"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button
          class="btn-text"
          type="text"
          @click="onClearFilters"
        >
          Borrar todos los filtros
        </el-button>
      </template>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">No. Manifiesto:</span>
            <el-input
              v-model="filtersSelected.shipmentNumber"
              type="text"
              placeholder="Buscar por No. Manifiesto"
              clearable
              size="small"
              @input="onSearchByShipmentNumber"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker
              v-model="filtersSelected.beginingDate"
              type="daterange"
              size="small"
              clearable
              range-separator="-"
              start-placeholder="F. Inicio"
              end-placeholder="F. Fin"
              value-format="yyyy-MM-dd"
              @input="onSearchBeginingDate"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Destino:</span>
            <el-select
              v-model="filtersSelected.destinyName"
              placeholder="Buscar Destino"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="searchDestinationFilter"
            >
              <el-option
                v-for="item in destinations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Responsable:</span>
            <el-select
              v-model="filtersSelected.responsible"
              placeholder="Buscar Responsable"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeRisponsable"
            >
              <el-option
                v-for="item in responsibles"
                :key="item.id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Operador:</span>
            <el-select
              v-model="filtersSelected.operator"
              placeholder="Buscar Operador"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeOperator"
            >
              <el-option
                v-for="item in operators"
                :key="item.id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Estado del envío:</span>
            <el-select
              v-model="filtersSelected.shipmentStatus"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="searchShipmentStatusFilter"
            >
              <el-option
                v-for="item in shipmentStatuses"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
