<template>
  <div id="app">
    <preloader-spinner v-if="fullscreenLoading === true" />
    <router-view v-else />
  </div>
</template>

<script>
import PreloaderSpinner from '@/components/preloader/PreloaderSpinner.vue'
export default {
  components :{
    'preloader-spinner':PreloaderSpinner
  },
  data() {
      return {
        fullscreenLoading: false
      }
    },
  Created() {  },

  beforeMount() {
    this.openFullScreen1()
   if (window.location.href.indexOf("admin") === -1 && window.location.href.indexOf("clientes") === -1 )  {
      import("bootstrap/dist/css/bootstrap.css");
      import("@/assets/website/css/vendor.css");
      import("@/assets/website/scss/style.scss");
      import("@/assets/website/element/element-variables.scss");
    } else {
      import("@/assets/dashboard/assets/scss/bootstrap.scss");
      import("@/assets/dashboard/assets/scss/icons.scss");
      import("@/assets/dashboard/app.scss");
      import("@/assets/dashboard/element/element-variables.scss");
     // import("@/assets/dashboard/js/simplebar/simplebar.min.css");
    }  
  },
  methods: {
        openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 500);
      },
  },
};
</script>
