import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import PrepaidPlanViewModel from '../../core/features/prepaidPlans/PrepaidPlanViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'PrepaidPlan',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                description: '',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR PLANES DE PREPAGO',
                onUploadImport: 'on-upload-import-prepaid-plans',
                onCancelImport: 'on-cancel-import-prepaid-plans'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-prepaid-plan', this.onSearch)
        this.onMountedEvent('on-upload-import-prepaid-plans', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-prepaid-plans', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-prepaid-plan', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-prepaid-plans', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-prepaid-plans', this.onCancelImport);
    },
    created() {
        this.viewModel = new PrepaidPlanViewModel(this)
        this.onActive()
    },

    methods: {
        onActive() {
            this.onSearch()
        },     
        onSelectPrepaidPlan() { },
        onCreate(){
            this.item = this.viewModel.create({  isActive: true})
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}