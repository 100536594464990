import BasePage from '../../infraestructure/BasePage'




export default {
    extends: BasePage,
    data() {
        return {
            viewModel: null,
        };
    },

    created() {

        

    },
    mounted() {
      },

    beforeUpdate: function () {
       
    },
    beforeDestroy: function () {
      
    },
    updated() {

    },
    methods: {
        
    }
}


