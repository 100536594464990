import _ from 'lodash'
import moment from "moment";
import PDFMerger from "pdf-merger-js";
import ApiContext from "./ApiContext";

export default class BaseViewModel {
  constructor(view) {
    this.view = view;
    this.api = ApiContext;
  }

  formatDate(date, formatString) {
    return moment(date).format(formatString);
  }

  getQueryParameters(filters) {
    return filters
      .map((item) => `${item.filterName}=${item.filterValue}`)
      .join("&");
  }

  mapPdfFile(pdfObject, fileName) {
    const url = window.URL.createObjectURL(new Blob([pdfObject]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  dataURItoBlob(dataURI, type) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }

  mergePdfFiles(base64Files, mergedFileName) {
    var merger = new PDFMerger();
    (async () => {
      for (const base64File of base64Files) {
        await merger.add(this.dataURItoBlob(base64File, "application/pdf"));
      }
      await merger.save(mergedFileName);
    })();
  }

  async mergeFiles(base64Files, mergedFileName, onFinish) {
    (async () => {
      var merger = new PDFMerger();
      let blobFiles = base64Files.map((baseFile) =>
        this.dataURItoBlob(baseFile, "application/pdf")
      );
      for (const blobFile of blobFiles) {
        await merger.add(blobFile);
      }
      await merger.save(mergedFileName);
      onFinish();
    })();
  }

  addDownloadEvent(base64FileString, fileName, type, onFinish) {
    let blobFile = this.dataURItoBlob(base64FileString, type);
    const url = window.URL.createObjectURL(blobFile);
    const link = document.createElement("a");
    link.href = url;
    switch (type) {
      case "application/pdf":
        link.setAttribute("download", `${fileName}.pdf`); //or any other extension
        break;
      case "application/vnd.ms-excel":
        link.setAttribute("download", `${fileName}.xlsx`); //or any other extension
        break;
    }
    document.body.appendChild(link);
    link.click();
    onFinish();
  }

  formatPrecision(value) {
    if(_.isNil(value)) return 0
    if(_.isNaN(value)) return 0
    if(!this.isNumeric(value)) return 0
    return Math.round(Number(value))
  }

  isNumeric(value) {
    return (_.isNumber(value) || (_.isString(value) && /^\d+(?:\.\d+)?$/.test(value)))
  }
}
