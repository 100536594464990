import BaseForm from "../infraestructure/BaseForm";
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";
export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      weightCostConfig: null,
      rules: {
        productTypePath: [
          {
            required: true,
            message: "Producto es requerido.",
            trigger: "blur",
          },
        ],
        measureUnit: [
          {
            required: true,
            message: "Unidad de Medida requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur, change",
          },
          {
            validator: this.onDescriptionSizeValidation,
            trigger: "blur, change",
          },
        ],
        quantity: [
          {
            required: true,
            message: "Cantidad requerido.",
            trigger: "blur, change",
          },
          {
            validator: this.onPackageQuantityValidator,
            trigger: "blur, change",
          },
        ],
        weight: [
          {
            required: true,
            message: "Peso requerido.",
            trigger: "blur, change",
          },
        ],
        height: [
          {
            required: true,
            message: "Alto requerido.",
            trigger: "blur, change",
          },
        ],
        width: [
          {
            required: true,
            message: "Ancho requerido.",
            trigger: "blur",
          },
        ],
        length: [
          {
            required: true,
            message: "Largo requerido.",
            trigger: "blur, change",
          },
        ],
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CustomerReceptionsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      // this.viewModel.bindReceptionPackageForm(this.formData)
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSave(formData) {
      this.onDoAfterLocalSaveResponse(
        formData,
        "on-submited-customer-reception-packages"
      );
    },
    onPackageQuantityValidator(rule, value, callback) {
      if (!value) {
        callback("Cantidad debe ser mayor a 1.");
      } else {
        callback();
      }
    },
    onDescriptionSizeValidation(rule, value, callback) {
        this.viewModel.descriptionSizeValidation(rule, value, callback)
    }
  },
};
