export default class Order {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  toSend() {
    return {
      id: this.id,
      client: this.mapCustomer("customer"),
      sender: this.mapOrderShipmentContact("sender"),
      receiver: this.mapOrderShipmentContact("receiver"),
    };
  }

  mapCustomer(customerField) {
    return {
      id: this[customerField] ? this[customerField].id : null,
      contactName: this[customerField] ? this[customerField].contactName : null,
      contactEmail: this[customerField]
        ? this[customerField].contactEmail
        : null,
      contactPhone: this[customerField]
        ? this[customerField].contactPhone
        : null,
    };
  }

  mapOrderShipmentContact(customerField) {
    return {
      id: this[customerField] ? this[customerField].id : null,
      name: this[customerField] ? this[customerField].name : null,
      email: this[customerField] ? this[customerField].email : null,
      phone: this[customerField] ? this[customerField].phone : null,
      observation: this[customerField] ? this[customerField].observation : null,
      locationId:
        this[customerField] && this[customerField].customerLocation
          ? this[customerField].customerLocation.id
          : null,
    };
  }

  toUpdate() {}

  toSendCreate() {
    return {
      // customerId: this.customer ? this.customer.id : null,
      receptionServiceType: this.receptionServiceType,
      payerResponsible: this.payerResponsible,
      serviceCostId: this.serviceCost ? this.serviceCost.id : null,
      serviceDeliveryPrice: this.serviceDeliveryPrice,
      isAutoDigitalInvoice: this.isAutoDigitalInvoice,
      sender: this.mapContact("sender"),
      receiver: this.mapContact("receiver"),
      orderFiscal: this.mapOrderFiscal(),
      traces: this.mapTraces(this.traces),
    };
  }

  toSendEdit() {
    return {
      // customerId: this.customer ? this.customer.id : null,
      receptionServiceType: this.receptionServiceType,
      payerResponsible: this.payerResponsible,
      serviceCostId: this.serviceCost ? this.serviceCost.id : null,
      serviceDeliveryPrice: this.serviceDeliveryPrice,
      isAutoDigitalInvoice: this.isAutoDigitalInvoice,
      sender: this.mapContact("sender"),
      receiver: this.mapContact("receiver"),
      orderFiscal: this.mapOrderFiscal(),
      traces: this.mapTraces(this.traces),
    };
  }

  mapTraces(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        description: item.description,
        quantity: item.quantity,
        length: item.length,
        width: item.width,
        height: item.height,
        weight: item.weight,
        trackingPackage: item.trackingPackage,
      };
    });
  }

  mapOrderFiscal() {
    return {
      fiscalEmail: this.orderFiscal.fiscalEmail,
      fiscalCustomerId: this.orderFiscal.customer ? this.orderFiscal.customer.id : null,
      fiscalName: this.orderFiscal.fiscalName,
      fiscalCode: this.orderFiscal.fiscalCode,
      postalCodeId: this.orderFiscal.postalCode ? this.orderFiscal.postalCode.id : null,
      fiscalRegimeId: this.orderFiscal.fiscalRegime ? this.orderFiscal.fiscalRegime.id : null,
      billUsingTypeId: this.orderFiscal.billUsingType ? this.orderFiscal.billUsingType.id : null,
      paymentMethodId: this.orderFiscal.paymentMethod ? this.orderFiscal.paymentMethod.id : null,
      type: this.orderFiscal.type
    };
  }

  mapContact(contactKey) {
    return {
      customerId: this[contactKey].customer
        ? this[contactKey].customer.id
        : null,
      postalCodeId: this[contactKey].postalCode
        ? this[contactKey].postalCode.id
        : null,
      neighborhoodId: this[contactKey].neighborhood
        ? this[contactKey].neighborhood.id
        : null,
      countryId: this[contactKey].country ? this[contactKey].country.id : null,
      districtId: this[contactKey].district
        ? this[contactKey].district.id
        : null,
      municipalityId: this[contactKey].municipality
        ? this[contactKey].municipality.id
        : null,
      cityId: this[contactKey].city ? this[contactKey].city.id : null,
      mainStreet: this[contactKey].mainStreet,
      secondaryStreet: this[contactKey].secondaryStreet,
      extNumber: this[contactKey].extNumber,
      intNumber: this[contactKey].intNumber,
      name: this[contactKey].name,
      email: this[contactKey].email,
      phone: this[contactKey].phone,
      observation: this[contactKey].observation,
      addressId: this[contactKey].customerLocation
        ? this[contactKey].customerLocation.id
        : null,
        type: this[contactKey].type
    };
  }

  getDescriptionFullAddressByContractKey(contactKey) {
    
    let result = []

    if(this[contactKey].mainStreet) {
      result.push(this[contactKey].mainStreet)
    }

    if(this[contactKey].secondaryStreet) {
      result.push(this[contactKey].secondaryStreet)
    }

    if(this[contactKey].extNumber) {
      result.push(`No. Ext: ${this[contactKey].extNumber}`)
    }

    if(this[contactKey].intNumber) {
      result.push(`No. Int: ${this[contactKey].intNumber}`)
    }

    if(this[contactKey].neighborhood) {
      result.push(this[contactKey].neighborhood.name)
    }

    if(this[contactKey].postalCode) {
      result.push(`C.P: ${this[contactKey].postalCode.code}`)
    }

    if(this[contactKey].city) {
      result.push(this[contactKey].city.name)
    }

    if(this[contactKey].municipality) {
      result.push(this[contactKey].municipality.name)
    }

    if(this[contactKey].district) {
      result.push(this[contactKey].district.name)
    }

    if(this[contactKey].country) {
      result.push(this[contactKey].country.name)
    }

    return result.join(", ")
  }
}
