import BaseForm from "../infraestructure/BaseForm";
import ShipmentViewModel from "../../core/features/shipments/ShipmentViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      rules: {
        destination: [
          {
            required: true,
            message: "El destino del envio es requerido.",
            trigger: "blur",
          },
        ],
      },
      destinations:[],
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new ShipmentViewModel(this);
    this.onActive();

  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-shipment");
    },
    onFindDestinations(data){
      if(data) {
          this.viewModel.findDestinations(`Name=${data}`)
      } else {
          this.destinations = []
      }
    },
    onFindDestinationsResponse(response){
      this.destinations = response.data
  },
  },
};
