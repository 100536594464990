import BaseViewModel from '../../../../core/infraestructure/BaseViewModel'


export default class OrderVerificationViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    verifyOrder(orderCode) {
        this.api.orders.getByCode(orderCode)
            .then((response) => this.view.onVerifyOrderResponse(response.data))
            .catch(this.view.onVerifyOrderError)
    }
    
}