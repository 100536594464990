<script>
import MapTrackingGpsComponent from "./MapTrackingGpsComponent";
export default {
  components: {},
  extends: MapTrackingGpsComponent,
};
</script>

<template>
  <div
    v-if="onUserPermission('Permission.DeviceLocation.Show')"
    v-loading="isMapLoading"
    class="card-body"
  >
    <div
      id="map"
      style="height: 650px;"
    />
  </div>
</template>
