export default class ReceptionCustomer {
  constructor(options, state) {
    if (state) {
      this.state = state
    }
    Object.assign(this, options)
  }

  toSend() {
    return {
      description: this.description.trim(),
      fiscalCode: this.fiscalCode,
      fiscalName: this.fiscalName,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      discountPercentage: this.discountPercentage,
      customerLocation: {
        description: this.descriptionLocation.trim(),
        mainStreet: this.mainStreet,
        secondaryStreet: this.secondaryStreet,
        extNumber: this.extNumber,
        postalCode: this.postalCode,
        neighborhoodId: (this.neighborhood) ? this.neighborhood.id : null,
      }
    }
  }
}