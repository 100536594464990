<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModulePlaceComponent from './ModulePlaceComponent'
import ListPlaceView from './ListPlaceView'
import ListBranchOfficeView from '../branchoffices/ListBranchOfficeView'
import ListPostalCodeView from '../postalCodes/ListPostalCodeView'
import ListCountryView from '../countries/ListCountryView'
import ListDistrictView from '../districts/ListDistrictView'
import ListMunicipalityView from '../municipalities/ListMunicipalityView'
import ListNeighborhoodView from '../neighborhoods/ListNeighborhoodView'

export default {
    components: {
        'admin-menu-view': AdminMenuView,
        'admin-header-view': AdminHeaderView,
        'admin-footer-view': AdminFooterView,
        'list-place-view': ListPlaceView,
        'list-branch-office-view': ListBranchOfficeView,
        'list-postal-code-view': ListPostalCodeView,
        'list-country-view': ListCountryView,
        'list-district-view': ListDistrictView,
        'list-municipality-view': ListMunicipalityView,
        'list-neighborhood-view': ListNeighborhoodView,
    },
    extends: ModulePlaceComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-map-marker module-header-icon" /> Catálogo de Ubicaciones
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="tabActive">
                    <el-tab-pane
                      label="Sucursales"
                      name="branch-offices"
                    >
                      <span slot="label"><i class="uil-store me-1" />Sucursales</span>
                      <list-branch-office-view v-if="tabActive === 'branch-offices'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Códigos Postales"
                      name="postal-codes"
                    >
                      <span slot="label"><i class="uil-sign-alt me-1" />Códigos Postales</span>
                      <list-postal-code-view v-if="tabActive === 'postal-codes'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Colonias"
                      name="neighborhoods"
                    >
                      <span slot="label"><i class="uil-map-marker me-1" />Colonias</span>
                      <list-neighborhood-view v-if="tabActive === 'neighborhoods'" />
                    </el-tab-pane>
                    
                    <el-tab-pane
                      label="Localidades"
                      name="places"
                    >
                      <span slot="label"><i class="uil-map-marker me-1" />Localidades</span>
                      <list-place-view v-if="tabActive === 'places'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Municipios"
                      name="municipalities"
                    >
                      <span slot="label"><i class="uil-map me-1" />Municipios</span>
                      <list-municipality-view v-if="tabActive === 'municipalities'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Estados"
                      name="districts"
                    >
                      <span slot="label"><i class="uil-map me-1" />Estados</span>
                      <list-district-view v-if="tabActive === 'districts'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Paises"
                      name="countries"
                    >
                      <span slot="label"><i class="uil-map me-1" />Paises</span>
                      <list-country-view v-if="tabActive === 'countries'" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>