import BasePage from '../../infraestructure/BasePage'

export default {
    extends: BasePage,
    created() {
        // let dom = document.readyState;
    },
    mounted() {
        // let dom = document.readyState;
    },
    updated() {
      
    },

    methods: {
        UI() {
            
        }
    }
}