import BaseViewModel from '../../infraestructure/BaseViewModel'
import ProductTypes from './ProductTypes'

export default class ProductTypesViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new ProductTypes(data, 'create')
    }

    edit(data) {
        return new ProductTypes(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.productTypes.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)

        } else {
            this.api.productTypes.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.productTypes.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.productTypes.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.productTypes.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

 /*    findFilterTree() {
        this.api.productTypes.findFilterTree()
            .then((response) => this.view.onFindItemFilterTreeResponse(response.data))
            .catch(this.view.onError)
    } */

    bindForm(formData) {
        if (formData.state === 'update') {
            this.view.measureUnits = (formData.measureUnit) ? [formData.measureUnit] : []
            this.view.parentProductTypes = (formData.parentProductType) ? [formData.parentProductType] : []
            this.view.transportMerchandiseTypes = (formData.transportMerchandiseType) ? [formData.transportMerchandiseType] : []
        }
    }

    import(file) {
        this.api.productTypes.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            description:'',
            parentProductTypeId:'',
            measureUnitId:'',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('Description');
        this.view.removeFilter('ParentProductTypeId');
        this.view.removeFilter('MeasureUnitId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }



    //#region 

    mapCollection(collection) {
        return collection.map((item) => new ProductTypes(item))
    }

    //#endregion

    //#region QUERIES
    
    findParentProductTypes(criteria) {
        this.api.productTypes
            .find(criteria)
            .then((response) => this.view.onFindParentProductTypesResponse(response.data))
            .catch(this.view.onError);
    }

    findMeasureUnits(criteria) {
        this.api.measureUnits.find(criteria)
            .then((response) => this.view.onFindMeasureUnitsResponse(response.data))
            .catch(this.view.onError)
    }

    findTransportMerchandiseTypes(criteria) {
        this.api.transportMechandiseTypes.find(criteria)
            .then((response) => this.view.onFindTransportMerchandiseTypesResponse(response.data))
            .catch(this.view.onError)
    }


    //#endregion QUERIES

}
