export default class CustomerSpecialPrice {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }

    toSend() {
      return {
        id: this.id,
        customerId: this.customer ? this.customer.id : null,
        serviceCostId: this.serviceCost ? this.serviceCost.id : null,
        amount: this.amount,
        isActive: this.isActive
      }
    }

}