import BaseForm from "../infraestructure/BaseForm";
import CustomerVerificationCodeViewModel from "../../core/features/customerVerificationCodes/CustomerVerificationCodeViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      destinations: [],
      neighborhoods: [],
      responsibles: [],
      fiscalSearch: null,
      activeName: "first",
      rules: {
        contactName: [
          {
            required: true,
            message: "Nombre de contacto requerido.",
            trigger: "blur",
          },
        ],
        contactEmail: [
          {
            required: true,
            message: "Correo de contacto requerido.",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "Teléfono de contacto requerido.",
            trigger: "blur",
          },
        ]
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CustomerVerificationCodeViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(
        response.message,
        "on-submited-customer-verification-codes"
      );
    },
    onGenerateVerificationCode() {
      this.config.isLoading = true;
      this.viewModel.generateVerificationCode(this.formData.customerId);
    },
    onGenerateVerificationResponse(response) {
      setTimeout(() => {
        this.$set(this.formData, "verificationCode", response.data);
        this.config.isLoading = false;
      }, 400);
    },
    onGenerateVerificationError(error) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onError(error);
      }, 400);
    },
    onFindResponsible(data){
      this.responsibles = []
      if(data) this.viewModel.findResponsibles(this.formData.customerId, `FullSearch=${data}&Skip=0&Limit=10000`)
    },
    onFindResponsiblesResponse(response){
      this.responsibles = this.viewModel.mapResponsibles(response.data)
    },
  },
};
