<script>
import ModalComponent from "./ModalComponent";

export default {
  components: {},
  extends: ModalComponent,
};
</script>

<template>
  <el-dialog custom-class="dialog-billing" :visible.sync="config.isVisible" :before-close="onCancel"
    :close-on-click-modal="false">
    <span slot="title" class="dialog-title">
      <i class="uil-receipt" /> Facturación de Cliente
    </span>
    <el-form ref="formData" v-loading="config.isLoading" :model="formData" :rules="rules" :label-position="'top'">
      <el-tabs v-loading="isLoading" type="border-card">
        <el-tab-pane>
          <span slot="label">Opciones</span>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item prop="customer" label="Cliente">
              <el-select v-model="formData.customer" placeholder="Seleccionar" clearable debounce value-key="id"
                size="medium" remote :remote-method="onSearchCustomers" filterable @change="onChangeCustomer">
                <el-option v-for="item in customers" :key="item.id" :label="item.description" :value="item" />
              </el-select>
            </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item prop="shipment" label="Manifiesto">
                <el-select v-model="formData.shipment" placeholder="Buscar manifiesto..." filterable clearable remote
                  :remote-method="onFindShipments" size="medium" value-key="id">
                  <el-option v-for="item in shipments" :key="item.id" :label="item.toBillingCatalog()"
                    :value="item" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="2" :offset="21">
              <el-button type="primary" size="medium" @click="onSubmitForm('formData')">Facturar</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <!-- <el-button type="primary" size="small" @click="onSubmitForm('formData')"
        >Guardar</el-button
      > -->
    </span>
  </el-dialog>
</template>
