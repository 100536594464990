
import _ from 'lodash'
import Vue from "vue";
import Router from "vue-router";
import store from "./../pages/dashboard/Store/store"
import ApiContext from '../core/infraestructure/ApiContext'


Vue.use(Router);



// WEBSITE PAGES
import HomePageView from "./../components/website/Home/HomePageView";
import AboutPageView from "./../components/website/About/AboutPageView";
import ContactPageView from "./../components/website/Contact/ContactPageView";
import FaqsPageView from "./../components/website/Faqs/FaqsPageView";
import RequestQuotePageView from "./../components/website/RequestQuote/RequestQuotePageView";
import TrackingDeliveryPageView from "./../components/website/TrackingDelivery/TrackingDeliveryPageView";
import TransportPageView from "./../components/website/TransportPage/TransportPageView";
import NotFound from "./../components/notfound/NotFound";


// ADMIN MODULES
import ModuleUsersView from "./../components/users/ModuleUsersView";
// import ModuleMeasureUnitsView from "./../components/measureUnits/ModuleMeasureUnitsView";

import ModuleWorkRoutesView from "../components/workRoutes/ModuleWorkRoutesView";

import ModulePlaceView from "./../components/places/ModulePlaceView";
//import ModuleDestinationView from "./../components/destinations/ModuleDestinationView";
// import ModuleTraceStatusReasonView from "./../components/traceStatusReasons/ModuleTraceStatusReasonView";
// import ModuleBranchOfficeView from "./../components/branchoffices/ModuleBranchOfficeView";
// import ModuleVehiclesView from "./../components/vehicles/ModuleVehiclesView";
import ModuleServiceCostView from "./../components/serviceCosts/ModuleServiceCostView";
import ModulePrepaidPlanView from "./../components/prepaidPlans/ModulePrepaidPlanView";
import ModuleReceptionsView from "./../components/receptions/ModuleReceptionsView";
import ModuleCustomersView from "./../components/customers/ModuleCustomersView";
import ModuleShipmentView from "./../components/shipment/ModuleShipmentView";
import ModuleTrackingView from "./../components/trackings/ModuleTrackingView";
import ModuleSolvencyView from "./../components/solvencies/ModuleSolvencyView";
import ModuleProductTypeView from "./../components/productTypes/ModuleProductTypeView";
import ModuleDashboardView from "./../components/dashboard/ModuleDashboardView";
import ModuleRoleView from "./../components/roles/ModuleRoleView";
import ModuleEmployeesView from './../components/employees/ModuleEmployeesView';
import ModuleBillConceptView from "./../components/billConcepts/ModuleBillConceptView";
import ModuleSaleView from "./../components/sales/ModuleSaleView";
import ModuleOrderView from "./../components/orders/ModuleOrderView";
import ModuleGpsTrackingView from "./../components/gpsTracking/ModuleGpsTrackingView"

import TokenStorageHelper from "./../core/helpers/TokenStorageHelper";
import LoginPageView from "./../components/authentication/LoginPageView";
import RecoveryPasswordView from "./../components/authentication/RecoveryPasswordView";
import RestorePasswordView from "./../components/authentication/RestorePasswordView";




//DASHBOARD CUSTOMERS
import ModuleDashboardCustomerView from "./../components/dashboardCustomer/ModuleDashboardCustomerView";
import ModuleCustomerLocationView from "./../components/customersLocations/ModuleCustomerLocationView";
import ModuleCustomerReceptionView from "./../components/customersReceptions/ModuleCustomerReceptionView";
import ModuleCustomerSolvencyView from "./../components/customersSolvencies/ModuleCustomerSolvencyView";
import ModuleCustomerUsersView from "./../components/customersAccounts/ModuleCustomerUsersView";
import ModuleCustomerBillsView from './../components/customersBills/ModuleCustomerBillView.vue'
import ModuleUserProfileView from "./../components/customersProfiles/ModuleUserProfileView";
import ModuleCustomerContactView from './../components/customersContacts/ModuleCustomerContactView'
import ModuleCustomerVerificationCodeView from './../components/customersVerificationCodes/ModuleCustomerVerificationCodeView'



const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    // WEBSITE PAGES
    {
      path: '/',
      name: 'index',
      component: HomePageView,

      meta: {
        auth: false,
      },
    },
    {
      path: "/index.html",
      name: "indexRedirect",
      redirect: { name: "index" },
      meta: {
        auth: false,
      },
    },
    {
      path: '/empresa/acerca',
      name: 'about-company',
      component: AboutPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/empresa/preguntas-frecuentes',
      name: 'faqs-company',
      component: FaqsPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/servicios/transporte',
      name: 'transport-service',
      component: TransportPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/servicios/solicitar-cotizacion',
      name: 'request-quote-service',
      component: RequestQuotePageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/servicios/rastrear-envios',
      name: 'tracking-delivery-service',
      component: TrackingDeliveryPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/contacto',
      name: 'contact',
      component: ContactPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        auth: false,
      },
    },

    // ADMIN MODULES ROUTE
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "ModuleDashboardView" },
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/login",
      name: "login",
      component: LoginPageView,
      meta: {
        auth: false,
        admin: true
      },
    },
    {
      path: "/admin/recovery-password",
      name: "recovery",
      component: RecoveryPasswordView,
      meta: {
        auth: false,
        admin: true
      },
    },
    {
      path: "/admin/restore-password",
      name: "restore",
      component: RestorePasswordView,
      meta: {
        auth: false,
        admin: true
      },
    },
    {
      path: "/admin/resumen",
      name: "ModuleDashboardView",
      component: ModuleDashboardView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/mostrador",
      name: "ModuleReceptionsView",
      component: ModuleReceptionsView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/manifiestos",
      name: "ModuleShipmentView",
      component: ModuleShipmentView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/guias-generadas",
      name: "ModuleTrackingView",
      component: ModuleTrackingView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/ventas-y-carta-porte",
      name: "ModuleSaleView",
      component: ModuleSaleView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/ordenes",
      name: "ModuleOrderView",
      component: ModuleOrderView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/costos-de-servicios",
      name: "ModuleServiceCostView",
      component: ModuleServiceCostView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/planes-de-prepago",
      name: "ModulePrepaidPlanView",
      component: ModulePrepaidPlanView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/clientes",
      name: "ModuleCustomers",
      component: ModuleCustomersView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/creditos-y-prepago",
      name: "ModuleSolvencyView",
      component: ModuleSolvencyView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/autorizacion/empleados",
      name: "ModuleEmployees",
      component: ModuleEmployeesView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/autorizacion/usuarios",
      name: "ModuleUsers",
      component: ModuleUsersView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/autorizacion/roles-y-permisos",
      name: "ModuleRoleView",
      component: ModuleRoleView,
      meta: {
        auth: true,
      },
    }, 
    {
      path: "/admin/catalogo-de-ubicaciones",
      name: "ModulePlace",
      component: ModulePlaceView,
      meta: {
        auth: true,
      },
    },    
    {
      path: "/admin/catalogo-de-transporte",
      name: "ModuleWorkRoutesView",
      component: ModuleWorkRoutesView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/catalogo-de-productos",
      name: "ModuleProductTypeView",
      component: ModuleProductTypeView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/catalogo-de-ventas",
      name: "ModuleBillConceptView",
      component: ModuleBillConceptView,
      meta: {
        auth: true,
      },
    },
    
    
    // DASHBOARD CUSTOMERS
    {
      path: "/clientes",
      name: "ModuleDashboardCustomerView",
      component: ModuleDashboardCustomerView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/ubicaciones",
      name: "ModuleCustomerLocationView",
      component: ModuleCustomerLocationView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/contactos",
      name: "ModuleCustomerContactView",
      component: ModuleCustomerContactView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/qrs-de-verificacion",
      name: "ModuleCustomerVerificationCodeView",
      component: ModuleCustomerVerificationCodeView,
      meta: {
        auth: true,
      },
    },
    
    {
      path: "/clientes/guias",
      name: "ModuleCustomerReceptionView",
      component: ModuleCustomerReceptionView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/facturas",
      name: "ModuleCustomerBillsView",
      component: ModuleCustomerBillsView,
      meta: {
        auth: true,
      },
    },
    
    {
      path: "/clientes/credito-y-prepago",
      name: "ModuleCustomerSolvencyView",
      component: ModuleCustomerSolvencyView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/cuentas-de-usuarios",
      name: "ModuleCustomerUsersView",
      component: ModuleCustomerUsersView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/clientes/mi-cuenta",
      name: "ModuleUserProfileView",
      component: ModuleUserProfileView,
      meta: {
        auth: true,
      },
    },
    {
      path: "/admin/autorizacion/rastreo-gps",
      name: "ModuleGpsTrackingView",
      component: ModuleGpsTrackingView,
      meta: {
        auth: true,
      },
    },
    
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (!TokenStorageHelper.isAuthenticated() && from.name !== "login") {
      next({ name: "login" });
    } else {
      if (!_.isEmpty(store.state.admin.Permissions) && !_.isEmpty(store.state.admin.userCurrent)) {
        next();
      } else {
        ApiContext.authorization.findPermissionsByRole(TokenStorageHelper.getUserRole().toString())
          .then((response) => {
            store.dispatch("admin/setRolePermissions", response.data)
            return ApiContext.users.getUserCurrentApi()
          })
          .then((response) => {
            store.dispatch("admin/setCurrentUser", response.data)
            if((response.data.userType === 0 || response.data.userType === 1) && _.includes(to.fullPath, '/clientes')){
              next({ name: "ModuleDashboardView" });
            }else if((response.data.userType === 2 || response.data.userType === 3) && _.includes(to.fullPath, '/admin')){
              next({ name: "ModuleDashboardCustomerView" });
            } else {
              next()
            }
          })
          .catch(() => {
            next({ name: "login" });
          })
      }
    }
  } else {
    next();
  }
});

export default router;

