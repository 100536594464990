import _ from "lodash"

export default class Shipment {
  constructor(options, state) {
    if (state) {
      this.state = state
    }
    Object.assign(this, options)
  }

  toSend() {
    return {
      id: this.id,
      shipmentDate: this.shipmentDate,
      shipmentArrivedDate: this.shipmentArrivedDate,
      operatorId: (this.operator) ? this.operator.id : null,
      responsibleId: (this.responsible) ? this.responsible.id : null,
      receiverEmployeeId: (this.receiverEmployee) ? this.receiverEmployee.id : null,
      shipmentStatus: this.shipmentStatus,
      isActive: this.isActive,
      vehicleId: this.vehicle.id,
      commentaries: this.commentaries,
      shipmentReceptions: this.mapShipmentReceptions(this.shipmentReceptions),
    }
  }

  toSendUpdate() {
    return {
      id: this.id,
      shipmentDate: this.shipmentDate,
      shipmentArrivedDate: this.shipmentArrivedDate,
      commentaries: this.commentaries,
      operatorId: this.operator.id,
      vehicleId: this.vehicle.id,
      shipmentStatus: this.shipmentStatus,
      receiverEmployeeId: (this.receiverEmployee) ? this.receiverEmployee.id : null,
      isActive: this.isActive,
      shipmentReceptions: this.mapShipmentReceptions(this.shipmentReceptions),
    }
  }

  mapShipmentReceptions(collection) {
    return _.map(collection, (item) => {
      return {
        id: item.id,
        destinationPlaceId: (item.destination) ? item.destination.id : null,
        updateState: item.updateState,
        receptions: this.mapReceptions(item.receptions)
      }
    })
  }

  mapReceptions(collection) {
    if(collection.length == 0) return []
    return collection.map((item) => {
      return {
        id: item.id,
        updateState: item.updateState,
        devolutionReasonId: (item.devolutionReason) ? item.devolutionReason.id : null,
      }
    })
  }

  toBillingCatalog() {
    return `${this.shipmentNumber} - ${this.destination}`
  }
}
