<script>
import StepThreeComponent from "./StepThreeComponent";
// import TransportFigureCollapseView from "../boxes/TransportFigureCollapseView";
// import TransportationCollapseView from "../boxes/TransportationCollapseView";
// import LocationBoxView from "../boxes/LocationBoxView";
import SaleConceptsBoxView from "../boxes/SaleConceptsBoxView";
import BillInformationBoxView from "../boxes/BillInformationBoxView.vue";

export default {
  components: {
    // "transport-figure-collapse-view": TransportFigureCollapseView,
    // "transportation-collapse-view": TransportationCollapseView,
    // "location-box-view": LocationBoxView,
    "sale-concepts-box-view": SaleConceptsBoxView,
    'bill-information-box-view': BillInformationBoxView
  },
  extends: StepThreeComponent,
};
</script>
<template>
  <el-row
    v-loading="isLoading"
    type="flex"
    class="row-bg reception-steps-wrapper"
    :gutter="20"
  >
    <el-col :span="24">
      <legend>Información De Recepción</legend>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="6">
          <el-form-item
            label="Cobrese ó Devuelvase (C.O.D.)"
            prop="paymentAgainstDelivery"
          >
            <el-input-number
              v-model="formData.paymentAgainstDelivery"
              size="medium"
              :min="0"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="Descuento (%)"
            prop="discountPercentage"
          >
            <el-input-number
              v-model="formData.discountPercentage"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :min="0"
              :max="100"
              step-strictly
              @change="onDiscountChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="Referencia de Guía Física"
            prop="documentReference"
          >
            <el-input
              v-model="formData.documentReference"
              autocomplete="off"
              autofocus="true"
              size="medium"
              type="text"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="6"
          align="center"
        >
          <el-form-item
            label="Estado del Flete"
            prop="freightPayable"
          >
            <el-checkbox
              v-model="formData.freightPayable"
              class="checkbox-primary"
              label="Pagado"
              border
              style="width:100%;"
              :disabled="isFreighPayableDisabled()"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <legend>Información Fiscal</legend>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-tabs
            type="border-card"
            class="tabs-primary"
          >
            <el-tab-pane label="DATOS DE FACTURACIÓN">
              <bill-information-box-view 
                :form-data="formData.sale.saleBill"
                prop-name="sale.saleBill"
                :reception-service-type="formData.receptionServiceType"
                :reception="formData"
              />
            </el-tab-pane>
            <el-tab-pane label="CONCEPTOS DE VENTA">
              <sale-concepts-box-view
                v-if="formData"
                :form-data="formData.sale"
                :packages="formData.receptionPackages"
                :delivery-price-cost="formData.serviceDeliveryPrice"
                :weight-cost-config="weightCostConfig"
                :reception="formData"
                prop-name="sale"
                :can-recalculate="false"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
