<script>
import ModalCustomerLocationComponent from "./ModalCustomerLocationComponent";
import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView"

export default {
  components: {
    AddressBoxView
  },
  extends: ModalCustomerLocationComponent
};
</script>

<template>
  <el-dialog
    width="45%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    custom-class="dialog"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-comment-notes" /> Ubicación de Cliente
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="6">
          <el-form-item
            label="Código Ubicación"
            prop="code"
          >
            <el-input
              ref="code"
              v-model="formData.code"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="6"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <address-box-view
        orientation="vertical"
        :form-data="formData"
        prop-name=""
      />
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
