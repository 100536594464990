<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleReceptionsComponent from './ModuleReceptionsComponent'
import ListReceptionsView from './ListReceptionsView'
// import ListCollectionRouteTracesView from '../collectionRouteTraces/ListCollectionRouteTracesView'

export default {
    components: {
        'admin-menu-view': AdminMenuView,
        'admin-header-view': AdminHeaderView,
        'admin-footer-view': AdminFooterView,
        'list-receptions-view': ListReceptionsView,
        // 'list-collection-route-traces': ListCollectionRouteTracesView
    },
    extends: ModuleReceptionsComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-qrcode-scan module-header-icon" /> Mostrador
                </h4>

                <div class="page-title-right">
                  <!-- <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <a href="javascript: void(0);">Configuraci&oacute;n</a>
                    </li>
                    <li class="breadcrumb-item active">
                      Sucursales
                    </li>
                  </ol> -->
                </div>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="tabActive">
                    <el-tab-pane
                      label="Rutas de Recolección"
                      name="receptions"
                    >
                      <span slot="label"><i class="uil-box me-1" />Recepciones</span>
                      <list-receptions-view v-if="tabActive === 'receptions'" />
                    </el-tab-pane>
                    <!-- <el-tab-pane
                      label="Rutas de Trabajo"
                      name="collection-routes-traces"
                    >
                      <span slot="label"><i class="uil-truck me-1" />Asignación de Paquetes en Ruta</span>
                      <list-collection-route-traces v-if="tabActive === 'collection-routes-traces'" />
                    </el-tab-pane> -->
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>