import BaseModule from '../infraestructure/BaseModule'
import CustomersLocationViewModel from "../../core/features/customerLocation/CustomersLocationViewModel";


export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomersLocationViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}
