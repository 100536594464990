import _ from "lodash";
import BaseModel from "../../infraestructure/BaseModel";
import ConsignmentNote from "./ConsignmentNote";
import SaleConcept from "./SaleConcept";

export default class Sale extends BaseModel {
  constructor(options, state) {
    super(options, state);
  }

  static BILL_TYPE = {
    PAYMENT: "p",
  };

  static DECIMAL_PRECISION = 2;

  toSend() {
    return {
      id: this.id,
      customerId: (this.saleBill.customerBill) ? this.saleBill.customerBill.id : this.customer.id,
      saleBills: this.mapSaleBills(this.saleBills),
      receptions: this.mapReceptions(this.receptions),
      saleConcepts: this.mapConcepts(this.saleConcepts),
      total: this.total,
      totalFederalTax: this.totalFederalTax,
      subtotal: this.subtotal,
      discount: this.discount,
      isActive: this.isActive,
    };
  }

  mapSaleBills(collection) {
    if (_.isEmpty(collection)) {
      return [
        {
          id: this.saleBill.id,
          isPaid: this.saleBill ? this.saleBill.isPaid : null,
          paymentTypeId: this.saleBill.paymentType
            ? this.saleBill.paymentType.id
            : null,
          paymentMethodId: this.saleBill.paymentMethod
            ? this.saleBill.paymentMethod.id
            : null,
          currencyId: this.saleBill.currency ? this.saleBill.currency.id : null,
          billUsingTypeId: this.saleBill.billUsingType
            ? this.saleBill.billUsingType.id
            : null,
          paymentConditions: this.saleBill.paymentConditions,
          fiscalCode: this.saleBill.fiscalCode,
          fiscalName: this.saleBill.fiscalName,
          fiscalEmail: this.saleBill.fiscalEmail,
          mainStreet: (this.saleBill.billAddress) ?  this.saleBill.billAddress.mainStreet : this.saleBill.mainStreet,
          secondaryStreet: (this.saleBill.billAddress) ? this.saleBill.billAddress.secondaryStreet : this.saleBill.secondaryStreet,
          intNumber: (this.saleBill.billAddress) ? this.saleBill.billAddress.intNumber : this.saleBill.intNumber,
          extNumber: (this.saleBill.billAddress) ?  this.saleBill.billAddress.extNumber: this.saleBill.extNumber,
          fiscalPostalCodeId: (this.saleBill.billAddress) ? this.saleBill.billAddress.fiscalPostalCode.id: null,
          postalCodeId: (this.saleBill.postalCode) ? this.saleBill.postalCode.id : null,
          // postalCodeId: (this.saleBill.billAddress) ? this.saleBill.billAddress.postalCode.id: this.saleBill.postalCode.id,
          // neighborhoodId: (this.saleBill.billAddress) ? this.saleBill.billAddress.neighborhood.id : this.saleBill.neighborhood.id,
          // cityId: (this.saleBill.billAddress) ? this.saleBill.billAddress.city.id : this.saleBill.city.id,
          // municipalityId: (this.saleBill.billAddress) ? this.saleBill.billAddress.municipality.id : this.saleBill.municipality.id,
          // districtId: (this.saleBill.billAddress) ? this.saleBill.billAddress.district.id : this.saleBill.district.id,
          // countryId: (this.saleBill.billAddress) ? this.saleBill.billAddress.country.id : this.saleBill.country.id,
          billTypeId: this.saleBill.billType ? this.saleBill.billType.id : null,
          consignmentNote: this.mapConsigmentNote(),
          saleBillPayments: this.saleBill.saleBillPayments
            ? this.mapSaleBillPayments(this.saleBill.saleBillPayments)
            : [],
        },
      ];
    } else {
      return [];
    }
  }

  mapConsigmentNote() {
    if (this.saleBill.billType.code.toLowerCase() == Sale.BILL_TYPE.PAYMENT) return null;
    
    const allPackages = _.flatMap(this.receptions, 'receptionPackages')
    if(this.consignmentNote) this.consignmentNote.hasDangerousMaterial = _.some(allPackages, 'isDangerousMaterial')
    return this.state && this.state == "update"
      ? new ConsignmentNote(this.consignmentNote).toSend()
      : this.consignmentNote;
  }

  mapReceptions(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        documentReference: (this.currentReception) ? this.currentReception.documentReference : "",
        freightPayable: (this.currentReception) ? this.currentReception.freightPayable : false,
        discountPercentage: (this.currentReception) ? this.currentReception.discountPercentage : 0,
        paymentAgainstDelivery: (this.currentReception) ? this.currentReception.paymentAgainstDelivery : 0,
        receptionPackages: item.receptionPackages
          ? this.mapReceptionPackages(item.receptionPackages)
          : [],
      };
    });
  }

  mapReceptionPackages(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        packagingTypeId: item.packagingType.id,
        productTypeId: (item.productType) ? item.productType.id : null,
        measureUnitId: (item.measureUnit) ? item.measureUnit.id : null,
        transportMerchandiseTypeId : (item.transportMerchandiseType) ? item.transportMerchandiseType.id : null,
        isDangerousMaterial: item.isDangerousMaterial,
        dangerousMaterialId: item.dangerousMaterial
          ? item.dangerousMaterial.id
          : null,
      };
    });
  }

  mapConcepts(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        billConceptId: item.billConcept ? item.billConcept.id : null,
        description: item.description,
        amount: item.amount,
        qty: item.qty || 1,
        updateState: item.updateState,
      };
    });
  }

  mapSaleBillPayments(collection) {
    return collection.map((saleBillPayment) => {
      return {
        id: saleBillPayment.id,
        paymentDate: saleBillPayment.paymentDate,
        paymentTypeId: saleBillPayment.paymentType
          ? saleBillPayment.paymentType.id
          : null,
        currencyId: saleBillPayment.currency
          ? saleBillPayment.currency.id
          : null,
        exchangeRate: saleBillPayment.exchangeRate,
        amount: saleBillPayment.amount,
        bankAccountId: saleBillPayment.bankAccount
          ? saleBillPayment.bankAccount.id
          : null,
        operationNumber: saleBillPayment.operationNumber,
        updateState: saleBillPayment.updateState,
        saleBillPaymentRelateds: saleBillPayment.saleBillPaymentRelateds
          ? this.mapPaymentDocument(saleBillPayment.saleBillPaymentRelateds)
          : [],
      };
    });
  }

  mapPaymentDocument(collection) {
    return collection.map((paymentDocument) => {
      return {
        id: paymentDocument.id,
        digitalFiscalReceipt: paymentDocument.digitalFiscalReceipt,
        partialPaymentNumber: paymentDocument.partialPaymentNumber,
        previousBalanceAmount: paymentDocument.previousBalanceAmount,
        amountPaid: paymentDocument.amountPaid,
        unpaidBalanceAmount: paymentDocument.unpaidBalanceAmount,
        updateState: paymentDocument.updateState,
      };
    });
  }

  getConcepts() {
    return this.saleConcepts.filter(
      (item) => item.updateState !== this.UPDATE_STATUS.REMOVED
    );
  }

  addConcept(qty, amount, unitPrice, description) {
    var data = new SaleConcept({
      qty: qty || 1,
      amount: amount || 0,
      unitPrice: unitPrice || 0,
      description: description || "",
      updateState: this.UPDATE_STATUS.ADDED,
    });
    this.saleConcepts.push(data);
  }

  editConcept(indexSelected) {
    let item = this.getConcepts()[indexSelected];
    if (item) {
      let index = this.saleConcepts.indexOf(item);
      if (index != -1) {
        if (item.id) {
          this.saleConcepts[index].updateState = this.UPDATE_STATUS.UPDATED;
        } else {
          this.saleConcepts[index].updateState = this.UPDATE_STATUS.ADDED;
        }
      } else {
        throw new Error("Concepto no encontrado.");
      }
    } else {
      throw new Error("Concepto no encontrado.");
    }
  }

  removeConcept(indexSelected) {
    let item = this.getConcepts()[indexSelected];
    if (item) {
      let index = this.saleConcepts.indexOf(item);
      if (index != -1) {
        if (item.id) {
          this.saleConcepts[index].updateState = this.UPDATE_STATUS.REMOVED;
        } else {
          this.saleConcepts.splice(index, 1);
        }
      } else {
        throw new Error("Concepto no encontrado.");
      }
    } else {
      throw new Error("Concepto no encontrado.");
    }
  }

  getSubtotal() {
    this.subtotal = this.saleConcepts.reduce((total, item) => {
      return total + (item.total || 0);
    }, 0);
    return this.subtotal;
  }

  getDiscount() {
    let currentDiscountPercentage = this.isDiscountAvailable()
      ? (this.discountPercentage || this.currentReception.discountPercentage) / 100
      : 0;
    this.discount = this.getSubtotal() * currentDiscountPercentage;
    return this.discount;
  }

  getFederalTax() {
    let federalTax = ((this.getSubtotal() - this.getDiscount()) * (this.extraWeightTax / 100));
    this.totalFederalTax = parseFloat(federalTax)
    return this.totalFederalTax;
  }

  getTotal() {
    this.total = this.getSubtotal() - this.getDiscount() + this.getFederalTax();
    return this.total;
  }

  isDiscountAvailable() {
    if(this.currentReception) {
      const isDiscountAvailable = (this.currentReception && this.currentReception.discountPercentage && this.currentReception.discountPercentage > 0)
      return isDiscountAvailable;
    } else {
      const isDiscountAvailable = (this.discountPercentage && this.discountPercentage > 0)
      return isDiscountAvailable;
    }    
  }
}
