// import _ from 'lodash'
import BaseModel from "../../infraestructure/BaseModel"
import SaleBillPayment from "./SaleBillPayment"

export default class SaleBill extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    addSaleBillPayment(data) {
        var item = new SaleBillPayment(data, 'create')
        item.updateState = this.UPDATE_STATUS.ADDED
        this.saleBillPayments.push(item)
    }

    deleteSaleBillPayment(currentIndex) {
        var item = this.getSaleBillPayments()[currentIndex]
        if(item) {
            var index = this.saleBillPayments.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.saleBillPayments[index].updateState = this.UPDATE_STATUS.REMOVED
                } else {
                    this.saleBillPayments.splice(index, 1)
                }
            }
        }
        
    }

    getSaleBillPayments() {
        return this.saleBillPayments.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED)
    }
}