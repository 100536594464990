import BaseForm from "../infraestructure/BaseForm";
import CustomerContactViewModel from "../../core/features/customerContact/CustomerContactViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      destinations: [],
      neighborhoods: [],
      fiscalSearch: null,
      activeName: "first",
      rules: {
        contactName: [
          {
            required: true,
            message: "Nombre de contacto requerido.",
            trigger: "blur",
          },
        ],
        contactEmail: [
          {
            required: true,
            message: "Correo de contacto requerido.",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "Teléfono de contacto requerido.",
            trigger: "blur",
          },
        ]
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CustomerContactViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(
        response.message,
        "on-submited-customer-contacts"
      );
    },
    onFindCustomerByFiscalSearch() {
      if (this.fiscalSearch) {
        this.config.isLoading = true;
        this.viewModel.findCustomerByFiscalSearch(
          `FiscalSearch=${this.fiscalSearch}`
        );
      }
    },
    onFindCustomerByFiscalSearchResponse(response) {
      setTimeout(() => {
        this.$set(this.formData, "id", response.data.id);
        this.$set(this.formData, "contactName", response.data.contactName);
        this.$set(this.formData, "contactPhone", response.data.contactPhone);
        this.$set(this.formData, "contactEmail", response.data.contactEmail);
        this.$set(this.formData, "fiscalName", response.data.fiscalName);
        this.$set(this.formData, "fiscalCode", response.data.fiscalCode);
        this.config.isLoading = false;
      }, 400);
    },
    onFindCustomerByFiscalSearchError(error) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onError(error);
      }, 400);
    },
  },
};
