<script>
import SaleConceptsBoxComponent from "./SaleConceptsBoxComponent";

export default {
  extends: SaleConceptsBoxComponent,
};
</script>
<template>
  <div>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24" align="right">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-circle-plus"
          @click="onAddConcept"
        >
          Agregar Concepto
        </el-button>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-form-item prop="sale.saleConcepts">
          <el-table
            v-loading="isLoadingTable"
            :data="formData.getConcepts()"
            style="width: 100%"
          >
            <el-table-column label="CONCEPTO" width="300">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.billConcept"
                  filterable
                  clearable
                  placeholder="Seleccionar..."
                  value-key="id"
                  size="medium"
                  :disabled="!reception.documentReference"
                  @change="onChangeConcept(scope.row)"
                >
                  <el-option
                    v-for="item in billConcepts"
                    :key="item.id"
                    :label="item.toCatalog()"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="DESCRIPCIÓN">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.description"
                  size="medium"
                  type="textarea"
                />
              </template>
            </el-table-column>
            <el-table-column label="CANTIDAD" width="160" align="center">
              <template slot-scope="scope">
                <el-input-number
                  v-if="canRecalculate"
                  v-model="scope.row.qty"
                  size="medium"
                  :min="1"
                  :precision="2"
                />
                <div v-else>
                  {{ scope.row.qty }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="V. UNITARIO" width="160" align="center">
              <template slot-scope="scope">
                <el-input-number
                  v-if="canRecalculate"
                  v-model="scope.row.amount"
                  size="medium"
                  :min="0"
                  :precision="2"
                />
                <div v-else>
                  {{ scope.row.amount | formatMXNCurrency }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="SUBTOTAL" width="180" align="center">
              <template slot-scope="scope">
                {{ scope.row.total | formatMXNCurrency }}
              </template>
            </el-table-column>
            <el-table-column
              label="Opciones"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <el-dropdown @command="onChangeOption">
                  <el-button size="small">
                    <i class="el-icon-more" />
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-if="scope.row.id"
                        :command="{
                          command: 'edit',
                          item: scope.row,
                          index: scope.$index,
                        }"
                      >
                        <i class="el-icon-edit" /> Mostrar
                      </el-dropdown-item>
                      <el-dropdown-item
                        :command="{
                          command: 'delete',
                          item: scope.row,
                          index: scope.$index,
                        }"
                      >
                        <i class="el-icon-delete" /> Eliminar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      v-if="isConceptsAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col :span="3" align="center">
        Subtotal
      </el-col>
      <el-col :span="3" align="center">
        {{ formData.getSubtotal() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      v-if="isConceptsAvailable() && formData.isDiscountAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col :span="3" align="center">
        Descuento
      </el-col>
      <el-col :span="3" align="center">
        {{ formData.getDiscount() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      v-if="isConceptsAvailable() && formData.isDiscountAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col :span="3" align="center">
        Subtotal con Descuento
      </el-col>
      <el-col :span="3" align="center">
        {{
          (formData.getSubtotal() - formData.getDiscount()) | formatMXNCurrency
        }}
      </el-col>
    </el-row>
    <el-row
      v-if="isConceptsAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col :span="3" align="center">
        I.V.A
      </el-col>
      <el-col :span="3" align="center">
        {{ formData.getFederalTax() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      v-if="isConceptsAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col :span="3" align="center">
        Total
      </el-col>
      <el-col :span="3" align="center">
        {{ formData.getTotal() | formatMXNCurrency }}
      </el-col>
    </el-row>
  </div>
</template>
