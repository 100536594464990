import BaseModule from '../infraestructure/BaseModule'
import PrepaidPlanViewModel from '../../core/features/prepaidPlans/PrepaidPlanViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new PrepaidPlanViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}