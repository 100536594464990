import _ from 'lodash'
import BaseForm from '../../infraestructure/BaseForm'
import ReceptionsViewModel from '../../../core/features/receptions/ReceptionsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            formData: {
                dateRange: null,
                customers: [],
                destinations: [],
                freightPayable: null,
            },
            reportType: "1",
            customers: [],
            destinations: [],
            result: [],
            filters: [],
            rules: {
                dateRange: [
                    {
                      required: true,
                      message: "Fecha requerida.",
                      trigger: "blur",
                    },
                ],
                /* customers: [
                    {
                      required: true,
                      message: "Cliente(s) requerido(s).",
                      trigger: "blur",
                    },
                ], */
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.config.isLoading = true;
            this.viewModel.bindFormFinancialHistory()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onFindFinancialHistory()
                    return true
                }
                return false
            })
        },
        onPrint(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.removeFilter('FileType');
                    this.addFilter('FileType', "pdf")
                    this.onPrintFinancialHistory()
                    return true
                }
                return false
            })
        },
        onExport(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.removeFilter('FileType');
                    this.addFilter('FileType', "xls")
                    this.onPrintFinancialHistory()
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-receptions')
        },
        onFindFinancialHistory() {
            this.config.isLoading = true
            this.viewModel.findFinancialHistory(this.viewModel.getQueryParameters(this.filters))
        },
        onFindFinancialHistoryError(error) {
            this.onError(error)
            setTimeout(() => {
                this.config.isLoading = false
            }, 400);
        },
        onFindFinancialHistoryResponse(response) {
            this.result = response.data
            setTimeout(() => {
                this.config.isLoading = false
            }, 800);
        },
        onPrintFinancialHistory() {
            this.config.isLoading = true
            this.viewModel.printFinancialHistory(this.viewModel.getQueryParameters(this.filters))
        },
        onPrintFinancialHistoryResponse(response) {
            setTimeout(() => {
                this.config.isLoading = false
                this.viewModel.addDownloadEvent(response.data, response.fileName, (response.fileExtension == 'xls') ? "application/vnd.ms-excel" : "application/pdf", () => {
                    setTimeout(() => {
                      this.notifySuccess(response.message);
                    }, 400);
                  });
            }, 800);
        },
        onChangeFreightPayable(data) {
            if(!_.isNil(data)) {
                this.addFilter('FreightPayable', data);
              } else {
                this.removeFilter('FreightPayable');
              }
        },
        onChangeDateRange(data) {
            if (data) {
                var dateRange = this.viewModel.formatDateRangeDate(data)
                this.addFilter('StartDate', dateRange.startDate)
                this.addFilter('EndDate', dateRange.endDate)

            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
        },
        onFindCustomers(data) {
            if(data) {
              this.viewModel.findCustomers(`FiscalName=${data}`)  
            } else {
                this.customers = []
            }
        },
        onFindCustomersResponse(response) {
            this.customers = response.data
        },
        onChangeCustomers(data) {
            if(data.length > 0) {
                this.addFilter('Customers', this.viewModel.mapFilterCustomers(data))
            } else {
                this.removeFilter('Customers')
            }
        },
        onChangeDestinations(data) {
            if(data.length > 0) {
                this.addFilter('DestinationNames', this.viewModel.mapFilterSenderCustomers(data))
            } else {
                this.removeFilter('DestinationNames')
            }
        },
        addFilter(filterName, filterValue) {
            this.removeFilter(filterName);
            this.filters.push({ filterName: filterName, filterValue: filterValue });
        },
        removeFilter(filterName) {
            _.remove(this.filters, { filterName: filterName });
        },
        
    }
}