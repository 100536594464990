import BasePage from "../infraestructure/BasePage";


export default {
  extends: BasePage,
  created() { },
  data: function () {
    return {
      fullscreenLoading: false,
      formData: {
        email: "",
        password: "",
      },
      rules: {
        email: [{
          required: true,
          message: 'Correo requerido.',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: 'Contraseña requerido.',
          trigger: 'blur'
        }],
      }
    };
  },

  methods: {
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.fullscreenLoading = true;
          this.viewModel.doLogin(this.formData)
          return true
        }
        return false
      })
    },
    onLoginResponse(response) {
      this.viewModel.setToken(response.token)
      this.viewModel.findPermissionsByRole()
    },
    onFindPermissionsByRoleResponse(response) {
      this.setProfilePermissions(response);

      this.$store.dispatch("admin/signIn", response).then(() => {
        this.viewModel.findCurrentUser()
      });
    },
    onFindCurrentUserResponse(response) {
      this.$store.dispatch("admin/setCurrentUser", response)
        .then(() => {
          setTimeout(() => {
            this.fullscreenLoading = false;
            if (response.userType === 2 || response.userType === 3) {
              this.$router.push({ name: "ModuleDashboardCustomerView" });
            } else {
              this.$router.push({ name: "ModuleDashboardView" });
            }
          }, 2000);

        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    onLoginError(error) {
      this.fullscreenLoading = false;
      this.onError(error)
    }
  },
};