<script>
import UserProfileComponent from "./UserProfileComponent";
import ModalUserProfileView from "./ModalUserProfileView";

export default {
  components: {
    "modal-user-profile-view": ModalUserProfileView,
  },
  extends: UserProfileComponent,
};
</script>
<template>
  <div class="row mb-4 text-center d-flex justify-content-center">
    <div class="col-xl-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="text-center">
            <div class="dropdown float-end">
              <!-- <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="onCreate"
              /> -->
              <modal-user-profile-view
                v-if="modalConfig.isVisible"
                :form-data="item"
                :config="modalConfig"
              />
            </div>
            <div class="clearfix" />
            <div>
              <img
                :src="
                  require('@/assets/dashboard/assets/images/users/user.png')
                "
                alt=""
                class="avatar-lg rounded-circle img-thumbnail"
              >
            </div>
            <h5 class="mt-3 mb-1">
              {{ currentSession.name }}
            </h5>
            <p class="text-muted">
              {{ currentSession.userTypeName }}
            </p>
          </div>
          <hr class="my-4">
          <div class="text-muted">
            <div class="table-responsive mt-4">
              <div>
                <p class="mb-1">
                  Nombre :
                </p>
                <h5 class="font-size-16">
                  {{ currentSession.contactName }}
                </h5>
              </div>
              <div class="mt-4">
                <p class="mb-1">
                  E-mail :
                </p>
                <h5 class="font-size-16">
                  {{ currentSession.email }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
