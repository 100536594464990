<script>
import ModalRoleComponent from "./ModalRoleComponent";

export default {
  extends: ModalRoleComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-role"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    height="50px"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-shield" /> Roles y permisos
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="16">
          <el-form-item
            label="Nombre"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="8"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="tabs-permit-assignment">
        <el-col :span="24">
          <el-tabs v-model="permissionsTabActive">
            <el-tab-pane
              label="Asiganación de Permisos"
              name="permissions"
              class="my-4"
            >
              <el-row
                type="flex"
                class="row-bg"
                :gutter="20"
              >
                <el-col
                  :span="24"
                  align="right"
                >
                  <el-checkbox
                    v-model="checkAllPermissions"
                    @change="onCheckAllPermissions"
                  >
                    Asignar Todos Los Permisos
                  </el-checkbox>
                </el-col>
              </el-row>
              <el-tabs :tab-position="'left'">
                <el-tab-pane
                  v-for="(module, index) in modules"
                  :key="index"
                  :label="module.name"
                >
                  <div>
                    <h6><i :class="module.icon" /> {{ module.name }}</h6>
                    <el-table
                      ref="multipleTable"
                      :data="module.permissions"
                      index
                      style="width: 100%"
                    >
                      <el-table-column
                        property="name"
                        label="Permiso"
                      >
                        <template slot-scope="scope">
                          {{ scope.row.name }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        property="isActive"
                        label="Estado"
                        width="120"
                        type="index"
                      >
                        <template
                          slot-scope="scopePermission"
                          name="scopePermission"
                        >
                          <el-switch
                            v-model="
                              module.permissions[scopePermission.$index]
                                .isActive
                            "
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                            
                            @change="onChangePermission(module,scopePermission.row,scopePermission.$index)"
                          />
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="my-4 d-flex justify-content-center">
                      <el-button
                        type="info"
                        icon="el-icon-turn-off"
                        @click="selectionAllOrClear(module, false)"
                      >
                        Limpiar selección
                      </el-button>
                      <el-button
                        type="success"
                        icon="el-icon-open"
                        @click="selectionAllOrClear(module, true)"
                      >
                        Seleccionar todo
                      </el-button>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" />
      </el-row>
      <el-row>
        <el-col :span="24" />
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
