import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import ReceptionsViewModel from '../../core/features/receptions/ReceptionsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Reception',
            viewModel: null,
            itemSelected: null,
            origins: [],
            destinations: [],
            isMoreFilterVisible: false,
            filtersSelected: {
                receptionNumber: '',
                originName: '',
                destinyName: '',
                beginingDate: '',
                endingDate: '',
                receptionType: '',
                receptionServiceType: '',
                statusTracking: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR RECEPCIÓN',
                onUploadImport: 'on-upload-import-receptions',
                onCancelImport: 'on-cancel-import-receptions'
            },
            paymentTypes: [],
            receptionTypes: ReceptionsViewModel.receptionTypes,
            //paymentTypes: ReceptionsViewModel.paymentTypes,
            receptionTrackingStatus: ReceptionsViewModel.receptionTrackingStatus,
            receptionServiceTypes: ReceptionsViewModel.receptionServiceTypes
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-reception', this.onSearch)
        this.onMountedEvent('on-upload-import-receptions', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-receptions', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-reception', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-receptions', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-receptions', this.onCancelImport);
    },
    created() {
        this.viewModel = new ReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectBranchOffice() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
                receptionDate: new Date(),
                receptionPackages: [],
                customerReceptions: [],
                autoTransports: [],
                payerResponsible: '',
                sender: {},
                receiver: {},
                receptionType: 2,
                sale: {
                    saleBill: {
                        fiscalName: '',
                        fiscalCode: '',
                        fiscalEmail: '',
                        billAddress: {}
                    },
                    saleConcepts: [],
                },
                consignmentNote: {
                    origin: {},
                    destination: {},
                    transportationFigures: [],
                    autoTransportations: [],
                },
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByReceptionNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ReceptionNumber', text)
            } else {
                this.removeFilter('ReceptionNumber')
            }
            this.onSearch();
        }, 500),
        searchOriginFilter(data) {
            if (data) {
                this.addFilter('OriginName', data.name)
            } else {
                this.removeFilter('OriginName')
            }
            this.onSearch()
        },
        searchDestinationFilter(data) {
            if (data) {
                this.addFilter('DestinyName', data.name)
            } else {
                this.removeFilter('DestinyName')
            }
            this.onSearch()
        },
        searchReceptionTypeFilter(data) {
            if (data !== null) {
                this.addFilter('ReceptionType', data)
            } else {
                this.removeFilter('ReceptionType')
            }
            this.onSearch()
        },
        searchReceptionServiceTypeFilter(data) {
            if (data !== null) {
                this.addFilter('ReceptionServiceType', data)
            } else {
                this.removeFilter('ReceptionServiceType')
            }
            this.onSearch()
        },
        serachStatusTrackingFilter(data) {
            if (data !== null) {
                this.addFilter('StatusTracking', data)
            } else {
                this.removeFilter('StatusTracking')
            }
            this.onSearch()
        },
        onSearchBeginingDate: _.debounce(function (dateRange) {
            if (dateRange) {
                this.addFilter('BeginingDate', dateRange[0])
                this.addFilter('EndingDate', dateRange[1])
            } else {
                this.removeFilter('BeginingDate')
                this.removeFilter('EndingDate')
            }
            this.onSearch();
        }, 500),
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onGetNameReceptionType(type) {
            return this.viewModel.getNameReceptionType(type)
        },
        onGetNamePaymentType(type) {
            return this.viewModel.getNamePaymentType(type)
        },
        onGetNameClientType(type) {
            return this.viewModel.getNameClientType(type)
        },
        onGetNameTrackingStatus(status) {
            return this.viewModel.getNameTrackingStatus(status)
        },
        onGetIconTrackingStatus(status) {
            return this.viewModel.getIconTrackingStatus(status)
        },
        onGetStyleTrackingStatus(status) {
            return this.viewModel.getStyleTrackingStatus(status)
        },
        onChangeOption(event) {
            switch (event.command) {
                case 'edit':
                    this.onEdit(event.item.id);
                    break;
                case 'delete':
                    this.onDelete(event.item.id);
                    break;
                case 'generate-tracking-document':
                    this.itemSelected = event.item;
                    this.onPrintDocument(event.item.id)
                    break;
                case 'generate-tracking-labels':
                    this.itemSelected = event.item;
                    this.onPrintTrackingLabels(event.item.id)
                    break;
                default:
                    this.onError('Opción no valida')
                    break;
            }
        },
        onPrintDocument(receptionId) {
            this.isListLoading = true
            this.viewModel.printTrackingDocument(receptionId);
        },
        onPrintTrackingDocumentResponse(response) {
            setTimeout(() => {
                this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () =>{
                    this.notifySuccess("Guía Generada.")
                    this.isListLoading = false
                })
            }, 400);
        },
        onPrintTrackingLabels(receptionId) {
            this.isListLoading = true
            this.viewModel.printTrackingLabels(receptionId);
        },
        onPrintTrackingLabelsResponse(response) {
            
            setTimeout(() => {
                this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () =>{
                    this.notifySuccess("Etiquetas Generadas.")
                    this.isListLoading = false
                })
            }, 400);
            
        }
    }
}
