import moment from 'moment'

export default class GpsLocation {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  getMarkerContent() {
    return `
        <strong>CUENTA:</strong><br />
        ${this.account.toUpperCase()} <br /><br /> 
        <strong>DESCRIPCIÓN:</strong><br />
        ${this.description}<br /><br />
        <strong>INFORMACIÓN DE MONITOREO:</strong><br />
        ${moment(this.locationDate).format("DD MMM YYYY hh:mm:ss a")}<br />
    `;
  }
}
