<script>
import AutotransportationBoxComponent from "./AutotransportationBoxComponent";

export default {
  extends: AutotransportationBoxComponent,
};
</script>
<template>
  <el-collapse-item
    title="AUTOTRANSPORTE"
    name="autoTransport"
  >
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-form-item
          label="Tipo de Permiso SCT"
          :prop="`${propName}.sctPermissionType`"
          required
        >
          <el-select
            v-model="formData.sctPermissionType"
            placeholder="Seleccionar tipo de permiso"
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in sctPermissionTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item
          label="Num. Permiso SCT"
          :prop="`${propName}.sctPermissionNumber`"
          required
        >
          <el-input
            type="text"
            v-model="formData.sctPermissionNumber"
            placeholder="Número de permiso"
            size="medium"
          />
        </el-form-item>
      </el-col> -->
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="right"
      >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-circle-plus"
          @click="onAddAutotransportation"
        >
          Agregar Transporte
        </el-button>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-form-item :prop="`${propName}.autoTransportations`">
          <el-table
            v-loading="isAutoTransportTableLoading"
            :data="formData.getAutotransportations()"
            style="width: 100%"
          >
            <el-table-column
              prop="vehicleDescription"
              label="Modelo"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.vehicle"
                  placeholder="Seleccionar vehículo"
                  value-key="id"
                  size="medium"
                  filterable
                  @change="
                    onChangeTransportVehicle(scope.$index, scope.row.vehicle)
                  "
                >
                  <el-option
                    v-for="item in vehicles"
                    :key="item.id"
                    :label="item.toCatalog()"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="Conf. Vehícular"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.vehicle && scope.row.vehicle.vehicleType.code">
                  {{ scope.row.vehicle.vehicleType.code }} - {{ scope.row.vehicle.vehicleType.name }}
                </div>
                <div v-if="scope.row.vehicle && scope.row.vehicleType">
                  {{ scope.row.vehicleType.code }} - {{ scope.row.vehicleType.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="plateNumber"
              label="Placas"
              align="center"
            />
            <el-table-column
              prop="insuranceCarrier"
              label="Aseguradora"
            />
            <el-table-column
              prop="policyNumber"
              label="Poliza"
              width="200"
              align="center"
            />
            <el-table-column
              label="Opciones"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template #default="scope">
                <el-dropdown @command="onChangeOption">
                  <el-button size="small">
                    <i class="el-icon-more" />
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="{
                          command: 'delete',
                          item: scope.row,
                          index: scope.$index,
                        }"
                      >
                        <i class="el-icon-delete" /> Eliminar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
  </el-collapse-item>
</template>
