import BaseForm from '../infraestructure/BaseForm'
import WorkRoutesViewModel from '../../core/features/workRoutes/WorkRoutesViewModel'
export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'WorkRoute',
            viewModel: null,
            branchOffices:[],
            rules:{
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                branchOffice: [{
                    required: true,
                    message: 'Sucursal requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new WorkRoutesViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-work-route')
        },
        onFindBranchOffices(data){
            if(data) {
                this.viewModel.findbranchOffices(`Name=${data}&IsActive=true`)
            } else {
                this.branchOffices = []
            }
        },
        onFindBranchOfficesResponse(response){
            this.branchOffices = response.data
        },
    }
}