<script>
import ListCustomerUsersComponent from "./ListCustomerUsersComponent";
import ModalCustomerUserView from "./ModalCustomerUserView";
import ModalImportView from "../shared/modals/ModalImportView";
export default {
  components: {
    "modal-customer-user-view": ModalCustomerUserView,
    "modal-import-view": ModalImportView,
  },
  extends: ListCustomerUsersComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="mx-1">
                    <el-input
                      v-model="searchUserName"
                      placeholder="Buscar por Usuario..."
                      prefix-icon="el-icon-search"
                      size="medium"
                      debounce
                      clearable
                      @input="searchUserNameFilter"
                    />
                  </div>
                  <div class="mx-1">
                    <el-input
                      v-model="searchName"
                      placeholder="Buscar por nombre..."
                      prefix-icon="el-icon-search"
                      debounce
                      clearable
                      size="medium"
                      @input="searchNameFilter"
                    />
                  </div>
                  <div class="ms-2 me-1">
                    <el-input
                      v-model="searchEmail"
                      placeholder="Buscar por email..."
                      prefix-icon="el-icon-search"
                      size="medium"
                      debounce
                      clearable
                      @input="searchEmailFilter"
                    />
                  </div>
                  <!-- <div class="ms-1 me-2">
                    <el-input
                      v-model="searchRoleName"
                      placeholder="Buscar por Rol..."
                      prefix-icon="el-icon-search"
                      size="medium"
                      debounce
                      clearable
                      @input="searchRoleNameFilter"
                    />
                  </div> -->
                  <div>
                    <div
                      id="buttonlist"
                      class="mb-3"
                    >
                      <el-dropdown
                        size="medium"
                        class="filter-action"
                      >
                        <el-button
                          type="danger"
                          size="medium"
                        >
                          <i class="uil-filter" /> Filtrar
                          <i class="el-icon-arrow-down el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="my-2">
                            <el-select
                              v-model="defaultStatus"
                              placeholder="Seleccionar Estado"
                              size="medium"
                              :popper-append-to-body="false"
                              clearable
                              @change="searchStatusFilter"
                            >
                              <el-option
                                v-for="item in statusOptionsActive"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              />
                            </el-select>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <!-- <el-dropdown
                        size="medium"
                        class="more-options-action"
                      >
                        <el-button
                          type="plain"
                          size="medium"
                        >
                          <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                          <i class="el-icon-arrow-down el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              @click="onShowImport"
                            >
                              <i class="el-icon-upload2" /> Importar
                            </el-button>
                          </el-dropdown-item>
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              @click="onExport"
                            >
                              <i class="el-icon-download" /> Exportar
                            </el-button>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown> -->
                      <el-button
                        v-if="onUserPermission('Permission.CustomerAccount.Create')"
                        type="primary"
                        size="medium"
                        class="main-action"
                        @click="onCreate"
                      >
                        <i class="fas fa-plus-circle" /> Crear Usuario
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.CustomerAccount.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectUser"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  label="Nombre de Cuenta"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ scope.row.accountName }}
                  </template>
                </el-table-column>
                <el-table-column
                  property="accountPhone"
                  label="Teléfono"
                  width="160"
                  align="center"
                >
                  <template #default="scope">
                    {{ scope.row.accountPhone }}
                  </template>
                </el-table-column>
                <el-table-column
                  property="username"
                  label="Usuario"
                  width="220"
                  align="center"
                >
                  <template #default="scope">
                    {{ scope.row.userName }}
                  </template>
                </el-table-column>
                
                <el-table-column
                  property="email"
                  label="Correo electrónico"
                  show-overflow-tooltip
                />
                <!-- <el-table-column
                  property="roleName"
                  label="Tipo Cuenta"
                  width="200"
                  align="center"
                  show-overflow-tooltip
                /> -->
                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isActive"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" /> Activo
                    </el-tag>
                    <el-tag
                      v-if="!scope.row.isActive"
                      type="danger"
                      effect="plain"
                    >
                      <i class="el-icon-error" /> Inactivo
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="
                    onUserPermission('Permission.CustomerAccount.Update') ||
                      onUserPermission('Permission.CustomerAccount.Delete')
                  "
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOptionCustomerAccounts">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.CustomerAccount.Update')
                            "
                            :command="{ command: 'edit', item: scope.row, customerId: customerId, }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.CustomerAccount.Delete')
                            "
                            :command="{ command: 'delete', item: scope.row, customerId: customerId, }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-customer-user-view
      v-if="modalConfig.isVisible && customerId"
      :form-data="item"
      :config="modalConfig"
      :customer-id="customerId"
    />
  </div>
</template>
