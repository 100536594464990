import BaseForm from '../../infraestructure/BaseForm';
import SaleViewModel from "../../../core/features/sales/SaleViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            drivers: [],
            viewModel: null,
            isAddressLoading: false,
            transportationTypes: [],
            diverSelectedIndex: null,
            isDriverTableLoading: false,
        }
    },
    created() {
        this.viewModel = new SaleViewModel(this)
        this.onActive()
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        propName: {
            type: String,
            required: true
        }
    },
    methods: {
        onActive() {
            this.viewModel.api.employees.find(`skip=0&limit=50000&IsActive=true&isTransportFigure=true`)
                .then((response) => {
                    this.drivers = this.viewModel.mapUsers(response.data.data)
                    return this.viewModel.api.transportationTypes.find(`skip=0&limit=50000&IsActive=true`)
                })
                .then((response) => this.onFindTransportationTypesResponse(response.data))
                .catch((error) => this.onError(error))
        },
        onFindTransportationTypesResponse(response){
            this.transportationTypes = response.data
            this.formData.transportationType = (this.formData.transportationType) ?  this.formData.transportationType :  response.data.find(item => item.isDefault)
        },
        onAddDriver() {
            this.isDriverTableLoading = true
            setTimeout(() => {
                this.formData.addTransportFigure()
                this.isDriverTableLoading = false
            }, 400);
        },
        onEditDriver() {

        },
        onDeleteDriver() {
            this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
              })
                .then(() => {
                    this.isDriverTableLoading = true
                    setTimeout(() => {
                        this.formData.deleteTransportFigure(this.diverSelectedIndex);
                        this.isDriverTableLoading = false
                    }, 400);
                })
                .catch(() => {
                    this.diverSelectedIndex = null
                });
        },
        onChangeDriver(index, driver) {
            this.diverSelectedIndex = index
            if (driver) {
                this.isDriverTableLoading = true
                this.viewModel.api.employees.findOne(driver.id)
                    .then((response) => this.onFindDriverResponse(response.data))
                    .catch((error) => this.onError(error))
            }
        },
        onFindDriverResponse(response) {
            this.$set(this.formData.getTransportFigures()[this.diverSelectedIndex], "federalTaxPayerRegistration", response.data.federalTaxPayerRegistration)
            this.$set(this.formData.getTransportFigures()[this.diverSelectedIndex], "licenseNumber", response.data.licenseNumber)
            this.$set(this.formData.getTransportFigures()[this.diverSelectedIndex], "licenseExpiration", response.data.licenseExpiration)
            this.$set(this.formData.getTransportFigures()[this.diverSelectedIndex], "identificationNumber", response.data.identificationNumber)
            this.$set(this.formData.getTransportFigures()[this.diverSelectedIndex], "address", this.getAddress(response.data.address))
            this.diverSelectedIndex = null
            setTimeout(() => {
                this.isDriverTableLoading = false
            }, 400);
        },
        getAddress(address) {
            if(!address) return "Sin Dirección"
            var result = []
            result.push(address.mainStreet)
            if(address.secondaryStreet) result.push(address.secondaryStreet)
            if(address.extNumber) result.push(`No. Ext: ${address.extNumber}`)
            if(address.intNumber) result.push(`No. Int: ${address.intNumber}`)
            if(address.postalCode) result.push(`C.P.: ${address.postalCode.code}`)
            if(address.neighborhood) result.push(`Col. ${address.neighborhood.name}`)
            if(address.city) result.push(`${address.city.name}`)
            if(address.municipality) result.push(`${address.municipality.name}`)
            if(address.district) result.push(`${address.district.name}`)
            if(address.country) result.push(`${address.country.code}`)
            return result.join(", ");
        },
        onChangeOption(option) {
            this.diverSelectedIndex = option.index
            if(option.command === 'edit') this.onEditDriver(option.item)
            if(option.command === 'delete') this.onDeleteDriver(option.item)
        },

    }
}