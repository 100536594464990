import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import TransportMerchandiseTypeViewModel from "../../core/features/transportMerchandiseTypes/TransportMerchandiseTypeViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: "TransportMerchandiseType",
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                status: '',
                dangerousMaterial: '',
            },
            batchGroups: [],
            batchPosition: 0,
            totalRegistersByBatch: 2000,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR TIPOS DE BIENES",
                onUploadImport: "on-upload-import-standard-transportation-commodities",
                onCancelImport: "on-cancel-import-standard-transportation-commodities",
            },
        };
    },
    mounted() {
        this.onMountedEvent(
            "on-submited-standard-transportation-commodity",
            this.onSearch
        );
        this.onMountedEvent(
            "on-upload-import-standard-transportation-commodities",
            this.onUploadImport
        );
        this.onMountedEvent(
            "on-cancel-import-standard-transportation-commodities",
            this.onCancelImport
        );
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent(
            "on-submited-standard-transportation-commodity",
            this.onSearch
        );
        this.onBeforeDestroyEvent(
            "on-upload-import-standard-transportation-commodities",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-standard-transportation-commodities",
            this.onCancelImport
        );
    },
    created() {
        this.viewModel = new TransportMerchandiseTypeViewModel(this);
        this.onActive();
    },

    methods: {
        onActive() {
            this.onSearch();
        },
        onSelectStandardTransportationCommodity() { },
        onCreate() {
            this.item = this.viewModel.create({ isActive: true });
            this.modalConfig.isVisible = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchDangerousMaterialFilter(data) {
            if (data !== null) {
                this.addFilter('IsDangerousMaterial', data)
            } else {
                this.removeFilter('IsDangerousMaterial')
            }
            this.onSearch()
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onUploadImport(file) {
            this.modalImport.isLoading = true;
            this.viewModel.import(file);
        },
        onImportResponse(response) {
            this.batchGroups = this.viewModel.getTransportMerchandiseTypesByChunks(
                response.data,
                this.totalRegistersByBatch
            );
            if (this.batchGroups.length > 0) {
                this.viewModel.batchTransportMerchandiseTypesByChunks(
                    this.batchGroups[this.batchPosition]
                );
            } else {
                this.modalImport.isLoading = false;
                this.notifyWarning(response.message);
            }
        },
        async onBatchResponse() {
            console.log('on batch response')
            this.batchPosition += 1;
            if (this.batchGroups.length <= this.batchPosition) {
                this.batchPosition = 0;
                this.modalImport.isLoading = false;
                this.modalImport.isVisible = false;
                this.notifySuccess("Tipos de bienes importados.");
                this.onSearch();
            } else {
                this.viewModel.batchTransportMerchandiseTypesByChunks(
                    this.batchGroups[this.batchPosition]
                );
            }
        },
    },
};
