export default class Destination {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    getCodeName(){
      return `${this.code} - ${this.name}`
    }

    toSend() {
      return {
        destinationId: this.id,
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description,
        isActive: this.isActive,
        municipalityId: (this.municipality) ? this.municipality.id : null,
      }
    }
  }