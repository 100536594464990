export default class ReceptionPackage {
    constructor(options, state) {
      Object.assign(this, options)
      if(state){
        this.state = state
      }
      
    }
  
    
    toSend() {
      return {
        id: this.id,
        description: this.description,
        length: this.length,
        weight: this.weight,
        width: this.width,
        height: this.height,
        quantity: this.quantity,
        volumetricWeight: this.volumetricWeight,
        subtotal: this.subtotal,
        serviceDeliveryPrice: this.serviceDeliveryPrice,
        measureUnit: this.measureUnit,
        productType: this.productType,
        extraWeightCost: this.extraWeightCost,
        extraWeightTax: this.extraWeightTax,
        extraWeight: this.extraWeight,
        weightBase: this.weightBase,
        isDangerousMaterial: this.isDangerousMaterial,
        transportMerchandiseId: (this.transportMerchandise) ? this.transportMerchandise.id : null,
        dangerousMaterialId: (this.dangerousMaterial) ? this.dangerousMaterial.id : null,
        packagingTypeId: (this.packagingType) ? this.packagingType.id : null,
        updateState: this.updateState,
      }
    }
  }