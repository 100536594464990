<script>
import AdminMenuCustomerComponent from "./AdminMenuCustomerComponent";

export default {
  extends: AdminMenuCustomerComponent,
};
</script>

<template>
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <router-link
        :to="{ name: 'customer' }"
        class="logo logo-dark"
        title="Resumen"
      >
        <span class="logo-sm">
          <img
            src="/admin/assets/images/logo-small.png"
            alt=""
          >
        </span>
        <span class="logo-lg">
          <img
            src="/admin/assets/images/logo.png"
            alt=""
          >
        </span>
      </router-link>
      <a
        href="index.html"
        class="logo logo-dark"
      />
    </div>

    <button
      type="button"
      class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    >
      <i class="fa fa-fw fa-bars" />
    </button>

    <div
      data-simplebar
      class="sidebar-menu-scroll"
    >
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul
          id="side-menu"
          class="metismenu list-unstyled"
        >
          <li class="menu-title">
            Menu
          </li>

          <li
            v-if="onUserPermission('Permission.CustomerDashboard.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes'"
              class="menu--link"
              title="Resumen"
            >
              <i
                class="uil-home-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Resumen</span>
            </router-link>
          </li>
          <li
            v-if="onUserPermission('Permission.CustomerReception.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/guias'"
              class="menu--link"
              title="Listado de Guías"
            >
              <i
                class="uil-clipboard-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Recolecciones y Guías</span>
            </router-link>
          </li>
          <li
            v-if="onUserPermission('Permission.CustomerBills.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/facturas'"
              class="menu--link"
              title="Listado de Facturas"
            >
              <i
                class="uil-bill"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Facturas</span>
            </router-link>
          </li>
          <li
            v-if="onUserPermission('Permission.CustomerSolvency.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/credito-y-prepago'"
              class="menu--link"
              title="Listado de Crédito y Prepago"
            >
              <i
                class="uil-ticket"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Crédito y Prepago</span>
            </router-link>
          </li>
          <li
            v-if="onUserPermission('Permission.CustomerLocation.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/ubicaciones'"
              class="menu--link"
              title="Listado de Ubicaciones"
            >
              <i
                class="uil-map-marker-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Ubicaciones</span>
            </router-link>
          </li>

          <li
            v-if="onUserPermission('Permission.CustomerLocation.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/contactos'"
              class="menu--link"
              title="Listado de contactos"
            >
              <i
                class="uil-comment-notes"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Contactos</span>
            </router-link>
          </li>
          <li
            v-if="onUserPermission('Permission.CustomersVerificationCode.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/qrs-de-verificacion'"
              class="menu--link"
              title="Lista de Qrs de Verificación"
            >
              <i
                class="uil-comment-notes"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Qrs de Verificación</span>
            </router-link>
          </li>
          
          
          <li
            v-if="onUserPermission('Permission.CustomerAccount.Show') && allowSubAccounts()"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/clientes/cuentas-de-usuarios'"
              class="menu--link"
              title="Cuentas de Usuarios"
            >
              <i
                class="uil-users-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Usuarios</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/clientes/mi-cuenta'"
              class="menu--link"
              title="Mi Cuenta"
            >
              <i
                class="uil-user-square"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Mi Cuenta</span>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
</template>
