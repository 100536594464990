<script>
import RecoveryPasswordComponent from "./RecoveryPasswordComponent";
export default {
  name: "RecoveryPasswordView",
  extends: RecoveryPasswordComponent,
};
</script>
<template>
  <div class="authentication-bg">
    <div class="account-pages">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5 text-center">
            <div class="login-logo" />
            <div>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">
                      Recuperar Contraseña
                    </h5>
                    <p class="text-muted">
                      Ingrese su correo electrónico
                    </p>
                  </div>
                  <div class="p-1 mt-4">
                    <el-alert
                      slot="title"
                      :type="typeAlert"
                      show-icon
                      :closable="false"
                    >
                      <template #default>
                        {{ message }}
                      </template>
                    </el-alert>
                    <el-form
                      ref="formData"
                      :model="formData"
                      :rules="rules"
                    >
                      <div class="mb-3">
                        <el-form-item
                          label="Correo Electrónico"
                          prop="email"
                        >
                          <el-input
                            v-model="formData.email"
                            type="text"
                            class="form-control"
                            placeholder="Ingresar correo"
                          />
                        </el-form-item>
                      </div>
                      <div class="mt-3 text-end">
                        <el-button
                          v-loading.fullscreen.lock="fullscreenLoading"
                          type="primary"
                          class="w-100"
                          @click="onSubmitForm('formData')"
                        >
                          Enviar
                        </el-button>
                      </div>
                    </el-form>
                    <div class="mt-4 text-center">
                      <p class="mb-0">
                        ¿Recuerda su contraseña?
                        <router-link
                          :to="{ name: 'login' }"
                          class="fw-medium text-primary"
                        >
                          Iniciar Sesión
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
