import BaseViewModel from '../../infraestructure/BaseViewModel'
import TransportMerchandiseType from './TransportMerchandiseType'

export default class TransportMerchandiseTypeViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new TransportMerchandiseType(data, 'create')
    }

    edit(data) {
        return new TransportMerchandiseType(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.transportMechandiseTypes.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.transportMechandiseTypes.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.transportMechandiseTypes.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.transportMechandiseTypes.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.transportMechandiseTypes.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm() { }

    import(file) {
        this.api.transportMechandiseTypes.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            status: '',
            dangerousMaterial: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('IsActive');
        this.view.removeFilter('IsDangerousMaterial');
        this.view.onSearch();
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new TransportMerchandiseType(item))
    }

    //#endregion

    getTransportMerchandiseTypesByChunks(postalCodes, chunkSize = 100) {
        return postalCodes
          .map((e, i) => {
            return i % chunkSize === 0 ? postalCodes.slice(i, i + chunkSize) : null;
          })
          .filter((e) => {
            return e;
          });
      }
    
      batchTransportMerchandiseTypesByChunks(data) {
        this.api.transportMechandiseTypes
          .doBatch({ merchandiseTypes: data })
          .then((response) => this.view.onBatchResponse(response.data))
          .catch(this.view.onBatchError);
      }
}
