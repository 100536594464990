import BaseViewModel from "../../infraestructure/BaseViewModel";
import Country from "../countries/Country";
import District from "../districts/District";
import Municipality from "../municipalities/Municipality";
import Place from "../places/Place";
import PostalCode from "./PostalCode";

export default class PostalCodeViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  create(data) {
    return new PostalCode(data, "create");
  }

  edit(data) {
    return new PostalCode(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.postalCodes
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.postalCodes
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(id) {
    this.api.postalCodes
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.postalCodes
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.postalCodes
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  async bindForm(formData) {
    try {
      if (formData.state === "create") {
        const requests = [
          this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`),
        ];

        const [countries] = await Promise.all(requests);

        this.view.countries = countries.data.data;
        
      } else {

        let requests = [
          this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`),
        ];

        if(formData.country) {
            requests.push(this.api.districts.find(
                `CountryId=${formData.country.id}&Skip=0&Limit=10000&IsActive=true`
              ))
        }

        if(formData.district) {
            requests.push(this.api.municipalities.find(
                `DistrictId=${formData.district.id}&Skip=0&Limit=10000&IsActive=true`
              ))
        }

        if(formData.municipality) {
            requests.push(this.api.places.find(
                `MunicipalityId=${formData.municipality.id}&Skip=0&Limit=10000&IsActive=true`
              ))
        }


        const [countries, districts, municipalities, places] = await Promise.all(requests);

        this.view.countries = countries.data.data;
        this.view.districts = (districts) ? districts.data.data : [];
        this.view.municipalities = (municipalities) ? municipalities.data.data : [];
        this.view.cities = (places) ? places.data.data : [];
      }
    } catch (error) {
      this.view.onError(error);
    }
  }

  import(file) {
    this.api.postalCodes
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      code: "",
      name: "",
      country: "",
      district: "",
      municipality: "",
      place: "",
      status: "",
    };

    this.view.removeFilter("Code");
    this.view.removeFilter("Name");
    this.view.removeFilter("CountryId");
    this.view.removeFilter("DistrictId");
    this.view.removeFilter("MunicipalityId");
    this.view.removeFilter("CityId");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  bindList() {
    this.api.countries
      .find(`Skip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.countries = this.mapCountry(response.data.data);
        setTimeout(() => {
          this.view.onSearch();
          this.view.isListLoading = false;
        }, 400);
      })
      .catch(this.view.onError);
  }

  //#region

  mapCollection(collection) {
    return collection.map((item) => new PostalCode(item));
  }

  mapCountry(collection) {
    return collection.map((item) => new Country(item));
  }

  mapDistricts(collection) {
    return collection.map((item) => new District(item));
  }

  mapMunicipality(collection) {
    return collection.map((item) => new Municipality(item));
  }

  mapPlace(collection) {
    return collection.map((item) => new Place(item));
  }

  //#endregion

  findDistricts(query) {
    this.api.districts
      .find(query)
      .then((response) => this.view.onFindDistrictsResponse(response.data))
      .catch(this.view.onError);
  }

  findMunicipalities(query) {
    this.api.municipalities
      .find(query)
      .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findCities(query) {
    this.api.places
      .find(query)
      .then((response) => this.view.onFindCitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findNeighborhoods(query) {
    this.api.neighborhoods
      .find(query)
      .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
      .catch(this.view.onError);
  }

  getPostalCodesByChunks(postalCodes, chunkSize = 100) {
    return postalCodes
      .map((e, i) => {
        return i % chunkSize === 0 ? postalCodes.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  }

  batchPostalCodesByChunks(data) {
    this.api.postalCodes
      .doBatch({ postalCodes: data })
      .then((response) => this.view.onBatchResponse(response.data))
      .catch(this.view.onBatchError);
  }
}
