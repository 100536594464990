import BaseViewModel from '../../infraestructure/BaseViewModel'

export default class HeaderCustomerViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    

    findCustomerReceptionsSolvency() {
        this.api.solvencies.findAvailableReceptionsByCustomerAccount()
            .then((response) => this.view.onFindCustomerReceptionsSolvency(response.data))
            .catch(this.view.onError)
    }
}
