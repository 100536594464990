import BaseForm from "../../../infraestructure/BaseForm";
import BoxViewModel from "./BoxViewModel";
export default {
  extends: BaseForm,
  data() {
    return {
      isLoading: false,
      viewModel: null,
      activeName: "first",
      fiscalSearch: null,
      rules: {
        description: [
          {
            required: true,
            message: "Alias de ubicación requerido.",
            trigger: "blur",
          },
        ],
        postalCode: [
          {
            required: true,
            message: "Código postal requerido.",
            trigger: "blur",
          },
        ],
        country: [
          { required: true, message: "País requerido.", trigger: "blur" },
        ],
        district: [
          { required: true, message: "Estado requerido.", trigger: "blur" },
        ],
        municipality: [
          { required: true, message: "Municipio requerido.", trigger: "blur" },
        ],
        city: [
          { required: true, message: "Ciudad requerido.", trigger: "blur" },
        ],
        neighborhood: [
          { required: true, message: "Colonia requerido.", trigger: "blur" },
        ],
        mainStreet: [
          {
            required: true,
            message: "Calle principal requerido.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.viewModel = new BoxViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      require: true,
    },
    config: {
      type: Object,
      require: true,
    },
  },
  methods: {
    onActive() {},
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.config.isLoading = true;
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onNotifyEvent("on-submited-contact", response);
      }, 400);
    },
    onSaveResponseError(error) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onError(error);
      }, 400);
    },
    onCustomerLocationResponse(response) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onNotifyEvent("on-submited-contact-location", response);
      }, 400);
    },
    onFindCustomerByFiscalSearch() {
      if (this.fiscalSearch) {
        this.config.isLoading = true;
        this.viewModel.findCustomerByFiscalSearch(
          `FiscalSearch=${this.fiscalSearch}`
        );
      }
    },
    onFindCustomerByFiscalSearchResponse(response) {
      setTimeout(() => {
        this.$set(this.formData, "id", response.data.id);
        this.$set(this.formData, "contactName", response.data.contactName);
        this.$set(this.formData, "contactPhone", response.data.contactPhone);
        this.$set(this.formData, "contactEmail", response.data.contactEmail);
        this.$set(this.formData, "fiscalName", response.data.fiscalName);
        this.$set(this.formData, "fiscalCode", response.data.fiscalCode);
        this.config.isLoading = false;
      }, 400);
    },
    onFindCustomerByFiscalSearchError(error) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onError(error);
      }, 400);
    },
  },
};
