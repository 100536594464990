import _ from 'lodash'
import BaseModel from "../../infraestructure/BaseModel"
import ConsignmentLocation from './ConsignmentLocation'

export default class ConsignmentNote extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id, 
            version: this.version.id,
            internationalTransport: this.internationalTransport.id,
            traveledDistance: this.traveledDistance,
            weightUnitId: (this.weightUnit) ? this.weightUnit.id : null,
            consignmentLocations: this.mapConsignmentLocations(this.consignmentLocations),
            transportationFigure: {
                id: (this.autoTransportation) ? this.transportationFigure.id : null,
                transportationTypeId: (this.transportationType) ? this.transportationType.id : null,
                staff: this.mapStaff(this.transportationFigures),
            },
            autotransportation: {
                id: (this.autoTransportation) ? this.autoTransportation.id : null,
                sctPermissionTypeId : (this.sctPermissionType) ? this.sctPermissionType.id : null,
                hasDangerousMaterial: this.hasDangerousMaterial,
                vehicles: this.mapVehicles(this.autoTransportations),
            },
            isActive: this.isActive
        }
    }

    mapConsignmentLocations(consignmentLocations) {
        if(this.origin && this.destination) {
            return [new ConsignmentLocation(this.origin).toSend(), new ConsignmentLocation(this.destination).toSend()]
        } else  if(!_.isEmpty(consignmentLocations)){
            return  _.map(consignmentLocations, (item) => new ConsignmentLocation(item).toSend())
        } else {
            return []
        }
    }

    mapStaff(collection) {
        return collection.filter(p => p.updateState != this.UPDATE_STATUS.REMOVED).map((item) => {
            return {
                staffId: (item.staff) ? item.staff.id : null,
            }
        });
    }

    mapVehicles(collection) {
        return collection.filter(p => p.updateState != this.UPDATE_STATUS.REMOVED).map((item) => {
            return {
                vehicleId: (item.vehicle) ? item.vehicle.id : null,
            }
        })
    }

    //#region TRANSPORT FIGURES

    getTransportFigures() {
        return this.transportationFigures.filter(transportFigure => transportFigure.updateState !== this.UPDATE_STATUS.REMOVED)
    }

    addTransportFigure(){
        this.transportationFigures.push({
            updateState: this.UPDATE_STATUS.ADDED
        })
    }

    editTransportFigure(indexSelected){
        let item = this.getTransportFigures()[indexSelected]
        if(item) {
            let index = this.transportationFigures.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.transportationFigures[index].updateState = this.UPDATE_STATUS.UPDATED
                } else {
                    this.transportationFigures[index].updateState = this.UPDATE_STATUS.ADDED
                }
            } else {
                throw new Error("Operador no encontrado.")
            }
        } else {
            throw new Error("Operador no encontrado.")
        }
    }

    deleteTransportFigure(indexSelected) {
        let item = this.getTransportFigures()[indexSelected]
        if(item) {
            let index = this.transportationFigures.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.transportationFigures[index].updateState = this.UPDATE_STATUS.REMOVED
                } else {
                    this.transportationFigures.splice(index, 1)
                }
            } else {
                throw new Error("Operador no encontrado.")
            }
        } else {
            throw new Error("Operador no encontrado.")
        }
    }

    //#endregion TRANSPORT FIGURES

    //#region AUTOTRANSPORT
    

    getAutotransportations() {
        return this.autoTransportations.filter(item => item.updateState !== this.UPDATE_STATUS.REMOVED)
    }

    addAutotransportation(){
        this.autoTransportations.push({
            updateState: this.UPDATE_STATUS.ADDED
        })
    }

    editAutotransportation(indexSelected){
        let item = this.getAutotransportations()[indexSelected]
        if(item) {
            let index = this.autoTransportations.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.autoTransportations[index].updateState = this.UPDATE_STATUS.UPDATED
                } else {
                    this.autoTransportations[index].updateState = this.UPDATE_STATUS.ADDED
                }
            } else {
                throw new Error("Transporte no encontrado.")
            }
        } else {
            throw new Error("Transporte no encontrado.")
        }
    }

    deleteAutotransportation(indexSelected) {
        let item = this.getAutotransportations()[indexSelected]
        if(item) {
            let index = this.autoTransportations.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.autoTransportations[index].updateState = this.UPDATE_STATUS.REMOVED
                } else {
                    this.autoTransportations.splice(index, 1)
                }
            } else {
                throw new Error("Transporte no encontrado.")
            }
        } else {
            throw new Error("Transporte no encontrado.")
        }
    }


    //#endregion AUTOTRANSPORT


}