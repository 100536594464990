import BaseForm from '../infraestructure/BaseForm'
import ReceptionsViewModel from '../../core/features/receptions/ReceptionsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Reception',
            viewModel: null,
            rules: {},
    
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-reception')
        },
        isCreateState() {
            return this.formData.state === 'create'
        },
        isEditState() {
            return this.formData.state === 'update'
        },

        
    }
}