import BaseApi from '../../infraestructure/BaseApi'

export default class OrderApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    getByCode(code){
        return this.client.get(`${this.endPoint}/by-code/${code}`)
    }

    requestPicking(data){
        return this.client.post(`${this.endPoint}/request-picking`, data)
    }

    findDetail(orderNumber) {
        return this.client.get(`${this.endPoint}/details/${orderNumber}`)
    }

    findPickingOrder(orderNumber){
        return this.client.get(`${this.endPoint}/request-picking-pdf/${orderNumber}`)
    }

    editV2(orderId, data){
        return this.client.put(`${this.endPoint}/${orderId}/edit-v2`, data)
    }
}