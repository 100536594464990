import _ from 'lodash'
import BaseForm from "../infraestructure/BaseForm";
import OrderCustomerViewModel from "../../core/features/orders/OrderCustomerViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: "Order",
      viewModel: null,
      activeName: "first",
      taxRegimes: [],
      postalCodes: [],
      isRfcSearching: false,
      rules: {
        fiscalName: [
          {
            message: "Razón Social requerido.",
            trigger: "blur",
          },
        ],
        fiscalCode: [
          {
            validator: this.onSearchRfc,
            trigger: ["blur", "change"],
          },
        ],
        description: [
          {
            required: true,
            validator: this.onVerifyDescription,
            trigger: ["blur", "change"],
          },
        ],
        contactName: [
          {
            required: true,
            message: "Nombre requerido.",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "Telefono requerido.",
            trigger: "blur",
          },
        ],
        contactEmail: [
          {
            trigger: ["blur", "change"],
            validator: this.onSearchEmail,
          },
        ],
        address: {
          description: [
            {
              required: true,
              message: "Descripción/Alias de Ubicación requerido.",
              trigger: "blur",
            },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle Principal requerido.",
              trigger: "blur",
            },
          ],
          secondaryStreet: [
            {
              required: true,
              message: "Entre Calles requerido.",
              trigger: "blur",
            },
          ],
          city: [
            {
              required: true,
              message: "Ciudad/Destino requerido.",
              trigger: "blur",
            },
          ],
          neighborhood: [
            {
              required: true,
              message: "Colonia requerido.",
              trigger: "blur",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código Postal requerido.",
              trigger: "blur",
            },
          ],
          country: [
            {
              required: true,
              message: "Pais requerido.",
              trigger: "blur",
            },
          ],
          district: [
            {
              required: true,
              message: "Estado requerido.",
              trigger: "blur",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio requerido.",
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new OrderCustomerViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(
        response.message,
        "on-submited-order-customer"
      );
    },
    onSearchRfc: _.debounce(function(rule, value, callback) {
      this.isRfcSearching = true;
      if (value) {
        this.viewModel.verifyRFC(value, (response) => {
          this.isRfcSearching = false;
          if (response.isValid) {
            callback();
          } else {
            callback(new Error(response.message));
          }
        });
      } else {
        setTimeout(() => {
          this.isRfcSearching = false;
          callback();
        }, 400);
      }
    }, 400),
    onSearchEmail: _.debounce(function(rule, value, callback) {
      if (value) {
        let testEmails = value.split(";");
        // eslint-disable-next-line
        let regex = new RegExp(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
        );
        if (testEmails.every((email) => regex.test(email))) {
          callback();
        } else {
          testEmails.forEach((email) => {
            if (!regex.test(email)) {
              callback(new Error(`El correo ${email} no es valido.`));
            }
          });
        }
      } else {
        setTimeout(() => {
          callback();
        }, 400);
      }
    }, 400),
    onFindPostalCodes(data) {
      this.postalCodes = [];
      if (data)
        this.viewModel.findPostalCodes(
          `Code=${data}&Skip=0&Limit=1000&IsActive=true`
        );
    },
    onFindPostalCodesResponse(response) {
      this.postalCodes = this.viewModel.mapPostalCodes(response.data);
    },
    onVerifyDescription: _.debounce(function(rule, value, callback) {
      if (value) {
        this.viewModel.verifyCustomer(
          `CustomerDescription=${value}`,
          (response) => {
            if (response.isValid) {
              callback();
            } else {
              callback(new Error(response.message));
            }
          }
        );
      } else {
        setTimeout(() => {
          callback(new Error("Descripción requerido"));
        }, 400);
      }
    }, 400),
  },
};
