import BaseForm from "../infraestructure/BaseForm";
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      tabActive: "reception-details",
      viewModel: null,
      isMapLoading: false,
      rules: {
        code: [
          {
            required: true,
            message: "Código(s) requerido.",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Nombre requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur",
          },
        ],
        isActive: [
          {
            required: true,
            message: "El Estado es requerido.",
            trigger: "blur",
          },
        ],
      },
      receptionTypes: CustomerReceptionsViewModel.receptionTypes,
      paymentTypes: CustomerReceptionsViewModel.paymentTypes,
      receptionStatuses: CustomerReceptionsViewModel.receptionStatuses,
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CustomerReceptionsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindFormAsync(this.formData, this.$store.getters["admin/getUserActive"]);
    },
    onCalculateTotalVolume(receptionPackage) {
      return this.viewModel.calculateTotalVolume(
        CustomerReceptionsViewModel.BoxType,
        receptionPackage.quantity || 1,
        receptionPackage.length,
        receptionPackage.height,
        receptionPackage.width
      );
    },
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type)
    },
    onGetNamePaymentType(type) {
      return this.viewModel.getNamePaymentType(type)
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type)
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status)
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status)
    },
    onGetOrderDescending(list) {
      return this.viewModel.getOrderDescending(list)
    },
    onGetColorTrackingStatus(status) {
      return this.viewModel.getColorTrackingStatus(status)
    },
    onGetTraceStatusDescription(item) {
      return this.viewModel.getTraceStatusDescription(item)
    },
    hasSenderCustomer(formData) {
      return formData.senderLocation && formData.senderLocation.customer;
    },
    hasSenderCustomerLocation(formData) {
      return formData.senderLocation && formData.senderLocation.mainStreet;
    },
    hasReceiverCustomer(formData) {
      return formData.receiverLocation && formData.receiverLocation.customer;
    },
    hasReceiverCustomerLocation(formData) {
      return formData.receiverLocation && formData.receiverLocation.mainStreet;
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    getTotalService() {
      if(this.formData.discountPercentage === 0) return this.formData.shippingQuote;
      var discount = (this.formData.discountPercentage * this.formData.shippingQuote) / 100
      return this.formData.shippingQuote - discount
    },
    onTabChange(tab) {
      if(tab.name === 'deliveryLocation') {
        this.isMapLoading = true
        setTimeout(() => {
          this.viewModel.startMap(this.formData)
          this.isMapLoading = false
        }, 400);
      }
    },
    
  },
};
