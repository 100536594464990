import moment from "moment";
import _ from "lodash";
import BaseViewModel from "../../../../core/infraestructure/BaseViewModel";
import Currency from "../../../../core/features/currencies/Currency";
import PaymentType from "../../../../core/features/paymentTypes/PaymentType";
import BankAccount from "../../../../core/features/bankAccounts/BankAccount";
import Customer from "../../../../core/features/customers/Customer";
import BaseModel from "../../../../core/infraestructure/BaseModel";

export default class SaleViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  bindPaymentForm() {
    var result = {
      currencies: [],
      paymentTypes: [],
      bankAccounts: [],
    };
    this.api.currencies
      .find(`Skip=0&Limit=1000&Active=true`)
      .then((response) => {
        result.currencies = this.mapCurrencies(response.data.data);
        return this.api.paymentTypes.find(`Skip=0&Limit=1000&Active=true`);
      })
      .then((response) => {
        result.paymentTypes = this.mapPaymentTypes(response.data.data);
        return this.api.bankAccounts.find(`Skip=0&Limit=1000&Active=true`);
      })
      .then((response) => {
        result.bankAccounts = this.mapBankAccounts(response.data.data);
        this.view.onBindPaymentFormSuccess(result);
      })
      .catch((error) => this.view.onBindPaymentFormError(error));
  }

  mapCurrencies(collection) {
    return collection.map((item) => new Currency(item));
  }

  mapPaymentTypes(collection) {
    return collection.map((item) => new PaymentType(item));
  }

  mapBankAccounts(collection) {
    return collection.map((item) => new BankAccount(item));
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  // QUERIES

  findCustomers(query) {
    this.api.customers
      .find(query)
      .then((response) =>
        this.view.onSearchCustomerResponse(
          this.mapCustomers(response.data.data)
        )
      )
      .catch(this.view.onSearchCustomerError);
  }

  findDocuments(query, currentDocuments) {
    this.api.bills
      .findListPaymentComplementDocuments(query)
      .then((response) => {

        currentDocuments.forEach((item) => {
          item.updateState = BaseModel.UPDATE_STATUS.UPDATED
        })

        var documents = response.data.data.map((item) => {
          item.updateState = BaseModel.UPDATE_STATUS.ADDED
          return item
        })

        var result = _.differenceWith(documents, currentDocuments, (item) => {
          return _.isEqual(item['digitalFiscalReceipt'])
        })
        
        this.view.onSearchSalesResponse(result)
      })
      .catch(this.view.onSearchSaleError);
  }

  /**
   * Retorna una cadena de texto que contiene la información de pago formateada.
   *
   * @param {object} paymentData - Objeto con información de pago.
   * @param {object} paymentData.bankAccount - Objeto con información de cuenta bancaria.
   * @param {string} paymentData.bankAccount.bankName - Nombre del banco.
   * @param {string} paymentData.bankAccount.accountNumber - Número de cuenta bancaria.
   * @param {string} paymentData.operationNumber - Número de operación.
   * @param {string} paymentData.paymentDate - Fecha de pago en formato ISO 8601.
   *
   * @returns {string} - Cadena de texto con la información de pago formateada, o
   *                    "Agregar Información a Registro de Pago" si falta alguna propiedad requerida.
   */
  getPaymentFormTitle({ bankAccount, operationNumber, paymentDate }) {
    const isMissingInfo = !(bankAccount && operationNumber && paymentDate);

    const isInvalidDate = paymentDate && !moment(paymentDate).isValid();

    if (isMissingInfo || isInvalidDate) {
      return "Agregar Información a Registro de Pago";
    }

    const { bankName, accountNumber } = bankAccount;
    const formattedDate = moment(paymentDate)
      .format("DD MMM yyyy")
      .toUpperCase();

    return `Banco: ${bankName} | Cuenta Bancaria: ${accountNumber} | Fecha Pago: ${formattedDate} | No. Operación: ${operationNumber}`;
  }

  isDocumentSearchableFilersAvailables({ customer, dateRange }) {
    return !_.isNil(customer) & (!_.isNil(dateRange) && !_.isEmpty(dateRange));
  }

  removeselectedRelatedDocuments(selectedDocuments, allDocuments, formData) {
    selectedDocuments.forEach((document) => {
      var index = allDocuments.indexOf(document);
      if (index != -1) {
        formData.deleteSaleBillPaymentDocumentRelated(index);
      }
    });
  }

}
