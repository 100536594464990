import BaseViewModel from "../../../../core/infraestructure/BaseViewModel";
import CustomerContact from '../../../../core/features/customerContact/CustomerContact'

export default class BoxViewModel extends BaseViewModel {
  
  constructor(view) {
    super(view);
  }

  save(data) {
    if(data.state === 'create') {
      this.api.customersContact.create(data.customer.id, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError)
    } else {
      this.api.customersContact.update(data.customer.id, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError)
    }
  }

  isCustomerAvailable(formData) {
    return (formData.id) || (formData.name && formData.email && formData.phone);
  }

  findCustomerContacts(customerId, criteria) {
    this.api.customersContact.find(customerId, criteria)
      .then((response) => this.view.onFindCustomerContactResponse(response.data))
      .catch(this.view.onFindCustomerContactError)
  }

  findCustomerContact(customerId, contactId) {
    this.api.customersContact.findOne(customerId, contactId)
      .then((response) => this.view.onShowContactResponse(response.data))
      .catch(this.view.onShowContactError)
  }

  findLocationsByCustomer(customerId, criteria) {
    this.api.customerLocation.find(customerId, criteria)
      .then((response) => this.view.onFindLocationsByCustomerResponse(response.data))
      .catch(this.view.onFindLocationsByCustomerError)
  }

  findSelectLocation(customerId, locationId){
    this.api.customerLocation.findOne(customerId, locationId)
    .then((response) => this.view.onFindSelectedLocationResponse(response.data))
    .catch(this.view.onFindSelectedLocationError)
  }

  createContact(data) {
    return new CustomerContact(data, 'create')
  }

  editContact(data) {
    var item = new CustomerContact(data, 'update')
    item.name = item.contactName
    item.phone = item.contactPhone
    item.email = item.contactEmail
    return item
  }

  findCustomerByFiscalSearch(query) {
    this.api.customers.findByFiscal(query)
      .then((response) => this.view.onFindCustomerByFiscalSearchResponse(response.data))
      .catch(this.view.onFindCustomerByFiscalSearchError)
  }
}