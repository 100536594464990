export default class BillConcept {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            template: this.template,
            isOverWeight:  this.isOverWeight,
            measureUnitId: (this.measureUnit) ? this.measureUnit.id : null,
            billConceptKeyId: (this.billConceptKey) ? this.billConceptKey.id : null,
            isActive: this.isActive
        }
    }

    toCatalog() {
        return `${this.code} - ${this.name}`
    }
}