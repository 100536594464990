import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomerReceptions extends BaseApi {
    
    constructor(client, endPoint) {
        super(client, endPoint)
    }
 
    create(customerId, data) {
       return this.client.post(`${this.endPoint}/${customerId}/receptions`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/receptions/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/receptions/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/receptions${parameters}`)
    }

    findOne(customerId, id) {
        return this.client.get(`${this.endPoint}/${customerId}/receptions/${id}`)
    }

    findCustomerReceptionLocation(customerId) {
        return this.client.get(`${this.endPoint}/${customerId}/locations`)
    }

    requestPickingPdf(customerId, orderId) {
        return this.client.get(`${this.endPoint}/${customerId}/receptions/request-picking-pdf/${orderId}`)
    }
    
    exportToExcel(customerId,criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/receptions/export-excel${parameters}`)
    }

    findReceptionOrderDetail(customerId, id){
        return this.client.get(`${this.endPoint}/${customerId}/receptions/${id}/order-details`)
    }

    cancelReceptionOrder(customerId, id){
        return this.client.post(`${this.endPoint}/${customerId}/receptions/${id}/cancel`)
    }

}