import BaseForm from '../../../../components/infraestructure/BaseForm'
import OrderVerificationViewModel from './OrderVerificationViewModel'


export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            isFormVerificationLoading: false,
            formDataValidation: {
                verificationCode: null
            },
            rulesValidation: {
                verificationCode: [{
                    required: true,
                    message: 'Código Requerido'
                }]
            },
        }
    },
    props: {
        
    },
    created() {
        this.viewModel = new OrderVerificationViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() { },
        onVerifyForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.isFormVerificationLoading = true
                    this.viewModel.verifyOrder(this.formDataValidation.verificationCode)
                    return true
                }
                return false
            })
        },
        onVerifyOrderResponse(response) {
            setTimeout(() => {
                this.isFormVerificationLoading = false
                this.onNotifyEvent('is-order-verified', response)
            }, 400);
        },
        onVerifyOrderError(error){
            setTimeout(() => {
                this.isFormVerificationLoading = false
                this.onError(error)
            }, 400);
        }
    }
}