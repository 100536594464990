import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Customer from "./Customer";
import CustomerLocation from "../customerLocation/CustomerLocation";
import CustomerSubAccount from "../customerAccount/CustomerSubAccount";
import Neighborhood from "../neighborhoods/Neighborhood";
import TaxRegime from "../taxRegimes/TaxRegime";
import PostalCode from "../postalCodes/PostalCode";

export default class CustomersViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  //#region MODULO CLIENTES

  create(data) {
    return new Customer(data, "create");
  }

  edit(data) {
    let item = new Customer(data, "update");
    if (!item.masterAccount) item.masterAccount = {};
    item.customerSubAccounts = item.customerSubAccounts.map((subAccount) => {
      return new CustomerSubAccount(subAccount);
    });
    item.customerLocations = item.customerLocations.map((location) => {
      return new CustomerLocation(location);
    });
    return item;
  }

  save(data) {
    if (data.state === "create") {
      this.api.customers
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    } else {
      this.api.customers
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    }
  }

  delete(id) {
    this.api.customers
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.customers
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.customers
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  findItemLocation(id) {
    this.api.customers
      .findOne(id)
      .then((response) => this.view.onSearchResponse(response.data))
      .catch(this.view.onError);
  }

  bindForm(formData) {
    this.api.taxRegimes
      .find(`Skip=0&Limit=10000&IsActive=true`)
      .then((response) => {
        this.view.taxRegimes = this.mapTaxRegimes(response.data.data);
        this.view.postalCodes = formData.fiscalPostalCode
          ? this.mapPostalCodes([formData.fiscalPostalCode])
          : [];
        if (formData.state === "update") {
          this.findVerificationCode(
            formData.id,
            `CustomerId=${formData.id}&
          Skip=${(this.view.currentPageVerificationCode - 1) *
            this.view.limitVerificationCode}&
          Limit=${this.view.limitVerificationCode}`
          );
          if (formData.masterAccount) {
            this.findSubAccount(
              formData.id,
              `CustomerId=${formData.id}&Skip=${(this.view
                .currentPageSubAccount -
                1) *
                this.view.limitSubAccount}&Limit=${this.view.limitSubAccount}`
            );
          } else {
            formData.masterAccount = {};
          }
        }
      });
  }

  import(file) {
    this.api.customers
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  importLocations(customerId, file) {
    this.api.customerLocation
      .import(customerId, file)
      .then((response) => this.view.onImportLocationsResponse(response.data))
      .catch(this.view.onImportLocationsError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      code: "",
      description: "",
      customerType: "",
      fiscalName: "",
      fiscalCode: "",
      status: "",
    };

    this.view.removeFilter("Code");
    this.view.removeFilter("Description");
    this.view.removeFilter("FiscalName");
    this.view.removeFilter("FiscalCode");
    this.view.removeFilter("CustomerType");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  mapCollection(collection) {
    return collection.map((item) => new Customer(item));
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  mapPostalCodes(collection) {
    return collection.map((item) => new PostalCode(item));
  }

  //#endregion

  //#region QUERIES

  findDistricts(query) {
    this.api.districts
      .find(query)
      .then((response) => this.view.onFindDistrictsResponse(response.data))
      .catch(this.view.onError);
  }

  findMunicipalities(query) {
    this.api.municipalities
      .find(query)
      .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findCities(query) {
    this.api.places
      .find(query)
      .then((response) => this.view.onFindCitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findNeighborhoods(query) {
    this.api.neighborhoods
      .find(query)
      .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
      .catch(this.view.onError);
  }

  findPostalCodes(query) {
    this.api.postalCodes
      .find(query)
      .then((response) => this.view.onFindPostalCodesResponse(response.data))
      .catch(this.view.onError);
  }

  findSubAccount(customerId, criteria) {
    this.api.customersAccount
      .findSubAccount(customerId, criteria)
      .then((response) =>
        this.view.onFindSubAccountResponse(response.headers, response.data)
      )
      .catch((error) => this.view.onFindSubAccountError(error));
  }

  findVerificationCode(customerId, criteria) {
    this.api.customerVerificationCodes
      .findVerificationCode(customerId, criteria)
      .then((response) =>
        this.view.onFindVerificationCodeResponse(
          response.headers,
          response.data
        )
      )
      .catch((error) => this.view.onFindVerificationCodeError(error));
  }

  //#endregion

  //#region UBICACIÓN DE CLIENTES

  bindFormCustomerLocation() {}

  findCustomerLocation(location) {
    this.api.neighborhoods
      .findOne(location.neighborhood.id)
      .then((response) => {
        this.view.$set(this.view.formData, "neighborhood", response.data.data);
        this.view.$set(
          this.view.formData,
          "district",
          response.data.data.district
        );
        this.view.$set(
          this.view.formData,
          "country",
          response.data.data.country
        );
        return this.api.placesfindOne(response.data.data.city.id);
      })
      .then((response) => {
        this.view.$set(
          this.view.formData,
          "country",
          response.data.data.country
        );
        this.view.$set(
          this.view.formData,
          "district",
          response.data.data.district
        );
        this.view.$set(
          this.view.formData,
          "municipality",
          response.data.data.municipality
        );
        this.view.$set(this.view.formData, "city", response.data.data);
        if (this.view.formData.country)
          return this.api.districts.find(
            `CountryId=${this.view.formData.country.id}&Skip=0&Limit=10000&IsActive=true`
          );
      })
      .then((response) => {
        if (response) {
          this.view.districts = response.data.data;
          if (this.view.formData.district)
            return this.api.municipalities.find(
              `DistrictId=${this.view.formData.district.id}&Skip=0&Limit=10000&IsActive=true`
            );
        }
      })
      .then((response) => {
        if (response) {
          this.view.municipalities = response.data.data;
          if (this.view.formData.municipality)
            return this.api.placesfind(
              `MunicipalityId=${this.view.formData.municipality.id}&Skip=0&Limit=10000&IsActive=true`
            );
        }
      })
      .then((response) => {
        if (response) {
          this.view.cities = response.data.data;
          if (this.view.formData.city)
            return this.api.neighborhoods.find(
              `CityId=${this.view.formData.city.id}&Skip=0&Limit=10000&IsActive=true`
            );
        }
      })
      .then((response) => {
        if (response) {
          this.view.neighborhoods = response.data.data;
        }
        console.log(location);
        // this.view.onFindCustomerLocationResponse(location);
      })
      .catch(this.view.onError);
  }

  filterCustomerLocations(collection) {
    return _.filter(collection, (item) => item.updateState != 2);
  }

  upsertCustomerLocation(collection, data, customerLocationSelectedIndex) {
    if (data.state === "update") {
      this.view.$set(collection, customerLocationSelectedIndex, data);
    } else {
      collection.push(data);
    }
    customerLocationSelectedIndex = null;
  }

  addNeighborhood(data) {
    return new Neighborhood(data);
  }

  createNeighborhood(cityId, data) {
    let neighborhoodResponse = null;
    this.api.neighborhoods
      .create(data.toSend())
      .then((response) => {
        return this.api.neighborhoods.findOne(response.data.id);
      })
      .then((response) => {
        neighborhoodResponse = response.data;
        return this.api.neighborhoods.find(
          `CityId=${cityId}&Skip=0&Limit=10000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.neighborhoods = response.data.data;
        this.view.onCreateNeighborhoodResponse(neighborhoodResponse);
      })
      .catch(this.view.onError);
  }

  createPostalCode(data) {
    this.api.postalCodes
      .create(data)
      .then((response) => {
        return this.api.postalCodes.findOne(response.data.data);
      })
      .then((response) => this.view.onCreatePostalCodeResponse(response.data))
      .catch(this.view.onCreatePostalCodeError);
  }

  //#endregion

  //#region SUBCUENTA DE CLIENTES

  createCustomerSubAccount(data) {
    return new CustomerSubAccount(data, "create");
  }

  editCustomerSubAccount(data) {
    return new CustomerSubAccount(data.toSendCustomerSubAccount(), "update");
  }

  deleteCustomerSubAccount(collection, index) {
    collection.splice(index, 1);
  }

  onUpsertCustomerSubAccount(
    collection,
    data,
    customerSubAccountSelectedIndex
  ) {
    if (data.state === "update") {
      this.view.$set(collection, customerSubAccountSelectedIndex, data);
    } else {
      collection.push(data);
    }
    customerSubAccountSelectedIndex = null;
  }

  getVerificationCodeQr(customerId, id) {
    this.api.customerVerificationCodes
      .getVerificationCodeQr(customerId, id)
      .then((response) =>
        this.view.onGetVerificationCodeQrResponse(response.data)
      )
      .catch(this.view.onGetVerificationCodeQrError);
  }

  updateRestructureLocations() {
    this.api.customers
      .updateRestructureLocations()
      .then((response) =>
        this.view.onRestructureLocationsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  onGetVerificationEmail(formName, email) {
    this.api.users
      .getVerificationEmail(email)
      .then((response) => {
        if (response) {
          if (!_.isNil(response) && response.data) {
            if (
              !this.validateArrayLocalSubAccounts(
                this.view.accounts,
                this.view.formData.email
              )
            ) {
              this.view.$refs[formName].validate((isValid) => {
                if (isValid) {
                  this.view.onSave(this.view.formData);
                  return true;
                }
                return false;
              });
            } else {
              this.view.config.isLoading = false;
              this.view.notifyWarning("Correo Electronico en uso.");
            }
          }
        }
      })
      .catch(this.view.onGetVerificationEmailError);
  }

  //#endregion

  //#region VALIDATIONS

  verifyRFC(value, callback) {
    let rfcRegex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    if(value.match(rfcRegex)) {
      var defaultFiscalCodes = ["XAXX010101000", "XEXX010101000"];
      if (_.includes(defaultFiscalCodes, value)) {
        callback({
          isValid: true,
          message: "Es codigo es generico es valido.",
        });
      } else {
        this.api.bills
          .verifyRfc(value)
          .then((response) => {
            callback({
              isValid: response.data.isValid,
              message: response.data.message,
            });
          })
          .catch((error) => {
            callback({
              isValid: false,
              message: error.message,
            });
          });
      }
    } else {
      callback({
        isValid: false,
        message: "RFC no valido.",
      });
    }
  }

  verifyCustomer(criteria, callback) {
    this.api.customers
      .verify(criteria)
      .then((response) => {
        callback({
          isValid: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        callback({
          isValid: false,
          message: error.message,
        });
      });
  }

  validateArrayLocalSubAccounts(collections, email) {
    let valid = _.some(collections, (p) => {
      return _.isEqual(p.user.email.toLowerCase(), email.toLowerCase());
    });
    return valid;
  }

  /**
   * Metodo para verificar las cuentas de usuario(maestras y subcuentas)de un cliente,
   * se puede envíar un correo o un nombre de usuario
   */
  async verifyUserName(customerId, value, callback) {
    try {
      if(_.isNil(value) || _.isEmpty(value)){
        callback(new Error("Usuario requerido")) 
      }
      else {
        const response = await this.api.customers.verifyCustomerAccount({
          accountName: value,
          customerId: customerId
        })
        const verificationResult = response.data.data
        if (verificationResult.isValid) {
          callback();
        } else {
          callback(new Error(verificationResult.message));
        }
      }
    } catch (error) {
      this.view.onError(error)
    }
  }

  /**
   * Metodo para verificar las cuentas de usuario(maestras y subcuentas)de un cliente,
   * se puede envíar un correo o un nombre de usuario
   */
  async verifyEmail(customerId, value, callback) {
    try {
      if(_.isNil(value) || _.isEmpty(value)){
        callback(new Error("Correo requerido")) 
      }
      else {
        const response = await this.api.customers.verifyCustomerAccount({
          accountEmail: value,
          customerId: customerId
        })
        const verificationResult = response.data.data
        if (verificationResult.isValid) {
          callback();
        } else {
          callback(new Error(verificationResult.message));
        }
      }
    } catch (error) {
      this.view.onError(error)
    }
  }

  verifyPassword(value, callback) {
    
    if(_.isNil(value) || _.isEmpty(value)){
      callback(new Error("Contraseña requerida.")) 
    }

    if (!(_.isNil(value) & _.isEmpty(value))) {
      this.view.$refs.formData.validateField("passwordConfirmation");
    }

    callback();
  }

  verifyPasswordConfirmation(formData, value, callback) {
    if(_.isNil(value) || _.isEmpty(value)){
      callback(new Error("Verificación de contraseña requerida.")) 
    }

    if (!_.isEqual(value, formData.masterAccount.password)) {
      callback(new Error("Confirmación contraseña no coincide."));
    } else {
      callback();
    }
  }

  //#endregion
}
