import BaseModel from "../../infraestructure/BaseModel"

export default class ConsignmentLocation extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    
    toSend() {
        return  {
            id: this.id,
            locationType: this.locationType,
            name: this.name,
            locationNumber: this.locationNumber,
            federalTaxPayerRegistration: this.federalTaxPayerRegistration,
            exchangeDate: this.exchangeDate,
            mainStreet: this.mainStreet,
            secondaryStreet: this.secondaryStreet,
            intNumber: this.intNumber,
            extNumber: this.extNumber,
            postalCodeId: (this.postalCode) ? this.postalCode.id : null,
            neighborhoodId: (this.neighborhood) ? this.neighborhood.id : null,
            cityId: (this.city) ? this.city.id : null,
            municipalityId: (this.municipality) ? this.municipality.id : null,
            districtId: (this.district) ? this.district.id : null,
            countryId: (this.country) ? this.country.id : null,
            updateState: this.updateState,
            traveledDistance: this.traveledDistance,
        }
    }

}