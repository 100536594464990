import BaseViewModel from "../../../../core/infraestructure/BaseViewModel";

export default class OrderDetailViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  bindOrderDetailView(orderNumber) {
    this.api.orders
      .findDetail(orderNumber)
      .then((response) => {
        this.view.onFindOrderDetailResponse(response.data);
        return this.api.orders.findPickingOrder(orderNumber);
      })
      .then((response) => this.view.onFindPickingOrderResponse(response.data))
      .catch(this.view.bindOrderDetailViewError);
  }

  findOrderDetail(orderNumber) {
    this.api.orders
      .findDetail(orderNumber)
      .then((response) => this.view.onFindOrderDetailResponse(response.data))
      .catch(this.view.onFindOrderDetailError);
  }

  getVerificationCodeQr(customerId, id) {
    this.api.customerVerificationCodes
      .getVerificationCodeQr(customerId, id)
      .then((response) =>
        this.view.onGetVerificationCodeQrResponse(response.data)
      )
      .catch(this.view.onGetVerificationCodeQrError);
  }
}
