<script>
import BaseList from "../../../infraestructure/BaseList";
import PaymentComplementViewModel from "./PaymentComplementViewModel";

export default {
  extends: BaseList,
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
    onFocus: {
      type: Function,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      viewModel: null,
      isDetailLoading: false,
      customers: [],
      documentSelected: [],
      formSearchData: {
        dateRange: null,
        customer: null,
      },
      formSearchRules: {
        customer: [
          {
            required: true,
            message: "Cliente requerido.",
          },
        ],
        dateRange: [
          {
            required: true,
            message: "Rango de fechas requerido.",
          },
        ],
        totalAmount: [
          {
            validator: this.onTotalAmountValidation,
          },
        ],
      },
    };
  },
  created() {
    this.viewModel = new PaymentComplementViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      console.log(this.formData);
    },
    onAddDocument() {
      this.isDetailLoading = true;
      setTimeout(() => {
        this.formData.addSaleBillPayment({
          paymentDate: null,
          paymentType: null,
          currency: null,
          exchangeRate: 1,
          amount: 0,
          bankName: null,
          bankAccount: null,
          operationNumber: null,
          saleBillPaymentRelateds: [],
        });
        this.isDetailLoading = false;
      }, 400);
    },
    onRemoveDocument(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.onFocus();
          this.isDetailLoading = true;
          setTimeout(() => {
            this.formData.deleteSaleBillPaymentDocumentRelated(index);
            this.isDetailLoading = false;
          }, 400);
        })
        .catch(() => {});
    },
    onSearchDocuments() {
      if (this.isAmountValid()) {
        this.$refs["formSearchData"].validate((isValid, errors) => {
          if (!isValid) {
            this.onShowWarningList(errors);
            return false;
          } else {
            this.onFocus();
            this.isDetailLoading = true;
            this.addFilter("CustomerId", this.formSearchData.customer.id);
            this.addFilter(
              "StartDate",
              this.viewModel.formatDate(
                this.formSearchData.dateRange[0],
                "DD/MM/YYYY"
              )
            );
            this.addFilter(
              "EndDate",
              this.viewModel.formatDate(
                this.formSearchData.dateRange[1],
                "DD/MM/YYYY"
              )
            );
            this.viewModel.findDocuments(
              this.viewModel.getQueryParameters(this.filters),
              this.formData.getSaleBillPaymentDocumentRelated()
            );
            return true;
          }
        });
      } else {
        this.onShowWarning("Monto total debe ser mayor a 0.")
      }
    },
    onSearchCustomer(data) {
      this.customers = [];
      if (data) {
        this.viewModel.findCustomers(
          `FullSearch=${data}&IsActive=true&Skip=0&Limit=500`
        );
      }
    },
    onSearchCustomerResponse(collection) {
      this.customers = collection;
    },
    onSearchCustomerError(error) {
      setTimeout(() => {
        this.onError(error);
      }, 400);
    },
    onSearchSalesResponse(collection) {
      setTimeout(() => {
        this.$set(this.formData, "saleBillPaymentRelateds", collection);
        this.isDetailLoading = false;
      }, 400);
    },
    onSearchSaleError(error) {
      setTimeout(() => {
        this.onError(error);
      }, 400);
    },
    onSelectDocuments(data) {
      console.log(data);
      this.$set(this, "documentSelected", data);
    },
    onDeleteDocuments() {
      this.$confirm(
        "¿Desea eliminar los registros seleccionados?",
        "Eliminar",
        {
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          this.onFocus();
          this.isDetailLoading = true;
          this.onRemoveDocuments();
        })
        .catch(() => {});
    },
    onRemoveDocuments() {
      this.documentSelected.forEach((document) => {
        var index = this.formData
          .getSaleBillPaymentDocumentRelated()
          .indexOf(document);
        if (index != -1) {
          this.formData.deleteSaleBillPaymentDocumentRelated(index);
        }
      });
      setTimeout(() => {
        this.isDetailLoading = false;
      }, 400);
    },
    calculateUnpaidBalanceAmount(document) {
      document.unpaidBalanceAmount =
        document.previousBalanceAmount - document.amountPaid;
      return document.unpaidBalanceAmount?.toFixed(3);
    },
    isAmountValid() {
      return this.totalAmount > 0;
    },
  },
};
</script>
<template>
  <div class="sale_payment_complement_document_list">
    <el-form
      ref="formSearchData"
      :model="formSearchData"
      :rules="formSearchRules"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="10">
          <el-form-item
            label="Cliente"
            prop="customer"
          >
            <el-select
              v-model="formSearchData.customer"
              placeholder="Seleccionar cliente"
              value-key="id"
              size="medium"
              filterable
              :remote-method="onSearchCustomer"
              remote
              clearable
            >
              <el-option
                v-for="item in customers"
                :key="item.id"
                :label="item.description"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="Rango de Fecha de Cfdi's"
            prop="dateRange"
          >
            <el-date-picker
              v-model="formSearchData.dateRange"
              type="daterange"
              range-separator="-"
              start-placeholder="F. Inicial"
              end-placeholder="F. Final"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="8"
          align="center"
        >
          <div class="box_form-item">
            <el-button-group>
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-refresh"
                @click="onSearchDocuments"
              >
                Actualizar Documentos
              </el-button>
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-delete"
                @click="onDeleteDocuments"
              >
                Eliminar Documentos
              </el-button>
            </el-button-group>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        v-loading="isDetailLoading"
        :span="24"
      >
        <el-table
          :data="formData.getSaleBillPaymentDocumentRelated()"
          style="width: 100%"
          height="350"
          @selection-change="onSelectDocuments"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <el-table-column
            prop="digitalFiscalReceipt"
            label="UUID"
            width="200"
          />
          <el-table-column
            prop="partialPaymentNumber"
            label="No. de Pago"
            width="160"
            align="center"
          />
          <el-table-column
            prop="previousBalanceAmount"
            label="Balance Anterior"
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.previousBalanceAmount.toFixed(3) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="amountPaid"
            label="Monto Pagado"
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.amountPaid"
                size="medium"
                :max="scope.row.previousBalanceAmount"
                :min="0"
                :precision="3"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="unpaidBalanceAmount"
            label="Monto Por Pagar"
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              {{ calculateUnpaidBalanceAmount(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="currency"
            label="Moneda"
            align="center"
          />
          <el-table-column
            prop="paymentMethod"
            label="Metodo de Pago"
            align="center"
          />
          <el-table-column
            label="Opciones"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                icon="el-icon-delete"
                type="default"
                size="medium"
                @click="onRemoveDocument(scope.$index)"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
