import BaseApi from '../../infraestructure/BaseApi'

export default class ApiReceptions extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    getByDestionations(destinations){
        return this.client.get(`${this.endPoint}/destination/${destinations}`)
    }
    getReceptionNumber(receptionNumber, destinationId){
        return  this.client.get(`${this.endPoint}/receptionnumber/${receptionNumber}/destination/${destinationId}`)
    }

    updateStatus(statusData) {
        return this.client.post(`${this.endPoint}/update-status`, statusData)
    }

    printTrackingDocument(receptionId) {
        return this.client.get(`${this.endPoint}/generate-tracking-document/${receptionId}`)
    }

    printTrackingLabels(receptionId) {
        return this.client.get(`${this.endPoint}/generate-tracking-labels/${receptionId}`)
    }

    findFinancialHistory(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return  this.client.get(`${this.endPoint}/financial-history${parameters}`)
    }

    printFinancialHistory(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return  this.client.get(`${this.endPoint}/financial-history/print${parameters}`)
    }

    nextTrackingNumber(serviceCostId) { 
        return this.client.post(`${this.endPoint}/next-tracking-number/${serviceCostId}`)
    }
}
