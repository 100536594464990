import BaseViewModel from "../../../../core/infraestructure/BaseViewModel";

export default class CustomerBoxViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  findCustomerByCode(code) {
    this.api.customers
      .findByCode(code)
      .then((response) => this.view.onFindCustomerByCodeResponse(response.data))
      .catch((error) => this.view.onFindCustomerByCodeError(error));
  }
}
