<script>
import ModalReceptionComponent from "./ModalCustomerReceptionComponent";
import ReceptionCreateView from "./CustomerReceptionCreateView";
import ReceptionEditView from "./CustomerReceptionEditView";

export default {
  components: {
    "reception-create-view": ReceptionCreateView,
    "reception-edit-view": ReceptionEditView,
  },
  extends: ModalReceptionComponent,
};
</script>

<template>
  <div>
    <el-dialog
      width="70%"
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
      custom-class="dialog"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-clipboard-alt" /> Recepción de Paquetes
      </span>
      <reception-create-view
        v-if="formData.state === 'create' || formData.state === 'update'"
        :form-data="formData"
        :config="config"
        :submit-form="onSubmitForm"
      />
      <reception-edit-view
        v-else
        :form-data="formData"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="formData.state === 'create' || formData.state === 'update'"
          size="small"
          @click="onCancel"
        >Cancelar</el-button>
        <el-button
          v-else
          size="small"
          @click="onCancel"
        >Aceptar</el-button>
        <!-- <el-button
          v-if="formData.state === 'create'"
          type="primary"
          size="small"
          @click="onSubmitForm('formData')"
          >Guardar</el-button
        >-->
      </span>
    </el-dialog>
  </div>
</template>
