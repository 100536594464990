<script>
import ModalCustomerBillComponent from "./ModalCustomerBillComponent";
import LocationBoxView from '../shared/boxes/myLocationsBox/BoxView'

export default {
  components: {
    LocationBoxView
  },
  extends: ModalCustomerBillComponent
};
</script>

<template>
  <el-dialog
    width="50%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    custom-class="dialog"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-bill" /> Factura
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        v-if="formData.state === 'create'"
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
        >
          <el-form-item prop="fiscalSearch">
            <el-input
              v-model="fiscalSearch"
              placeholder="Buscar contacto por Razón Social o R.F.C."
              autocomplete="off"
              size="medium"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="onFindCustomerByFiscalSearch"
              >
                Buscar
              </el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="10"
        >
          <el-form-item
            label="Nombre de Contacto"
            prop="contactName"
          >
            <el-input
              v-model="formData.contactName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="9"
        >
          <el-form-item
            label="Correo Electrónico de Contacto"
            prop="contactEmail"
          >
            <el-input
              v-model="formData.contactEmail"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="5"
        >
          <el-form-item
            label="Tel. de Contacto"
            prop="contactPhone"
          >
            <el-input
              v-model="formData.contactPhone"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="18"
        >
          <el-form-item
            label="Razón Social"
            prop="fiscalName"
          >
            <el-input
              v-model="formData.fiscalName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          ::xs="24"
          :sm="6"
        >
          <el-form-item
            label="RFC"
            prop="fiscalCode"
          >
            <el-input
              v-model="formData.fiscalCode"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="Direcciones"
          name="first"
        >
          <location-box-view
            :form-data="formData"
            :is-location-selected="false"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
