import { render, staticRenderFns } from "./PackageBoxView.vue?vue&type=template&id=74c0fffa&"
import script from "./PackageBoxView.vue?vue&type=script&lang=js&"
export * from "./PackageBoxView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports