import BaseForm from "../infraestructure/BaseForm";
import SaleViewModel from "../../core/features/sales/SaleViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: "Sale",
      tabActive: "1",
      viewModel: null,
      paymentMethods: [],
      paymentTypes: [],
      currencies: [],
      billUsingTypes: [],
      billTypes: [],
      weightUnits: [],
      customers: [],
      taxRegimes: [],
      postalCodes: [],
      useCustomerData: "none",
      consignmentNotesVersions: [
        /* {
          id: "2.0",
          name: "2.0",
        },*/
        {
          id: "3.1",
          name: "3.1",
        },
      ],
      internationalTransports: [
        {
          id: "Si",
          name: "Si",
        },
        {
          id: "No",
          name: "No",
        },
      ],
      rules: {
        customer: [{
          required: true,
          message: 'Cliente requerido',
          trigger: 'blur, change'
        }],
        saleBill: {
          fiscalName: [
            {
              required: true,
              message: "Razón social requerido.",
              trigger: "blur",
            },
          ],
          fiscalCode: [
            {
              required: true,
              message: "R.F.C. requerido.",
              trigger: "blur",
            },
          ],
          fiscalEmail: [
            {
              validator: this.onSearchEmail,
              trigger: "blur",
            },
          ],
          billUsingType: [
            {
              required: true,
              message: "Uso de cfdi requerido.",
              trigger: "blur, change",
            },
          ],
          paymentType: [
            {
              required: true,
              message: "Forma de pago requerido.",
              trigger: "blur, change",
            },
          ],
          paymentMethod: [
            {
              required: true,
              message: "Metodo de pago requerido.",
              trigger: "blur, change",
            },
          ],
          billType: [
            {
              required: true,
              message: "Tipo de factura requerido.",
              trigger: "blur, change",
            },
          ],
          currency: [
            {
              required: true,
              message: "Moneda requerido.",
              trigger: "blur, change",
            },
          ],
          postalCode: [
            {
              validator: this.onValidatePostalCode,
              trigger: "blur, change",
            },
          ],
          payments: [
            {
              validator: this.onValidatePayments,
              trigger: "blur, change",
            },
          ],
        },
        currentReception: {
          documentReference: [
            {
              required: true,
              message: "Documento de referencia requerido.",
              trigger: "blur, change",
            }
          ],
        },
        saleConcepts: [
          {
            validator: this.onConceptsValidation,
            trigger: "blur, change",
          },
        ],
        consignmentNote: {
          version: [
            {
              required: true,
              message: "Carta porte versión requerido.",
              trigger: "blur, change",
            },
          ],
          internationalTransport: [
            {
              required: true,
              message: "Transporte internacional requerido.",
              trigger: "blur, change",
            },
          ],
          traveledDistance: [
            {
              required: true,
              message: "Distancia recorrida requerido.",
              trigger: "blur, change",
            },
          ],
          weightUnit: [
            {
              required: true,
              message: "Unidad de peso requerido.",
              trigger: "blur, change",
            },
          ],
          origin: {
            name: [
              {
                required: true,
                message: "Nombre remitente requerido.",
                trigger: "blur, change",
              },
            ],
            federalTaxPayerRegistration: [
              {
                required: true,
                message: "RFC remitente requerido.",
                trigger: "blur, change",
              },
            ],
            exchangeDate: [
              {
                required: true,
                message: "Fecha y hora de salida.",
                trigger: "blur, change",
              },
            ],
          },
          destination: {
            name: [
              {
                required: true,
                message: "Nombre destinatario requerido.",
                trigger: "blur, change",
              },
            ],
            federalTaxPayerRegistration: [
              {
                required: true,
                message: "RFC destinatario requerido.",
                trigger: "blur, change",
              },
            ],
            exchangeDate: [
              {
                required: true,
                message: "Fecha y hora de salida.",
                trigger: "blur, change",
              },
            ],
          },
          sctPermissionType: [
            {
              required: true,
              message: "Tipo de Permiso SCT requerido.",
              trigger: "blur, change",
            },
          ],
          sctPermissionNumber: [
            {
              required: true,
              message: "Numero de Permiso SCT requerido.",
              trigger: "blur, change",
            },
          ],
          autoTransportations: [
            {
              validator: this.onAutoTransportationsValidation,
              trigger: "blur, change",
            },
          ],
          transportationType: [
            {
              required: true,
              message: "Clave de transporte requerido.",
              trigger: "blur, change",
            },
          ],
          transportationFigures: [
            {
              validator: this.onTransportationFiguresValidation,
              trigger: "blur, change",
            },
          ],
        },
        receptionPackages: [{ validator: this.onMerchandiseValidation }],
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new SaleViewModel(this);
    this.onActive();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onActive() {
      this.viewModel.bindFormView(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid, errors) => {
        if (!isValid) {
          this.onShowWarningList(errors);
          return false;
        } else {
          this.onSave(this.formData);
          return true;
        }
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-sale");
    },
    onTransportationFiguresValidation(rule, value, callback) {
      this.viewModel.verifyTransportationFigure(value, callback);
    },
    onAutoTransportationsValidation(rule, value, callback) {
      this.viewModel.verifyAutoTransportation(value, callback);
    },
    onConceptsValidation(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error("Al menos un concepto requerido"));
      }
    },
    onMerchandiseValidation(rule, value, callback) {
      this.viewModel.verifyMerchandise(this.formData.receptions, callback);
    },
    isBillTypeIncome() {
      return this.formData.saleBill?.billType?.code === "I";
    },
    onSearchCustomers(data) {
      this.customers = [];
      if (data) this.viewModel.findCustomers(`FullSearch=${data}&IsActive=true`);
    },
    async onChangeCustomer(customer) {
      this.customers = []
      this.postalCodes = []
      if(customer) {
        this.config.isLoading = true
        await this.viewModel.findCustomer(customer.id)
      } else {
        this.setCustomerFiscal({});
      }
    },
    onFindCustomerResponse(response) {
      setTimeout(() => {
        this.setCustomerFiscal({
          fiscalName: response.data.fiscalName,
          fiscalCode: response.data.fiscalCode,
          fiscalEmail: response.data.contactEmail,
          fiscalPostalCode: response.data.fiscalPostalCode,
          taxRegime: response.data.taxRegime
        });
        
        this.config.isLoading = false
      }, 400);
    },
    onSearchCustomersSuccess(response) {
      this.customers = response;
    },
    onSearchCustomerError(error) {
      setTimeout(() => {
        this.onError(error);
      }, 400);
    },
    setCustomerFiscal({
      fiscalName = null,
      fiscalCode = null,
      fiscalEmail = null,
      fiscalPostalCode = null,
      taxRegime = null
    }) {
      
      if(fiscalPostalCode) this.postalCodes.push(fiscalPostalCode)
      
      this.$set(this.formData.saleBill, "fiscalName", fiscalName);
      this.$set(this.formData.saleBill, "fiscalCode", fiscalCode);
      this.$set(this.formData.saleBill, "fiscalEmail", fiscalEmail);
      this.$set(this.formData.saleBill, "postalCode", fiscalPostalCode);
      if(this.formData.customer) this.$set(this.formData.customer, "taxRegime", taxRegime);
    },
    onValidatePostalCode(rule, value, callback) {
      if (this.isAPaymentBillType()) {
        if (!this.formData.saleBill.postalCode)
          callback(new Error("Código Postal requerido"));
        else callback();
      } else {
        callback();
      }
    },
    onValidatePayments(rule, value, callback) {
      if (this.isAPaymentBillType()) {
        if (this.hasPayments()) {
          this.validatePayments(callback)
        } else {
          callback(new Error("Pagos requeridos."));
        }
      } else {
        callback();
      }
    },
    isAPaymentBillType() {
      return this.formData.saleBill.billType.code === "P";
    },
    hasPayments() {
      return this.formData.saleBill.saleBillPayments.length > 0;
    },
    validatePayments(callback) {
      this.formData.saleBill.saleBillPayments.forEach((payment, index) => {
        if (this.hasDocuments(payment)) {
          let totalAmountPaid = payment.saleBillPaymentRelateds.reduce((total, item) => { return total + item.amountPaid; }, 0 );
          if (payment.amount != totalAmountPaid) {
            callback(new Error(`En el registro de pago ${ index + 1 }: La suma del monto pagado de los documentos no equivale al monto total del pago.`))
          }
        } else {
          callback(new Error(`No hay documentos agregados en el registro de pago ${ index + 1 }`))
        }
      });
    },
    hasDocuments(payment) {
      return payment.saleBillPaymentRelateds.length > 0
    },
    onSearchEmail(rule, value, callback) {
      if (value) {
        let testEmails = value.split(";");
        // eslint-disable-next-line
        let regex = new RegExp(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
        );
        if (testEmails.length === 0) callback();
        if (testEmails.every((email) => regex.test(email))) callback();
        testEmails.forEach((email) => {
          if (!regex.test(email)) {
            callback(new Error(`El correo ${email} no es valido.`));
          }
        });
      } else {
        callback();
      }
    },
    onDiscountChange(data){
      this.onNotifyEvent('on-discount-update', data || 0)
    }
  },
};
