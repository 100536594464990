<script>
import ModalUserProfileComponent from "./ModalUserProfileComponent";

export default {
  extends: ModalUserProfileComponent,
};
</script>

<template>
  <el-dialog
    width="25%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-user-square" /> Actualizar Contraseña
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Contraseña"
            prop="password"
          >
            <el-input
              v-model="formData.password"
              autocomplete="off"
              size="medium"
              show-password
              name="password"
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Verificar Contraseña"
            prop="passwordConfirmation"
          >
            <el-input
              v-model="formData.passwordConfirmation"
              autocomplete="off"
              name="passwordConfirmation"
              size="medium"
              show-password
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
