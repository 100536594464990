import _ from 'lodash'
import BaseViewModel from '../../../../core/infraestructure/BaseViewModel'
import { MXN } from "../../../../core/helpers/MoneyHelper"
import ServiceCost from '../../../../core/features/serviceCosts/ServiceCost'


export default class ShipmentBoxViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    
    bindBoxView() {
        this.api.serviceCosts.find()
            .then((response) => this.view.onServiceCostResponse(response.data))
            .catch(this.view.onError)
    }

    findMunicipalities(query) {
        this.api.municipalities.find(query)
            .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
            .catch(this.view.onFindMunicipalitiesError)
    }

    findDestinations(query) {
        this.api.destinations.find(query)
            .then((response) => this.view.onFindDestinationsResponse(response.data))
            .catch(this.view.onFindDestinationsError)
    }

    formatMoney(item){
        return `${item.code} | ${item.description} | ${MXN(item.amount).format()}`
    }

    getItemData(formData, property, orProperty = null){
        return _.get(formData, property) || _.get(formData, orProperty) || ""
    }

    mapServiceCost(collection) {
        return collection.map((item) => new ServiceCost(item))
    }
}