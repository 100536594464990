<script>
import FaqsPageComponent from "./FaqsPageComponent"
import HeroSection from "../components/HeroSection"
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView"
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView"
export default {
  name: "FaqsPageView",
  components: {
    "hero-section": HeroSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  },
  extends: FaqsPageComponent,
};
</script>
<template>
  <div>
    <!-- Start .header-1-->
    <website-header-view />
    <!-- Start #page-title-->
    <!-- Start #page-title-->
    <hero-section
      :is-home="false"
      url="/website/images/sliders/faqs.jpg"
      title-sub-heading="Preguntas Frecuentes"
      title-heading="Encuentre Respuestas E Información General Acerca De Nuestros Servicios"
      :breadcrumb-item="[
        {
          href: 'index.html',
          name: 'Inicio',
        },
        {
          href: '',
          name: 'Empresa',
        },
        {
          href: '',
          name: 'Preguntas Frecuentes',
        },
      ]"
    />
    <!-- End #page-title-->
    <!--
      ============================
      FAQs Section
      ============================
      -->
    <section class="accordion accordion-3">
      <div class="container">
        <div class="row">
          <!-- 
            ============================
            Sidebar Area
            ============================
            -->
          <div class="col-sm-12 col-md-12 col-lg-4 order-2 order-lg-0">
            <div class="sidebar sidebar-case-study">
              <!-- Start .widget-categories-->
              <div class="widget widget-categories">
                <div class="widget-title">
                  <h5>Servicios</h5>
                </div>
                <div class="widget-content">
                  <ul class="list-unstyled">
                    <li><a href="javascript:void(0)">Mensajería</a></li>
                    <li><a href="javascript:void(0)">Guías pre-pagadas</a></li>
                    <li>
                      <a href="javascript:void(0)">Crédito empresarial</a>
                    </li>
                    <li><a href="javascript:void(0)">Recolección</a></li>
                    <li>
                      <a
                        href="javascript:void(0)"
                      >Recolección Aeromexpress carga</a>
                    </li>
                    <li><a href="javascript:void(0)">Flete por cobrar</a></li>
                  </ul>
                </div>
              </div>
              <!-- End .widget-categories -->
              <!-- Start .widget-reservation-->
              <div class="widget widget-reservation">
                <img
                  src="assets/images/blog/sidebar/reservation.jpg"
                  alt="Background Image"
                >
                <div class="widget-content">
                  <h5>Hola. ¿Cómo podemos ayudarte?</h5>
                  <p>
                    Si aún tienes dudas ponte en contacto con nosotros. Estamos
                    para asesorarte y ayudarte en los servicios que necesitas
                    para tu envio.
                  </p>
                  <a
                    class="btn btn--transparent btn--inverse btn--block"
                    href="javascript:void(0)"
                  >Contacto</a>
                </div>
              </div>
              <!-- End .widget-reservation-->
              <!-- Start .widget-download-->
              <!-- End .widget-download-->
            </div>
          </div>
          <!-- End .col-lg-4 -->
          <div class="col-12 col-lg-8">
            <div id="accordion03">
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-1"
                  >¿Qué plan es el adecuado para mí?
                  </a>
                </div>
                <div
                  id="collapse01-1"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-2"
                  >¿Tengo que comprometerme con un contrato?</a>
                </div>
                <div
                  id="collapse01-2"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card active-acc">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-3"
                  >¿Qué métodos de pago están disponibles?
                  </a>
                </div>
                <div
                  id="collapse01-3"
                  class="collapse show"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-10"
                  >¿Qué sucede con mi envío si cancelo?
                  </a>
                </div>
                <div
                  id="collapse01-10"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-4"
                  >¿Qué pasa si elijo el plan incorrecto?
                  </a>
                </div>
                <div
                  id="collapse01-4"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-5"
                  >¿Algún contrato o compromiso?
                  </a>
                </div>
                <div
                  id="collapse01-5"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-6"
                  >¿Cuáles son mis opciones de pago?
                  </a>
                </div>
                <div
                  id="collapse01-6"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-7"
                  >¿Cómo funciona la prueba gratuita?
                  </a>
                </div>
                <div
                  id="collapse01-7"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-8"
                  >¿Cuándo debo recibir mi envío?</a>
                </div>
                <div
                  id="collapse01-8"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-heading">
                  <a
                    class="card-link collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion03"
                    href="#collapse01-9"
                  >¿Cómo cancelo mi envío?</a>
                </div>
                <div
                  id="collapse01-9"
                  class="collapse"
                  data-parent="#accordion03"
                >
                  <div class="card-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit sem
                    magna interdum tristique himenaeos, arcu eros cubilia
                    habitasse risus.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .container-->
    </section>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <website-footer-view />
  </div>
</template>
