import BaseList from "../infraestructure/BaseList";
import CustomerVerificationCodeViewModel from "../../core/features/customerVerificationCodes/CustomerVerificationCodeViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      customerId: null,
      searchCityId: "",
      searchCode: "",
      searchDescription: "",
      searchFullAddress: "",
      searchNeighborhood: "",
      searchPostalCode: "",
      neighborHoods: [],
      destinations: [],
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR CONTACTOS",
        onUploadImport: "on-upload-import-verification-codes",
        onCancelImport: "on-cancel-import-verification-codes",
      },
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-verification-codes", this.onActive);
    this.onMountedEvent("on-upload-import-verification-codes", this.onUploadImport);
    this.onMountedEvent("on-cancel-import-verification-codes", this.onCancelImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-customer-verification-codes", this.onSearch);
    this.onBeforeDestroyEvent(
      "on-upload-import-verification-codes",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-import-verification-codes",
      this.onCancelImport
    );
  },
  created() {
    this.viewModel = new CustomerVerificationCodeViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.viewModel.bindListAsync(this.$store.getters["admin/getUserActive"].email)
      
    },
    onSelectCustomer() {},
    onCreate() {
      this.item = this.viewModel.create({
        customerId: this.customerId,
        isActive: true,
      });
      this.modalConfig.isVisible = true;
    },
    searchCodeFilter() {
      this.addFilter("VerificationCode", this.searchCode);
      this.onSearch(this.customerId);
    },
    searchDescriptionFilter() {
      this.addFilter("Description", this.searchDescription);
      this.onSearch(this.customerId);
    },
    searchFullAddressFilter() {
      this.addFilter("FullAddress", this.searchFullAddress);
      this.onSearch(this.customerId);
    },
    searchNeighborhoodFilter() {
      this.addFilter("NeighborhoodId", this.searchNeighborhood.id);
      this.onSearch(this.customerId);
    },
    onFindNeighborhoods(data) {
      this.neighborHoods = [];
      if (data) {
        this.viewModel.findNeighborhoods(`Name=${data}`);
      }
    },
    onFindNeighborhoodsResponse(response) {
      this.neighborHoods = this.viewModel.mapNeighborhoods(response.data);
    },
    searchPostalCodeFilter() {
      this.addFilter("postalCode", this.searchPostalCode);
      this.onSearch(this.customerId);
    },
    searchCityIdFilter() {
      this.addFilter("cityId", this.searchCityId.id);
      this.onSearch(this.customerId);
    },
    onSearch(customerId) {
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(customerId, this.filters);
      }, 400);
    },
    onChangeEntries(data) {
      this.limit = data;
      this.onSearch(this.customerId);
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("isActive", data);
      } else {
        this.removeFilter("isActive");
      }
      this.onSearch(this.customerId);
    },
    onFindDestinations(data) {
      this.destinations = [];
      if (data) {
        this.viewModel.findDestinations(`Name=${data}`);
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = response.data;
    },
    onChangeOptionCustomerVerificationCodes(event) {
      if (event.command === "edit")
        this.onEditCustomerVerificationCode(event.customerId, event.item.id);
      if (event.command === "delete")
        this.onDeleteCustomerVerificationCode(event.customerId, event.item.id);
    },
    onEditCustomerVerificationCode(customerId, id) {
      this.viewModel.findItem(customerId, id);
    },
    onFindItemResponseCustomerVerificationCode(response, customerId) {
      this.item = this.viewModel.edit(response.data, customerId);
      this.modalConfig.isVisible = true;
    },
    onDeleteCustomerVerificationCode(customerId, id) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.delete(customerId, id);
        })
        .catch(() => {});
    },
    onDeleteResponseCustomerVerificationCode(response, customerId) {
      this.notifySuccess(response.message);
      this.onSearch(customerId);
    },
    getVerificationCodeQr(id) {
      this.isListLoading = true
      this.viewModel.getVerificationCodeQr(this.customerId, id)
    },
    onGetVerificationCodeQrResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () =>{
            this.notifySuccess("Qr de Verificación Generado.")
            this.isListLoading = false
        })
      }, 400);
    },
    onGetVerificationCodeQrError(error) {
      this.isListLoading = false
      this.onError(error)
    }
  },
};
