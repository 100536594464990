<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleProductTypeComponent from './ModuleProductTypeComponent'
import ListProductTypeView from './ListProductTypeView'
import ListMeasureUnitsView from '../measureUnits/ListMeasureUnitsView'
import ListTransportMerchandiseTypeView from '../transportMerchandiseTypes/ListTransportMerchandiseTypeView'
import ListDangerousMaterialView from '../dangerousMaterials/ListDangerousMaterialView'
import ListPackagingTypeView from '../packagingTypes/ListPackagingTypeView'
import ListUnitKeyView from '../unitKeys/ListUnitKeyView'

export default {
    components: {
        'admin-menu-view': AdminMenuView,
        'admin-header-view': AdminHeaderView,
        'admin-footer-view': AdminFooterView,
        'list-product-type-view': ListProductTypeView,
        'list-measure-units-view': ListMeasureUnitsView,
        'list-transport-merchandise-type-view': ListTransportMerchandiseTypeView,
        'list-dangerous-material-view': ListDangerousMaterialView,
        'list-packaging-type-view': ListPackagingTypeView,
        'list-unit-key-view': ListUnitKeyView,
    },
    extends: ModuleProductTypeComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="mdi mdi-bulletin-board module-header-icon" /> Catálogo de Productos
                </h4>

                <div class="page-title-right">
                  <!-- <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <a href="javascript: void(0);">Configuraci&oacute;n</a>
                    </li>
                    <li class="breadcrumb-item active">
                      Unidades de Medida
                    </li>
                  </ol> -->
                </div>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="tabActive">
                    <el-tab-pane
                      label="Tipos de Productos"
                      name="product-types"
                    >
                      <span slot="label"><i class="uil-tag me-1" />Tipos de Productos</span>
                      <list-product-type-view v-if="tabActive === 'product-types'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Unidades de Medida"
                      name="measure-units"
                    >
                      <span slot="label"><i class="uil-tape me-1" />Unidades de Medida</span>
                      <list-measure-units-view v-if="tabActive === 'measure-units'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Materiales Peligrosos"
                      name="dangerous-materials"
                    >
                      <span slot="label"><i class="uil-tape me-1" />Materiales Peligrosos</span>
                      <list-dangerous-material-view v-if="tabActive === 'dangerous-materials'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipos de Embalaje"
                      name="packaging-types"
                    >
                      <span slot="label"><i class="uil-tape me-1" />Tipos de Embalaje</span>
                      <list-packaging-type-view v-if="tabActive === 'packaging-types'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Estandares de Transportación de Productos"
                      name="standard-transportation-commodity"
                    >
                      <span slot="label"><i class="uil-tape me-1" />Bienes Transportados</span>
                      <list-transport-merchandise-type-view v-if="tabActive === 'standard-transportation-commodity'" />
                    </el-tab-pane>

                    <el-tab-pane
                      label="Claves de Unidad"
                      name="unit-key"
                    >
                      <span slot="label"><i class="uil-tape me-1" />Claves de Unidad</span>
                      <list-unit-key-view v-if="tabActive === 'unit-key'" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>