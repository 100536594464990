<script>
import LoginPageComponent from "./LoginPageComponent";
export default {
  name: "LoginPageView",
  extends: LoginPageComponent,
};
</script>
<template>
  <div class="authentication-bg">
    <div class="account-pages">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5 text-center">
            <div class="login-logo" />
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Bienvenidos !
                  </h5>
                  <p class="text-muted">
                    Ingresa con tu cuenta.
                  </p>
                </div>
                <div class="p-2 mt-4">
                  <el-form
                    ref="formData"
                    :model="formData"
                    :rules="rules"
                  >
                    <div class="mb-3">
                      <el-form-item
                        label="Correo/Usuario"
                        prop="email"
                      >
                        <el-input
                          v-model="formData.email"
                          type="text"
                          class="form-control"
                          placeholder="Ingresar correo"
                        />
                      </el-form-item>
                    </div>

                    <div class="mb-3">
                      <el-form-item
                        label="Contraseña"
                        prop="password"
                      >
                        <el-input
                          id="userpassword"
                          v-model="formData.password"
                          type="password"
                          class="form-control"
                          placeholder="Ingresar contraseña"
                          @keyup.enter.native="onSubmitForm('formData')"
                        />
                      </el-form-item>
                    </div>

                    <div class="form-check">
                      <div class="float-end">
                        <router-link
                          :to="{ name: 'recovery' }"
                          class="text-muted"
                        >
                          ¿Olvidaste tu contraseña?
                        </router-link>
                      </div>
                      <input
                        id="auth-remember-check"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label"
                        for="auth-remember-check"
                      >Recordarme</label>
                    </div>

                    <div class="mt-3 text-end">
                      <el-button
                        v-loading.fullscreen.lock="fullscreenLoading"
                        type="primary"
                        class="w-100"
                        @click="onSubmitForm('formData')"
                      >
                        Ingresar
                      </el-button>
                    </div>

                    <div class="mt-4 text-center">
                      <p class="mb-0">
                        Regresar a
                        <a
                          href="/"
                          class="fw-medium text-primary"
                        >
                          Sitio Web
                        </a>
                      </p>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>

            <div class="mt-5 text-center">
              <!-- <p>
                ©
                Minible. Crafted with
                <i class="mdi mdi-heart text-danger" /> by Themesbrand
              </p> -->
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>