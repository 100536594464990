import BaseApi from '../../infraestructure/BaseApi'

export default class TransportMerchandiseTypeApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doBatch(data) {
        return this.client.post(`${this.endPoint}/batch-import`, data)
    }
}