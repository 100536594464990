<script>
import AdminMenuComponent from "./AdminMenuComponent";



export default {
  
  extends: AdminMenuComponent,
};
</script>

<template>
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <router-link
        :to="'/admin/resumen'"
        class="logo logo-dark"
        title="Resumen"
      >
        <span class="logo-sm">
          <img
            src="/admin/assets/images/logo-small.png"
            alt=""
          >
        </span>
        <span class="logo-lg">
          <img
            src="/admin/assets/images/logo.png"
            alt=""
          >
        </span>
      </router-link>
      <a
        href="index.html"
        class="logo logo-dark"
      />
    </div>

    <button
      type="button"
      class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    >
      <i class="fa fa-fw fa-bars" />
    </button>

    <vue-simplebar class="sidebar-menu-scroll">
      <!--   <div data-simplebar >  --> 
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul
          id="side-menu"
          class="metismenu list-unstyled"
        >
          <li class="menu-title">
            Menu
          </li>

          <li
            v-if="onCanShow('AdminDashboard')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/resumen'"
              class="menu--link"
              title="Resumen"
            >
              <i
                class="uil-home-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Resumen</span>
            </router-link>
          </li>
          <li
            v-if="
              canShowMultiple([
                'Reception',
                'Delivery',
                'Tracking',
                'CollectionRouteTrace',
                'Order',
              ])
            "
            class="menu-title"
            @click.capture="toggleSideBarNavbar()"
          >
            Paqueter&iacute;a
          </li>
          <li
            v-if="
              canShowMultiple([
                'Reception',
                'CollectionRouteTrace',
              ])
            "
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/mostrador'"
              class="menu--link"
              title="Mostrador"
            >
              <i
                class="uil-qrcode-scan"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Mostrador</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('Order')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/ordenes'"
              class="menu--link"
              title="Ordenes"
              @click.capture="toggleSideBarNavbar()"
            >
              <i
                class="uil-location-pin-alt"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Ordenes</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('Delivery')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/manifiestos'"
              class="menu--link"
              title="Manifiestos"
            >
              <i
                class="uil-parcel"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Manifiestos</span>
            </router-link>
          </li>

          <li
            v-if="onCanShow('Tracking')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/guias-generadas'"
              class="menu--link"
              title="Guías Generadas"
              @click.capture="toggleSideBarNavbar()"
            >
              <i
                class="uil-crosshair"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Guías Generadas</span>
            </router-link>
          </li>
          
          <li
            v-if="
              canShowMultiple([
                'Sale',
                'ServiceDeliveryPrice',
                'PrepaidPlan',
              ])
            "
            class="menu-title"
            @click.capture="toggleSideBarNavbar()"
          >
            Ventas y Servicios
          </li>
          <li
            v-if="onCanShow('Sale')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/ventas-y-carta-porte'"
              class="menu--link"
              title="Ventas y Carta Porte"
            >
              <i
                class="uil-shopping-cart"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Ventas y Carta Porte</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('ServiceDeliveryPrice')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/costos-de-servicios'"
              title="Costos de Servicios"
            >
              <i
                class="uil-bill"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Costos de Servicios</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('PrepaidPlan')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/planes-de-prepago'"
              title="Planes de Prepago"
            >
              <i
                class="uil-file-contract-dollar"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Planes de Prepago</span>
            </router-link>
          </li>
          
          <li
            v-if="
              canShowMultiple([
                'Customer',
                'CustomerSolvency',
              ])
            "
            class="menu-title"
            @click.capture="toggleSideBarNavbar()"
          >
            Nuestros Clientes
          </li>
          <li
            v-if="onCanShow('Customer')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/clientes'"
              class="menu--link"
              title="Clientes"
            >
              <i
                class="uil-comment-notes"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Clientes</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('CustomerSolvency')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/creditos-y-prepago'"
              class="menu--link"
              title="Creditos y Prepago"
            >
              <i
                class="uil-ticket"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Creditos y Prepago</span>
            </router-link>
          </li>
          <li
            v-if="
              canShowMultiple([
                'Employee',
                'User',
                'Role',
              ])
            "
            class="menu-title"
            @click.capture="toggleSideBarNavbar()"
          >
            Autorización
          </li>
          <li
            v-if="onCanShow('Employee')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/autorizacion/empleados'"
              title="Empleados"
            >
              <i
                class="uil-head-side"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Empleados</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('User')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/autorizacion/usuarios'"
              title="Usuarios"
            >
              <i
                class="uil-user"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Usuarios</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('Role')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/autorizacion/roles-y-permisos'"
              title="Roles y Permisos"
            >
              <i
                class="uil-shield"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Roles y Permisos</span>
            </router-link>
          </li>
          <li
            v-if="onCanShow('Role')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/autorizacion/rastreo-gps'"
              title="Rastreo GPS"
            >
              <i
                class="uil-map-marker"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Rastreo GPS</span>
            </router-link>
          </li>
          <li
            v-if="
              canShowMultiple([
                'CollectionRoute',
                'SctPermissionType',
                'TraceStatusReason',
                'TransportationType',
                'Vehicle',
                'VehicleType',
                'WorkRoute',
                'BillConcept',
                'BillConceptKey',
                'BillType',
                'TaxType',
                'TaxFee',
                'TaxFactorType',
                'BillUsingType',
                'BillRelation',
                'ProductType',
                'MeasureUnit',
                'DangerousMaterial',
                'PackagingType',
                'TransportMerchandiseType',
                'UnitKey',
                'BranchOffice',
                'PostalCode',
                'Neighborhood',
                'Destination',
                'Municipality',
                'District',
                'Country',
              ])
            "
            class="menu-title"
            @click.capture="toggleSideBarNavbar()"
          >
            Configuraciones
          </li>
          <li
            v-if="
              canShowMultiple([
                'CollectionRoute',
                'SctPermissionType',
                'TraceStatusReason',
                'TransportationType',
                'Vehicle',
                'VehicleType',
                'WorkRoute',
              ])
            "
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/catalogo-de-transporte'"
              title="Catálogo de Transporte"
            >
              <i
                class="mdi mdi-map-marker-path"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Catálogo de Transporte</span>
            </router-link>
          </li>
          <!-- <li
            v-if="onUserPermission('Permission.Vehicle.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/configuracion/flotilla-vehiculos'"
              title="Flotilla de Vehículos"
            >
              <i
                class="uil-truck"
              />
              <span>Flotilla de Vehículos</span>
            </router-link>
          </li> -->
          <!-- <li
            v-if="onUserPermission('Permission.TraceStatusReason.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/configuracion/motivos-de-estatus'"
              title="Motivos de Estatus"
            >
              <i
                class="uil-layer-group"
              />
              <span>Motivos de Estatus</span>
            </router-link>
          </li> -->
          <li
            v-if="
              canShowMultiple([
                'BillConcept',
                'BillConceptKey',
                'BillType',
                'TaxType',
                'TaxFee',
                'TaxFactorType',
                'BillUsingType',
                'BillRelation',
              ])
            "
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/catalogo-de-ventas'"
              class="menu--link"
              title="Catálogo de Ventas"
            >
              <i
                class="uil-chart-line"
              />
              <span>Catálogo de Ventas</span>
            </router-link>
          </li>
          <li
            v-if="
              canShowMultiple([
                'ProductType',
                'MeasureUnit',
                'DangerousMaterial',
                'PackagingType',
                'TransportMerchandiseType',
                'UnitKey',
              ])
            "
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/catalogo-de-productos'"
              title="Tipos de productos"
            >
              <i
                class="mdi mdi-bulletin-board"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> :to="'/admin/configuracion/tipos-de-productos'" -->
              <span>Catálogo de Productos</span>
            </router-link>
          </li>
          <!-- <li
            v-if="onUserPermission('Permission.MeasureUnit.Show')"
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/configuracion/unidades-de-medida'"
              title="Unidades de Medida"
            >
              <i
                class="uil-tape"
              />
              <span>Unidades de Medida</span>
            </router-link>
          </li> -->

          
          <li
            v-if="
              canShowMultiple([
                'BranchOffice',
                'PostalCode',
                'Neighborhood',
                'Destination',
                'Municipality',
                'District',
                'Country',
              ])
            "
            @click.capture="toggleSideBarNavbar()"
          >
            <router-link
              :to="'/admin/catalogo-de-ubicaciones'"
              title="Catálogo de Ubicaciones"
            >
              <i
                class="uil-map-marker"
              /><!-- <span class="badge rounded-pill bg-primary float-end">01</span> -->
              <span>Catálogo de Ubicaciones</span>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
      <!--  </div>   -->
    </vue-simplebar>
  </div>
</template>
