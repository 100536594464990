export default class WorkRoutes {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description,
        branchOfficeId: this.branchOffice.id,
        isActive: this.isActive,
      }
    }
  }