import BaseForm from '../infraestructure/BaseForm'
import BillConceptViewModel from '../../core/features/billConcepts/BillConceptViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'BillConcept',
            viewModel: null,
            unitKeys: [],
            billConceptKeys: [],
            measureUnits: [],
            rules:{
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }],
                template: [{
                    required: true,
                    message: 'Plantilla requerido.',
                    trigger: 'blur'
                }],
                billConceptKey: [{
                    required: true,
                    message: 'Producto/Servicio requerido.',
                    trigger: 'blur'
                }],
                measureUnit: [{
                    required: true,
                    message: 'Unidad de Medida requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new BillConceptViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-bill-concept')
        },
        onFindUnitKeys(query) {
            this.unitKeys = []
            if(query)
                this.viewModel.findUnitKeys(`FullSearch=${query}&Skip=0&Limit=100000&IsActive=true`)
        },
        onUnitKeysResponse(response) {
            this.unitKeys = this.viewModel.mapUnitKeys(response.data)
        },
        onFindBillConceptKeys(query) {
            this.billConceptKeys = []
            if(query)
                this.viewModel.findBillConceptKeys(`FullSearch=${query}&Skip=0&Limit=100000&IsActive=true`)
        },
        onBillConceptKeysResponse(response) {
            this.billConceptKeys = this.viewModel.mapBillConceptKeys(response.data)
        },
        onFindMeasureUnits(query) {
            this.measureUnits = []
            if(query)
                this.viewModel.findMeasureUnits(`FullSearch=${query}&Skip=0&Limit=100000&IsActive=true`)
        },
        onMeasureUnitsResponse(response) {
            this.measureUnits = this.viewModel.mapMeasureUnits(response.data)
        }
    }
}