<script>
import AdminMenuCustomerView from "../shared/menus/AdminMenuCustomerView.vue";
import AdminHeaderCustomerView from "../shared/headers/AdminHeaderCustomerView";
import AdminFooterView from "../shared/footers/AdminFooterView";
import ModuleDashboardCustomerComponent from "./ModuleDashboardCustomerComponent";
import totalComponentView from "../total/totalComponentView";

export default {
  components: {
    "admin-menu-customer-view": AdminMenuCustomerView,
    "admin-header-view": AdminHeaderCustomerView,
    "admin-footer-view": AdminFooterView,
    "total-component-view": totalComponentView,
  },
  extends: ModuleDashboardCustomerComponent,
};
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-customer-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div
                class="page-title-box d-flex align-items-center justify-content-between"
              >
                <h4 class="mb-0">
                  <i class="uil-home-alt module-header-icon" /> Resumen Cliente
                </h4>
                <div class="page-title-right" />
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <total-component-view
              v-for="(item, index) in totalResumenes"
              :key="index"
              :total-amount="item.totalAmount"
              :url-img="item.urlImg"
              :title="item.title"
            />

            <!-- end col-->
          </div>
          <!-- end row-->
          <div class="row">
            <div class="col-xl-8">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    <i class="uil-map-marker module-header-icon" /> Guías por
                    Destino
                  </h4>
                  <div class="mt-3">
                    <apexchart
                      ref="destinationsChart"
                      type="bar"
                      height="388"
                      :options="chartOptions"
                      :series="series"
                    />
                  </div>
                </div>
                <!-- end card-body-->
              </div>
              <!-- end card-->
            </div>
            <!-- end col-->

            <div class="col-xl-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    <i class="uil-map-marker module-header-icon" /> Guías por
                    Estado
                  </h4>
                  <apexchart
                    type="pie"
                    :options="chartOptionsPie"
                    :series="chartOptionsPie.seriesPie"
                  />

                  <!-- end row-->
                </div>
                <!-- end card-body-->
              </div>
              <div class="card bg-custom-charts">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-sm-5">
                      <p class="text-white font-size-18">
                        Actualizar <b>Datos</b> Estadísticos
                        <i class="mdi mdi-arrow-right" />
                      </p>
                      <div class="mt-4">
                        <el-button
                          v-loading.fullscreen.lock="fullscreenLoading"
                          type="primary"
                          size="medium"
                          class="main-action"
                          @click="getData()"
                        >
                          <i class="uil-refresh " /> Actualizar Datos
                        </el-button>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <div class="mt-4 mt-sm-0">
                        <img
                          src="/admin/assets/images/img/dataanalisys.png"
                          class="img-fluid"
                          alt=""
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
              <!-- end card-->
            
              <!-- end card-->
            </div>
            <!-- end Col -->
          </div>
          <!-- end row-->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>
