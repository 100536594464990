<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleCustomersComponent from './ModuleCustomersComponent'
import ListCustomersView from './ListCustomersView'

export default {
    components: {
        'admin-menu-view': AdminMenuView,
        'admin-header-view': AdminHeaderView,
        'admin-footer-view': AdminFooterView,
        'list-customers-view': ListCustomersView
    },
    extends: ModuleCustomersComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-comment-notes module-header-icon" /> Clientes
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <list-customers-view />
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>