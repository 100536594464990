<script>
import ListCustomerReceptionComponent from "./ListCustomerReceptionComponent";
import ModalCustomerReceptionView from "./ModalCustomerReceptionView";
import ModalImportView from "../shared/modals/ModalImportView";
import ModalSummaryOfShipments from "./ModalSummaryOfShipments.vue"
export default {
  components: {
    "modal-customer-reception-view": ModalCustomerReceptionView,
    "modal-import-view": ModalImportView,
    ModalSummaryOfShipments,
  },
  extends: ListCustomerReceptionComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-around">
                <div
                  id="entradas"
                  class="mb-3 w-25"
                >
                  <span class="mx-2">Mostrar</span>
                  <el-select
                    v-model="defaultShowEntry"
                    placeholder="Opción"
                    size="small"
                    @change="onChangeEntries"
                  >
                    <el-option
                      v-for="item in showEntries"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <span class="mx-2">registros </span>
                </div>

                <div class="d-flex justify-content-around">
                  <div class="mx-2">
                    <el-input
                      v-model="searchReceptionNumber"
                      placeholder="Buscar Guía..."
                      prefix-icon="el-icon-search"
                      debounce
                      clearable
                      size="medium"
                      @input="searchReceptionNumberFilter"
                    />
                  </div>
                  <div class="mx-2">
                    <el-select
                      v-model="searchOriginName"
                      placeholder="Buscar Origen"
                      filterable
                      clearable
                      debounce
                      value-key="id"
                      remote
                      size="medium"
                      :remote-method="onFindOrigins"
                      @change="searchOriginNameFilter"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                      />
                      <el-option
                        v-for="item in origins"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </div>
                  <div>
                    <el-select
                      v-model="searchDestinyName"
                      placeholder="Buscar Destino"
                      filterable
                      clearable
                      debounce
                      value-key="id"
                      remote
                      size="medium"
                      :remote-method="onFindDestinations"
                      @change="searchDestinyNameFilter"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                      />
                      <el-option
                        v-for="item in destinations"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </div>

                  <div class="mx-2">
                    <div class="searchreceptiondate">
                      <el-date-picker
                        v-model="searchBeginingDate"
                        size="medium"
                        type="daterange"
                        clearable
                        range-separator="a"
                        start-placeholder="Fecha Inicio"
                        end-placeholder="Fecha Final"
                        @change="searchBeginingDateFilter"
                      />
                    </div>
                  </div>
                </div>

                <div style="width: 44%">
                  <div
                    id="buttonlist"
                    class="mb-3"
                  >
                    <el-dropdown
                      size="medium"
                      class="filter-action"
                    >
                      <el-button
                        type="danger"
                        size="medium"
                      >
                        <i class="uil-filter" /> Filtrar
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="my-2">
                          <el-select
                            v-model="searchReceptionType"
                            placeholder="Seleccionar Tipo Recepción"
                            :popper-append-to-body="false"
                            clearable
                            @change="searchReceptionTypeFilter"
                          >
                            <el-option
                              v-for="item in receptionTypes"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-dropdown-item>
                        <el-dropdown-item class="my-2">
                          <el-select
                            v-model="serachStatusTracking"
                            placeholder="Seleccionar Seguimiento"
                            :popper-append-to-body="false"
                            clearable
                            @change="serachStatusTrackingFilter"
                          >
                            <el-option
                              v-for="item in receptionTrackingStatus"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item class="my-2">
                          <el-select
                            v-model="searchpaymentType"
                            placeholder="Seleccionar tipo de pago"
                            :popper-append-to-body="false"
                            clearable
                            @change="searchpaymentTypeFilter"
                          >
                            <el-option
                              v-for="item in paymentTypes"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown
                      size="medium"
                      class="more-options-action"
                    >
                      <el-button
                        type="plain"
                        size="medium"
                      >
                        <i class="uil-ellipsis-v" /> Más Opciones
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onExport"
                          >
                            <i class="el-icon-download" /> Exportar
                          </el-button>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onSummaryOfShipments"
                          >
                            <i class="el-icon-truck" /> Resumen de Envíos
                          </el-button>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button
                      v-if="
                        onUserPermission('Permission.CustomerReception.Create')
                      "
                      type="primary"
                      size="medium"
                      class="main-action"
                      @click="onCreate"
                    >
                      <i class="fas fa-plus-circle" /> Nueva Recolección
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectCustomer"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="orderNumber"
                  label="No. Solicitud"
                  align="center"
                  width="120"
                />
                <el-table-column
                  property="trackingNumber"
                  label="No. Guía"
                  align="center"
                  width="190"
                >
                  <template #default="scope">
                    {{ scope.row.trackingNumber }}
                  </template>
                </el-table-column>

                <el-table-column
                  label="Fecha Solicitud"
                  property="receptionDate"
                  show-overflow-tooltip
                  align="center"
                  width="150"
                >
                  <template #default="scope">
                    <el-tag
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-date" />
                      <span class="mx-2">
                        {{ scope.row.receptionDate | formatDate }}
                      </span>
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Tipo de Servicio"
                  property="receptionServiceType"
                  show-overflow-tooltip
                  align="center"
                  width="140"
                >
                  <template #default="scope">
                    <el-tag
                      type="primary"
                      effect="plain"
                    >
                      <i
                        v-if="scope.row.receptionServiceType == 'Credito'"
                        class="uil-bill"
                      />
                      <i
                        v-if="scope.row.receptionServiceType == 'Prepago'"
                        class="uil-money-stack"
                      />
                      <i
                        v-if="scope.row.receptionServiceType == 'Contado'"
                        class="uil-money-bill"
                      />
                      {{ scope.row.receptionServiceType }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Costo Flete"
                  show-overflow-tooltip
                  align="center"
                  width="180"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isPickingVerified"
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-success" />
                      {{ onFormatMoney(scope.row.shippingQuote) }}
                    </el-tag>
                    <el-tag
                      v-else
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-warning" />
                      Por Verificar
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  property="senderPlace"
                  label="Origen"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  property="receiverPlace"
                  label="Destino"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  label="Seguimiento"
                  show-overflow-tooltip
                  align="center"
                  width="200"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.lastStatus"
                      :type="onGetStyleTrackingStatus(scope.row.status)"
                      effect="plain"
                    >
                      <i :class="onGetIconTrackingStatus(scope.row.status)" />
                      {{ scope.row.lastStatus }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-if="scope.row.orderNumber"
                            :command="{
                              command: 'generate-tracking-document',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Generar Etiquetas de Guía
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.CustomerReception.Update'
                              ) && scope.row.status === 1
                            "
                            :command="{
                              command: 'edit',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.CustomerReception.Update'
                              ) && scope.row.status !== 1
                            "
                            :command="{
                              command: 'show-reception',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.CustomerReception.Update'
                              )
                            "
                            :command="{
                              command: 'cancel',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-remove-outline" /> Cancelar
                          </el-dropdown-item>
                          <!-- <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.CustomerReception.Delete'
                              )
                            "
                            :command="{
                              command: 'delete',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>-->
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-customer-reception-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <ModalSummaryOfShipments
      v-if="modalSummaryOfShipments.isVisible"
      :config="modalSummaryOfShipments"
    />
  </div>
</template>
