import BaseViewModel from "../../infraestructure/BaseViewModel";
import GpsTrackingHelper from "../../helpers/GpsTrackingHelper";
import Tracking from "./Tracking";
import ReceptionTraceStatus from "./ReceptionTraceStatus";
import Customer from "../customers/Customer";
import ReceptionHelper from "../../helpers/ReceptionHelper";
import Destination from "../destinations/Destination";
import User from "../users/User";
import _ from "lodash";
import moment from "moment";

export default class TrackingViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  static receptionTrackingStatus = ReceptionHelper.receptionTrackingStatus;

  create(data) {
    return new Tracking(data, "create");
  }

  edit(data) {
    return new Tracking(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.trackings
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.trackings
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(id) {
    this.api.trackings
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.trackings
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.trackings
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  findItemTrackingNumber(trackingNumber) {
    this.api.trackings
      .findTrackingNumber(trackingNumber)
      .then((response) =>
        this.view.onFindItemTrackingNumberResponse(response.data)
      )
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      receptionNumber: "",
      originName: "",
      destinyName: "",
      beginingDate: "",
      endingDate: "",
      receptionType: "",
      paymentType: "",
      statusTracking: "",
      status: "",
    };

    this.view.removeFilter("ReceptionNumber");
    this.view.removeFilter("OriginPlaceId");
    this.view.removeFilter("DestinationPlaceId");
    this.view.removeFilter("ReceptionType");
    this.view.removeFilter("PaymentType");
    this.view.removeFilter("TrackingStatus");
    this.view.removeFilter("BeginingDate");
    this.view.removeFilter("EndingDate");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  bindForm(formData) {
    formData.isDelivered = formData.deliveryLat && formData.deliveryLng;
  }

  startMap(formData) {
    // Paso 1: Ordenar los registros por fecha de forma descendente
    const sortedRecords = _.orderBy(formData.historyTrace, ["date"], ["desc"]);

    // Paso 2: Filtrar los registros con estatus 5
    const filteredRecords = _.filter(sortedRecords, { status: 5 });

    // Paso 3: Obtener el primer registro con estatus 5
    const firstRecordWithDeliveredStatus = _.head(filteredRecords);

    this.drawMap([
      {
        lat: formData.deliveryLat,
        lng: formData.deliveryLng,
        getMarkerContent: () => {
          return `
                          <strong>Estado:</strong><br />
                          ${firstRecordWithDeliveredStatus.traceStatusName.toUpperCase()} <br /><br /> 
                          <strong>COMENTARIOS:</strong><br />
                          ${firstRecordWithDeliveredStatus.comments}<br /><br />
                          <strong>FECHA Y HORA DE ENTREGA:</strong><br />
                          ${moment(firstRecordWithDeliveredStatus.date).format(
                            "DD MMM YYYY hh:mm:ss a"
                          )}<br />
                      `;
        },
      },
    ]);
  }

  bindList() {
    this.api.places
      .find(`Skip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.origins = this.mapPlaces(response.data.data);
        this.view.destinations = this.mapPlaces(response.data.data);
        return this.api.customers.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.senderCustomers = this.mapCustomers(response.data.data);
        this.view.receiverCustomers = this.mapCustomers(response.data.data);
        setTimeout(() => {
          this.view.isListLoading = false;
          this.view.onSearch();
        }, 400);
      })
      .catch(this.view.onError);
  }

  getIconTrackingStatus(status) {
    return ReceptionHelper.getIconTrackingStatus(status);
  }

  getStyleTrackingStatus(status) {
    return ReceptionHelper.getStyleTrackingStatus(status);
  }
  getColorTrackingStatus(status) {
    return ReceptionHelper.getColorTrackingStatus(status);
  }

  getNameTrackingStatus(status) {
    return ReceptionHelper.getNameTrackingStatus(status);
  }

  getTraceStatusDescription(item) {
    var result = [];

    result.push(item.traceStatusName);

    if (item.traceStatusReason) {
      result.push(`Motivo: ${item.traceStatusReason}`);
    }

    if (item.comments) {
      result.push(`Observaciones: ${item.comments}`);
    }

    return result.join(" | ");
  }

  getOrderDescending(list) {
    return _.orderBy(list, ["date"], ["desc"]);
  }

  //#Region Find Orifins and Destinations
  findOrigins(criteria) {
    this.api.destinations
      .find(criteria)
      .then((response) => this.view.onFindOriginsResponse(response.data))
      .catch(this.view.onError);
  }

  findDestinations(criteria) {
    this.api.destinations
      .find(criteria)
      .then((response) => this.view.onFindDestinationsResponse(response.data))
      .catch(this.view.onError);
  }
  //#region

  findSenderCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) =>
        this.view.onFindSenderCustomersResponse(response.data)
      )
      .catch(this.view.onError);
  }
  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  findReceiverCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) =>
        this.view.onFindReceiverCustomersResponse(response.data)
      )
      .catch(this.view.onError);
  }

  //#Region
  mapCollection(collection) {
    return collection.map((item) => new Tracking(item));
  }

  mapPlaces(collection) {
    return collection.map((item) => new Destination(item));
  }

  mapUsers(collection) {
    return collection.map((item) => new User(item));
  }

  //#endregion

  createReceptionStatus(data) {
    var item = new ReceptionTraceStatus(data);
    return item;
  }

  saveReceptionStatus(data) {
    this.api.receptions
      .updateStatus(data.toSend())
      .then((response) =>
        this.view.onSaveReceptionStatusResponse(response.data)
      )
      .catch(this.view.onSaveReceptionStatusError);
  }

  onBindFormReceptionStatus(formData) {
    // console.log(formData)
    if (formData.state === "update") {
      this.view.receptionStatusReasons = formData.receptionStatusReason
        ? [formData.receptionStatusReason]
        : [];
    }
  }

  findTraceStatusReasons(traceStatus) {
    this.api.traceStatusReasons
      .find(`TraceStatus=${traceStatus}`)
      .then((response) =>
        this.view.onFindTraceStatusReasonsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  printTrackingDocument(receptionId) {
    this.api.receptions
      .printTrackingDocument(receptionId)
      .then((response) =>
        this.view.onPrintTrackingDocumentResponse(response.data)
      )
      .catch(this.view.onError);
  }

  printTrackingLabels(receptionId) {
    this.api.receptions
      .printTrackingLabels(receptionId)
      .then((response) =>
        this.view.onPrintTrackingLabelsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  drawMap(collection) {
    if (this.map) {
        GpsTrackingHelper.removeMap(this.map);
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      } else {
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      }

      GpsTrackingHelper.loadCopyRights(this.map);
      var customIcon = GpsTrackingHelper.createCustomIcon();
      collection.forEach((deviceLocation) => {
        GpsTrackingHelper.createMarker(
          this.map,
          customIcon,
          [deviceLocation.lat, deviceLocation.lng],
          deviceLocation.getMarkerContent()
        );
      });
      this.map.closePopup();
      this.view.isMapLoading = false;
  }
}
