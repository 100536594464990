<script>
import MerchandiseBoxComponent from "./MerchandiseBoxComponent";
import ModalReceptionPackageView from '../../receptions/ModalReceptionPackageView'

export default {
  components: {
    ModalReceptionPackageView
  },
  extends: MerchandiseBoxComponent
};
</script>
<template>
  <div>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <div
          v-for="(reception, receptionIndex) in receptions"
          :key="receptionIndex"
        >
          <el-form-item
            :prop="propName"
            class="field-reception-packages"
          >
            <el-table
              v-loading="isTableLoading"
              show-summary
              sum-text="Total"
              :summary-method="getSummaries"
              :data="reception.receptionPackages"
              style="width: 100%"
            >
              <el-table-column
                label="Indice"
                width="100"
                align="center"
              >
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="description"
                label="Descripción"
              />
              <el-table-column
                label="Material Peligroso"
                width="200"
                align="center"
              >
                <template #default="scope">
                  <div
                    v-if="scope.row.isDangerousMaterial && scope.row.dangerousMaterial"
                  >
                    {{ scope.row.dangerousMaterial.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Bienes Transportados"
                width="200"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.transportMerchandiseType">
                    {{ scope.row.transportMerchandiseType.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Embalaje"
                width="200"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.packagingType">
                    {{ scope.row.packagingType.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="U.M."
                width="100"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.measureUnit">
                    {{ scope.row.measureUnit.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="Cantidad"
                width="100"
                align="center"
              />
              <el-table-column
                label="Valor Mercancia"
                width="130"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.merchandiseValue }}
                </template>
              </el-table-column>
              <el-table-column
                label="Moneda"
                width="80"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.currency">
                    {{ scope.row.currency.code }}
                  </div>
                  <!-- <el-select
                    v-model="scope.row.currency"
                    placeholder="Seleccionar"
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in currencies"
                      :key="item.id"
                      :label="item.code"
                      :value="item"
                    />
                  </el-select> -->
                </template>
              </el-table-column>
              <el-table-column
                label="Opciones"
                width="120"
                align="center"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <el-dropdown @command="onChangeOptionReceptionPackage">
                    <el-button size="small">
                      <i class="el-icon-more" />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{
                            command: 'edit',
                            item: scope.row,
                            receptionSaleIndex: receptionIndex,
                            index: scope.$index,
                          }"
                        >
                          <i class="el-icon-edit" /> Mostrar
                        </el-dropdown-item>
                        <!-- <el-dropdown-item
                              :command="{
                                command: 'delete',
                                item: scope.row,
                                receptionSaleIndex: receptionIndex,
                                index: scope.$index,
                              }"
                            >
                              <i class="el-icon-delete" /> Eliminar
                            </el-dropdown-item>-->
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <modal-reception-package-view 
      v-if="modalReceptionPackageConfig.isVisible"
      :form-data="itemReceptionPackage"
      :config="modalReceptionPackageConfig"
    />
  </div>
</template>
