<script>
import ListNeighborhoodComponent from "./ListNeighborhoodComponent";
import ModalNeighborhoodView from "./ModalNeighborhoodView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-neighborhood-view": ModalNeighborhoodView,
    "modal-import-view": ModalImportView,
  },
  extends: ListNeighborhoodComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div
                      id="buttonlist"
                      class="mb-3"
                    >
                      <el-button
                        type="danger"
                        class="filter-btn"
                        icon="fa fa-filter"
                        size="medium"
                        @click="onMoreFilters"
                      >
                        Filtrar por:
                      </el-button>
                      <el-dropdown
                        size="medium"
                        class="more-options-action"
                      >
                        <el-button
                          type="plain"
                          size="medium"
                        >
                          <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                          <i class="el-icon-arrow-down el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              :disabled="!onCanCreate(modulePermissions)"
                              @click="onShowImport"
                            >
                              <i class="el-icon-upload2" /> Importar
                            </el-button>
                          </el-dropdown-item>
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              :disabled="!onCanRead(modulePermissions)"
                              @click="onCreate"
                            >
                              <i class="el-icon-download" /> Exportar
                            </el-button>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-button
                        v-if="
                          onUserPermission('Permission.Neighborhood.Create')
                        "
                        type="primary"
                        size="medium"
                        class="main-action"
                        :disabled="!onCanCreate(modulePermissions)"
                        @click="onCreate"
                      >
                        <i class="fas fa-plus-circle" /> Crear Colonia
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.Neighborhood.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectNeighborhood"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="code"
                  label="Código"
                />

                <el-table-column
                  label="Nombre"
                  property="name"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="city"
                  label="Ciudad"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="municipality"
                  label="Municipio"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="district"
                  label="Estado"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="country"
                  label="País"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isActive"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" /> Activo
                    </el-tag>
                    <el-tag
                      v-if="!scope.row.isActive"
                      type="danger"
                      effect="plain"
                    >
                      <i class="el-icon-error" /> Inactivo
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="
                    onUserPermission('Permission.Neighborhood.Update') ||
                      onUserPermission('Permission.Neighborhood.Delete')
                  "
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.Neighborhood.Update')
                            "
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'edit', item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.Neighborhood.Delete')
                            "
                            :disabled="!onCanDelete(modulePermissions)"
                            :command="{ command: 'delete', item: scope.row }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-neighborhood-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button
          class="btn-text"
          type="text"
          @click="onClearFilters"
        >
          Borrar todos los filtros
        </el-button>
      </template>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Codigo:</span>
            <el-input
              v-model="filtersSelected.code"
              type="text"
              placeholder="Buscar por código"
              clearable
              size="small"
              @input="onSearchByCode"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Nombre:</span>
            <el-input
              v-model="filtersSelected.name"
              type="text"
              placeholder="Buscar por nombre"
              clearable
              size="small"
              @input="onSearchByName"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Pais:</span>
            <el-select
              v-model="filtersSelected.country"
              size="small"
              filterable
              clearable
              placeholder="Seleccionar"
              value-key="id"
              @change="onCountryChange"
            >
              <el-option
                v-for="item in countries"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Estado:</span>
            <el-select
              v-model="filtersSelected.district"
              size="small"
              filterable
              clearable
              placeholder="Seleccionar"
              value-key="id"
              @change="onDistrictChange"
            >
              <el-option
                v-for="item in districts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Municipio:</span>
            <el-select
              v-model="filtersSelected.municipality"
              size="small"
              filterable
              clearable
              placeholder="Seleccionar"
              value-key="id"
              @change="onMunicipalityChange"
            >
              <el-option
                v-for="item in municipalities"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Localidad:</span>
            <el-select
              v-model="filtersSelected.place"
              size="small"
              filterable
              clearable
              placeholder="Seleccionar"
              value-key="id"
              @change="onPlaceChange"
            >
              <el-option
                v-for="item in places"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">¿Activo?:</span>
            <el-select
              v-model="filtersSelected.status"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              size="medium"
              clearable
              debounce
              @change="searchStatusFilter"
            >
              <el-option
                v-for="item in statusOptionsActive"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
