import BaseForm from "../infraestructure/BaseForm";
import BaseList from "../infraestructure/BaseList";

export default {
  extends: BaseForm,
  mixins: [BaseList],
  data() {
    return {
      viewModel: null,
      rules: { },
      searchReceptionNumber: "",
      searchOriginName:"",
      searchDestinyName:"",
      searchSenderPlace: "",
      searchReceiverPlace:"",
      searchBeginingDate: "",
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {

  },
  methods: {
    onExport(){
      this.onNotifyEvent("on-export-customer-solvencies-excel",this.formData.idSelected);
    },
    searchReceptionNumberFilter(text) {
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchReceptionNumberFilter",text});
    },
    searchOriginNameFilter(text){
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchOriginNameFilter",text});
    },
    searchDestinyNameFilter(text){
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchDestinyNameFilter",text});
    },
    searchSenderPlaceFilter(text) {
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchSenderPlaceFilter",text});
    },
    searchReceiverPlaceFilter(text) {
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchReceiverPlaceFilter",text});
    },
    searchBeginingDateFilter(text) {
      this.onNotifyEvent("on-filters-customer-solvencies",{nameFilter:"searchBeginingDateFilter",text});
    },
  },
};