<script>
import ModalTaxRegimeComponent from "./ModalTaxRegimeComponent";

export default {
  extends: ModalTaxRegimeComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-tax-regime"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    width="35%"
  >
    <span slot="title" class="dialog-title">
      <i class="uil-puzzle-piece" /> Regimen Fiscal
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="Generales" name="general">
          <el-row>
            <el-col :span="14">
              <el-form-item label="Código" prop="code">
                <el-input
                  ref="code"
                  v-model="formData.code"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Estado" align="center">
                <el-switch
                  v-model="formData.isActive"
                  active-text="Activo"
                  inactive-text="Inactivo"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Nombre" prop="name">
                <el-input
                  v-model="formData.name"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Descripción" prop="description">
                <el-input
                  v-model="formData.description"
                  autocomplete="off"
                  size="medium"
                  type="textarea"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="¿Es Persona Física?" align="center">
                <el-switch
                  v-model="formData.isPhysicalPerson"
                  active-text="Si"
                  inactive-text="No"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="¿Es Moral?" align="center">
                <el-switch
                  v-model="formData.isMoral"
                  active-text="Si"
                  inactive-text="No"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Condiguración Uso de Cfdi's" name="bill-using-type">
          <el-row>
            <el-col :span="24">
              <el-select
                v-model="formData.billUsingTypes"
                multiple
                placeholder="Seleccionar"
                value-key="id"
              >
                <el-option
                  v-for="item in billUsingTypes"
                  :key="item.id"
                  :label="item.getFullDescription()"
                  :value="item"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
