import BaseViewModel from '../../infraestructure/BaseViewModel'
import TaxRegime from './TaxRegime'
import BillUsingType from '../billUsingTypes/BillUsingType'

export default class TaxRegimeViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new TaxRegime(data, 'create')
    }

    edit(data) {
        return new TaxRegime(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.taxRegimes.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.taxRegimes.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.taxRegimes.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.taxRegimes.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.taxRegimes.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    async bindForm() { 
        try {
            const billUsingTypesResponse = await this.api.billUsingTypes.find(`Skip=0&Limit=10000&IsActive=true`)
            this.view.billUsingTypes = this.mapBillUsingTypes(billUsingTypesResponse.data.data)
        } catch (error) {
            this.view.onError(error)
        }
    }

    import(file) {
        this.api.taxRegimes.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    importBillUsingTypes(file) {
        this.api.taxRegimes.importRelationsWithBillUsingTypes(file)
            .then((response) => this.view.onUploadImportBillUsingTypeResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            physicalPerson: '',
            moral: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('IsActive');
        this.view.removeFilter('IsMoral');
        this.view.removeFilter('IsPhysicalPerson');
        this.view.onSearch();
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new TaxRegime(item))
    }

    mapBillUsingTypes(collection){
        return collection.map((item) => new BillUsingType(item))
    }

    //#endregion
}
