
import BaseForm from '../infraestructure/BaseForm'
import ServiceCostViewModel from '../../core/features/serviceCosts/ServiceCostViewModel'

export default {
    extends: BaseForm,
    data() {
        var formatPrice = (rule, value, callback) => {
            if(!value){
                return callback(new Error('El costo requerido.'));
            }else if (!Number.parseFloat(value)) {
                callback(new Error('Ingrese dígitos'));
            }else {
                callback();
           } 
        }
        var originPlace = (rule, value, callback) => {
            if (!value) {
              callback(new Error('El Origen es requerido'));
            } else {
              callback();
            }
          }
          var destinationPlace = (rule, value, callback) => {
            if (!value) {
              callback(new Error('El destino requerido'));
            } else {
              callback();
            }
          };
        return {
            modulePermissions: 'ServiceDeliveryPrice',
            viewModel: null,
            rules:{
                code: [{
                    required: true,
                    message: 'El código requerido.',
                    trigger: 'blur'
                }],
                origin: [{
                    validator: originPlace, trigger: 'blur', required: true
                }],
                destination: [{
                    validator: destinationPlace, trigger: 'blur', required: true
                }],
                amount: [{
                    validator: formatPrice, trigger: 'blur', required: true
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]
            },
            origins: [],
            destinations:[],

        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ServiceCostViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
          this.formData.amount = Number.parseFloat(this.formData.amount)
              this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            })    
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-service-cost')
        },
        onFindOrigins(data) {
            if(data) {
                this.viewModel.findOrigins(`Name=${data}&IsActive=true&IsDestination=true`)
            } else {
                this.origins = []
            }
        },
        onFindOriginsResponse(response) {
            this.origins = response.data
        },
        onFindDestinations(data){
            if(data) {
                this.viewModel.findDestinations(`Name=${data}&IsActive=true&IsDestination=true`)
            } else {
                this.destinations = []
            }
        },
        onFindDestinationsResponse(response){
            this.destinations = response.data
        },
        
    }
}