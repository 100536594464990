import BaseForm from '../../../../components/infraestructure/BaseForm'
import AcceptTermsBoxViewModel from './AcceptTermsBoxViewModel'


export default {
    extends: BaseForm,
    data() {
        return {
            dialogVisible: false,
            viewModel: null,
        }
    },
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new AcceptTermsBoxViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onNotifyEvent('is-form-loading', false)
        },
        onChangeTerms(data) {
            console.log(data)
            console.log(this.formData)
        },
    }
}