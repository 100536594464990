import BaseForm from "../infraestructure/BaseForm";
import TrackingViewModel from "../../core/features/trackings/TrackingViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: 'Tracking',
      viewModel: null,
      activeTabName: 'historyTrace',
      isMapLoading: false,
      rules: {
        trackingNumber: [
          {
            required: true,
            message: "Código(s) requerido.",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Nombre requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new TrackingViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-tracking");
    },

    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status)
    },
    onGetColorTrackingStatus(status) {
      return this.viewModel.getColorTrackingStatus(status)
    },

    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status)
    },
    onGetTraceStatusDescription(item) {
      return this.viewModel.getTraceStatusDescription(item)
    },
    onGetOrderDescending(list) {
      return this.viewModel.getOrderDescending(list)
    },
    onTabChange(tab) {
      if(tab.name === 'deliveryLocation') {
        this.isMapLoading = true
        setTimeout(() => {
          this.viewModel.startMap(this.formData)
          this.isMapLoading = false
        }, 400);
      }
    }
  },
};
