<script>
import ModalSaleComponent from "./ModalSaleComponent";
import BillConceptsBoxView from "./boxes/BillConceptsBoxView";
import ExchangerBoxView from "./boxes/ExchangerBoxView";
import TransportationFigureView from "./boxes/TransportationFigureView";
import AutotransportationBoxView from "./boxes/AutotransportationBoxView";
import MerchandiseBoxView from "./boxes/MerchandiseBoxView";
// import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";
import PaymentComplementListView from "./boxes/paymentComplement/PaymentComplementListView";

export default {
  components: {
    "bill-concepts-box-view": BillConceptsBoxView,
    "exchanger-box-view": ExchangerBoxView,
    "transportation-figure-view": TransportationFigureView,
    "autotransportation-box-view": AutotransportationBoxView,
    "merchandise-box-view": MerchandiseBoxView,
    // AddressBoxView,
    "payment-complement-list-view": PaymentComplementListView,
  },
  extends: ModalSaleComponent,
};
</script>

<template>
  <el-dialog
    width="90%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <i class="uil-shopping-cart" /> Ventas
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs v-model="tabActive" type="border-card" class="tabs-primary">
        <el-tab-pane name="1" label="INFORMACIÓN FISCAL">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Cliente" prop="customer">
                <el-select
                  v-model="formData.customer"
                  placeholder="Seleccionar"
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  remote
                  :remote-method="onSearchCustomers"
                  filterable
                  @change="onChangeCustomer"
                >
                  <el-option
                    v-for="item in customers"
                    :key="item.id"
                    :label="item.description"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="formData.customer">
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="12">
                <el-form-item label="Razón Social" prop="saleBill.fiscalName">
                  <el-input
                    v-model="formData.saleBill.fiscalName"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    type="text"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="R.F.C." prop="saleBill.fiscalCode">
                  <el-input
                    v-model="formData.saleBill.fiscalCode"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    type="text"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Correo" prop="saleBill.fiscalEmail">
                  <el-input
                    v-model="formData.saleBill.fiscalEmail"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    type="text"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="16">
                <el-form-item label="Regimen Fiscal" prop="customer.taxRegime">
                  <el-select
                    v-model="formData.customer.taxRegime"
                    placeholder="Seleccionar"
                    filterable
                    clearable
                    debounce
                    value-key="id"
                    size="large"
                    disabled
                  >
                    <el-option
                      v-for="item in taxRegimes"
                      :key="item.id"
                      :label="item.getFullDescription()"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Dirección Fiscal (C.P.)"
                  prop="saleBill.postalCode"
                >
                  <el-select
                    v-model="formData.saleBill.postalCode"
                    placeholder="Seleccionar"
                    filterable
                    clearable
                    debounce
                    value-key="id"
                    size="large"
                    disabled
                  >
                    <el-option
                      v-for="item in postalCodes"
                      :key="item.id"
                      :label="item.code"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg reception-steps-wrapper"
              :gutter="20"
            >
              <el-col :span="5">
                <el-form-item
                  label="Metodo de Pago"
                  prop="saleBill.paymentMethod"
                  required
                >
                  <el-select
                    v-model="formData.saleBill.paymentMethod"
                    placeholder="Seleccionar"
                    clearable
                    debounce
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in paymentMethods"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  label="Tipo de Pago"
                  prop="saleBill.paymentType"
                  required
                >
                  <el-select
                    v-model="formData.saleBill.paymentType"
                    placeholder="Seleccionar"
                    clearable
                    debounce
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in paymentTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item
                  label="Uso de CFDI"
                  prop="saleBill.billUsingType"
                  required
                >
                  <el-select
                    v-model="formData.saleBill.billUsingType"
                    placeholder="Seleccionar"
                    clearable
                    debounce
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in billUsingTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                  label="Tipo de Factura"
                  prop="saleBill.billType"
                  required
                >
                  <el-select
                    v-model="formData.saleBill.billType"
                    placeholder="Seleccionar"
                    clearable
                    debounce
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in billTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Moneda" prop="saleBill.currency" required>
                  <el-select
                    v-model="formData.saleBill.currency"
                    placeholder="Seleccionar"
                    clearable
                    debounce
                    value-key="id"
                    size="medium"
                  >
                    <el-option
                      v-for="item in currencies"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3" align="center">
                <el-form-item label="Estatus de Pago" prop="isPaid">
                  <el-checkbox
                    v-model="formData.saleBill.isPaid"
                    class="checkbox-primary"
                    label="Pagado"
                    border
                    style="width:100%;"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="Condiciones de Pago"
                  prop="paymentConditions"
                >
                  <el-input
                    v-model="formData.saleBill.paymentConditions"
                    syze="medium"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <!--

          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <address-box-view
                orientation="horizontal"
                :form-data="formData.saleBill"
                prop-name="saleBill"
              />
            </el-col>
          </el-row>
          -->
        </el-tab-pane>
        <el-tab-pane
          v-if="!isBillTypeIncome()"
          name="2"
          label="COMPLEMENTO DE PAGO"
        >
          <payment-complement-list-view
            :form-data="formData.saleBill"
            prop-name="saleBill.payments"
          />
        </el-tab-pane>
        <el-tab-pane name="3" label="CONCEPTOS DE FACTURACIÓN">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col v-if="formData.currentReception" :span="6">
              <el-form-item
                label="Cobrese ó Devuelvase (C.O.D.)"
                prop="paymentAgainstDelivery"
              >
                <el-input-number
                  v-model="formData.currentReception.paymentAgainstDelivery"
                  size="medium"
                  :min="0"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="formData.currentReception" :span="6">
              <el-form-item label="Descuento (%)" prop="discountPercentage">
                <el-input-number
                  v-model="formData.currentReception.discountPercentage"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :min="0"
                  :max="100"
                  step-strictly
                  @change="onDiscountChange"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="formData.currentReception" :span="6">
              <el-form-item
                label="Referencia de Guía Física"
                prop="currentReception.documentReference"
              >
                <el-input
                  v-model="formData.currentReception.documentReference"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  type="text"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="formData.currentReception" :span="6" align="center">
              <el-form-item label="Estado del Flete" prop="freightPayable">
                <el-checkbox
                  v-model="formData.currentReception.freightPayable"
                  class="checkbox-primary"
                  label="Pagado"
                  border
                  style="width:100%;"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <bill-concepts-box-view
            v-if="formData"
            :form-data="formData"
            prop-name="concepts"
          />
        </el-tab-pane>
        <el-tab-pane v-if="isBillTypeIncome()" name="4" label="CARTA PORTE">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="4">
              <el-form-item
                label="Carta Porte Versión"
                prop="consignmentNote.version"
              >
                <el-select
                  v-model="formData.consignmentNote.version"
                  placeholder="Seleccionar"
                  value-key="id"
                  size="medium"
                >
                  <el-option
                    v-for="item in consignmentNotesVersions"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item
                label="Transporte Internacional"
                prop="consignmentNote.internationalTransport"
              >
                <el-select
                  v-model="
                    formData.saleBill.consignmentNote.internationalTransport
                  "
                  placeholder="Seleccionar"
                  value-key="id"
                  size="medium"
                >
                  <el-option
                    v-for="item in internationalTransports"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item
                label="Distancia Recorrida (Km)"
                prop="consignmentNote.traveledDistance"
                required
              >
                <el-input-number
                  v-model="formData.consignmentNote.traveledDistance"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-collapse>
                <el-collapse-item title="UBICACIONES" name="locations">
                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24">
                      <el-tabs type="border-card">
                        <el-tab-pane label="Origen">
                          <exchanger-box-view
                            v-if="formData.consignmentNote.origin"
                            :transaction-info="formData.consignmentNote.origin"
                            :form-data="formData.consignmentNote.origin"
                            prop-name="consignmentNote.origin"
                            :sale="formData"
                          />
                        </el-tab-pane>
                        <el-tab-pane label="Destino">
                          <exchanger-box-view
                            v-if="formData.consignmentNote.destination"
                            :transaction-info="
                              formData.consignmentNote.destination
                            "
                            :form-data="formData.consignmentNote.destination"
                            prop-name="consignmentNote.destination"
                            :sale="formData"
                          />
                        </el-tab-pane>
                      </el-tabs>
                    </el-col>
                  </el-row>
                </el-collapse-item>
                <el-collapse-item title="MERCANCIA" name="merchandise">
                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        label="Unidad de Peso"
                        :prop="`consignmentNote.weightUnit`"
                        required
                      >
                        <el-select
                          v-model="formData.consignmentNote.weightUnit"
                          placeholder="Seleccionar unidad de peso"
                          value-key="id"
                          size="medium"
                        >
                          <el-option
                            v-for="item in weightUnits"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <merchandise-box-view
                    :receptions="formData.receptions"
                    :currencies="currencies"
                    prop-name="receptionPackages"
                  />
                </el-collapse-item>

                <autotransportation-box-view
                  v-if="formData"
                  :form-data="formData.consignmentNote"
                  prop-name="consignmentNote"
                />

                <transportation-figure-view
                  v-if="formData"
                  :form-data="formData.consignmentNote"
                  prop-name="consignmentNote"
                />
              </el-collapse>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
