class TokenStorageHelper {
    static #JsonWebTokenKey = 'jwt';

    isAuthenticated() {
        return localStorage.getItem(TokenStorageHelper.#JsonWebTokenKey) !== null;
    }

    set storeToken(token) {
        localStorage.setItem(TokenStorageHelper.#JsonWebTokenKey, token);
    }

    get Token() {
        return localStorage.getItem(TokenStorageHelper.#JsonWebTokenKey);
    }

    get BearerHeader() {
        return {
            header: {
                'Authorization' : `Bearer ${this.Token}`
            }
        }
    }
    getUserRole(){
        const jwt = localStorage.getItem(TokenStorageHelper.#JsonWebTokenKey);
        var payload = atob(jwt.split(".")[1]);
        const items = payload.split(",");
        const role = items.find(item => item.includes("role"));
        return role.split(":")[2].replace(/['"]+/g, "");
        
    }

    removeToken() {
        localStorage.removeItem(TokenStorageHelper.#JsonWebTokenKey);
    }
}

export default new TokenStorageHelper();
