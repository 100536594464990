import BaseComponent from "../../infraestructure/BaseComponent";

export default {
  extends: BaseComponent,
  data() {
    return {
      show: false,
      navbarOpen: false,
    };
  },
  mounted() {
    this.show = false;
    this.navbarOpen = false;
  },
  beforeDestroy() {
    this.removeClassSubMenu("menuempresa");
    this.removeClassSubMenu("menuservicios");
  },
  methods: {
    toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    toggleSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      if (!item.classList.contains("show")) {
        item.classList.add("show");
      } else {
        item.classList.remove("show");
      }
    },
    removeClassSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      item.classList.remove("show");
    },
  },
};
