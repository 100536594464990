import BaseModule from "../infraestructure/BaseModule";
import DashboardViewModel from "../../core/features/dashboard/DashboardViewModel";
import es from "apexcharts/dist/locales/es.json";

export default {
  extends: BaseModule,
  data() {
    return {
      viewModel: null,
      fullscreenLoading: false,
      totalResumenes: [
        {
          totalAmount: 0,
          urlImg: "/admin/assets/images/img/guiasgeneradas.svg",
          title: "Total Guías",
        },
        {
          totalAmount: 0,
          urlImg: "/admin/assets/images/img/users.svg",
          title: "Total Usuarios de la Plataforma",
        },
        {
          totalAmount: 0,
          urlImg: "/admin/assets/images/img/clientes.svg",
          title: "Total Clientes",
        },
        {
          totalAmount: 0,
          urlImg: "/admin/assets/images/img/manifiestos.svg",
          title: "Total Manifiestos (Reportes de envío)",
        },
      ],
      series: [{
        name: 'Total guías',
        data: []
      }],
      chartOptions: {
        chart: {
          id: 'destinationsChart',
          stacked: false,
          zoom: false,
          //  toolbar: false,
          locales: [es],
          defaultLocale: "es",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            dataLabels: {
              enabled: false,
              position: "top",
            },
          },
        },
        stroke: {
          width: 2,
        },

        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "CANTIDAD GUÍAS",
          },
          min: 0,
        },
        tooltip: {
          enabled: true,
          shared: false,

          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
       chartOptionsPie: {
        chart: {
          id: "guideChartByState",
          width: "100",
          type: "pie",
          redrawOnParentResize: true
        },
        labels: [],
        colors: ['#2E93fA', '#FF9800', '#170055', '#865439', '#66DA26','#D4ECDD', '#E91E63'],
        seriesPie: [1,1,1,1,1,1,1],
      },
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["admin/isAuth"];
    },
  },
  created() {
    this.viewModel = new DashboardViewModel(this);
  },
  mounted() {
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.getDataResumen();
    },
    onSearchResponse(response) {
      this.totalResumenes[0].totalAmount = response.data.totalResponses.receptions;
      this.totalResumenes[1].totalAmount = response.data.totalResponses.users;
      this.totalResumenes[2].totalAmount = response.data.totalResponses.customers;
      this.totalResumenes[3].totalAmount = response.data.totalResponses.deliveries;
      this.updateSeriesDestinations(response.data.totalByLocation);
    this.updateSeriesPie(response.data.totalByTraceStatus);
    },
    updateSeriesDestinations(response) {
      const arrDataSeries = response.map(item => {
        return{
          x: item.destinationCode,
          y: item.total
        };
      })
      this.series = [{
        data: arrDataSeries
      }]

    },
    updateSeriesPie(response) {
       let seriespieArr = []
       let labelsPie = []
       Object.keys(response).forEach((key) => {
        if (key !== "totalReceptions") {
          seriespieArr.push(response[key]) 
          labelsPie.push(this.getTagName(key))
        }
      });
      this.chartOptionsPie = {
        labels: labelsPie,
        seriesPie: seriespieArr
      }  
    },
    getTagName(key){
      let label = ''
      switch (key) {
        case 'onWareHouse':
            label = "En Almacen";
            break;
        case 'onRoute':
            label = "En Ruta";
            break;
        case 'delivered':
            label = "Entregados";
            break;
        case 'devolution':
            label = "Devolución";
            break;
        case 'onWaitingPicking':
            label = "En Espera de Recolección";
            break;
        case 'collected':
            label = "Recolectado";
            break;
        case 'cancelled':
            label = "Cancelados";
            break;
    }
    return label
},
    
    getData() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.onActive();
        this.fullscreenLoading = false;
      }, 2000);
    },
  },
};
