export default {
  props: {
    totalAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    urlImg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    
  },
 

};

