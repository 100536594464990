import BaseViewModel from '../../infraestructure/BaseViewModel'
import Country from '../countries/Country'
import District from './District'

export default class DistrictViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new District(data, 'create')
    }

    edit(data) {
        return new District(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.districts.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.districts.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.districts.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.districts.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.districts.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm() { 
        this.api.countries.find(`Skip=0&Limit=100&IsActive=true`)
            .then((response) => {
                this.view.countries = response.data.data
            })
            .catch(this.view.onError)
    }

    import(file) {
        this.api.districts.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            country: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('CountryId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    bindList() {
        this.api.countries.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.countries = this.mapCountry(response.data.data)
                setTimeout(() => {
                    this.view.onSearch();
                    this.view.isListLoading = false;
                }, 400);
            })
            .catch(this.view.onError);
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new District(item))
    }

    mapCountry(collection) {
        return collection.map((item) => new Country(item))
    }

    //#endregion
}