import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import TrackingViewModel from "../../core/features/trackings/TrackingViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: "Tracking",
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                receptionNumber: '',
                originName: '',
                destinyName: '',
                beginingDate: '',
                sender: '',
                receiver: '',
                customerSender: '',
                customerReceiver: '',
                receptionType: '',
                paymentType: '',
                statusTracking: '',
            },
            origins: [],
            destinations: [],
            searchReceptionType: "",
            searchpaymentType: "",
            searchTrackingStatus: "",
            senderCustomers: [],
            receiverCustomers: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR TRAZABILIDAD",
                onUploadImport: "on-upload-import-destinations",
                onCancelImport: "on-cancel-import-destinations",
            },
            itemReceptionStatus: null,
            modalReceptionStatus: {
                isVisible: false,
                isLoading: false,
            },
            receptionTrackingStatus: TrackingViewModel.receptionTrackingStatus,
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-tracking", this.onSearch);
        this.onMountedEvent("on-upload-import-destinations", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-destinations", this.onCancelImport);
        this.onMountedEvent("on-submited-reception-status", this.onUpdateStatus);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-tracking", this.onSearch);
        this.onBeforeDestroyEvent(
            "on-upload-import-destinations",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-destinations",
            this.onCancelImport
        );
        this.onBeforeDestroyEvent(
            "on-submited-reception-status",
            this.onUpdateStatus
        );
    },
    created() {
        this.viewModel = new TrackingViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectTracking() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
            });
            this.modalConfig.isVisible = true;
        },
        onDeliveryHistory() {
            this.modalConfig.isVisible = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByReceptionNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ReceptionNumber', text)
            } else {
                this.removeFilter('ReceptionNumber')
            }
            this.onSearch();
        }, 500),
        searchOriginFilter(data) {
            if (data) {
                this.addFilter('OriginName', data.name)
            } else {
                this.removeFilter('OriginName')
            }
            this.onSearch()
        },
        searchDestinationFilter(data) {
            if (data) {
                this.addFilter('DestinyName', data.name)
            } else {
                this.removeFilter('DestinyName')
            }
            this.onSearch()
        },
        onSearchBySenderName: _.debounce(function (text) {
            if (text) {
                this.addFilter('SenderName', text)
            } else {
                this.removeFilter('SenderName')
            }
            this.onSearch();
        }, 500),
        onSearchByReceiverName: _.debounce(function (text) {
            if (text) {
                this.addFilter('ReceiverName', text)
            } else {
                this.removeFilter('ReceiverName')
            }
            this.onSearch();
        }, 500),
        searchReceptionTypeFilter(data) {
            if (data !== null) {
                this.addFilter('ReceptionType', data)
            } else {
                this.removeFilter('ReceptionType')
            }
            this.onSearch()
        },
        searchCustomerSenderNameFilter(data) {
            if (data !== null) {
                this.addFilter('CustomerSenderId', data)
            } else {
                this.removeFilter('CustomerSenderId')
            }
            this.onSearch()
        },
        searchCustomerReceiverNameFilter(data) {
            if (data !== null) {
                this.addFilter('CustomerReceiverId', data)
            } else {
                this.removeFilter('CustomerReceiverId')
            }
            this.onSearch()
        },
        serachStatusTrackingFilter(data) {
            if (data !== null) {
                this.addFilter('TrackingStatus', data)
            } else {
                this.removeFilter('TrackingStatus')
            }
            this.onSearch()
        },
        onSearchDate: _.debounce(function (text) {
            if (text) {
                this.addFilter('StartDate', text[0])
                this.addFilter('EndDate', text[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onSearch();
        }, 500),
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onGetNameTrackingStatus(status) {
            return this.viewModel.getNameTrackingStatus(status);
        },
        onGetIconTrackingStatus(status) {
            return this.viewModel.getIconTrackingStatus(status);
        },
        onGetStyleTrackingStatus(status) {
            return this.viewModel.getStyleTrackingStatus(status);
        },
        searchOriginNameFilter() {
            if (this.searchOriginName !== "") {
                this.addFilter("OriginName", this.searchOriginName.name);
            } else {
                this.removeFilter("OriginName");
            }
            this.onSearch();
        },
        searchDestinyNameFilter() {
            if (this.searchDestinyName !== "") {
                this.addFilter("DestinyName", this.searchDestinyName.name);
            } else {
                this.removeFilter("DestinyName");
            }
            this.onSearch();
        },
        searchTrackingStatusFilter() {
            if (this.searchTrackingStatus !== null) {
                this.addFilter("trackingStatus", this.searchTrackingStatus);
            } else {
                this.removeFilter("trackingStatus");
            }
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter("IsActive", data);
            } else {
                this.removeFilter("IsActive");
            }
            this.onSearch();
        },
        onChangeOption(event) {
            switch (event.command) {
                case "show":
                    this.onEdit(event.item.id);
                    break;
                case "add-reception-status":
                    this.onAddReceptionStatus(event.item.id);
                    break;
                case "generate-tracking-document":
                    this.itemSelected = event.item;
                    this.onPrintDocument(event.item.id);
                    break;
                case "generate-tracking-labels":
                    this.itemSelected = event.item;
                    this.onPrintTrackingLabels(event.item.id);
                    break;
                default:
                    this.onError("Opción no valida");
                    break;
            }
        },
        onAddReceptionStatus(receptionId) {
            this.itemReceptionStatus = this.viewModel.createReceptionStatus({
                receptionId,
            });
            this.modalReceptionStatus.isVisible = true;
        },
        onUpdateStatus() {
            this.onSearch();
        },
        onPrintDocument(receptionId) {
            this.isListLoading = true;
            this.viewModel.printTrackingDocument(receptionId);
        },
        onPrintTrackingDocumentResponse(response) {
            setTimeout(() => {
                this.viewModel.addDownloadEvent(
                    response.data,
                    response.fileName,
                    "application/pdf",
                    () => {
                        this.notifySuccess("Guía Generada.");
                        this.isListLoading = false;
                    }
                );
            }, 400);
        },
        onPrintTrackingLabels(receptionId) {
            this.isListLoading = true;
            this.viewModel.printTrackingLabels(receptionId);
        },
        onPrintTrackingLabelsResponse(response) {
            setTimeout(() => {
                this.viewModel.addDownloadEvent(
                    response.data,
                    response.fileName,
                    "application/pdf",
                    () => {
                        this.notifySuccess("Etiquetas Generadas.");
                        this.isListLoading = false;
                    }
                );
            }, 400);
        },
    },
};
