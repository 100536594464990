

 function createJs(url, type, id) {
  const script = document.createElement("script")
  script.src = url
  script.type = type
  script.id = id
  return script
} 


  // loadJS("/website/js/vendor/jquery-3.4.1.min.js", "text/javascript");
  if (window.location.href.indexOf('admin') === -1){
    document.body.insertAdjacentHTML(
      "beforeend",
      createJs("/website/js/vendor/jquery-3.4.1.min.js", "text/javascript", "website").outerHTML
    );
    document.body.insertAdjacentHTML(
      "beforeend",
      createJs("/website/js/vendor.min.js", "text/javascript", "website").outerHTML
    );
    document.body.insertAdjacentHTML(
      "beforeend",
      createJs("/website/js/functions.js", "text/javascript", "website").outerHTML
    );
    // loadJS("/website/js/functions.js", "text/javascript");
  }/*  else {
    document.body.insertAdjacentHTML(
      "beforeend",
      createJs("/dashboard/js/simplebar/simplebar.min.js", "text/javascript", "dashboard").outerHTML
    );
  }  */
  


