<script>
import StepFourComponent from "./StepFourComponent";

export default {
  extends: StepFourComponent,
};
</script>
<template>
  <el-row
    type="flex"
    class="row-bg reception-steps-wrapper"
    :gutter="20"
  >
    <el-col :span="24">
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :span="24"
          align="center"
        >
          <h4>GUÍA DE ENVÍO</h4>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <i class="uil-check-circle success" />
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <h3>
                <i class="uil-qrcode-scan" />
                {{ formData.trackingNumber }}
              </h3>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row
        type="flex"
        class="row-bg reception-info-wrapper"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row
            type="flex"
            class="row-bg reception-info-details"
            :gutter="20"
          >
            <el-col :span="8">
              <el-card class="box-card">
                <div
                  slot="header"
                  class="clearfix"
                >
                  <h5><i class="uil-check-circle" /> Tipo de Recepción</h5>
                </div>
                <div>
                  {{ onGetNameReceptionType(formData.receptionType) }}
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="box-card">
                <div
                  slot="header"
                  class="clearfix"
                >
                  <h5><i class="uil-check-circle" /> Responsable de Pago</h5>
                </div>
                <div>
                  {{ onGetPayerResponsible(formData.payerResponsible) }}
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="box-card">
                <div
                  slot="header"
                  class="clearfix"
                >
                  <h5><i class="uil-check-circle" /> Estado de Recepción</h5>
                </div>
                <div>
                  {{ onGetNameTrackingStatus(formData.status) }}
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="12">
              <el-card class="box-card">
                <div
                  slot="header"
                  class="clearfix"
                >
                  <h5>
                    <i class="uil-map-marker" /> Remitente |
                    {{ formData.senderPlace.name }}
                  </h5>
                </div>
                <el-row
                  v-if="hasSenderCustomer(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Cliente:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasSenderCustomer(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>
                      {{ formData.senderLocation.customer.description }}
                    </p>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasSenderCustomerLocation(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Dirección:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasSenderCustomerLocation(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>
                      {{
                        formData.senderLocation.getDescriptionFullAddress()
                      }}
                    </p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Nombre:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>{{ formData.senderName }}</p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="12">
                    <h5 class="field-details">
                      Teléfono:
                    </h5>
                  </el-col>
                  <el-col :span="12">
                    <h5 class="field-details">
                      Correo:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="12">
                    <p>{{ formData.senderPhone }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>{{ formData.senderEmail }}</p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Referencia:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>{{ formData.senderObservation }}</p>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <div
                  slot="header"
                  class="clearfix"
                >
                  <h5>
                    <i class="uil-map-marker" /> Destinatario |
                    {{ formData.receiverPlace.name }}
                  </h5>
                </div>
                <el-row
                  v-if="hasReceiverCustomer(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Cliente:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasReceiverCustomer(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>
                      {{ formData.receiverLocation.customer.description }}
                    </p>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasReceiverCustomerLocation(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Dirección:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  v-if="hasReceiverCustomerLocation(formData)"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>
                      {{
                        formData.receiverLocation.getDescriptionFullAddress()
                      }}
                    </p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Nombre:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>{{ formData.receiverName }}</p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="12">
                    <h5 class="field-details">
                      Teléfono:
                    </h5>
                  </el-col>
                  <el-col :span="12">
                    <h5 class="field-details">
                      Correo:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="12">
                    <p>{{ formData.receiverPhone }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>{{ formData.receiverEmail }}</p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <h5 class="field-details">
                      Referencia:
                    </h5>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <p>{{ formData.receiverObservation }}</p>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
