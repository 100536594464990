export default class CustomerSolvency {
    constructor(options, state) {
      if (state) {
        this.state = state
      }
      Object.assign(this, options)
    }
  
    toSend() {
      return {
        id: this.id,
        code: this.code.trim(),
        description: this.description.trim(),
        quantity: this.quantity,
        customerId: this.customer.id,
        type: this.type,
        paymentType: this.paymentType,
        prepaidPlanId: (this.prepaidPlan) ? this.prepaidPlan.id : null,
        isActive: this.isActive
      }
    }
  }