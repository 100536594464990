<script>
import HomePageComponent from "./HomePageComponent";
import SliderSection from "./sections/SliderSection";
import FeaturesBarSection from "./sections/FeaturesBarSection";
import CountersSection from "./sections/CountersSection";
import ServicesSection from "./sections/ServicesSection";
import CallToActionSection from "./sections/CallToActionSection";
import AboutSection from "./sections/AboutSection";
import LocationMapSection from "./sections/LocationMapSection";
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView"
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView"

export default {
  name: "HomePageView",
  components: {
    "slider-section": SliderSection,
    "features-bar-section": FeaturesBarSection,
    "counters-section": CountersSection,
    "services-section": ServicesSection,
    "call-to-action-section": CallToActionSection,
    "about-section": AboutSection,
    "location-map-section": LocationMapSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  },
  extends: HomePageComponent,
};
</script>
<template>
  <div>
    <website-header-view />
    <!-- Start .header-->
    <slider-section />
    <features-bar-section />
    <about-section />
    <counters-section />
    <services-section />
    <call-to-action-section />
    <section
      id="cases-clients-2"
      class="cases-clients cases-clients-2 bg-parllax"
      style="padding-top:200px;"
    >
      <div class="cases-standard">
        <div class="container">
          <div class="heading text-center">
            <p class="heading-subtitle">
              No compre riesgos, nosotros somos su solución
            </p>
            <h2 class="heading-title">
              Nos enorgullecemos de ofrecer las mejores servicios en mensajería,
              paquetería y carga express
            </h2>
          </div>
          <!-- End .row-->
        </div>
      </div>
    </section>
    <location-map-section />
    <website-footer-view />
  </div>
</template>
