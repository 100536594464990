import BaseForm from "../infraestructure/BaseForm";
import ShipmentViewModel from "../../core/features/shipments/ShipmentViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: "Delivery",
      viewModel: null,
      rules: {
        shipmentDate: [{ validator: this.onValidateShipmentDate }],
        destination: [
          {
            required: true,
            message: "El destino es requerido.",
            trigger: "blur",
          },
        ],
        vehicle: [
          {
            required: true,
            message: "El vehículo es requerido.",
            trigger: "blur",
          },
        ],
        operator: [
          {
            required: true,
            message: "El operador es requerido.",
            trigger: "blur",
          },
        ],
        /* receptions: [
          {
            required: false,
            message: "Debe cargar las guías.",
            trigger: "blur",
          },
        ], */
      },
      shipmentStatuses: ShipmentViewModel.shipmentStatuses,
      itemGuide: null,
      modalViewFormGuide: {
        isVisible: false,
        isLoading: false,
      },
      userCurrent: [],
      destinations: [],
      vehicles: [],
      operators: [],
      responsibles: [],
      receivers: [],
      loading: false,
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new ShipmentViewModel(this);
    this.onActive();
  },
  mounted() {
    this.onMountedEvent(
      "on-submited-delivery-guide",
      this.onUpsertShipmentGuide
    );
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "on-submited-delivery-guide",
      this.onUpsertShipmentGuide
    );
  },
  methods: {
    onUpsertShipmentGuide(data) {
      this.viewModel.onUpsertShipmentGuide(this.formData.receptions, data);
    },
    indexMethod(index) {
      return index + 1;
    },
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid, errors) => {
        if (isValid) {
          this.onSave(this.formData);
          return true; /*
          if (
            !this.viewModel.isShipmentReceptionsEmmpty(
              this.formData.shipmentReceptions
            )
          ) {
            this.onSave(this.formData);
            return true;
          } else {
            this.onShowError("Debe cargar las guías.");
          }*/
        } else {
          this.onShowWarningList(errors);
          return false;
        }
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-shipment");
    },
    onCreateFormGuide() {
      this.itemGuide = this.viewModel.createItemGuide({
        currentReceptions: this.formData.receptions,
        receptionsUpdate: this.receptionsUpdate,
        destinationId: this.formData.destination.id,
      });
      this.modalViewFormGuide.isVisible = true;
    },
    onFindDestinations(data) {
      if (data) {
        this.viewModel.findDestinations(
          `FullSearch=${data}&IsDestination=true&IsActive=true`
        );
      } else {
        this.destinations = [];
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = response.data;
    },
    onFindReceptionDestinations(data) {
      this.config.isLoading = true;
      if (!this.viewModel.isEmpty(data)) {
        this.viewModel.findReceptionsByDestinations(
          this.viewModel.mapDestinationsIds(data)
        );
      } else {
        this.onShowWarning("Debe seleccionar al menos 1 destino.");
        this.formData.shipmentReceptions = [];
        this.config.isLoading = false;
      }
    },
    onFindReceptionDestinationsResponse(response) {
      setTimeout(() => {
        this.viewModel.bindShipmentReceptions(
          this.formData.shipmentReceptions,
          response.data
        );
        this.config.isLoading = false;
      }, 400);
    },
    onFindVehicle(data) {
      if (data) {
        this.viewModel.findvehicles(`FullSearch=${data}&IsActive=true`);
      } else {
        this.vehicles = [];
      }
    },
    onFindVehiclesResponse(response) {
      this.vehicles = this.viewModel.mapVehicles(response.data);
    },
    onFindoperator(data) {
      if (data) {
        this.viewModel.findOperator(
          `Name=${data}&IsActive=true&isTransportFigure=true`
        );
      } else {
        this.operators = [];
      }
    },
    onFindOperatorResponse(response) {
      this.operators = response.data;
    },
    onFindReceivers(data) {
      if (data) {
        this.viewModel.findReceiver(`Name=${data}&IsActive=true`);
      } else {
        this.receivers = [];
      }
    },
    onFindonReceiversResponse(response) {
      this.receivers = response.data;
    },
    getTotalPackages() {
      return this.viewModel.calculateTotalPackages(
        this.formData.shipmentReceptions
      );
    },
    getTotalCods() {
      return this.viewModel.calculateTotalCods(
        this.formData.shipmentReceptions
      );
    },
    getTotalShippingQuote() {
      return this.viewModel.calculateTotalShippingQuote(
        this.formData.shipmentReceptions
      );
    },
    parseTotal(reception) {
      reception.total = reception.freightPayable
        ? 0
        : reception.sale?.total || 0;
      return reception.total;
    },
    parseTotalPackages(reception) {
      reception.totalPackages = reception.packages;
      return reception.measureUnit;
    },
    onValidateShipmentDate(rule, value, callback) {
      if (!value) {
        callback(new Error("La fecha es requerida."));
      } else if (
        this.formData.state === "create" &&
        !this.viewModel.isValidSelectedDate(value)
      ) {
        callback(new Error("La fecha no puede ser menor a la actual."));
      } else {
        callback();
      }
    },
    onDeleteReceptionGuide(shipmentReceptionIndex, receptionIndex) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.config.isLoading = true;
          setTimeout(() => {
            this.viewModel.deleteReception(
              this.formData.shipmentReceptions,
              shipmentReceptionIndex,
              receptionIndex
            );
            this.config.isLoading = false;
          }, 400);
        })
        .catch(() => {});
    },
    onFilterReceptions(collection) {
      return this.viewModel.filterByUpdateState(collection);
    },
    onFilterShipmentReceptions(collection) {
      return this.viewModel.filterByUpdateState(collection);
    },
    onGetSummaries(params) {
      return this.viewModel.getSummaries(params);
    },
    getTotalReceptions() {
      return this.viewModel.getTotalReceptions(
        this.formData.shipmentReceptions
      );
    },
    onDeleteShipmentReception(shipmentReception, shipmentReceptionIndex) {
      this.$confirm("¿Desea eliminar el destino seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.config.isLoading = true;
          setTimeout(() => {
            this.viewModel.deleteDestinationByShipmentReception(
              this.formData.destination,
              shipmentReception
            );

            this.viewModel.deleteShipmentReception(
              this.formData.shipmentReceptions,
              shipmentReceptionIndex
            );
            this.config.isLoading = false;
          }, 400);
        })
        .catch(() => {});
    },
    onGetTraceStatus(receptionHistoryTrace) {
      return this.viewModel.mapTraceStatus(receptionHistoryTrace);
    },
    onChangeDestination(destinations) {
      const processedDestinations = this.viewModel.processDestinations(
        destinations,
        this.formData.shipmentReceptions
      );
      this.$set(this.formData, "shipmentReceptions", processedDestinations);
    },
  },
};
