export default class WeightCost {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }


    toSend() {
        return {
            id: this.id,
            weightBase: this.weightBase,
            extraWeightCost: this.extraWeightCost,
            extraWeightTax: this.extraWeightTax
        }
    }
}