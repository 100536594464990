<script>
import ModalComponent from "./ModalComponent";
import AddressBoxView from "../addressBox/AddressBoxView"

export default {
  components: {
    AddressBoxView
  },
  extends: ModalComponent
};
</script>
<template>
  <el-dialog
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
    custom-class="dialog"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      Ubicación
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        class="row-bg"
        :gutter="20"
        type="flex"
      >
        <el-col :span="24">
          <el-form-item
            label="Alias de Ubicación:"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              type="text"
              size="small"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <address-box-view
        :form-data="formData"
        orientation="vertical"
        prop-name=""
      />
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
