import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import User from "../users/User";
import CollectionRoute from "../collectionRoutes/CollectionRoute";
import Order from "./Order";
import Customer from "../customers/Customer";
import CustomerLocation from "../customerLocation/CustomerLocation";
import ServiceCost from "../serviceCosts/ServiceCost";
import ReceptionHelper from "../../helpers/ReceptionHelper";
import PostalCode from "../postalCodes/PostalCode";
import ProductTypesHelper from "../../helpers/ProductTypesHelper";
import TaxRegime from "../taxRegimes/TaxRegime";
import PaymentMethod from "../paymentMethods/PaymentMethod";
import BillUsingType from "../billUsingTypes/BillUsingType";

export default class OrderViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  static SolvencyType = ReceptionHelper.SolvencyType;
  static receptionTypes = ReceptionHelper.receptionTypes;
  static receptionServiceTypes = ReceptionHelper.receptionServiceTypes;
  static receptionTrackingStatus = ReceptionHelper.receptionTrackingStatus;

  create(data) {
    return new Order(data, "create");
  }

  edit(data) {
    return new Order(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.orders
        .create(data.toSendCreate())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError);
    } else {
      this.api.orders
        .editV2(data.id, data.toSendEdit())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError);
    }
  }

  delete(id) {
    this.api.orders
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.orders
      .find(this.getQueryParameters(filters))
      .then((response) => {
        this.view.onSearchResponse(response.data, response.headers);
        return this.api.collectionRoutes.find(`Skip=0&Limit=100000`);
      })
      .then((response) => {
        this.view.collectionRoutes = response.data.data;
      })
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.orders
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  async bindForm(formData) {
    try {
      const taxRegimesResponse = await this.api.taxRegimes.find(
        `Skip=0&Limit=10000&IsActive=true`
      );
      this.view.taxRegimes = this.mapTaxRegimes(taxRegimesResponse.data.data);

      const billUsingTypeResponse = await this.api.billUsingTypes.find(
        `Skip=0&Limit=1000&IsActive=true`
      );
      this.view.billUsingTypes = this.mapBillUsingTypes(
        billUsingTypeResponse.data.data
      );

      const paymentMethodsResponse = await this.api.paymentMethods.find(
        `Skip=0&Limit=1000&IsActive=true`
      );
      this.view.paymentMethods = this.mapPaymentMethods(
        paymentMethodsResponse.data.data
      );

      if (formData.state === "update") {
        this.view.postalCodes =
          formData.orderFiscal && formData.orderFiscal.postalCode
            ? this.mapPostalCodes([formData.orderFiscal.postalCode])
            : [];
        this.view.customers =
          formData.orderFiscal && formData.orderFiscal.customer
            ? this.mapCustomers([formData.orderFiscal.customer])
            : [];

        if (formData.sender.customer) {
          if (formData.sender.type === "contacts") {
            const customerLocationsResponse = await this.api.customerLocation.find(
              formData.sender.customer.id,
              `Skip=0&limit=100000&IsActive=true`
            );
            this.view.senderCustomerLocations = this.mapCustomerLocations(
              customerLocationsResponse.data.data
            );

            const senderContactsResponse = await this.api.customersContact.find(
              formData.customerId,
              `Skip=0&Limit=10000`
            );
            this.view.senderContacts = senderContactsResponse.data.data;
            //this.view.receiverContacts.push(formData.customer)
          } else {
            const customerLocationsResponse = await this.api.customerLocation.find(
              formData.customerId,
              `Skip=0&limit=100000&IsActive=true`
            );
            this.view.senderCustomerLocations = this.mapCustomerLocations(
              customerLocationsResponse.data.data
            );
          }
        }

        if (formData.receiver.customer) {
          if (formData.receiver.type === "contacts") {
            const receiverLocationsResponse = await this.api.customerLocation.find(
              formData.receiver.customer.id,
              `Skip=0&limit=100000&IsActive=true`
            );

            this.view.receiverCustomerLocations = this.mapCustomerLocations(
              receiverLocationsResponse.data.data
            );

            const receiverContactsResponse = await this.api.customersContact.find(
              formData.customerId,
              `Skip=0&Limit=10000`
            );
            this.view.receiverContacts = receiverContactsResponse.data.data;
            //this.view.receiverContacts.push(formData.customer)
          } else {
            const receiverLocationsResponse = await this.api.customerLocation.find(
              formData.customerId,
              `Skip=0&limit=100000&IsActive=true`
            );

            this.view.receiverCustomerLocations = this.mapCustomerLocations(
              receiverLocationsResponse.data.data
            );
          }
        }
      }
    } catch (error) {
      this.view.onError(error);
    }
  }

  mapCustomerLocations(collection) {
    return collection.map((item) => new CustomerLocation(item));
  }

  mapBillUsingTypes(collection) {
    return collection.map((item) => new BillUsingType(item));
  }

  mapPaymentMethods(collection) {
    return collection.map((item) => new PaymentMethod(item));
  }

  import(file) {
    this.api.orders
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  bindList() {
    this.api.orderStatuses
      .find(`Skip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.orderStatuses = response.data.data;
        return this.api.users.find(
          `skip=0&limit=50000&IsActive=true&isTransportFigure=true`
        );
      })
      .then((response) => {
        this.view.operators = this.mapUsers(response.data.data);
        return this.api.collectionRoutes.find(
          `skip=0&limit=50000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.routes = this.mapCollectionRoutes(response.data.data);

        setTimeout(() => {
          this.view.isListLoading = false;
          this.view.onSearch();
        }, 400);
      })
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      orderNumber: "",
      contactName: "",
      contactPhone: "",
      email: "",
      operator: "",
      route: "",
      status: "",
    };

    this.view.removeFilter("OrderNumber");
    this.view.removeFilter("ContactName");
    this.view.removeFilter("ContactPhone");
    this.view.removeFilter("ContactEmail");
    this.view.removeFilter("EmployeeProfileId");
    this.view.removeFilter("CollectionRouteId");
    this.view.removeFilter("Status");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  printOrderTickets(orderNumber) {
    this.api.orders
      .findPickingOrder(orderNumber)
      .then((response) => this.view.onPrintOrderTicketsResponse(response.data))
      .catch(this.view.onPrintOrderTicketsError);
  }

  findOperator(criteria) {
    this.api.employees
      .find(criteria)
      .then((response) => this.view.onFindOperatorResponse(response.data))
      .catch(this.view.onError);
  }

  findRoute(criteria) {
    this.api.collectionRoutes
      .find(criteria)
      .then((response) => this.view.onFindRouteResponse(response.data))
      .catch(this.view.onError);
  }

  async findCustomerAsync(id) {
    try {
      const response = await this.api.customers.findOne(id);
      return new Customer(response.data.data);
    } catch (error) {
      this.view.onError(error);
    }
  }
  //#region

  mapCollection(collection) {
    return collection.map((item) => new Order(item));
  }

  mapUsers(collection) {
    return collection.map((item) => new User(item));
  }

  mapCollectionRoutes(collection) {
    return collection.map((item) => new CollectionRoute(item));
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  //#endregion

  verifyRFC(value, callback) {
    this.api.bills
      .verifyRfc(value)
      .then((response) => {
        setTimeout(() => {
          if (response.data.isValid) {
            callback();
          } else {
            callback(new Error(response.data.message));
          }
          this.view.config.isLoading = false;
        }, 400);
      })
      .catch(this.view.onError);
  }

  setCollectionRoute(id, data) {
    if (!id) {
      this.api.collectionRouteTraces
        .create(data)
        .then((response) =>
          this.view.onSetCollectionRouteResponse(response.data)
        )
        .catch(this.view.onError);
    } else {
      this.api.collectionRouteTraces
        .update(id, data)
        .then((response) =>
          this.view.onSetCollectionRouteResponse(response.data)
        )
        .catch(this.view.onError);
    }
  }

  async findCustomers(criteria) {
    try {
      const customersResponse = await this.api.customers.find(criteria);
      return customersResponse.data.data;
    } catch (error) {
      this.view.onError(error);
    }
  }

  createCustomer(data) {
    return new Customer(data, "create");
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  deletePackage(index, collection) {
    collection.splice(index, 1);
  }

  resetCustomer(formData, formStep, properties) {
    this.resetCustomerData(formData);
    this.resetFormValidation(formStep, properties);
  }

  /**
   * Metodo para limpiar los valores de los campos de clientes (Emisores/Receptores)
   * @param {*} formData
   */
  resetCustomerData(formData) {
    this.view.$set(formData, "customer", null);
    this.view.$set(formData, "customerLocation", null);
    this.view.$set(formData, "customerLocations", []);
    this.view.$set(formData, "name", null);
    this.view.$set(formData, "phone", null);
    this.view.$set(formData, "email", null);
    this.view.$set(formData, "secondaryStreet", null);
    this.view.$set(formData, "intNumber", null);
    this.view.$set(formData, "extNumber", null);
    this.view.$set(formData, "country", null);
    this.view.$set(formData, "district", null);
    this.view.$set(formData, "municipality", null);
    this.view.$set(formData, "city", null);
    this.view.$set(formData, "neighborhood", null);
    this.view.$set(formData, "postalCode", null);
  }

  /**
   * Metodo para eliminar los mensajes de las validaciones de los clientes (Emisores/Receptores)
   * @param {*} formStep
   * @param {*} properties
   */
  resetFormValidation(formStep, properties) {
    setTimeout(() => {
      properties.forEach((property) => {
        formStep.clearValidate(property);
      });
    }, 50);
  }

  findAvailableCustomerSolvency(customerId, solvencyType) {
    this.api.solvencies
      .findAvailableSolvencyByCustomer(customerId, solvencyType)
      .then((response) =>
        this.view.onFindByAilableCustomerSolvencyResponse(response.data)
      )
      .catch(this.view.onError);
  }

  /**
   * Metodo para saber si el servicio al cliente va a ser por credito o prepago
   * @param {*} formData
   * @param {*} customerSolvency
   */
  setCustomerSolvency(formData, customerSolvency) {
    const {
      customerSolvencyId,
      paymentType,
      serviceDeliveryPrice,
      solvencyType,
      message,
    } = customerSolvency || {};

    this.view.$set(formData, "customerSolvencyId", customerSolvencyId || null);
    this.view.$set(formData, "paymentType", paymentType || null);
    this.view.$set(
      formData,
      "serviceDeliveryPrice",
      serviceDeliveryPrice || null
    );
    this.view.$set(formData, "solvencyType", solvencyType || null);
    this.view.$set(formData, "solvencyMessage", message || null);
    this.view.$set(formData, "freightPayable", this.isPayedDefault(formData));
  }

  /**
   * Metodo para saber si el servicio ya esta pagado
   * @param {*} formData
   * @returns
   */
  isPayedDefault(formData) {
    return (
      formData.receptionServiceType.id ==
        OrderViewModel.SolvencyType.CONSTANT_SOLVENCY_TYPES.CASH ||
      formData.solvencyType === OrderViewModel.SolvencyType.Prepaid
    );
  }

  async bindStepTwoFormView() {
    try {
      const specialPrices = await this.findSpecialPrices();
      const serviceCostResponse = await this.api.serviceCosts.find(
        `Skip=0&Limit=10000&IsActive=true`
      );
      const serviceCosts = this.mapSpecialPricesWithServiceCosts(
        specialPrices,
        serviceCostResponse.data.data
      );
      this.view.serviceCosts = this.mapServiceCosts(serviceCosts);
      const weightCostsResponse = await this.api.weightCosts.find();

      if (weightCostsResponse.data.data.length === 0) {
        throw new Error("No hay parametros de peso configurados");
      } else {
        this.view.weightCostConfig = weightCostsResponse.data.data[0];
      }

      setTimeout(() => {
        this.view.config.isLoading = false;
      }, 400);
    } catch (error) {
      this.view.onError(error);
    }
  }

  async findSpecialPrices() {
    try {
      const reception = this.view.formData;
      const customerId =
        reception.payerResponsible === "sender"
          ? reception.sender.customer.id
          : reception.receiver.customer.id;
      const specialPricesResponse = await this.api.customerSpecialPrices.find(
        `CustomerId=${customerId}&Skip=0&Limit=10000&IsActive=true`
      );
      return specialPricesResponse.data.data;
    } catch (error) {
      this.view.onError(error);
    }
  }

  mapSpecialPricesWithServiceCosts(specialPrices, serviceCosts) {
    return serviceCosts.map((serviceCost) => {
      const specialPrice = _.find(
        specialPrices,
        (item) => item.serviceCost?.id === serviceCost.id
      );
      if (specialPrice) {
        serviceCost.amount = specialPrice.amount;
      }
      return serviceCost;
    });
  }

  formatServiceCost(serviceCost, receptionServiceType, serviceDeliveryPrice) {
    let resultAmount = this.isPrepaid(receptionServiceType)
      ? serviceDeliveryPrice
      : serviceCost.amount;
    return serviceCost.formatFullDescription(resultAmount);
  }

  getServiceDeliveryPrice(formData, serviceCostAmount) {
    return this.isPrepaid(formData.receptionServiceType)
      ? formData.serviceDeliveryPrice
      : serviceCostAmount;
  }

  isPrepaid(receptionServiceType) {
    return (
      receptionServiceType &&
      receptionServiceType === OrderViewModel.SolvencyType.Prepaid
    );
  }

  mapServiceCosts(collection) {
    return collection.map((item) => new ServiceCost(item));
  }

  async findPostalCodesAsync(query) {
    try {
      const response = await this.api.postalCodes.find(query);
      return this.mapPostalCodes(response.data.data);
    } catch (error) {
      this.view.onError(error);
    }
  }

  mapPostalCodes(collection) {
    return collection.map((item) => new PostalCode(item));
  }

  getNameReceptionType = (type) => ReceptionHelper.getNameReceptionType(type);

  getNamePaymentType = (type) => ReceptionHelper.getNamePaymentType(type);

  getNameClientType = (type) => ReceptionHelper.getNameClientType(type);

  getIconTrackingStatus = (status) =>
    ReceptionHelper.getIconTrackingStatus(status);

  getStyleTrackingStatus = (status) =>
    ReceptionHelper.getStyleTrackingStatus(status);

  getNameTrackingStatus = (status) =>
    ReceptionHelper.getNameTrackingStatus(status);

  getSelectedProductType = (collection) =>
    ProductTypesHelper.getSelectedProductType(collection);

  calculateSummary(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "Total";
        return;
      }
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => isNaN(value))) {
        if (column.label === "Costo") {
          var total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatMoney(total);
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  async updateFiscalInformation(option, formData) {
    try {
      let informationResult = null;

      this.view.setFiscalData({
        id: null,
        fiscalCode: "",
        fiscalName: "",
        fiscalEmail: "",
        postalCode: null,
      });

      switch (option) {
        case "sender":
          if (this.view.senderData === "account") {
            informationResult = await this.findFiscalCustomerAsync(
              this.view.$store.getters["admin/getUserActive"].email
            );
          } else if (formData.sender.customer) {
            // currentEmail = formData.sender.customer.contactEmail
            informationResult = await this.findCustomerContactAsync(
              this.view.$store.getters["admin/getUserActive"].email,
              formData.sender.customer.id
            );
          } else {
            throw new Error("Contacto de remitente no seleccionado.");
          }
          break;
        case "receiver":
          if (this.view.receiverData === "account") {
            informationResult = await this.findFiscalCustomerAsync(
              this.view.$store.getters["admin/getUserActive"].email
            );
          } else if (formData.receiver.customer) {
            informationResult = await this.findCustomerContactAsync(
              this.view.$store.getters["admin/getUserActive"].email,
              formData.receiver.customer.id
            );
          } else {
            throw new Error("Contacto de destinatario no seleccionado.");
          }
          break;
        case "my-account":
          informationResult = await this.findFiscalCustomerAsync(
            this.view.$store.getters["admin/getUserActive"].email
          );
          break;
        default:
          informationResult = await this.findFiscalGeneralPublic();
          break;
      }
      
      if (informationResult.fiscalRegime) {
        const fiscalRegimeResponse = await this.api.taxRegimes.findOne(informationResult.fiscalRegime.id)
        this.view.billUsingTypes = this.mapBillUsingTypes(fiscalRegimeResponse.data.data.billUsingTypes)
      }

      return informationResult;
    } catch (error) {
      this.view.notifyWarning(error.message);
    }
  }

  async findFiscalGeneralPublic() {
    try {
      // Obtiene el cliente con datos de publico en general para la factura
      const defaultCustomer = await this.api.customers.find(
        `FullSearch=publico en general`
      );

      if (
        _.isNil(defaultCustomer.data.data) ||
        _.isEmpty(defaultCustomer.data.data[0])
      ) {
        this.view.config.isVisible = false;
        throw new Error("Configuración de publico en general no existente");
      }

      const response = await this.api.customers.findOne(
        defaultCustomer.data.data[0].id
      );

      this.view.customers = [response.data.data];
      
      if (response.data.data.taxRegime) {
        const fiscalRegimeResponse = await this.api.taxRegimes.findOne(response.data.data.taxRegime.id)
        this.view.billUsingTypes = this.mapBillUsingTypes(fiscalRegimeResponse.data.data.billUsingTypes)
      }

      // this.view.taxRegimes = [new TaxRegime(response.data.data.taxRegime)];
      this.view.postalCodes = [
        new PostalCode(response.data.data.fiscalPostalCode),
      ];
      
      this.view.$set(
        this.view.formData.orderFiscal,
        "customer",
        {
          id: response.data.data.id,
          description: response.data.data.description,
          fiscalName: response.data.data.fiscalName,
          fiscalCode: response.data.data.fiscalCode,
          fiscalEmail: response.data.data.contactEmail,
          postalCode: response.data.data.fiscalPostalCode,
        }
      );

      this.view.$set(
        this.view.formData.orderFiscal,
        "fiscalName",
        response.data.data.fiscalName
      );
      this.view.$set(
        this.view.formData.orderFiscal,
        "fiscalCode",
        response.data.data.fiscalCode
      );
      this.view.$set(
        this.view.formData.orderFiscal,
        "fiscalEmail",
        response.data.data.contactEmail
      );
      this.view.$set(
        this.view.formData.orderFiscal,
        "postalCode",
        new PostalCode(response.data.data.fiscalPostalCode)
      );
      this.view.$set(
        this.view.formData.orderFiscal,
        "fiscalRegime",
        new TaxRegime(response.data.data.taxRegime)
      );

      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.customer"]);
      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.fiscalName"]);
      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.fiscalCode"]);
      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.fiscalEmail"]);
      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.postalCode"]);
      this.view.$refs.formDataStep0.clearValidate(["orderFiscal.fiscalRegime"]);
    } catch (error) {
      this.view.onError(error);
    }
  }

  setPaymentMethods(formData, receptionServiceType, paymentMethods) {
    this.view.isFiscalLoading = true;
    setTimeout(() => {
      const paymentMethodCode = receptionServiceType === 1 ? "PPD" : "PUE";
      const paymentMethod = paymentMethods.find(
        (item) => item.code === paymentMethodCode
      );
      this.view.$set(formData.orderFiscal, "paymentMethod", paymentMethod);
      this.view.isFiscalLoading = false;
    }, 400);
  }

  setBillUsingTypeGeneralPublic(formData, billUsingTypes) {
    this.view.isFiscalLoading = true;
    setTimeout(() => {
      const defaultBillUsingType = billUsingTypes.find(item => item.code === "S01")
      this.view.$set(formData.orderFiscal, "billUsingType", defaultBillUsingType);
      this.view.isFiscalLoading = false;
    }, 400);
  }

  async findTaxRegimeAsync(id) {
    try {
      const response = await this.api.taxRegimes.findOne(id)
      this.view.billUsingTypes = this.mapBillUsingTypes(response.data.data.billUsingTypes)

    } catch (error) {
      this.view.onError(error)
    }
  }
}
