<script>
import ModalReceptionStatusComponent from "./ModalReceptionStatusComponent";

export default {
  extends: ModalReceptionStatusComponent,
};
</script>

<template>
  <el-dialog
    width="35%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-layer-group" /> Estatus de Guía
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="8">
          <el-form-item
            label="Estatus"
            prop="traceStatus"
          >
            <el-select
              v-model="formData.traceStatus"
              placeholder="Seleccionar"
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeTraceStatus"
            >
              <el-option
                v-for="item in receptionTrackingStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item
            label="Motivo"
            prop="traceStatusReason"
          >
            <el-select
              v-model="formData.traceStatusReason"
              placeholder="Seleccionar"
              clearable
              debounce
              value-key="id"
              size="medium"
            >
              <el-option
                v-for="item in traceStatusReasons"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Comentarios"
            prop="comments"
          >
            <el-input
              v-model="formData.comments"
              autocomplete="off"
              size="medium"
              type="textarea"
              :row="8"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
