import BaseForm from "../../infraestructure/BaseForm";
import ViewModel from "./ViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            formData: {},
            customers:[],
            shipments: [],
            isLoading: false,
            rules: {
                shipment: [{
                    required: true, message: "Manifiesto requerido."
                }],
                customer: [{
                    required: true, message: "Cliente requerido."
                }]
            },
        };
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.viewModel = new ViewModel(this);
        this.onActive();
    },
    methods: {
        async onActive() {
            await this.viewModel.bindFormViewAsync()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                this.isLoading = true
                if (isValid) {
                    this.viewModel.doBillGroup({
                        customerId: this.formData.customer.id,
                        shipmentId: this.formData.shipment.id
                    })
                    return true
                } else {
                    setTimeout(() => {
                         this.isLoading = false
                    }, 400)

                    return false
                }
            });
        },
        onSaveResponse(response) {
            console.log(response)
        },
        onSearchCustomers(query) {
            this.customers = []
            if(query){
                this.viewModel.findCustomers(`FullSearch=${query}&Skip=0&Limit=1000&IsActive=true`)
            }
        },
        onChangeCustomer() {

        },
        onFindShipments(query) {
            this.shipments = []
            if(query) {
                this.viewModel.findShipments(`FullSearch=${query}&Skip=0&Limit=1000&IsActive=true&BillingShipmentStatus=1`)
            }
        },
        onDoBillGroupResponse(response) {
            console.log(response)
            this.isLoading = false
            this.config.isLoading = false
        }
    },
};
