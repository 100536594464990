<script>
import ModalCustomerSubAccountComponent from "./ModalCustomerSubAccountComponent";

export default {
  extends: ModalCustomerSubAccountComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-customer-sub-account"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-user" /> SubCuenta Cliente
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      element-loading-spinner="el-icon-loading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Descripción de Cuenta"
            prop="accountName"
          >
            <el-input
              v-model="formData.accountName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Usuario"
            prop="userName"
          >
            <el-input
              v-model="formData.userName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            type="email"
            label="Correo electrónico"
            prop="email"
          >
            <el-input
              v-model="formData.email"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="formData.state === 'update'">
        <el-col :span="24">
          <el-form-item prop="editPass">
            <el-checkbox
              v-model="editPass"
              border
              @change="onClearFieldForm('formData')"
            >
              Editar Contraseña
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="Contraseña"
            prop="password"
          >
            <el-input
              v-model="formData.password"
              autocomplete="off"
              size="medium"
              show-password
              name="password"
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Confirmar Contraseña"
            prop="passwordConfirmation"
          >
            <el-input
              v-model="formData.passwordConfirmation"
              autocomplete="off"
              name="passwordConfirmation"
              size="medium"
              show-password
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
