import _ from "lodash";
import BaseForm from "../infraestructure/BaseForm";
import CustomersAccountViewModel from "../../core/features/customerAccount/CustomersAccountViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      editPass: false,
      destinations: [],
      rules: {
        accountName: [
          {
            trigger: ["blur", "change"],
            required: true,
            message: "Descripción requerida.",
          },
          {
            trigger: ["blur", "change"],
            min: 3,
            message: "Minimo 3 dígitos.",
          },
        ],
        userName: [
          {
            required: true,
            message: "Usuario requerido.",
          },
          {
            trigger: "blur",
            validator: this.onValidateUserName,
          },
        ],
        email: [
          {
            required: true,
            message: "Correo requerido.",
          },
          {
            trigger: ["blur", "change"],

            validator: this.onValidateEmail,
          },
          {
            type: "email",
            message: "Ingrese una dirección de correo electrónico correcta",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            validator: this.onValidatePassword,
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            message: "Minimo de 6 digitos",
            trigger: ["blur", "change"],
          },
        ],
        passwordConfirmation: [
          {
            validator: this.onValidatePasswordConfirmation,
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            message: "Minimo de 6 digitos",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    accounts: {
      type: Array,
      required: false,
    },
  },
  created() {
    this.viewModel = new CustomersAccountViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.config.isLoading = true;
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        } else {
          this.config.isLoading = false;
          return false;
        }
      });
    },
    onSave(formData) {
        console.log(formData)
      this.config.isLoading = false;
      this.onDoAfterLocalSaveResponse(formData, "on-submited-customer-sub-account");
    },
    onGetVerificationEmailError(error) {
      this.config.isLoading = false;
      this.onError(error);
    },
    onClearFieldForm(formName) {
      if (this.editPass) {
        if (this.$refs[formName].rules["password"])
          this.$refs[formName].rules["password"][0].required = this.editPass;
        if (this.$refs[formName].rules["passwordConfirmation"])
          this.$refs[formName].rules[
            "passwordConfirmation"
          ][0].required = this.editPass;
      } else {
        if (this.$refs[formName].rules["password"])
          this.$refs[formName].rules["password"][0].required = !this.editPass;
        if (this.$refs[formName].rules["passwordConfirmation"])
          this.$refs[formName].rules["passwordConfirmation"][0].required = !this
            .editPass;
      }
      this.$refs[formName].clearValidate(["password", "passwordConfirmation"]);
    },
    onValidateUserName(rule, value, callback) {
      _.debounce(() => {
        this.viewModel.verifyUserName(this.formData.id, value, callback);
      }, 500)();
    },
    onValidateEmail(rule, value, callback) {
      _.debounce(() => {
        this.viewModel.verifyEmail(this.formData.id, value, callback);
      }, 500)();
    },
    onValidatePassword(rule, value, callback) {
      _.debounce(() => {
        if (this.isPasswordEnabled()) {
          this.viewModel.verifyPassword(value, callback);
        } else {
          callback();
        }
      }, 500)();
    },
    onValidatePasswordConfirmation(rule, value, callback) {
      _.debounce(() => {
        if (this.isPasswordEnabled()) {
          this.viewModel.verifyPasswordConfirmation(
            this.formData,
            value,
            callback
          );
        } else {
          callback();
        }
      }, 500)();
    },
    isPasswordEnabled() {
      return true;
    },
    onSaveError(error) {
      this.isLoading = false;
      this.onError(error);
    },
  },
};
