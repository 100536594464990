import BaseViewModel from "../../../../core/infraestructure/BaseViewModel";
import CustomerLocation from "../../../../core/features/customerLocation/CustomerLocation";

export default class BoxViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  isCustomerAvailable(formData) {
    return (formData.name || formData.contactName) && (formData.email || formData.contactEmail) && (formData.phone || formData.contactPhone);
  }

  createCustomerLocation(data) {
    return new CustomerLocation(data, "create");
  }

  editCustomerLocation(data) {
    return new CustomerLocation(data, "update");
  }

  save(data) {
    if (!data.customer.id) {
      this.api.customers
        .register(this.getRegisterCustomer(data))
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError);
    } else {
      this.api.customers
        .update(data.customer.id, this.getCustomer(data))
        .then(() => {
          if(!data.id) return this.api.customerLocation.create(data.customer.id, data.toSend())
          else return this.api.customerLocation.update(data.customer.id, data.id, data.toSend())
        })
        .then((response) => this.view.onCustomerLocationResponse(response.data))
        .catch(this.view.onSaveResponseError);
    }
  }

  getRegisterCustomer(data) {
    return {
      id: data.customer.id,
      description: data.customer.fiscalName || data.customer.contactName,
      fiscalName: data.customer.fiscalName,
      fiscalCode: data.customer.fiscalCode,
      contactName: data.customer.contactName,
      contactPhone: data.customer.contactPhone,
      contactEmail: data.customer.contactEmail,
      isActive: true,
      address: data.toSend()
    }
  }

  getCustomer(data) {
    return {
      id: data.customer.id || null,
      description: data.customer.fiscalName || data.customer.contactName,
      fiscalName: data.customer.fiscalName,
      fiscalCode: data.customer.fiscalCode,
      contactName: data.customer.contactName,
      contactPhone: data.customer.contactPhone,
      contactEmail: data.customer.contactEmail,
      isActive: true,
    }
  }

  getCustomerLocation(data) {
    return {
      id: data.id
    }
  }

  findLocationsByCustomer(customerId, criteria) {
    this.api.customerLocation.find(customerId, criteria)
      .then((response) => this.view.onFindLocationsByCustomerResponse(response.data))
      .catch(this.view.onFindLocationsByCustomerError)
  }

  removeLocation(customerId, locationId) {
    this.api.customerLocation.delete(customerId, locationId)
      .then((response) => this.view.onRemoveLocationResponse(response.data))
      .catch(this.view.onRemoveLocationError)
  }

  findItemLocation(customerId, locationId) {
    this.api.customerLocation.findOne(customerId, locationId)
    .then((response) => this.view.onFindItemLocationResponse(response.data))
    .catch(this.view.onFindItemLocationError)
  }

  findSelectLocation(customerId, locationId){
    this.api.customerLocation.findOne(customerId, locationId)
    .then((response) => this.view.onFindSelectedLocationResponse(response.data))
    .catch(this.view.onFindSelectedLocationError)
  }
}
