import { MXN } from '../../../core/helpers/MoneyHelper'
export default class ServiceCost {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    getFullDescription() {
      return `Código: ${this.code}  |  Origen: ${this.origin} - Destino: ${this.destination}  |  Precio: ${MXN(this.amount).format()} MXN`
    }

    getFullDescriptionNoPrice() {
      return `${this.code}  |  Origen: ${this.origin} - Destino: ${this.destination}`
    }

    getCustomerFullDescription() {
      return `Origen: ${this.origin} - Destino: ${this.destination}  |  Precio: ${MXN(this.amount).format()} MXN`
    }

    formatFullDescription(currentAmount) {
      return `SERVICIO: ${this.description} |  PRECIO: ${MXN(currentAmount).format()} MXN`
    }

    getFullDescriptionNoPriceObj() {
      return `${this.code}  |  Origen: ${this.origin.description || this.origin} - Destino: ${this.destination.description || this.destination}`
    }

    getSpecialPriceFormat() {
      return `${this.code}  | ${this.description}`
    }

    toSend() {
      return {
        id: this.id,
        code: this.code.trim(),
        originPlaceId : (this.origin) ? this.origin.id : null,
        destinationPlaceId  : (this.destination) ? this.destination.id : null,
        amount: this.amount,
        distance: this.distance,
        description: this.description,
        isActive: this.isActive
      }
    }
  }