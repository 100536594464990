import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import UsersViewModel from '../../core/features/users/UsersViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'User',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                userName: '',
                email: '',
                roleName: '',
                userType: '',
                status: '',
            },
            roles:[],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR USUARIOS',
                onUploadImport: 'on-upload-import-users',
                onCancelImport: 'on-cancel-import-users'
            }

        }
    },
    mounted() {
        this.onMountedEvent('on-submited-user', this.onSearch);
        this.onMountedEvent('on-upload-import-users', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-users', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-user', this.onSearch);
        this.onBeforeDestroyEvent('on-upload-import-users', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-users', this.onCancelImport);
    },
    created() {
        this.viewModel = new UsersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch();
        },
        onSelectUser() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByUserName: _.debounce(function (text) {
            if (text) {
                this.addFilter('UserName', text)
            } else {
                this.removeFilter('UserName')
            }
            this.onSearch();
        }, 500),
        onSearchByEmail: _.debounce(function (text) {
            if (text) {
                this.addFilter('Email', text)
            } else {
                this.removeFilter('Email')
            }
            this.onSearch();
        }, 500),
        onSearchByRoleName: _.debounce(function (text) {
            if (text) {
                this.addFilter('RoleName', text)
            } else {
                this.removeFilter('RoleName')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },       
    }
}
