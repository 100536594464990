<script>
import ModalCollectionRouteComponent from "./ModalCollectionRouteComponent";

export default {
  extends: ModalCollectionRouteComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-collection-route"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="el-icon-map-location" /> Ruta de Recolección
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="5">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              v-model="formData.code"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Nombre"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              type="textarea"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Responsable de Ruta"
            prop="employeeProfile"
          >
            <el-select
              v-model="formData.employeeProfile"
              placeholder="Seleccionar Responsable"
              filterable
              clearable
              debounce
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindEmployees"
            >
              <el-option
                v-for="item in employees"
                :key="item.id"
                :label="item.getFullName()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="Ruta de Trabajo">
                <el-select
                  v-model="formData.workRoute"
                  placeholder="Buscar y seleccionar"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  remote
                  size="medium"
                  :remote-method="onFindWorkRoutes"
                  @change="onWorkRoutesChange"
                >
                  <el-option
                    v-for="item in workRoutes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                label="Lista de Rutas de Trabajo"
                prop="workRoutes"
              >
                <el-table
                  :data="onFilterWorkRoutes(formData.workRoutes)"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="name"
                    label="Nombre"
                  />
                  <el-table-column
                    label="Opciones"
                    width="90"
                    align="center"
                  >
                    <template #default="scope">
                      <el-button
                        type="primary"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDeleteWorkRoute(scope.$index)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
