import BaseForm from '../infraestructure/BaseForm'
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            rules: {},
    
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CustomerReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            // if(this.formData.state === 'update') this.viewModel.bindFormAsync(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-receptions')
        },
        isCreateState() {
            return this.formData.state === 'create'
        },
        isEditState() {
            return this.formData.state === 'update'
        },
        onCancel() {
            this.config.isVisible = false
            this.config.isLoading = false
            this.onDoCancel('on-submited-customer-reception', null)
        }     
    }
}