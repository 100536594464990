import BaseViewModel from '../../infraestructure/BaseViewModel'
import BankAccount from './BankAccount'

export default class BankAccountViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new BankAccount(data, 'create')
    }

    edit(data) {
        return new BankAccount(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.bankAccounts.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.bankAccounts.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.bankAccounts.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.bankAccounts.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.bankAccounts.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm() { }

    import(file) {
        this.api.bankAccounts.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            bankName: '',
            accountNumber: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('BankName');
        this.view.removeFilter('AccountNumber');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new BankAccount(item))
    }

    //#endregion
}