import BaseForm from "../infraestructure/BaseForm";
import ReceptionsViewModel from "../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      tabActive: "reception-details",
      viewModel: null,
      locationSelected: null,
      rules: {
        code: [
          {
            required: true,
            message: "Código(s) requerido.",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Nombre requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur",
          },
        ],
        isActive: [
          {
            required: true,
            message: "El Estado es requerido.",
            trigger: "blur",
          },
        ],
        senderLocation: {
          postalCode: [{
            required: true,
            message: "Código Postal requerido.",
            trigger: "blur",
          }]
        }
      },
      receptionTypes: ReceptionsViewModel.receptionTypes,
      receptionServiceTypes: ReceptionsViewModel.receptionServiceTypes,
      paymentTypes: ReceptionsViewModel.paymentTypes,
      receptionTrackingStatus: ReceptionsViewModel.receptionTrackingStatus,
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onCalculateTotalVolume(receptionPackage) {
      return this.viewModel.calculateTotalVolume(
        ReceptionsViewModel.BoxType,
        receptionPackage.quantity || 1,
        receptionPackage.length,
        receptionPackage.height,
        receptionPackage.width
      );
    },
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type)
    },
    onGetPayerResponsible(payerResponsible) {
      if(payerResponsible === "sender") return "Remitente"
      if(payerResponsible === "receiver") return "Destinatario"
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type)
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status)
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status)
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    getTotalService() {
      if (this.formData.discountPercentage === 0) return this.formData.shippingQuote;
      var discount = (this.formData.discountPercentage * this.formData.shippingQuote) / 100
      return this.formData.shippingQuote - discount
    }
  },
};
