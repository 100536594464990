import BaseForm from '../../infraestructure/BaseForm'
import BaseList from '../../infraestructure/BaseList'
import CustomerSpecialPriceViewModel from '../viewModels/CustomerSpecialPriceViewModel'

export default {
    extends: BaseForm,
    mixins: [BaseList],
    data() {
        return {
            modulePermissions: 'ServiceDeliveryPrice',
            viewModel: null,
            customers: [],
            serviceCosts: [],
            customer: null,
            serviceCost: null,
            collection: [],
            selectedIndex: null,
            multipleSelection: [],
            pricesLoading: false,
            isSelectionLoading: false,
            showSelectionMultiple: false,
            isTableLoading: false,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR PRECIOS ESPECIALES',
                onUploadImport: 'on-upload-import-special-prices',
                onCancelImport: 'on-cancel-import-special-prices'
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.onMountedEvent('on-upload-import-special-prices', this.onUploadImportSpecialPrices);
        this.onMountedEvent('on-cancel-import-special-prices', this.onCancelImportSpecialPrices);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-upload-import-special-prices', this.onUploadImportSpecialPrices);
        this.onBeforeDestroyEvent('on-cancel-import-special-prices', this.onCancelImportSpecialPrices);
    },
    created() {
        this.viewModel = new CustomerSpecialPriceViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.config.isLoading = true
                    this.viewModel.saveWeighCost(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.notifySuccess(response.message)
            setTimeout(() => {
                this.config.isLoading = false
                this.config.isVisible = false
            }, 800);
        },
        onAddSpecialPrice() {
            this.pricesLoading = true
            const item = this.viewModel.create({
                customer: null,
                customers: [],
                serviceCost: null,
                serviceCosts: [],
                price: 0,
                isActive: true
            })
            setTimeout(() => {
                this.collection.push(item)
                this.pricesLoading = false
            }, 400);
        },
        onRemoveSpecialPrice(index) {
            const item = this.collection[index]
            this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
              })
                .then(() => {
                    this.isTableLoading = true
                    if(item.id) {
                        this.viewModel.deleteSpecialPrice(item.id);
                    } else {
                        setTimeout(() => {
                            this.collection.splice(index, 1)
                            this.isTableLoading = false
                        }, 400);
                    }
                  
                })
                .catch(() => {});
            
        },
        onImportSpecialPrice() {

        },
        onFilterCustomers(query) {
            if (query) {
                this.viewModel.findCustomers(
                    `FullSearch=${query}&Skip=0&Limit=1000&IsActive=true`,
                    this.onFilterCustomersResponse,
                    this.onFilterCustomersError
                )
            }
        },
        onFilterCustomersResponse(response) {
            this.customers = response.data
        },
        onFilterCustomersError(error) {
            this.onError(error)
        },
        onFilterServiceCosts(query) {
            if (query) {
                this.viewModel.findServiceCosts(
                    `FullSearch=${query}&Skip=0&Limit=1000&IsActive=true`,
                    this.onFilterServiceCostResponse,
                    this.onFilterServiceCostError
                )
            }
        },
        onFilterServiceCostResponse(response) {
            this.serviceCosts = this.viewModel.mapServiceCosts(response.data)
        },
        onFilterServiceCostError(error) {
            this.onError(error)
        },
        onFindCustomers(query) {
            if (query) {
                this.viewModel.findCustomers(
                    `FullSearch=${query}&Skip=0&Limit=1000&IsActive=true`,
                    this.onFindCustomersResponse,
                    this.onFindCustomersError
                )
            }
        },
        onFindCustomersResponse(response) {
            this.collection[this.selectedIndex].customers = response.data
        },
        onFindCustomersError(error) {
            this.onError(error)
        },
        onFindServiceCosts(query) {
            if (query) {
                this.viewModel.findServiceCosts(
                    `FullSearch=${query}&Skip=0&Limit=1000&IsActive=true`,
                    this.onFindServiceCostResponse,
                    this.onFindServiceCostError
                )
            }
        },
        onFindServiceCostResponse(response) {
            this.collection[this.selectedIndex].serviceCosts = this.viewModel.mapServiceCosts(response.data)
        },
        onFindServiceCostError(error) {
            this.onError(error)
        },
        onFocusComponent(index) {
            this.selectedIndex = index
        },
        onSelectionChange(selection) {
            this.multipleSelection = selection
            this.showSelectionMultiple = (this.multipleSelection.length > 0)
            this.isSelectionLoading = true
            setTimeout(() => {
                this.isSelectionLoading = false
            }, 400);
        },
        isSelectionMultiple() {
            return this.multipleSelection.length > 0
        },
        onFindCustomersComponent(query) {
            if (query) this.viewModel.findCustomer
        },
        onSaveSpecialPrice(index, item) {
            this.selectedIndex = index
            this.isTableLoading = true
            if(item) this.viewModel.save(item)
        },
        onSaveSpecialPriceResponse(response) {
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.isTableLoading = false
            }, 400);
        },
        onSaveSpecialPriceError(error) {
            setTimeout(() => {
                this.onError(error)
                this.isTableLoading = false
            }, 400);
        },
        onUploadImportSpecialPrices(file) {
            this.modalImport.isLoading = true
            this.viewModel.import(file);
        },
        onImportSpecialPricesResponse(response) {
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.viewModel.findCustomerSpecialPrices(this.filters)
                this.modalImport.isLoading = false
                this.modalImport.isVisible = false
            }, 400);
        },
        onImportSpecialPricesError(error) {
            setTimeout(() => {
                this.onError(error)
                this.modalImport.isLoading = false
            }, 400);
        },
        onShowImportSpecialPrice() {
            this.modalImport.isVisible = true
        },
        onCancelImportSpecialPrices() {
            this.modalImport.isVisible = false
        },
        onRemoveSelected(){
            this.$confirm("¿Desea eliminar los registros seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
              })
                .then(() => {
                    this.isTableLoading = true
                    this.viewModel.removeSelected(this.multipleSelection)
                })
                .catch(() => {});
        },
        onRemoveSelectedResponse(response) {
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.isTableLoading = false
            }, 400);
        },
        onRemoveSelectedError(error) {
            setTimeout(() => {
                this.onError(error)
                this.isTableLoading = false
            }, 400);
        }
    }
}