import BaseApi from '../../infraestructure/BaseApi'

export default class CustomerBillApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/bills${parameters}`)
    }

    generateExcelSalesBySaleGroup(customerId, data){
        return this.client.post(`${this.endPoint}/${customerId}/generate-excel-sale-group`, data)
    }
}