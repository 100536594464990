import BaseForm from '../infraestructure/BaseForm'
import MunicipalityViewModel from '../../core/features/municipalities/MunicipalityViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Municipality',
            viewModel: null,
            countries: [],
            districts: [],
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                country: [{
                    required: true,
                    message: 'Pais requerido.',
                    trigger: 'blur'
                }],
                district: [{
                    required: true,
                    message: 'Estado requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new MunicipalityViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-municipality')
        },
        onChangeCountry(country){
            this.districts = []
            if(!country) this.formData.district = null
            if(country) this.viewModel.findDistricts(`CountryId=${country.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindDistrictsResponse(response) {
            this.districts = response.data
        }
    }
}