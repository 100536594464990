import BaseApi from '../../infraestructure/BaseApi'

export default class ApiDashboard extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    getData() {
        return this.client.get(`${this.endPoint}/resumen`)
    }
}