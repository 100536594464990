import BaseApi from '../../infraestructure/BaseApi'

export default class PostalCodeApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findAddress(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return  this.client.get(`${this.endPoint}/get-address${parameters}`)
    }

    doBatch(data) {
        return this.client.post(`${this.endPoint}/batch-import`, data)
    }
}