import BaseForm from "../infraestructure/BaseForm";
import SaleViewModel from "../../core/features/sales/SaleViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      formData: {
        billingType: null,
        shipment: null,
        isRebilling: false,
        vehicle: null,
        transportFigure: null,
        billRelation: null,
      },
      rules: {
        billingType: [
          {
            required: true,
            message: "Metodo requerido",
            trigger: "change",
          },
        ],
        shipment: [
          {
            required: true,
            message: "Manifiesto requerido",
            trigger: "blur",
          },
        ],
        billRelation: [
          {
            required: true,
            message: "Relación de facturación requerido",
            trigger: "blur",
          },
        ],
      },
      shipments: [],
      billings: [
        {
          id: 1,
          name: "Facturar Por Manifiesto",
        },
        {
          id: 2,
          name: "Refacturar Por Manifiesto",
        },
        {
          id: 3,
          name: "Cancelar Por Manifiesto",
        },
      ],
      vehicles: [],
      transportFigures: [],
      billRelations: [],
      isLoading: false,
      pdfFileName: null,
      batchGroups: [],
      batchPosition: 0,
      pdfStrings: [],
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new SaleViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      // this.viewModel.bindFormView(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.isLoading = true;
          this.onDoAction();
          return true;
        }
        return false;
      });
    },
    onSaveResponse() {
      // this.onDoAfterSaveResponse(response.message, "on-submited-sale");
    },
    onChangeBillingType() {
      this.isLoading = true;
      setTimeout(() => {
        this.$set(this.formData, "isRebilling", false);
        this.$set(this.formData, "vehicle", null);
        this.$set(this.formData, "transportFigure", null);
        this.$set(this.formData, "shipment", null);
        this.isLoading = false;
      }, 400);
    },
    getSubmitLabel() {
      if (this.formData.billingType === 1) return "Facturar";
      if (this.formData.billingType === 2) return "Refacturar";
      if (this.formData.billingType === 3) return "Cancelar Facturas";
    },
    onFindShipments(query) {
      this.shipments = [];
      if (query)
        this.viewModel.findShipments(
          `FullSearch=${query}&BillingShipmentStatus=2`
        );
    },
    onFindShipmentsResponse(response) {
      this.shipments = this.viewModel.mapShipments(response.data);
    },
    onFindTransportFigures(query) {
      if (query)
        this.viewModel.findTransportFigures(
          `Name=${query}&IsTransportFigure=true&IsActive=true`
        );
    },
    onFindTransportFiguresResponse(response) {
      this.transportFigures = this.viewModel.mapTransportFigures(response.data);
    },
    onFindVehicles(query) {
      if (query)
        this.viewModel.findVehicles(`FullSearch=${query}&IsActive=true`);
    },
    onFindVehiclesResponse(response) {
      this.vehicles = this.viewModel.mapVehicles(response.data);
    },
    onFindBillRelations(query) {
      if (query)
        this.viewModel.findBillRelations(`FullSearch=${query}&IsActive=true`);
    },
    onFindBillRelationsResponse(response) {
      this.billRelations = this.viewModel.mapBillRelations(response.data);
    },
    onDoAction() {
      if (this.formData.billingType === 1)
        this.viewModel.createBillByManifest(this.formData.shipment);
      if (this.formData.billingType === 2)
        this.viewModel.reBillByManifest(this.formData.shipment, {
          billRelationId: this.formData.billRelation || null,
        });
      if (this.formData.billingType === 3)
        this.viewModel.cancelBillsByManifest(this.formData.shipment);
    },
    onCreateBillByManifestResponse(response) {
      this.pdfFileName = response.fileName;
      this.batchGroups = this.viewModel.getSalesByChunks(response.data);
      if (this.batchGroups.length > 0) {
        this.viewModel.batchBillsByChunks(this.batchGroups[this.batchPosition]);
      } else {
        this.conf.isLoading = false;
        this.notifyWarning(response.message);
      }
    },
    async onBatchBillsResponse(response) {
      this.batchPosition += 1
      response.data.forEach((bill) => {
        this.pdfStrings.push(bill)
      })
      if(this.batchGroups.length <= this.batchPosition){
          this.batchPosition = 0
          await this.viewModel.mergeFiles(this.pdfStrings, this.pdfFileName, () => {
              this.isLoading = false
              this.notifySuccess("Etiquetas generadas.")
              this.pdfStrings = []
              this.onDoAfterSaveResponse(response.message, "on-submited-sale");
          });
      } else {
          this.viewModel.batchBillsByChunks(this.batchGroups[this.batchPosition])
      }
    },
    onBatchBillsError(error) {
      this.isLoading = false
      this.onError(error)
    },
    onCancelBillsByManifestResponse(response) {
      // this.onShowSuccess(response.message);
      setTimeout(() => {
        this.isLoading = false;
        this.onDoAfterSaveResponse(response.message, "on-submited-sale");
      }, 400);
    },
    onReBillByManifestResponse(response) {
      // this.onShowSuccess(response.message);
      setTimeout(() => {
        this.isLoading = false;
        this.onDoAfterSaveResponse(response.message, "on-submited-sale");
      }, 400);
    },
    onBillError(error) {
      this.isLoading = false;
      this.onError(error);
    },
  },
};
