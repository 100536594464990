<script>
import AdminFooterView from "./AdminFooterView";

export default {
  extends: AdminFooterView,
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          2021 © Log&iacute;stica Ram.
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            Powered <i class="mdi mdi-heart text-danger" /> by
            <a
              href="https://www.devandtech.com/"
              target="_blank"
              class="text-reset"
            >DEVANDTECH</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
