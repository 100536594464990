import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import ReceptionCustomer from "./ReceptionCustomer";
import TaxRegime from "../taxRegimes/TaxRegime";
import PostalCode from '../postalCodes/PostalCode'

export default class ReceptionPackageViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  save(data) {
    this.api.customers
      .register(data.toSend())
      .then((response) => this.view.onSaveResponse(response.data))
      .catch(this.view.onSaveError);
  }

  createCustomer(data) {
    return new ReceptionCustomer(data, "create");
  }

  bindFormView() {
    this.api.taxRegimes
      .find(`Skip=0&Limit=10000&IsActive=true`)
      .then((response) => {
        this.view.taxRegimes = this.mapTaxRegimes(response.data.data);
      })
      .catch(this.view.onError);
  }

  verifyRFC(value, callback) {
    let rfcRegex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    if(value.match(rfcRegex)) {
      var defaultFiscalCodes = ["XAXX010101000", "XEXX010101000"];
      if (_.includes(defaultFiscalCodes, value)) {
        callback({
          isValid: true,
          message: "Es codigo es generico es valido.",
        });
      } else {
        this.api.bills
          .verifyRfc(value)
          .then((response) => {
            callback({
              isValid: response.data.isValid,
              message: response.data.message,
            });
          })
          .catch((error) => {
            callback({
              isValid: false,
              message: error.message,
            });
          });
      }
    } else {
      callback({
        isValid: false,
        message: "RFC no valido.",
      });
    }
    
  }

  verifyCustomer(criteria, callback) {
    this.api.customers
      .verify(criteria)
      .then((response) => {
        callback({
          isValid: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        callback({
          isValid: false,
          message: error.message,
        });
      });
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  findPostalCodes(criteria) {
    this.api.postalCodes.find(criteria)
      .then((response) => this.view.onFindPostalCodesResponse(response.data))
      .catch(this.view.onError)
  }

  mapPostalCodes(collection) {
    return collection.map((item) => new PostalCode(item));
  }
}
