import BaseViewModel from '../../infraestructure/BaseViewModel'
import VehicleType from '../vehicleTypes/VehicleType'
import Vehicle from './Vehicle'

export default class VehicleViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }

    create(data) {
        return new Vehicle(data, 'create')
    }

    edit(data) {
        return new Vehicle(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.vehicles.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
            } else {
            this.api.vehicles.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.vehicles.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.vehicles.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.vehicles.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    async bindForm() {
        try{
            const branchOfficesResponse = await this.api.branchOffices.find(`Skip=0&Limit=1000&IsActive=true`)
            this.view.branchOffices = branchOfficesResponse.data.data

            const vehicleTypesResponse = await this.api.vehicleTypes.find(`Skip=0&Limit=1000&IsActive=true`)
            this.view.vehicleTypes = vehicleTypesResponse.data.data

            const sctPermissionsTypesResponse = await this.api.sctPermissionTypes.find(`Skip=0&Limit=1000&IsActive=true`)
            this.view.sctPermissionTypes = sctPermissionsTypesResponse.data.data


        } catch(error) {
            this.view.onError(error)
        }        
    }

    import(file) {
        this.api.vehicles.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindList() {
        this.api.vehicleTypes.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.vehicleTypes = this.mapVehicleTypes(response.data.data)
                setTimeout(() => {
                    this.view.isListLoading = false;
                    this.view.onSearch()
                }, 400);
            })
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            equipmentNumber: '',
            plateNumber: '',
            description: '',
            branchOfficeName: '',
            vehicleType: '',
            status: '',
        };

        this.view.removeFilter('EquipmentNumber');
        this.view.removeFilter('PlateNumber');
        this.view.removeFilter('Description');
        this.view.removeFilter('BranchOfficeName');
        this.view.removeFilter('VehicleTypeId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    //#region 
    
    mapCollection(collection){
        return collection.map((item) => new Vehicle(item))
    }

    mapVehicleTypes(collection) {
        return collection.map((item) => new VehicleType(item))
    }

    //#endregion
     //#region QUERIES
    findBranchOffice(criteria) {
        this.api.branchOffices.find(criteria)
            .then((response) => this.view.onFindBranchOfficeResponse(response.data))
            .catch(this.view.onError)
    }


//#endregion QUERIES
    
}

