import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomerContact extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }
 
    create(customerId, contactId, data) {
        return this.client.post(`${this.endPoint}/${customerId}/contacts/${contactId}`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/contacts/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/contacts/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/contacts${parameters}`)
    }

    findOne(customerId, id) {
          return this.client.get(`${this.endPoint}/${customerId}/contacts/${id}`)
    }
    exportToExcel(customerId,criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/contacts/export-excel${parameters}`)
    }
}