<script>
import PackageBoxComponent from "./PackageBoxComponent";

export default {
  components: {},
  extends: PackageBoxComponent,
};
</script>
<template>
  <div class="request-quote__packages">
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        align="right"
        class="control"
      >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="onAddPackage"
        >
          Agregar Paquete
        </el-button>
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-form-item
          prop="packages"
          align="center"
        >
          <el-table
            :data="formData.packages"
            class="table__request_quote"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-row
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col
                    :xs="12"
                    :sm="6"
                    align="center"
                  >
                    <el-form-item
                      label="Cantidad"
                      :prop="'packages.' + props.$index + '.quantity'"
                      :rules="[
                        {
                          required: true,
                          message: 'Cantidad requerido.',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="props.row.quantity"
                        size="small"
                        :min="1"
                        :precision="3"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="6"
                    align="center"
                  >
                    <el-form-item
                      label="Largo (cm.)"
                      :prop="'packages.' + props.$index + '.length'"
                      :rules="[
                        {
                          required: true,
                          message: 'Largo requerido.',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="props.row.length"
                        size="small"
                        :min="0"
                        :precision="3"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="6"
                    align="center"
                  >
                    <el-form-item
                      label="Alto (cm.)"
                      :prop="'packages.' + props.$index + '.height'"
                      :rules="[
                        {
                          required: true,
                          message: 'Alto requerido.',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="props.row.height"
                        size="small"
                        :min="0"
                        :precision="3"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="6"
                    align="center"
                  >
                    <el-form-item
                      label="Ancho (cm.)"
                      :prop="'packages.' + props.$index + '.width'"
                      :rules="[
                        {
                          required: true,
                          message: 'Ancho requerido.',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="props.row.width"
                        size="small"
                        :min="0"
                        :precision="3"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="6"
                    align="center"
                  >
                    <el-form-item
                      label="Peso Total (kg.)"
                      :prop="'packages.' + props.$index + '.weight'"
                      :rules="[
                        {
                          required: true,
                          message: 'Ancho requerido.',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="props.row.weight"
                        size="small"
                        :min="0"
                        :precision="3"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              label="Descripción"
            >
              <template slot-scope="scope">
                <small
                  class="table-package-especification-warning"
                >Seleccionar el icono de +/- para ocultar o mostrar
                  especificaciones</small>
                <el-form-item
                  :prop="'packages.' + scope.$index + '.description'"
                  :rules="[
                    {
                      required: true,
                      message: 'Descripción requerido.',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.description"
                    size="small"
                    type="textarea"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="Opciones"
              width="85"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="default"
                  icon="el-icon-delete-solid"
                  size="small"
                  @click="onDeletePackage(scope.$index)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
