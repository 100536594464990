<script>
import ReceptionEditComponent from "./CustomerReceptionEditComponent";

export default {
  extends: ReceptionEditComponent,
};
</script>
<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-row type="flex" class="row-bg" :gutter="20" style="padding-top:15px;">
          <el-col :span="6" align="center">
            <h4>GUÍA DE ENVÍO</h4>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i class="uil-qrcode-scan" />
                  {{ formData.trackingNumber }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" align="center">
            <h4>FECHA DE RECEPCIÓN</h4>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i class="uil-calendar-alt" />
                  {{ formData.receptionDate | formatDate }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" align="center">
            <h4>TIPO DE RECEPCIÓN</h4>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  {{ onGetNameReceptionType(formData.receptionType) }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" align="center">
            <h4>SEGUIMIENTO</h4>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i :class="onGetIconTrackingStatus(formData.status)" />
                  {{ onGetNameTrackingStatus(formData.status) }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="tabActive" @tab-click="onTabChange">
          <el-tab-pane label="Detalles" name="reception-details">
            <el-row type="flex" class="row-bg reception-info-wrapper" :gutter="20">
              <el-col :span="24">
                <!--    <el-row
                  type="flex"
                  class="row-bg reception-info-details"
                  :gutter="20"
                >
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Forma de Pago</h5>
                      </div>
                      <div>
                        {{ getPaymentType(formData.paymentType) }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5>
                          <i class="uil-check-circle" /> Estado de Recepción
                        </h5>
                      </div>
                      <div>
                        {{ getReceptionStatus(formData.receptionStatus) }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5>
                          <i class="uil-check-circle" /> Cobrase o Devuelvase
                        </h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          formData.paymentAgainstDelivery | formatMXNCurrency
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Referencia</h5>
                      </div>
                      <div class="box-input-body">
                        <el-input
                          v-model="formData.documentReference"
                          size="medium"
                          type="text"
                        />
                      </div>
                    </el-card>
                  </el-col>
                </el-row> -->
                <el-row type="flex" class="row-bg reception-info-details" :gutter="20">
                  <el-col :span="12">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <h5>
                          <i class="uil-map-marker" /> Remitente |
                          {{ formData.senderPlace.name }}
                        </h5>
                      </div>
                      <el-row v-if="hasSenderCustomer(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Cliente:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasSenderCustomer(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>
                            {{ formData.senderLocation.customer.description }}
                          </p>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasSenderCustomerLocation(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Dirección:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasSenderCustomerLocation(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>
                            {{
                              formData.senderLocation.getDescriptionFullAddress()
                            }}
                          </p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Nombre:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>{{ formData.senderName }}</p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="8">
                          <h5 class="field-details">
                            Teléfono:
                          </h5>
                        </el-col>
                        <el-col :span="16">
                          <h5 class="field-details">
                            Correo:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="8">
                          <p>{{ formData.senderPhone }}</p>
                        </el-col>
                        <el-col :span="16">
                          <p>{{ formData.senderEmail }}</p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Referencia:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>{{ formData.senderObservation }}</p>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-col>
                  <el-col :span="12">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <h5>
                          <i class="uil-map-marker" /> Destinatario |
                          {{ formData.receiverPlace.name }}
                        </h5>
                      </div>
                      <el-row v-if="hasReceiverCustomer(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Cliente:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasReceiverCustomer(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>
                            {{ formData.receiverLocation.customer.description }}
                          </p>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasReceiverCustomerLocation(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Dirección:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row v-if="hasReceiverCustomerLocation(formData)" type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>
                            {{
                              formData.receiverLocation.getDescriptionFullAddress()
                            }}
                          </p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Nombre:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>{{ formData.receiverName }}</p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="8">
                          <h5 class="field-details">
                            Teléfono:
                          </h5>
                        </el-col>
                        <el-col :span="16">
                          <h5 class="field-details">
                            Correo:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="8">
                          <p>{{ formData.receiverPhone }}</p>
                        </el-col>
                        <el-col :span="16">
                          <p>{{ formData.receiverEmail }}</p>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <h5 class="field-details">
                            Referencia:
                          </h5>
                        </el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <p>{{ formData.receiverObservation }}</p>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-col>
                </el-row>
                <!--    <el-row
                  type="flex"
                  class="row-bg reception-info-details"
                  :gutter="20"
                >
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Flete</h5>
                      </div>
                      <div class="box-input-body">
                        {{ formData.shippingQuote | formatMXNCurrency }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Descuento</h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          formData.discountPercentage + '%'
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Servicio</h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          getTotalService() | formatMXNCurrency
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Pagado</h5>
                      </div>
                      <div class="box-input-body">
                        <el-checkbox
                          v-model="formData.freightPayable"
                          label="Pagado"
                          border
                        />
                      </div>
                    </el-card>
                  </el-col>
                </el-row> -->
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Paquetes" name="packages">
            <el-table :data="formData.receptionPackages" show-summary sum-text="Total" :summary-method="getSummaries"
              style="width: 100%">
              <el-table-column label="Indice" width="100" align="center">
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="description" label="Descripción Mercancia" />
              <el-table-column label="Tipo Producto" width="150">
                <template #default="scope">
                  <div v-if="scope.row.productType">
                    {{ scope.row.productType.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="U.M." width="100">
                <template #default="scope">
                  <div v-if="scope.row.measureUnit">
                    {{ scope.row.measureUnit.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="quantity" label="Cantidad" width="100" align="center" />
              <el-table-column prop="weight" label="Peso Fis. KG" width="110" align="center" />
              <el-table-column prop="volumetricWeight" label="Peso Vol. KG" width="110" align="center" />
              <el-table-column label="Dimensiones" width="200">
                <template #default="scope">
                  {{ scope.row.length }}cm x {{ scope.row.height }}cm x {{ scope.row.height }}cm
                </template>
              </el-table-column>
              <!--               <el-table-column
                prop="subtotal"
                label="Costo"
                width="140"
                align="center"
              >
                <template #default="scope">
                  {{ scope.row.subtotal | formatMXNCurrency }}
                </template>
              </el-table-column> -->
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Historial" name="historyTrace">
            <el-timeline class="time-line-reception-trace">
              <el-timeline-item v-for="(item, index) in onGetOrderDescending(formData.historyTrace)" :key="index"
                :icon="onGetIconTrackingStatus(item.status)" :color="onGetColorTrackingStatus(item.status)"
                :timestamp="item.date | formatDWithTime">
                {{ onGetTraceStatusDescription(item) }}
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <el-tab-pane v-if="formData.isDelivered" label="Ubicación de Entrega" name="deliveryLocation">
            <div id="map" v-loading="isMapLoading" style="height: 450px;" />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
