<script>
import { EventBus } from "../../core/helpers/EventsHelper";
import _ from "lodash";
import PermissionHelper from "../../core/helpers/PermissionHelper";
import TokenStorageHelper from "../../core/helpers/TokenStorageHelper";
export default {
  methods: {
    canDo(moduleName, permissionName) {
      return PermissionHelper.verifyPermission(moduleName, permissionName);
    },
    onError(error) {
      console.log(error)
      if (error.response) {
        switch (error.response.status) {
          case 400:
          case 404:
          case 409:
            this.onRequestError(error);
            break;
          case 403:
            this.onPermissionsError(error);
            break;
          case 401:
            this.onAuthenticationError(error);
            break;
          case 500:
            this.onServerError(error);
            break;
          default:
            this.onBaseError(error);
        }
      } else {
        this.onBaseError(error);
      }
    },
    onBaseError(error) {
      if (error instanceof Error) {
        this.onShowError(error.message);
      } else {
        // Errores sin solicitud a servidor
        var messages = _.flattenDeep(
          _.toArray(_.toArray(error.data.errors)[0])
        );
        messages.forEach((message) => {
          setTimeout(() => {
            this.onShowError("error", "Error", message);
          }, 150);
        });
        //this.showNotification('error', 'Error', _.flattenDeep(_.toArray(_.toArray(error.data.errors)[0])).toString());
      }
    },
    onUserPermission(permission) {
      return this.$store.getters["admin/getUserPermission"](permission);
    },
    onPermissionsError() {
      // Errores sin permisos
      this.viewModel.showNotification(
        "error",
        "Error",
        "No tienes permisos para realizar esta acción."
      );
    },
    onAuthenticationError() {
      TokenStorageHelper.removeToken();
      this.notifyWarning("Sin autorización.");
      if (this.$router.currentRoute.path !== "/admin/login") {
        this.$nextTick(() => this.$router.push("/admin/login"));
      }
    },
    onRequestError(error) {
      var that = this;
      if (_.isArray(error.response.data)) {
        const messages = _.flattenDeep(
          _.toArray(_.toArray(error.response.data)[0])
        );
        messages.forEach(function(message) {
          setTimeout(() => {
            // that.viewModel.showNotification("warning", "Advertencia", message);
            that.onShowWarning(message);
          }, 150);
        });
      } else if(_.isArray(error.response.data.data)){
        let messages = error.response.data.data.filter((item) => item.message != null).map((item) => item.message);
        if(_.isEmpty(messages)) messages = error.response.data.message
        setTimeout(() => {
          this.onShowWarning(messages);
        }, 150);
      } else {
        setTimeout(() => {
          this.onShowWarning(error.response.data.message);
        }, 150);
      }
    },
    onServerError(error) {
      if (error.response.data.message)
        this.notifyError(error.response.data.message);
      else if (error.response.statusText)
        this.notifyError(error.response.statusText);
    },
    onShowWarningList(errors) {
      let resultErrors = [];
      Object.entries(errors).forEach((error) => {
        resultErrors.push(error[1][0].message);
      });
      setTimeout(() => {
        this.onShowWarning(resultErrors);
      }, 400);
    },
    onShowSuccess(message) {
      this.$notify({
        title: "FINALIZADO",
        message: message,
        type: "success",
      });
    },
    onShowWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: _.isArray(message) ? this.mapListMessages(message) : message,
        type: "warning",
      });
    },
    onShowError(message) {
      if (this.config) this.config.isLoading = false;
      this.$notify({
        title: "ERROR",
        message: message,
        type: "error",
      });
    },
    onMountedEvent(featureName, actionMethod) {
      EventBus.$on(featureName, actionMethod);
    },
    onBeforeDestroyEvent(featureName, actionMethod) {
      EventBus.$off(featureName, actionMethod);
    },
    onDoAfterSaveResponse(message, featureName, data) {
      this.config.isVisible = false;
      this.notifySuccess(message);
      EventBus.$emit(featureName, data);
    },
    onDoAfterLocalSaveResponse(data, featureName) {
      this.config.isVisible = false;
      EventBus.$emit(featureName, data);
    },
    onDoAfterLocalSaveNoHideResponse(data, featureName) {
      EventBus.$emit(featureName, data);
    },
    onDoImportFile(featureName, file) {
      EventBus.$emit(featureName, file);
    },
    onNotifyEvent(featureName, data){
      EventBus.$emit(featureName, data);
    },
    onDoCancel(featureName, data) {
      EventBus.$emit(featureName, data);
    },
    notifySuccess(message) {
      this.$notify({
        title: "CONFIRMACIÓN",
        message: message,
        type: "success",
      });
    },
    notifyWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: message,
        type: "warning",
      });
    },
    notifyError(message) {
      this.$notify({
        title: "ERROR",
        message: message,
        type: "error",
      });
    },
    mapListMessages(collection) {
      const h = this.$createElement;
      let list = h(
        "ul",
        null,
        collection.map((item) => h("li", null, item))
      );
      return list;
    },
    setProfilePermissions(data) {
      const permissions = _.map(data.permissions, permission => permission.code)
      PermissionHelper.setPermissions(permissions)
    },
    onCanSaveOrUpdate() {
        return this.onCanCreate() || this.onCanUpdate()
    },
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName, PermissionHelper.getPermissions())
    },
    canShowMultiple(modules) {
      return PermissionHelper.canShowMultiple(modules, PermissionHelper.getPermissions())
    },
    onCanCreate() {
        return PermissionHelper.canCreate(this.modulePermissions, PermissionHelper.getPermissions())
    },
    onCanRead() {
         return PermissionHelper.canRead(this.modulePermissions, PermissionHelper.getPermissions())
    },
    onCanUpdate() {
        return PermissionHelper.canUpdate(this.modulePermissions, PermissionHelper.getPermissions())
    },
    onCanDelete() {
        return PermissionHelper.canDelete(this.modulePermissions, PermissionHelper.getPermissions())
    },
  },
};
</script>
