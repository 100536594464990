<script>
import ContactPageComponent from './ContactPageComponent'
import LocationMapSection from './sections/LocationMapSection'
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView"
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView"

export default {
  name: 'ContactPageView',
       components: {
    'location-map-section' : LocationMapSection,
        "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  },
  extends: ContactPageComponent,
};
</script>
<template>
  <div>
    <!-- Start .header-1-->
    <website-header-view />
    <!-- Start #page-title-->
    <!--
      ============================
      Google Maps Section
      ============================
      -->
    <location-map-section />
    <!--
      ============================
      Contact Information Section
      ============================
      -->
    <section class="contact-info">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-12">
                <div class="contact-details">
                  <h6>Datos de Contacto</h6>
                  <ul class="list-unstyled info">
                    <li class="d-flex align-items-center">
                      <span class="fas fa-map-marker-alt" />
                      <div class="ms-2 me-auto">
                        Ignacio Ramírez 2215, Zona Centro, C.P. 23000 La Paz,
                        B.C.S.
                      </div>
                    </li>
                    <li>
                      <span class="fas fa-envelope" /><a
                        href="mailto::contacto@ram-logistica.com"
                      >contacto@ram-logistica.com</a>
                    </li>
                    <li>
                      <span class="fas fa-phone-alt" /><a
                        href="tel:+526121251780"
                      >(612)125-1780</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-12">
                <div class="opening-hours">
                  <h6>Horario de atención</h6>
                  <ul class="list-unstyled">
                    <li>
                      <span>Lunes a viernes</span><span> 09:00 - 20:00 hrs.</span>
                    </li>
                    <li>
                      <span>Sábado </span><span>09:00 - 14:30 hrs. </span>
                    </li>
                    <li><span>Domingo</span><span>cerrado</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <form
              class="contactForm"
              method="post"
              action="assets/php/contact.php"
            >
              <div class="row">
                <div class="col-12 col-lg-6">
                  <input
                    class="form-control"
                    type="text"
                    name="contact-name"
                    placeholder="Nombre"
                    required=""
                  >
                </div>
                <div class="col-12 col-lg-6">
                  <input
                    class="form-control"
                    type="text"
                    name="contact-email"
                    placeholder="Correo electrónico"
                    required=""
                  >
                </div>
                <div class="col-12">
                  <textarea
                    class="form-control"
                    name="contact-message"
                    cols="30"
                    rows="2"
                    placeholder="mensaje"
                    required=""
                  />
                </div>
                <div class="col-12">
                  <input
                    class="btn btn--primary"
                    type="submit"
                    value="Enviar"
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .container-->
    </section>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <website-footer-view />      
  </div>
</template>
