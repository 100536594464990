import $ from 'jquery'
import 'metismenu'
import 'node-waves'
import BaseViewModel from '../../../core/infraestructure/BaseViewModel'

export default class MainMenuViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    setupScripts() {
        setTimeout(() => {
            $("#side-menu").metisMenu();

            $('.vertical-menu-btn').on('click', function (event) {
                event.preventDefault();
                $('body').toggleClass('sidebar-enable');
                if ($(window).width() >= 992) {
                    $('body').toggleClass('vertical-collpsed');
                } else {
                    $('body').removeClass('vertical-collpsed');
                }
            });

            $("#sidebar-menu a").each(function () {
                var pageUrl = window.location.href.split(/[?#]/)[0];
                if (this.href == pageUrl) {
                    $(this).addClass("active");
                    $(this).parent().addClass("mm-active"); // add active to li of the current link
                    $(this).parent().parent().addClass("mm-show");
                    $(this).parent().parent().prev().addClass("mm-active"); // add active class to an anchor
                    $(this).parent().parent().parent().addClass("mm-active");
                    $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
                    $(this).parent().parent().parent().parent().parent().addClass("mm-active");
                }
            });

            $(document).ready(function () {
                if ($("#sidebar-menu").length > 0) {
                    var activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
                    if (activeMenu > 300) {
                        activeMenu = activeMenu - 300;
                        $(".vertical-menu .simplebar-content-wrapper").animate({ scrollTop: activeMenu }, "slow");
                    }
                }
            });

            $(".navbar-nav a").each(function () {
                var pageUrl = window.location.href.split(/[?#]/)[0];
                if (this.href == pageUrl) {
                    $(this).addClass("active");
                    $(this).parent().addClass("active");
                    $(this).parent().parent().addClass("active");
                    $(this).parent().parent().parent().addClass("active");
                    $(this).parent().parent().parent().parent().addClass("active");
                    $(this).parent().parent().parent().parent().parent().addClass("active");
                }
            });

            $('[data-bs-toggle="fullscreen"]').on("click", function (e) {
                e.preventDefault();
                $('body').toggleClass('fullscreen-enable');
                if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    } else if (document.documentElement.mozRequestFullScreen) {
                        document.documentElement.mozRequestFullScreen();
                    } else if (document.documentElement.webkitRequestFullscreen) {
                        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                }
            });
            document.addEventListener('fullscreenchange', exitHandler);
            document.addEventListener("webkitfullscreenchange", exitHandler);
            document.addEventListener("mozfullscreenchange", exitHandler);
            function exitHandler() {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    $('body').removeClass('fullscreen-enable');
                }
            }

            // right side-bar toggle
            $('.right-bar-toggle').on('click', function () {
                $('body').toggleClass('right-bar-enabled');
            });

            $(document).on('click', 'body', function (e) {
                if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                    return;
                }

                $('body').removeClass('right-bar-enabled');
                return;
            });

            // var elements = document.getElementById("topnav-menu-content").getElementsByTagName("a");
            // for (var i = 0, len = elements.length; i < len; i++) {
            //     if (elements[i].parentElement.getAttribute("class") === "nav-item dropdown active") {
            //         elements[i].parentElement.classList.remove("active");
            //         elements[i].nextElementSibling.classList.remove("show");
            //     }
            // }
        }, 200);
    }
}
