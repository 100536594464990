import BaseComponent from "../../infraestructure/BaseComponent";

export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null,
      userCurrent: {},
      show: false,
      navbarOpen: false,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["admin/isAuth"];
    },
  },

  methods: {
    onEndSession() {
      this.$store.commit("admin/ON_END_SESSION");
      this.$router.push({ name: "login" });
    },
    toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    toggleSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      if (!item.classList.contains("show")) {
        item.classList.add("show");
        this.show = true
      } else {
        item.classList.remove("show");
        this.show = false
      }
    },
    removeClassSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      item.classList.remove("show");
    },
 
  },
};
