export default class Place {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }

    getCodeName(){
      return `${this.code} - ${this.name}`
    }

    toSend() {
      return {
        destinationId: this.id,
        catalogNumber: this.catalogNumber ?? "",
        name: this.name,
        description: this.description,
        isActive: this.isActive,
        isDestination: this.isDestination,
        destinationCode: (this.isDestination) ? this.destinationCode : "",
        // municipalityId: (this.municipality) ? this.municipality.id : null,
        districtId: (this.district) ? this.district.id : null,
      }
    }

    toSelectionList() {
      return (this.district) ? `${this.name} ${this.district}` : `${this.name}`
    }
  }