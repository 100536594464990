import BaseModule from '../infraestructure/BaseModule'
import ProductTypesViewModel from '../../core/features/productTypes/ProductTypesViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            tabActive: 'product-types',
        }
    },
    created() {
        this.viewModel = new ProductTypesViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}