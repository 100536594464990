import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      senderCustomers: [],
      senderCustomerLocations: [],
      receiverCustomers: [],
      receiverCustomerLocations: [],
      receptionServiceTypes: ReceptionsViewModel.receptionServiceTypes,
      payerResponsibles: ReceptionsViewModel.payerResponsibles,
      isSenderLoading: false,
      isReceiverLoading: false,
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Metodo para el evento cuando el tipo de servicio (Contado/Credito/Prepago)
     */
    onChangeReceptionServiceType(data) {
      this.formData.freightPayable = (data === 3)
      this.onResetCustomerForm("sender", ["sender.name", "sender.phone"]);
      this.onResetCustomerForm("receiver", ["receiver.name", "receiver.phone"]);
    },
    /**
     * Metodo para el evento cuando el responsable de pago cambia (Remitente /Destinatario)
     */
    onChangePayerResponsible(data) {
      this.formData.freightPayable = (this.formData.receptionServiceType === 3 && data === 'sender')
      this.onResetCustomerForm("sender", ["sender.name", "sender.phone"]);
      this.onResetCustomerForm("receiver", ["receiver.name", "receiver.phone"]);
    },
    /**
     * Metodo para el evento cuando se selecciona el cliente y verificar si tiene solvencia (Credito/Prepago)
     * @param {*} data 
     */
    onFindAvailableCustomerSolvency(data) {
      if (data) {
        this.viewModel.findAvailableCustomerSolvency(
          data.id,
          this.formData.receptionServiceType
        );
      } else {
        this.viewModel.setCustomerSolvency(this.formData, null);
      }
    },
    /**
     * Metodo de respuesta para cuando se busca si el cliente responsable de pago
     * @param {*} response 
     */
    onFindByAilableCustomerSolvencyResponse(response) {
      this.viewModel.setCustomerSolvency(this.formData, response);
    },
    /**
     * Metodo para limpiar los datos de los clientes (Emisor/Receptor)
     * @param {*} customerType 
     * @param {*} properties 
     */
    onResetCustomerForm(customerType, properties) {
      this.viewModel.resetCustomer(
        this.formData[customerType],
        this.$parent.$parent.$refs["formDataStep0"],
        properties
      );
    },
  },
};
