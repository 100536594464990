import BaseForm from '../../infraestructure/BaseForm';
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            countries: [],
            districts: [],
            municipalities: [],
            cities: [],
            neighborhoods: [],
            postalCodes: [],
            viewModel: null,
            isAddressLoading: false,
            usingDataLabel: "",
            nameLabel: "",
            rfcLabel: "",
            dateTimeLabel: "",
            federalTaxRegistrationSelected: '1',
            lastFederalTax: ""
        }
    },
    created() {
        this.viewModel = new ReceptionsViewModel(this)
        this.onActive()
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        propName: {
            type: String,
            required: true
        },
        transactionInfo: {
            type: Object,
            required: true
        },
        sale: {
            type: Object,
            required: true
        }
    },
    methods: {
        onActive() {
            this.usingDataLabel = (this.propName === "consignmentNote.origin") ? "Usar Datos de Remitente" : "Usar Datos de Destinatario";
            this.nameLabel = (this.propName === "consignmentNote.origin") ? "Nombre Remitente" : "Nombre Destinatario";
            this.rfcLabel = (this.propName === "consignmentNote.origin") ? "RFC Remitente" : "RFC Destinatario";
            this.dateTimeLabel = (this.propName === "consignmentNote.origin") ? "Fecha y Hora de Salida" : "Fecha y Hora de Llegada";
            this.locationTypeLabel = "Tipo de Ubicación";
            this.lastFederalTax = this.formData.federalTaxPayerRegistration
        },
        onChangeRfc(data) {
            this.$set(this.formData, 'federalTaxPayerRegistration' , (data == 2) ? "XAXX010101000" : this.lastFederalTax)
        }
    }
}