import BaseList from "../infraestructure/BaseList";
import CustomersLocationViewModel from "../../core/features/customerLocation/CustomersLocationViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      customerId: null,
      searchCityId: "",
      searchCode: "",
      searchDescription: "",
      searchFullAddress: "",
      searchNeighborhood: "",
      searchPostalCode: "",
      neighborHoods: [],
      destinations: [],
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR UBICACIONES",
        onUploadImport: "on-upload-import-customers",
        onCancelImport: "on-cancel-import-customers",
      },
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-locations", this.onActive);
    this.onMountedEvent("on-upload-import-customers", this.onUploadImport);
    this.onMountedEvent("on-cancel-import-customers", this.onCancelImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "on-submited-customer-locations",
      this.onSearch
    );
    this.onBeforeDestroyEvent(
      "on-upload-import-customers",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-import-customers",
      this.onCancelImport
    );
  },
  created() {
    this.viewModel = new CustomersLocationViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.onSearch(this.customerId);
    },
    onSelectCustomer() { },
    onCreate() {
      this.item = this.viewModel.create({
        customerId: this.customerId,
        isActive: true,
      });
      this.modalConfig.isVisible = true;
    },
    searchCodeFilter() {
      this.addFilter("Code", this.searchCode);
      this.onSearch(this.customerId);
    },
    searchDescriptionFilter() {
      this.addFilter("Description", this.searchDescription);
      this.onSearch(this.customerId);
    },
    searchFullAddressFilter() {
      this.addFilter("FullAddress", this.searchFullAddress);
      this.onSearch(this.customerId);
    },
    searchNeighborhoodFilter() {
      this.addFilter("NeighborhoodId", this.searchNeighborhood.id);
      this.onSearch(this.customerId);
    },

     onFindNeighborhoods(data) {
      if (data) {
        this.viewModel.findNeighborhoods(`Name=${data}`);
      } else {
        this.neighborHoods = [];
      }
    },
    onFindNeighborhoodsResponse(response) {
      this.neighborHoods = this.viewModel.mapNeighborhoods(response.data);
    }, 
    searchPostalCodeFilter() {
      this.addFilter("postalCode", this.searchPostalCode);
      this.onSearch(this.customerId);
    },
    searchCityIdFilter() {
      this.addFilter("cityId", this.searchCityId.id);
      this.onSearch(this.customerId);
    },
    onSearch(customerId) {
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(customerId, this.filters);
      }, 400);
    },
    onChangeEntries(data) {
      this.limit = data;
      this.onSearch(this.customerId);
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("isActive", data);
      } else {
        this.removeFilter("isActive");
      }
      this.onSearch(this.customerId);
    },
    onFindDestinations(data) {
      if (data) {
        this.viewModel.findDestinations(`Name=${data}`);
      } else {
        this.destinations = [];
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = response.data;
    },
    //Edit
    onChangeOptionCustomerLocations(event) {
      if (event.command === "edit")
        this.onEditCustomerLocation(event.customerId, event.item.id);
      if (event.command === "delete")
        this.onDeleteCustomerLocation(event.customerId, event.item.id);
    },

    onEditCustomerLocation(customerId, id) {
      this.viewModel.findItem(customerId, id);
    },

    onFindItemResponseCustomerLocation(response, customerId) {
      this.item = this.viewModel.edit(response.data, customerId);
      this.modalConfig.isVisible = true;
    },

    onDeleteCustomerLocation(customerId, id) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.delete(customerId, id);
        })
        .catch(() => {});
    },
    onDeleteResponseCustomerLocation(response, customerId) {
      this.notifySuccess(response.message);
      this.onSearch(customerId);
    },
    onPageChange(data) {
      this.currentPage = data;
      this.onActive()
    },
  },
};
