import { MXN } from '../../helpers/MoneyHelper'

export default class CustomerReception {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    getDescriptionFullAddress() {
        return `${this.mainStreet || ''}, ${this.secondaryStreet || ''} ${this.extNumber || 'SN'}, ${this.neighborhood} C.P: ${this.postalCode.name} ${this.city}`
    }
   
    getDescriptionFullAddressReceiver() {
      return `${this.mainStreet || ''}, ${this.secondaryStreet || ''} ${this.extNumber || 'SN'}, ${this.neighborhood.name} C.P: ${this.postalCode.name} ${this.city.name}`
    }

    getFullDescription() {
      return `Código: ${this.code}  |  Origen: ${this.origin} - Destino: ${this.destination}  |  Precio: ${MXN(this.amount).format()} MXN`
    }

    toSend() {
      return {
        customerId: this.customerId,
        sender: this.mapShipmentContact(this.sender),
        receiver: this.mapShipmentContact(this.receiver),
        orderFiscal: this.mapOrderFiscal(this.orderFiscal),
        serviceCostId: (this.serviceCost) ? this.serviceCost.id : null,
        receptionServiceType: this.receptionServiceType,
        customerSolvencyId: this.customerSolvencyId,
        traces: this.traces,
        payerResponsible: this.payerResponsible
      }
    }

    mapShipmentContact(contact) {
      return {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        observation: contact.observation,
        addressId: (contact.customerLocation) ? contact.customerLocation.id : null,
        mainStreet: (contact.customerLocation) ? contact.customerLocation.mainStreet : null,
        secondaryStreet: (contact.customerLocation) ? contact.customerLocation.secondaryStreet : null,
        intNumber: (contact.customerLocation) ? contact.customerLocation.intNumber : null,
        extNumber: (contact.customerLocation) ? contact.customerLocation.extNumber : null,
        postalCodeId: (contact.customerLocation && contact.customerLocation.postalCode) ? contact.customerLocation.postalCode.id : null,
        neighborhoodId: (contact.customerLocation && contact.customerLocation.neighborhood) ? contact.customerLocation.neighborhood.id : null,
        type: contact.type
      }
    }
  
    mapOrderFiscal(orderFiscal) {
      return {
        fiscalCustomerId: orderFiscal.fiscalCustomerId,
        type: orderFiscal.type,
        billUsingTypeId: orderFiscal.billUsingType ? orderFiscal.billUsingType.id : null,
        paymentMethodId: orderFiscal.paymentMethod ? orderFiscal.paymentMethod.id : null,
      }
    }
}