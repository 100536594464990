import BaseApi from '../../infraestructure/BaseApi'

export default class BillApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    createBillByManifest(shipmentId) { 
        return this.client.get(`${this.endPoint}/by-manifest/${shipmentId}`)
    }

    doBatchBills(data) {
        return this.client.post(`${this.endPoint}/batch-bills-pdf`, data)
    }

    reBillByManifest(shipmentId, data) { 
        return this.client.post(`${this.endPoint}/re-bill-by-manifest/${shipmentId}`, data)
    }

    cancelBillsByManifest(shipmentId) {
        return this.client.post(`${this.endPoint}/cancel-by-manifest/${shipmentId}`)
    }

    verifyRfc(value) {
        return this.client.get(`${this.endPoint}/verify-rfc/${value}`)
    }

    findListPaymentComplementDocuments(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/list-payment-complement-documents${parameters}`)
    }
}