import BaseComponent from "../../infraestructure/BaseComponent";
import HeaderCustomerViewModel from "../../../core/features/shared/HeaderCustomerViewModel";

export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null,
      userCurrent: {},
      show: false,
      navbarOpen: false,
      amountGuide: '',
      messageAvailability: '',
      type: '',
      bgclass: '',
      receptionSolvency: null,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["admin/isAuth"];
    },
  },

  created() {
      this.viewModel = new HeaderCustomerViewModel(this)
      this.Active()
  },

  methods: {
    Active(){
       this.viewModel.findCustomerReceptionsSolvency()
    },
    onFindCustomerReceptionsSolvency(response){
      this.receptionSolvency = response
      this.type = (response.isAvailable) ?   'success' : 'warning'
      this.bgclass = (response.isAvailable) ?   'el-alert--success is-light' : 'el-alert--error is-light'
    },
    onEndSession() {
      this.$store.commit("admin/ON_END_SESSION");
      this.$router.push({ name: "login" });
    },
    toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    toggleSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      if (!item.classList.contains("show")) {
        item.classList.add("show");
        this.show = true
      } else {
        item.classList.remove("show");
        this.show = false
      }
    },
    removeClassSubMenu(idmenu) {
      var item = document.getElementById(idmenu);
      item.classList.remove("show");
    },
  },
};
