import _ from 'lodash'
import Customer from "../../../core/features/customers/Customer";
import Shipment from "../../../core/features/shipments/Shipment";
import BaseViewModel from "../../../core/infraestructure/BaseViewModel";
export default class ViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  findCollection(filters) {
    this.api.sales
      .findGroupSaleBills(this.getQueryParameters(filters))
      .then((response) => {
        this.view.onSearchResponse(response.data, response.headers)
      })
      .catch(this.view.onError);
  }

  async bindListAsync() {}

  async bindFormViewAsync() {
    try {
      const customerResponse = await this.api.customers.find(
        `Skip=0&Limit=50&IsActive=true`
      );
      this.view.customers = this.mapCustomers(customerResponse.data.data);

      const shipmentResponse = await this.api.shipments.find(
        `Skip=0&Limit=50&IsActive=true&BillingShipmentStatus=1`
      );
      this.view.shipments = this.mapShipments(shipmentResponse.data.data);
    } catch (error) {
      this.view.onError(error);
    }
  }

  async findCustomers(query) {
    try {
      const customerResponse = await this.api.customers.find(query);
      this.view.customers = this.mapCustomers(customerResponse.data.data);
    } catch (error) {
      this.view.onError(error);
    }
  }

  async findShipments(query) {
    try {
      const shipmentsResponse = await this.api.shipments.find(query);
      this.view.shipments = this.mapShipments(shipmentsResponse.data.data);
    } catch (error) {
      this.view.onError(error);
    }
  }

  async doBillGroup(data) {
    try {
      const response = await this.api.sales.doBillGroup(data);
      this.view.onDoBillGroupResponse(response.data);
    } catch (error) {
      this.view.isLoading = false;
      this.view.config.isLoading = false;
      this.view.onError(error);
    }
  }

  mapCollection(collection){
    let groups = _.groupBy(collection, 'groupCode');

    let result = []
    for (const itemKey in groups) {
      let group = {
        groupCode: groups[itemKey][0].groupCode,
        customer: groups[itemKey][0].customer,
        shipment: groups[itemKey][0].shipment,
        saleBill: groups[itemKey][0].saleBill,
        reception: groups[itemKey][0].reception,
        total: _.sumBy(groups[itemKey], 'sale.total'),
        receptions: groups[itemKey].map((item) => {
          return {
            reception: item.reception,
            sale: item.sale
          }
        })
      }
      result.push(group)
    }
    return result
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  mapShipments(collection) {
    return collection.map((item) => new Shipment(item));
  }

  async generateExcelSalesBySaleGroup(data) {
    try {
      const response = await this.api.sales.generateExcelSalesBySaleGroup(data)
      return response.data
    } catch (error) {
      this.view.onError(error)
    }
  }
}
