<script>
import totalComponent from "./totalComponent";

export default {
  extends: totalComponent,
};
</script>
<template>
  <div class="col-md-6 col-xl-3">
    <div class="card">
      <div class="card-body d-flex justify-content-between align-items-center">
        <div>
          <h4 class="mb-1 mt-1">
            <span data-plugin="counterup">{{ totalAmount }}</span>
          </h4>
          <p class="text-muted mb-0">
            {{ title }}
          </p>
        </div>
        <div>
          <img
           
            class="img-totales"
            :src="urlImg"
            :alt="title"
          >
        </div>
      </div>
    </div>
  </div>
</template>
