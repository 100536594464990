import leaflet from "leaflet";

export default {
  EARTH_RADIUS: 6371, // Radio de la tierra en kilómetros,
  createMap(initialCenter, initialZoom){
    return leaflet.map("map").setView(initialCenter, initialZoom);
  },
  createCustomIcon() {
    return leaflet.icon({
        iconUrl: '/admin/assets/images/logo-small.png',
        // shadowUrl: 'leaf-shadow.png',
    
        iconSize:     [35, 35], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
  },
  createMarker(map, icon, markerPosition, markerContent) {
    leaflet.marker(markerPosition, {icon: icon })
    .addTo(map)
    .bindPopup(markerContent)
    .openPopup();
  },
  removeMap(map) {
    map.off()
    map.remove()
  },
  loadCopyRights(map) {
    leaflet.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
  },
};
