<script>
import WebsiteFooterView from './WebsiteFooterView'

export default {
    extends: WebsiteFooterView,
}
</script>

<template>
  <div>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <footer class="footerwebsite footerwebsite-1">
      <div class="footerwebsite-top"> 
        <div class="container"> 
          <div class="row"> 
            <div class="col-12 col-lg-3 col-xl-4">
              <div class="footerwebsite-logo">
                <img
                  class="footerwebsite-logo"
                  src="@/assets/website/images/logo/logo.svg"
                  alt="logo"
                >
              </div>
            </div>
            <div class="col-12 col-lg-9 col-xl-8">
              <div class="widget-newsletter">
                <div class="widget-content">
                  <p>Solicita una cotización, Tenemos el servicio perfecto para tus necesidades de Mensajería y paquetería.</p>
                  <div class="module"> 
                    <!-- Start .module-contact-->
                    <div class="module-contact">
                      <a
                        class="btn btn--primary"
                        :href="$router.resolve({name: 'request-quote-service'}).href"
                      >Cotizaci&oacute;n</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerwebsite-center">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3 footerwebsite-widget widget-about">
              <div class="footerwebsite-widget-title">
                <h5>Nosotros</h5>
              </div>
              <div class="widget-content">
                <p>Es una empresa dedicada, desde hace más de 21 años, a brindar servicio de mensajería y paquetería en todo el estado. Cuentan con infraestructura adecuada y personal capacitado para realizar entregas puntuales.</p>
                <!-- Start .module-social-->
                <div class="module module-social">
                  <a
                    class="share-facebook"
                    href="javascript:void(0)"
                  ><i class="fab fa-facebook-f" /></a><a
                    class="share-instagram"
                    href="javascript:void(0)"
                  ><i class="fab fa-instagram" /></a><a
                    class="share-twitter"
                    href="javascript:void(0)"
                  ><i class="fab fa-twitter" /></a>
                </div>
                <!-- End .module-social-->
              </div>
            </div>
            <!--  End .col-lg-4 -->
            <div class="col-sm-6 col-md-6 col-lg-2 offset-lg-2 footerwebsite-widget widget-links">
              <div class="footerwebsite-widget-title">
                <h5>Menú</h5>
              </div>
              <div class="widget-content">
                <ul>
                  <li><a :href="$router.resolve({name: 'index'}).href">Inicio</a></li>
                  <li><a :href="$router.resolve({name: 'about-company'}).href">Acerca de nosotros</a></li>
                  <li><a :href="$router.resolve({name: 'faqs-company'}).href">Preguntas frecuentes</a></li>
                  <li><a :href="$router.resolve({name: 'contact'}).href">Contacto</a></li>
                </ul>
              </div>
            </div>
            <!--  End .col-lg-2-->
            <div class="col-sm-6 col-md-6 col-lg-2 footerwebsite-widget widget-links">
              <div class="footerwebsite-widget-title">
                <h5
                  clas="contenido"
                >
                  Servicios
                </h5>
              </div>
              <div class="widget-content">
                <ul>
                  <li><a :href="$router.resolve({name: 'transport-service'}).href">Transporte</a></li>
                  <li><a :href="$router.resolve({name: 'request-quote-service'}).href">Solicitar cotización</a></li>
                  <li><a :href="$router.resolve({name: 'tracking-delivery-service'}).href">Rastrear envío</a></li>
                </ul>
              </div>
            </div>
            <!--  End .col-lg-2-->
            <div class="col-sm-6 col-md-6 col-lg-3 footerwebsite-widget widget-contact">
              <div class="footerwebsite-widget-title">
                <h5>Contáctanos</h5>
              </div>
              <div class="widget-content">
                <p>Si tiene alguna pregunta o necesita ayuda, no dude en ponerse en contacto con nuestro equipo.</p>
                <ul> 
                  <li class="phone">
                    <a href="tel:+tel:+526121251780"><i class="fas fa-phone-alt" /> (612)125-1780</a>
                  </li>
                  <li class="address">
                    <a
                      href="https://bit.ly/2WVBt6m"
                      target="_blank"
                    > Ignacio Ramírez 2215, Zona Centro,C.P. 23000 La Paz, B.C.S.</a>
                  </li>
                </ul>
              </div>
            </div>
            <!--  End .col-lg-2-->
          </div>
          <div class="clearfix" />
        </div>
        <!--  End .container-->
      </div>
      <!--  End .footerwebsite-center-->
      <div class="footerwebsite-bottom">
        <div class="row">
          <div class="col-md-12 col-md-12 text--center footerwebsite-copyright">
            <div class="copyright">
              <span>&copy; Ram Log&iacute;stica</span>
            </div>
          </div>
        </div>
        <!--  End .row-->
      </div>
      <!--  End .footerwebsite-bottom-->
    </footer>
    <div
      id="back-to-top"
      class="backtop"
    >
      <i class="fas fa-chevron-up" />
    </div>
  </div>
</template>
