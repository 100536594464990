import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      isEditable: false,
      isEditableLoading: false,
      currentFormData: null,
      currentCustomerLocation: null,
    };
  },
  mounted() {
    this.onMountedEvent(
      `on-${this.prefixName}-address-load`,
      this.onAddressLoad
    );
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      `on-${this.prefixName}-address-load`,
      this.onAddressLoad
    );
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    titleBox: {
      type: String,
      required: true,
    },
    prefixName: {
      type: String,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onActive() {
      this.currentFormData = JSON.parse(JSON.stringify(this.formData));
      this.currentCustomerLocation = JSON.parse(
        JSON.stringify(this.formData[`${this.prefixName}Location`])
      );
    },
    hasCustomer() {
      return (
        this.currentCustomerLocation && this.currentCustomerLocation.customer
      );
    },
    hasCustomerLocation() {
      return this.currentCustomerLocation && this.currentCustomerLocation.id;
    },
    onEdit() {
      this.isEditable = true;
      this.isEditableLoading = true;
    },
    onCancel() {
      this.isEditableLoading = true;
      setTimeout(() => {
        this.isEditable = false;
        this.isEditableLoading = false;
      }, 400);
    },
    onUpdateCustomer() {
        let data = this.formData[`${this.prefixName}Location`];
        let validationRequirements = this.getRequirements(data);

        if (validationRequirements.length === 0) {
            this.isEditableLoading = true;
            setTimeout(() => {
                this.viewModel.updateCustomerLocation(data); 
            }, 200);
        } else {
            this.onShowWarning(validationRequirements);
        }
    },
    onUpdateCustomerResponse() {
        this.isEditable = false;
      setTimeout(() => {
        this.isEditableLoading = false;
      }, 400);
    },
    onAddressLoad() {
      this.setField("mainStreet", this.currentCustomerLocation.mainStreet);
      this.setField(
        "secondaryStreet",
        this.currentCustomerLocation.secondaryStreet
      );
      this.setField("extNumber", this.currentCustomerLocation.extNumber || "");
      this.setField("intNumber", this.currentCustomerLocation.intNumber || "");
      this.onStopLoading();
    },
    setField(fieldName, fieldValue) {
      this.$set(
        this.formData[`${this.prefixName}Location`],
        fieldName,
        fieldValue
      );
    },
    onStopLoading() {
      setTimeout(() => {
        this.isEditableLoading = false;
      }, 400);
    },
    getRequirements(formData) {
      let result = [];

      if (!formData.country) {
        result.push("País requerido");
      }

      if (!formData.district) {
        result.push("Estado requerido");
      }

      if (!formData.municipality) {
        result.push("Municipio requerido");
      }

      if (!formData.city) {
        result.push("Ciudad requerido");
      }

      if (!formData.neighborhood) {
        result.push("Colonia requerido");
      }

      return result;
    },
  },
};
