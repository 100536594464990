export default class BranchOffice {
    constructor(options, state) {
      if (state) {
        this.state = state
      }
      Object.assign(this, options)
    }
  
    toSend() {
      return {
        id: this.id,
        code: this.code.trim(),
        description: this.description,
        quantity: this.quantity,
        amount: this.amount,
        amountReception: this.amountReception,
        isActive: this.isActive
      }
    }
  }