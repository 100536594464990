<script>
import ModalBillingComponent from "./ModalBillingComponent";

export default {
  components: {},
  extends: ModalBillingComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-billing"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-receipt" /> Facturación de Manifiestos
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs
        v-loading="isLoading"
        type="border-card"
      >
        <el-tab-pane>
          <span slot="label">Opciones</span>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                prop="billingType"
                required
              >
                <el-radio-group
                  v-model="formData.billingType"
                  @change="onChangeBillingType"
                >
                  <el-radio
                    v-for="item in billings"
                    :key="item.id"
                    :label="item.id"
                  >
                    <i :class="item.icon" /> {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
            v-if="formData.billingType === 2"
          >
            <el-col :span="12">
              <el-form-item label="Vehículo" prop="vehicle">
                <el-select
                  v-model="formData.vehicle"
                  placeholder="Buscar manifiesto..."
                  filterable
                  clearable
                  remote
                  :remote-method="onFindVehicles"
                  size="medium"
                  value-key="id"
                >
                  <el-option
                    v-for="item in vehicles"
                    :key="item.id"
                    :label="item.toCatalog()"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Figura de Transporte" prop="transportFigure">
                <el-select
                  v-model="formData.transportFigure"
                  placeholder="Buscar figura de transporte..."
                  filterable
                  clearable
                  remote
                  :remote-method="onFindTransportFigures"
                  size="medium"
                  value-key="id"
                >
                  <el-option
                    v-for="item in transportFigures"
                    :key="item.id"
                    :label="item.getFullName()"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row
            v-if="formData.billingType === 2"
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                label="Relación"
                prop="billRelation"
                required
              >
                <el-select
                  v-model="formData.billRelation"
                  placeholder="Buscar relación..."
                  filterable
                  clearable
                  remote
                  :remote-method="onFindBillRelations"
                  size="medium"
                >
                  <el-option
                    v-for="item in billRelations"
                    :key="item.id"
                    :label="item.toCatalog()"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                prop="shipment"
                label="Manifiesto"
              >
                <el-select
                  v-model="formData.shipment"
                  placeholder="Buscar manifiesto..."
                  filterable
                  clearable
                  remote
                  :remote-method="onFindShipments"
                  :disabled="!formData.billingType"
                  size="medium"
                >
                  <el-option
                    v-for="item in shipments"
                    :key="item.id"
                    :label="item.toBillingCatalog()"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            v-if="formData.billingType"
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="19" />
            <el-col
              :span="5"
              align="center"
            >
              <el-button
                type="primary"
                size="medium"
                class="w-100"
                @click="onSubmitForm('formData')"
              >
                <i class="uil-cloud-redo" /> {{ getSubmitLabel() }}
              </el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <!-- <el-button type="primary" size="small" @click="onSubmitForm('formData')"
        >Guardar</el-button
      > -->
    </span>
  </el-dialog>
</template>
