import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";
import BillConcept from "../../../core/features/billConcepts/BillConcept";

export default {
  extends: BaseForm,
  data() {
    return {
      conceptSelectedIndex: null,
      isLoadingTable: false,
      billConcepts: [],
      weightCostConfig: null,
    };
  },
  mounted() {
    this.onMountedEvent('on-discount-update', this.onDiscountUpdate)
  },
  beforeDestroy() {
      this.onBeforeDestroyEvent('on-discount-update', this.onDiscountUpdate)
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
    packages: {
      type: Array,
      required: true,
    },
    deliveryPriceCost: {
      type: Number,
      required: true,
    },
    reception: {
      type: Object,
      required: true,
    },
    canRecalculate: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  methods: {
    onActive() {
      this.viewModel.api.billConcepts
        .find(`Skip=0&Limit=1000000&IsActive=true`)
        .then((response) => {
          this.billConcepts = this.mapConcepts(response.data.data);
          return this.viewModel.api.weightCosts.find();
        })
        .then((response) => {
          if (response.data.data) {
            this.weightCostConfig = response.data.data[0];
            this.$set(
              this.formData,
              "extraWeightTax",
              this.weightCostConfig.extraWeightTax
            );
            this.$set(
              this.formData,
              "discountPercentage",
              this.reception.discountPercentage
            );
          } else {
            throw new Error("No hay parametros de peso configurados");
          }
        })
        .catch((error) => this.onError(error));
    },
    onAddConcept() {
      if(this.reception.documentReference) {
        this.isLoadingTable = true;
        setTimeout(() => {
          this.formData.addConcept();
          this.isLoadingTable = false;
        }, 400);
      } else {
        this.notifyWarning("Requiere la referencia de la guía fisica")
      }
    },
    onChangeConcept(item) {
      this.isLoadingTable = true;
      if (item && item.billConcept) {
        if (item.billConcept.isOverWeight) {
          this.onSetOverWeightConcept(item);
        } else {
          this.viewModel.nextTrackingNumber(
            this.reception.serviceCost.id,
            (response) => { this.onSetBillConcept(item, response) }
          );
        }
      } else {
        this.onClearConcept(item);
      }
    },
    onSetBillConcept(item, response) {
      setTimeout(() => {
        item.formatTransportation({
          template: item.billConcept.template,
          trackingNumber: response.trackingNumber,
          origin: response.origin,
          destination: response.destination,
          physicalTrackingNumber: this.reception.documentReference
        });

        item.calculateTransportation({
          qtyPackages: this.viewModel.getTotalPackages(this.packages),
          deliveryPrice: this.deliveryPriceCost,
        });

        this.$set(this.reception, "trackingNumber", response.trackingNumber);
        this.isLoadingTable = false;
      }, 400);
    },
    onSetOverWeightConcept(item) {
      setTimeout(() => {
        item.formatOverWeight({
          template: item.billConcept.template,
          overWeight: this.reception.totalOverWeight,
        });

        item.calculateOverWeight({
          qty: 1,
          overWeight: this.reception.totalOverWeight,
          extraWeightCost: this.weightCostConfig.extraWeightCost,
        });
        
        this.isLoadingTable = false;
      }, 400);
    },
    onClearConcept(item) {
      setTimeout(() => {
        item.reset();
        this.isLoadingTable = false;
      }, 400);
    },
    onChangeOption(option) {
      this.conceptSelectedIndex = option.index;
      if (option.command === "edit") this.onEditConcept(option.item);
      if (option.command === "delete") this.onDeleteConcept(option.index);
    },
    onEditConcept() {},
    onDeleteConcept(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isLoadingTable = true;
          setTimeout(() => {
            this.formData.removeConcept(index);
            this.isLoadingTable = false;
          }, 400);
        })
        .catch(() => {});
    },
    isConceptsAvailable() {
      return this.formData.saleConcepts.length > 0 && this.weightCostConfig;
    },
    getTotalPackages() {
      return this.viewModel.getTotalPackages(this.packages);
    },
    mapConcepts(collection) {
      return collection.map((item) => new BillConcept(item));
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    onDiscountUpdate(data) {
      this.$set(this.formData, "discountPercentage", data);
    }
  },
};
