<script>
import BaseComponent from "./BaseComponent";
import PageViewModel from "../../core/features/website/PagesViewModel";
export default {
  extends: BaseComponent,
  /*   components: {
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
  }, */
  data() {
    return {
      viewModel: null,
    };
  },
  beforeCreate() {

  },
  beforeDestroy() {
  },
  mounted() {
    this.viewModel = new PageViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onInitBackGroundInsert();
        //  this.onInitCounterUp();
    },
    onInitBackGroundInsert() {
      this.viewModel.initBackgroundInsert();
    },
    onInitCounterUp() {
      //this.viewModel.initCounterUp();
    },
  },
};
</script>

