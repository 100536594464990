import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import PlaceViewModel from '../../core/features/places/PlaceViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Place',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                destinationCode: '',
                name: '',
                country: '',
                district: '',
                municipality: '',
                status: '',
            },
            countries: [],
            districts: [],
            municipalities: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR LOCALIDADES',
                onUploadImport: 'on-upload-import-places',
                onCancelImport: 'on-cancel-import-places'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-place', this.onSearch)
        this.onMountedEvent('on-upload-import-places', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-places', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-place', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-places', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-places', this.onCancelImport);
    },
    created() {
        this.viewModel = new PlaceViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList();
        },
        onSelectPlace() { },
        onCreate(){
            this.item = this.viewModel.create({isActive: true})
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        onSearchByDestinationCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('DestinationCode', text)
            } else {
                this.removeFilter('DestinationCode')
            }
            this.onSearch();
        }, 500),
        onCountryChange(countryId) {
            console.log(countryId)
            this.districts = []
            this.municipalities = []
            this.filtersSelected.district = ''
            this.filtersSelected.municipality = ''
            if(countryId) {
                this.viewModel.findDistricts(`CountryId=${countryId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (countryId) ? this.addFilter('CountryId', countryId) : this.removeFilter('CountryId')
            this.onSearch();
        },
        onFindDistrictsResponse(response) {
            this.districts = this.viewModel.mapDistricts(response.data)
        },
        onDistrictChange(districtId) {
            this.municipalities = []
            if(!districtId) this.filtersSelected.municipality = '';
            if(districtId) {
                this.viewModel.findMunicipalities(`DistrictId=${districtId}&Skip=0&Limit=10000&IsActive=true`)
            }
            (districtId) ? this.addFilter('DistrictId', districtId) : this.removeFilter('DistrictId')
            this.onSearch();
        },
        onFindMunicipalitiesResponse(response) {
            this.municipalities = this.viewModel.mapMunicipality(response.data)
        },
        onMunicipalityChange(municipalityId) {
            (municipalityId) ? this.addFilter('MunicipalityId', municipalityId) : this.removeFilter('MunicipalityId')
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}