import BaseApi from '../../infraestructure/BaseApi'

export default class AuthorizationApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doLogin(account) {
        return this.client.post(`${this.endPoint}/login`, account)
    }

    findPermissionsByRole(roleId) {
        return this.client.get(`${this.endPoint}/permission/${roleId}`)
    }

    sendEmailRecovery(email) {
        return this.client.post(`${this.endPoint}/passwordrecovery-email`, email)
    }

    passwordRecoveryToken(data){
        return this.client.put(`${this.endPoint}/passwordrecovery-token`, data)
    }
}