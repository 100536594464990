<script>
import ModalWorkRoutesComponent from "./ModalWorkRoutesComponent";

export default {
  extends: ModalWorkRoutesComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-work-routes"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-map-pin-alt" /> Ruta de Trabajo
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="8">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              v-model="formData.code"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item
            label="Nombre"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="16">
          <el-form-item
            label="Sucursal"
            prop="branchOffice"
          >
            <el-select
              v-model="formData.branchOffice"
              placeholder="Seleccionar Sucursal"
              filterable
              clearable
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindBranchOffices"
            >
              <el-option
                v-for="item in branchOffices"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
