import GpsTrackingHelper from "../../helpers/GpsTrackingHelper";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Employee from "../employees/Employee";
import GpsLocation from "./GpsLocation";

export default class GpsTrackingViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  findCollection(filters) {
    this.api.deviceLocations
      .find(this.getQueryParameters(filters))
      .then((response) => this.view.onBindMapSuccess(response.data))
      .catch((error) => this.view.onBindMapError(error));
  }

  drawMap(collection) {
    setTimeout(() => {
      if (this.map) {
        GpsTrackingHelper.removeMap(this.map);
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      } else {
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      }

      GpsTrackingHelper.loadCopyRights(this.map);
      var customIcon = GpsTrackingHelper.createCustomIcon();
      collection.forEach((deviceLocation) => {
        GpsTrackingHelper.createMarker(
          this.map,
          customIcon,
          [deviceLocation.latitude, deviceLocation.longitude],
          deviceLocation.getMarkerContent()
        );
      });
      this.map.closePopup();
      this.view.isMapLoading = false;
    }, 400);
  }

  mapGpsLocation(collection) {
    return collection.map((item) => new GpsLocation(item));
  }

  allClearFilters() {
    this.view.filtersSelected = {
      description: "",
      operator: "",
      mapTrackingDate: "",
    };

    this.view.removeFilter("Description");
    this.view.removeFilter("EmployeeProfileId");
    this.view.removeFilter("StartDate");
    this.view.removeFilter("EndDate");
    this.view.onFilter();
  }

  bindList() {
    this.api.employees
      .find(`isTransportFigure=trueSkip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.operators = this.mapEmployees(response.data.data);
        setTimeout(() => {
          this.view.isListLoading = false;
        }, 400);
      })
      .catch(this.view.onError);
  }

  //#region Querys
  findOperator(criteria) {
    this.api.employees
      .find(criteria)
      .then((response) => this.view.onFindOperatorResponse(response.data))
      .catch(this.view.onError);
  }
  //#endregion

  //#region

  mapEmployees(collection) {
    return collection.map((item) => new Employee(item));
  }
  //#endregion
}
