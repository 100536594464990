export default class ReceptionCustomer {
  constructor(options, state) {
    if (state) {
      this.state = state
    }
    Object.assign(this, options)
  }

  toSend() {
    return {
      description: this.description,
      fiscalCode: this.fiscalCode,
      fiscalName: this.fiscalName,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      discountPercentage: this.discountPercentage,
      taxRegimeId: (this.taxRegime) ? this.taxRegime.id : null,
      fiscalPostalCodeId: (this.fiscalPostalCode) ? this.fiscalPostalCode.id : null,
      address: {
        description: this.address.description,
        mainStreet: this.address.mainStreet,
        secondaryStreet: this.address.secondaryStreet,
        extNumber: this.address.extNumber,
        intNumber: this.address.intNumber,
        postalCodeId: ( this.address.postalCode) ? this.address.postalCode.id:  null,
        neighborhoodId: (this.address.neighborhood) ? this.address.neighborhood.id : null,
        cityId: ( this.address.city) ? this.address.city.id:  null,
        municipalityId: (this.address.municipality) ? this.address.municipality.id : null,
        districtId: ( this.address.district) ? this.address.district.id:  null,
        countryId: (this.address.country) ? this.address.country.id : null,
      }
    }
  }
}