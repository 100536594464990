import _ from 'lodash'
import BaseViewModel from "../../infraestructure/BaseViewModel";
import TaxRegime from '../taxRegimes/TaxRegime'
import PostalCode from '../postalCodes/PostalCode'

export default class OrderCustomerViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    save(data) {
        this.api.orders
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveResponseError);
    }

    async bindForm() {
        try {
            const fiscalRegimesResponse = await this.api.taxRegimes.find(`Skip=0&Limit=1000&IsActive=true`)
            this.view.taxRegimes = this.mapTaxRegimes(fiscalRegimesResponse.data.data)
        } catch (error) {
            this.view.onError(error)
        }
    }

    verifyRFC(value, callback) {
        let rfcRegex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
        if(value.match(rfcRegex)) {
          var defaultFiscalCodes = ["XAXX010101000", "XEXX010101000"];
          if (_.includes(defaultFiscalCodes, value)) {
            callback({
              isValid: true,
              message: "Es codigo es generico es valido.",
            });
          } else {
            this.api.bills
              .verifyRfc(value)
              .then((response) => {
                callback({
                  isValid: response.data.isValid,
                  message: response.data.message,
                });
              })
              .catch((error) => {
                callback({
                  isValid: false,
                  message: error.message,
                });
              });
          }
        } else {
          callback({
            isValid: false,
            message: "RFC no valido.",
          });
        }
        
      }
    
      verifyCustomer(criteria, callback) {
        this.api.customers
          .verify(criteria)
          .then((response) => {
            callback({
              isValid: response.data.data,
              message: response.data.message,
            });
          })
          .catch((error) => {
            callback({
              isValid: false,
              message: error.message,
            });
          });
      }
    
      mapTaxRegimes(collection) {
        return collection.map((item) => new TaxRegime(item));
      }

      mapPostalCodes(collection) {
        return collection.map((item) => new PostalCode(item));
      }
    
      findPostalCodes(criteria) {
        this.api.postalCodes.find(criteria)
          .then((response) => this.view.onFindPostalCodesResponse(response.data))
          .catch(this.view.onError)
      }
}
