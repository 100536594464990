import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import BillConceptViewModel from '../../core/features/billConcepts/BillConceptViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'BillConcept',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                billConceptKey: '',
                measureUnit:'',
                status: '',
            },
            measureUnits: [],
            billConceptKeys: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR CONCEPTOS DE FACTURA',
                onUploadImport: 'on-upload-import-bill-concepts',
                onCancelImport: 'on-cancel-import-bill-concepts'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-bill-concept', this.onSearch)
        this.onMountedEvent('on-upload-import-bill-concepts', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-bill-concepts', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-bill-concept', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-bill-concepts', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-bill-concepts', this.onCancelImport);
    },
    created() {
        this.viewModel = new BillConceptViewModel(this)
        this.onActive()
    },

    methods: {
        onActive() {
            this.onSearch()
        },
        onSelectBillConcept() { },
        onCreate(){
            this.item = this.viewModel.create({  isActive: true})
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        searchBillConceptKeyFilter(data){
            if(data) {
                this.addFilter('BillConceptKeyId', data.id)
            } else {
                this.removeFilter('BillConceptKeyId')
            }
            this.onSearch()
        },
        onFindBillConceptKeys(query) {
            this.billConceptKeys = []
            if(query)
                this.viewModel.findBillConceptKeys(`FullSearch=${query}&Skip=0&Limit=100000&IsActive=true`)
        },
        onBillConceptKeysResponse(response) {
            this.billConceptKeys = this.viewModel.mapBillConceptKeys(response.data)
        },
        searchMeasureUnitIdFilter(data){
            if(data) {
                this.addFilter('MeasureUnitId', data.id)
            } else {
                this.removeFilter('MeasureUnitId')
            }
            this.onSearch()
        },
        onFindMeasureUnit(data){
            if(data) {
                this.viewModel.findMeasureUnits(`Name=${data}`)
            } else {
                this.measureUnits = []
            }
        },
        onMeasureUnitsResponse(response){
            this.measureUnits = response.data
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}