import BaseForm from "../infraestructure/BaseForm";
import BaseList from "../infraestructure/BaseList";
import CustomerReceptionSummaryOfShipmentsViewModel from "../../core/features/customersReceptions/CustomerReceptionSummaryOfShipmentsViewModel";
import moment from "moment";

export default {
  extends: BaseForm,
  mixins: [BaseList],
  data() {
    return {
      viewModel: null,
      rules: { },
      searchReceptionNumber: "",
      searchOriginName:"",
      searchDestinyName:"",
      searchSenderPlace: "",
      searchReceiverPlace:"",
      searchBeginingDate: "",
      collection:[],
      formData: null,
      customerId:null,
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    // formData: {
    //   type: Object,
    //   required: false,
    // },
  },
  created() {
    this.viewModel = new CustomerReceptionSummaryOfShipmentsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive(){
        this.customerId = this.$store.getters["admin/getUserActive"].id;
        this.onSearch();
    },
    onSearch(){
        this.defaultFilters();
        this.isListLoading = true;
        setTimeout(() => {
            this.viewModel.findCollection(this.customerId, this.filters);
        }, 400);
    },
    onExport(){
        this.isListLoading = true;
        this.viewModel.export(this.filters);
    },
    searchReceptionNumberFilter(text) {
        if (text) {
            this.addFilter("TrackingNumber", text);
        }else {
            this.removeFilter("TrackingNumber");
        }
        this.onSearch();
    },
    searchOriginNameFilter(text){
        if (text) {
            this.addFilter("OriginName", text);
        } else {
            this.removeFilter("OriginName");
        }
        this.onSearch();
    },
    searchDestinyNameFilter(text){
        if (text) {
            this.addFilter("DestinyName", text);
        } else {
            this.removeFilter("DestinyName");
        }
        this.onSearch();
    },
    searchSenderPlaceFilter(text) {
        if (text) {
            this.addFilter("SenderPlace", text);
        } else {
            this.removeFilter("SenderPlace");
        }
        this.onSearch();
    },
    searchReceiverPlaceFilter(text) {
        if (text) {
            this.addFilter("ReceiverPlace", text);
        } else {
            this.removeFilter("ReceiverPlace");
        }
        this.onSearch();
    },
    searchBeginingDateFilter(text) {
        if (text) {
            this.addFilter(
                "BeginingDate",
                moment(text[0]).format("YYYY-MM-DD")
              );
              this.addFilter(
                "EndingDate",
                moment(text[1]).format("YYYY-MM-DD")
              );
            } else {
              this.removeFilter("BeginingDate");
              this.removeFilter("EndingDate");
            }
        this.onSearch();
    },
    onFormatMoney(value) {
        return this.viewModel.formatMoney(value);
      },
  },
};