import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type)
    },
    onGetPayerResponsible(payerResponsible) {
      if(payerResponsible === "sender") return "Remitente"
      if(payerResponsible === "receiver") return "Destinatario"
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status)
    },
    hasSenderCustomer(receptionData) {
      return (
        receptionData.senderLocation && receptionData.senderLocation.customer
      );
    },
    hasSenderCustomerLocation(receptionData) {
      return (
        receptionData.senderLocation && receptionData.senderLocation.mainStreet
      );
    },
    hasReceiverCustomer(receptionData) {
      return (
        receptionData.receiverLocation &&
        receptionData.receiverLocation.customer
      );
    },
    hasReceiverCustomerLocation(receptionData) {
      return (
        receptionData.receiverLocation &&
        receptionData.receiverLocation.mainStreet
      );
    },
  },
};
