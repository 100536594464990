import BaseList from '../infraestructure/BaseList'
import CustomersAccountViewModel from "../../core/features/customerAccount/CustomersAccountViewModel";



export default {
    extends: BaseList,
    data() {
        return {
            viewModel: null,
            customerId: null,
            searchUserName: '',
            searchName: '',
            searchEmail: '',
            searchRoleName: '',
            roles: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR USUARIOS',
                onUploadImport: 'on-upload-import-customer-user',
                onCancelImport: 'on-cancel-import-customer-user'
            }

        }
    },
    mounted() {
        this.onMountedEvent('on-submited-customer-user', this.onActive);
        this.onMountedEvent('on-upload-import-customer-user', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-customer-user', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-customer-user', this.onActive);
        this.onBeforeDestroyEvent('on-upload-import-customer-user', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-customer-user', this.onCancelImport);
    },
    created() {
        this.viewModel = new CustomersAccountViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormAsync(this.$store.getters['admin/getUserActive'].email)
            // this.customerId = this.$store.getters['admin/getUserActive'].id;
            // console.log(this.$store.getters['admin/getUserActive'])
            
        },
        onSelectUser() { },
        onCreate() {
            this.item = this.viewModel.create({
                customerId: this.customerId,
                isActive: true,
            })
            this.modalConfig.isVisible = true
        },
        searchUserNameFilter(){
            this.addFilter('UserName',this.searchUserName)
            this.onSearch()
        },
        searchNameFilter() {
            this.addFilter('Name', this.searchName)
            this.onSearch();
        },
        searchEmailFilter() {
            this.addFilter('Email', this.searchEmail)
            this.onSearch();
        },
        searchRoleNameFilter() {
            this.addFilter('RoleName', this.searchRoleName)
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch();
        },
        onSearch() {
            this.defaultFilters();
            this.isListLoading = true;
            setTimeout(() => {
                this.viewModel.findCollection(this.customerId, this.filters);
            }, 400)
        },
        onChangeOptionCustomerAccounts(event) {
            if (event.command === "edit")
                this.onEditCustomerAccounts(event.customerId, event.item.id);
            if (event.command === "delete")
                this.onDeleteCustomerAccounts(event.customerId, event.item.id);
        },
        onEditCustomerAccounts(customerId, id) {
            this.viewModel.findItem(customerId, id);
        },
        onFindItemResponseCustomerAccounts(response, customerId) {
            this.item = this.viewModel.edit(response.data, customerId);
            this.modalConfig.isVisible = true;
        },
        onDeleteCustomerAccounts(customerId, id) {
            this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.viewModel.delete(customerId, id);
                })
                .catch(() => { });
        },
        onDeleteResponseCustomerAccounts(response) {
            this.notifySuccess(response.message);
            this.onSearchCustomerLocation();
        },
    }
}
