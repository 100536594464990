import BaseForm from "../../infraestructure/BaseForm";
import SaleViewModel from "../../../core/features/sales/SaleViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      itemReceptionPackage: null,
      receptionSaleIndex: null,
      receptionPackageSelectedIndex: null,
      modalReceptionPackageConfig: {
        isVisible:false,
        isLoading: false
      },
      isTableLoading: false
    };
  },
  created() {
    this.viewModel = new SaleViewModel(this);
    this.onActive();
  },
  mounted() {
    this.onMountedEvent("on-submited-reception-packages", this.onUpsertReceptionPackage);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-reception-packages", this.onUpsertReceptionPackage);
  },
  props: {
    receptions: {
      type: Array,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
    currencies: {
      type: Array,
      required: true,
    }
  },
  watch: {
    
  },
  methods: {
    onActive() {
      // console.log("onActive", this.receptions);
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    onChangeOptionReceptionPackage(event) {
      if (event.command === "edit") this.onEditReceptionPackage(event);
      if (event.command === "delete") this.onDeleteReceptionPackage(event);
    },
    onEditReceptionPackage(event) {
      this.isTableLoading = true;
      setTimeout(() => {
        this.itemReceptionPackage = this.viewModel.editReceptionPackage(event.item);
        this.itemReceptionPackage.serviceDeliveryPrice = event.item.serviceDeliveryPrice;
        this.receptionPackageSelectedIndex = event.index;
        this.receptionSaleIndex = event.receptionSaleIndex;
        this.modalReceptionPackageConfig.isVisible = true;
        this.isTableLoading = false;
      }, 400);
    },
    onDeleteReceptionPackage(event) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isTableLoading = true;
          setTimeout(() => {
            this.viewModel.deleteReceptionPackage(this.formData.receptionPackages, event.index);
            this.isTableLoading = false;
          }, 400);
        })
        .catch(() => {});
    },
    onUpsertReceptionPackage(data) {
      this.isTableLoading = true;
      setTimeout(() => {
        this.$set(this.receptions[this.receptionSaleIndex].receptionPackages, this.receptionPackageSelectedIndex, data)
        this.isTableLoading = false;
      }, 400);
    }
  },
};
