import BasePage from '../../infraestructure/BasePage'

export default {
    extends: BasePage,
    created() {

    },
    methods: {
        UI() {
            
        }
    }
}