<script>
import CustomerBoxComponent from "./CustomerBoxComponent";
import AddressBoxView from "../../shared/boxes/addressBox/AddressBoxView";
import ModalReceptionsCustomerView from "../ModalReceptionsCustomerView";


export default {
  components: {
    AddressBoxView,
    "modal-receptions-customer-view": ModalReceptionsCustomerView,
  },
  extends: CustomerBoxComponent,
};
</script>

<template>
  <div v-loading="isLoading">
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="17">
        <div><h4>{{ title }}</h4></div>
      </el-col>
      <el-col :span="7">
        <div class=" pt-3">
          <el-button
            type="primary"
            size="small"
            class="main-action"
            @click="onRegisterCustomer"
          >
            <i class="fas fa-plus-circle" /> Registrar Cliente
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-tabs type="border-card">
      <el-tab-pane label="Información General">
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Cliente"
              :prop="`${propName}.customer`"
            >
              <el-select
                v-model="formData.customer"
                placeholder="Seleccionar"
                filterable
                clearable
                debounce
                value-key="id"
                remote
                size="medium"
                :remote-method="onFindCustomers"
                :disabled="!isCustomerEditable()"
                @change="onChangeCustomer"
              >
                <el-option
                  v-for="item in customers"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="15">
            <el-form-item
              label="Nombre"
              required
              :prop="`${propName}.name`"
            >
              <el-input
                v-model="formData.name"
                autocomplete="off"
                autofocus="true"
                size="medium"
                :disabled="!isCustomerEditable()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              label="Teléfono"
              :prop="`${propName}.phone`"
              required
            >
              <el-input
                v-model="formData.phone"
                autocomplete="off"
                autofocus="true"
                size="medium"
                :disabled="!isCustomerEditable()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Correo Electrónico"
              :prop="`${propName}.email`"
            >
              <el-input
                v-model="formData.email"
                autocomplete="off"
                autofocus="true"
                size="medium"
                :disabled="!isCustomerEditable()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Referencia/Observación"
              :prop="`${propName}.observation`"
            >
              <el-input
                v-model="formData.observation"
                autocomplete="off"
                autofocus="true"
                type="textarea"
                size="medium"
                :rows="4"
                :disabled="!isCustomerEditable()"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane
        v-if="isCustomerEditable()"
        label="Dirección"
      >
        <address-box-view
          v-if="formData"
          :form-data="formData"
          orientation="vertical"
          :prop-name="`${propName}`"
          :customer="formData.customer"
        />
      </el-tab-pane>
    </el-tabs>
    <modal-receptions-customer-view
      v-if="modalCustomerConfig.isVisible"
      :form-data="itemCustomer"
      :config="modalCustomerConfig"
      :customer-type="propName"
    />
  </div>
</template>
