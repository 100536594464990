export default class ReceptionPackage {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
  
    
    toSend() {
      return {
        id: this.id,
        description: this.description,
        length: this.length,
        weight: this.weight,
        width: this.width,
        height: this.height,
        quantity: this.quantity,
        volumetricWeight: this.volumetricWeight,
        subtotal: this.subtotal,
        serviceDeliveryPrice: this.serviceDeliveryPrice,
        measureUnit: this.measureUnit,
        productType: this.productType
      }
    }
  }