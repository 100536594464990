import _ from 'lodash'
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Employee from "./Employee";

export default class EmployeesViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  create(data) {
    return new Employee(data, "create");
  }

  edit(data) {
    return new Employee(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.employees
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.employees
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(id) {
    this.api.employees
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.employees
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.employees
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  import(file) {
    this.api.employees
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      employeeCode: "",
      name: "",
      userName: "",
      email: "",
      roleName: "",
      status: "",
    };

    this.view.removeFilter("EmployeeCode");
    this.view.removeFilter("Name");
    this.view.removeFilter("UserName");
    this.view.removeFilter("Email");
    this.view.removeFilter("RoleName");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  bindForm() {
    this.view.config.isLoading = true;
    this.api.branchOffices
      .find("Skip=0&Limit=10000&IsActive=true")
      .then((response) => {
        this.view.branchOffices = response.data.data;
        return this.api.transportFigureTypes.find(
          "Skip=0&Limit=10000&IsActive=true"
        );
      })
      .then((response) => {
        this.view.figureTransportTypes = response.data.data;
        return this.api.roles.find("Skip=0&Limit=10000&IsActive=true");
      })
      .then((response) => {
        this.view.roles = response.data.data;
        setTimeout(() => {
          this.view.config.isLoading = false;
        }, 400);
      })
      .catch(this.view.onError);
  }

  bindList() {
    this.api.roles
      .find(`Skip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.roles = response.data.data;
      })
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new Employee(item));
  }

  findRole() {
    this.api.roles
      .find()
      .then((response) => this.view.onFindRoleResponse(response.data))
      .catch(this.view.onError);
  }

  findRoles(criteria) {
    this.api.roles
      .find(criteria)
      .then((response) => this.view.onFindRoleResponse(response.data))
      .catch(this.view.onError);
  }

  findBranchOffices(criteria) {
    this.api.branchOffices
      .find(criteria)
      .then((response) => this.view.onFindBranchOfficesResponse(response.data))
      .catch(this.view.onError);
  }

  //#region VALIDATIONS

  verifyRFC(value, callback) {
    var defaultFiscalCodes = ["XAXX010101000", "XEXX010101000"];
    if (_.includes(defaultFiscalCodes, value)) {
      callback({
        isValid: true,
        message: "Es codigo es generico es valido.",
      });
    } else {
      this.api.bills
        .verifyRfc(value)
        .then((response) => {
          callback({
            isValid: response.data.isValid,
            message: response.data.message,
          });
        })
        .catch((error) => {
          callback({
            isValid: false,
            message: error.message,
          });
        });
    }
  }

  //#endregion
}
