<script>
import AdminHeaderComponent from "./AdminHeaderComponent";

export default {
  extends: AdminHeaderComponent,
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link
            :to="'/admin/resumen'"
            class="logo logo-dark"
            title="Resumen"
          >
            <span class="logo-sm">
              <img
                :src="require('@/assets/dashboard/assets/images/logo-small.png')"
                alt=""
              >
              
            </span>
            <span class="logo-lg">
              <img
                src="assets/images/logo.svg"
                alt=""
                height="20"
              >
            </span>
          </router-link>
          <!-- 
          <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo.svg" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo.svg" alt="" height="20" />
            </span>
          </a> -->
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars" />
        </button>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            id="page-header-user-dropdown"
            type="button"
            class="btn header-item waves-effect"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click.capture="toggleSubMenu('page-header-user-dropdown')"
          >
            <img
              class="rounded-circle header-profile-user"
              :src="require('@/assets/dashboard/assets/images/users/user.png')"
              alt="User Avatar"
            >
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
            > {{ this.$store.state.admin.userCurrent.name
            }}</span>
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15" />
          </button>
          <div
            class="dropdown-menu dropdown-menu-end"
            :class="{'show' : show}"
          >
            <!-- item-->
            <a
              class="dropdown-item"
              href="#"
              @click="onEndSession"
            ><i
               class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
             />
              <span class="align-middle">Cerrar sesión</span></a>
          </div>
        </div>

      <!--   <div class="dropdown d-inline-block">
          <div class="d-flex align-items-center">
            <div class="dropdown d-none d-lg-inline-block my-4">
              <img
                class="rounded-circle header-profile-user"
                :src="
                  require('@/assets/dashboard/assets/images/users/user.png')
                "
                alt="User Avatar"
              />
              <el-dropdown>
                <span
                  class="el-dropdown-link d-none d-xl-inline-block ms-1 fw-medium font-size-15 "
                >
                  {{ this.$store.state.admin.userCurrent.name
                  }}<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <i
                      class="uil uil-user-circle font-size-18 align-middle text-muted me-1"
                    />
                    Ver Perfil
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="onEndSession">
                      <i
                        class="uil uil-sign-out-alt font-size-18 align-middle text-muted me-1"
                      />
                      Cerrar sesión
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </header>
</template>
