import currency from 'currency.js'
export const MXN = value => currency(value, {
    symbol: '$',
    decimal: '.',
    separator: ',',
    precision: 2
});

export const Money = value => currency(value, {
    decimal: '.',
    separator: ',',
    precision: 2
});
