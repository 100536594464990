import BaseForm from '../infraestructure/BaseForm'
import CollectionRouteViewModel from '../../core/features/collectionRoutes/CollectionRouteViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'CollectionRoute',
            viewModel: null,
            workRoutes: [],
            employees: [],
            rules:{
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CollectionRouteViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-collection-route')
        },
        onFindWorkRoutes(data) {
            this.workRoutes = []
            if (data) {
                this.viewModel.findWorkRoutes(`Name=${data}&IsActive=true`)
            }
        },
        onFindWorkRoutesResponse(response) {
            this.workRoutes = this.viewModel.mapWorkRoutes(response.data)
        },
        onFindEmployees(data) {
            this.employees = []
            if (data) {
                this.viewModel.findEmployees(`Name=${data}&RoleName=${"chofer repartidor"}&IsActive=true`)
            }
        },
        onFindEmployeesResponse(response) {
            this.employees = this.viewModel.mapEmployees(response.data)
        },
        onWorkRoutesChange(data) {
            if (data) {
                if (!this.viewModel.verifyWorkRouteExistence(this.formData.workRoutes, data)) {
                    this.viewModel.addWorkRoute(this.formData.workRoutes, data)
                }
                this.formData.workRoute = ''
            }
        },
        onDeleteWorkRoute(index) {
            if(this.formData.state === 'update') {
                this.viewModel.deleteWorkRoute(this.formData.workRoutes, index)
            } else {
                this.formData.workRoutes.splice(index, 1);
            }
        },
        onFilterWorkRoutes(collection) {
            return this.viewModel.filterWorkRoutes(collection)
        }
    }
}