<script>
import ListComponent from "./ListComponent";
import ModalBillingGroupView from "./ModalView"

export default {
  components: {
    ModalBillingGroupView
  },
  extends: ListComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div class="col-2">
                  <div id="entradas" class="mb-3">
                    <span class="mx-2">Mostrar</span>
                    <el-select v-model="defaultShowEntry" placeholder="Opción" size="small" @change="onChangeEntries">
                      <el-option v-for="item in showEntries" :key="item.value" :label="item.label"
                        :value="item.value" />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-2" />
                <!-- <div class="col-1 me-5" style="margin-top: 5px;">
                  <el-checkbox v-model="isAutoDigitalInvoice" @change="onChangeAutoDigitalInvoice">
                    Facturación Automática
                  </el-checkbox>
                </div>-->
                <div class="col-5" align="right">
                  <div id="buttonlist">
                    <el-button type="danger" class="filter-btn" icon="fa fa-filter" size="medium"
                      @click="onMoreFilters">
                      Filtrar por:
                    </el-button>
                    <!--<el-dropdown size="medium" class="more-options-action">
                      <el-button type="plain" size="medium">
                        <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="my-2">
                          <el-button size="medium" class="w-100 text-start" :disabled="!onCanRead(modulePermissions)"
                            @click="onShowBillingGroup">
                            <i class="uil uil-invoice" /> Facturación Por Cliente
                          </el-button>
                        </el-dropdown-item>

                        <el-dropdown-item class="my-2" align="center">
                          <el-button size="medium" class="w-100 text-start" @click="onShowBilling">
                            <i class="uil-receipt" /> Facturación De Manifiestos
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            @click="onExport"
                          >
                            <i class="uil-comparison" /> Reportes de Facturación
                          </el-button>
                        </el-dropdown-item>

                        <el-dropdown-item class="my-2">
                          <el-button size="medium" class="w-100 text-start" :disabled="!onCanRead(modulePermissions)"
                            @click="onOpenFinancialHistory">
                            <i class="uil uil-invoice" /> Resumen de Financiero
                            de Servicios
                          </el-button>
                        </el-dropdown-item>


                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            :disabled="!onCanRead(modulePermissions)"
                            @click="onRestructureLocations"
                          >
                            <i class="el-icon-refresh" /> Restructurar Direcciones
                          </el-button>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown> -->
                    <el-button v-if="onUserPermission('Permission.Sale.Show')" type="primary" size="medium"
                      class="main-action" @click="onCreate" style="margin-left: 0px;">
                      <i class="fas fa-plus-circle" /> Crear Grupo de Facturación
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table ref="multipleTable" v-loading="isListLoading" class="table table-centered table-nowrap mb-0"
                :data="collection" style="width: 100%" @selection-change="onSelectSale">
                <el-table-column type="selection" width="45" />
                <el-table-column property="groupCode" label="No. Grupo" width="140" align="center" />
                <el-table-column label="Manifiesto" width="140" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.shipment.shipmentNumber }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Origen"
                >
                  <template slot-scope="scope">
                    {{ scope.row.reception.senderPlace.name }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Destino"
                >
                  <template slot-scope="scope">
                    {{ scope.row.reception.receiverPlace.name }}
                  </template>
                </el-table-column>
                <el-table-column label="Cliente">
                  <template slot-scope="scope">
                    {{ scope.row.customer.description }}
                  </template>
                </el-table-column>
                <el-table-column label="Total" width="140">
                  <template slot-scope="scope">
                    {{ scope.row.total | formatCurrency }}
                  </template>
                </el-table-column>
               
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table :data="props.row.receptions" style="width: 100%" height="200px">
                      <el-table-column label="No. Guía" width="180">
                        <template slot-scope="item">
                          {{ item.row.reception.trackingNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Origen" width="200">
                        <template slot-scope="item">
                          {{ item.row.reception.senderPlace.name }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Destino" width="200">
                        <template slot-scope="item">
                          {{ item.row.reception.receiverPlace.name }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Remitente">
                        <template slot-scope="item">
                          {{ item.row.reception.senderName }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Destinatario">
                        <template slot-scope="item">
                          {{ item.row.reception.receiverName }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Subtotal" width="200">
                        <template slot-scope="item">
                          {{ item.row.sale.total | formatCurrency }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column label="Opciones" width="120" align="center" show-overflow-tooltip>
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!-- <el-dropdown-item
                            :command="{ command:'do-bill',item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Generar Factura
                          </el-dropdown-item> -->
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.saleBill)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'download-pdf',
                              item: scope.row
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Factura Pdf
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.saleBill)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'download-xml',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-xml" /> Factura Xml
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.saleBill)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'download-excel',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-excel-outline" /> Descargar Excel
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination :page-count="pagination.totalPages" :current-page.sync="currentPage"
                    layout="prev, pager, next" @current-change="onPageChange" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalBillingGroupView v-if="modalBillingGroup.isVisible" :config="modalBillingGroup" />
    <el-drawer custom-class="filters-drawer" :visible.sync="isMoreFilterVisible" direction="rtl" size="20%">
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters">
          Borrar todos los filtros
        </el-button>
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Folio:</span>
            <el-input v-model="filtersSelected.folNumber" type="text" placeholder="Buscar por folio" clearable
              size="small" @input="onSearchByFolNumber" />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Guía:</span>
            <el-input v-model="filtersSelected.receptionNumber" type="text" placeholder="Buscar por Guía" clearable
              size="small" @input="onSearchByReceptionNumber" />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-tabs v-model="activeName">
            <el-tab-pane label="Buscar por Total Mínimo y Máximo" name="total">
              <el-slider v-model="filtersSelected.total" class="px-2" range :max="maxTotal"
                :format-tooltip="formatTooltip" @change="searchTotalFilter" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker v-model="filtersSelected.saleDate" type="daterange" size="small" clearable
              range-separator="-" start-placeholder="F. Inicio" end-placeholder="F. Fin" value-format="yyyy-MM-dd"
              @input="searchSaleDateFilter" />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Tipo de pago:</span>
            <el-select v-model="filtersSelected.paymentType" placeholder="Seleccionar" :popper-append-to-body="false"
              clearable @change="searchpaymentTypeFilter">
              <el-option v-for="item in paymentTypeStatus" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Estado de Factura:</span>
            <el-select v-model="filtersSelected.billStatus" placeholder="Seleccionar" :popper-append-to-body="false"
              clearable @change="serachBillStatusFilter">
              <el-option v-for="item in saleBillStatus" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
