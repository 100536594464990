import BaseViewModel from "../../infraestructure/BaseViewModel";
import CustomerContact from "./CustomerContact";

export default class CustomerContactViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  //#region MODULO CLIENTES

  create(data) {
    return new CustomerContact(data, "create");
  }

  edit(data, customerId) {
    let item = new CustomerContact(data, "update");
    item.customerId = customerId
    return item;
  }

  save(data) {
    if (data.state === "create") {
      this.api.customersContact
        .create(data.customerId, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.customersContact
        .update(data.customerId, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(customerId, id) {
    this.api.customersContact
      .delete(customerId, id)
      .then((response) => this.view.onDeleteResponseCustomerContact(response.data, customerId))
      .catch(this.view.onError);
  }

  findCollection(customerId, filters) {
    this.api.customersContact
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(customerId, id) {
    this.api.customersContact
      .findOne(customerId, id)
      .then((response) =>
        this.view.onFindItemResponseCustomerContact(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  bindForm(formData) {
    if (formData.state === "update") {
      this.view.destinations = (formData.place) ? [formData.place] : []
      this.view.neighborhoods = (formData.neighborhood) ? [formData.neighborhood] : []
      this.view.destinations = (formData.city) ? [formData.city] : []
    }
  }

  import(file) {
    this.api.customerLocation
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new CustomerContact(item));
  }

  findCustomerByFiscalSearch(query) {
    this.api.customers.findByFiscal(query)
      .then((response) => this.view.onFindCustomerByFiscalSearchResponse(response.data))
      .catch(this.view.onFindCustomerByFiscalSearchError)
  }

  export() {
    this.api.customersContact
        .exportToExcel(this.view.$store.getters["admin/getUserActive"].id,this.getQueryParameters(this.view.filters))
        .then((response) => this.view.onExportExcelResponse(response.data))
        .catch(this.view.onError);
  }
}
