var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-menu"},[_c('div',{staticClass:"navbar-brand-box"},[_c('router-link',{staticClass:"logo logo-dark",attrs:{"to":'/admin/resumen',"title":"Resumen"}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"/admin/assets/images/logo-small.png","alt":""}})]),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"/admin/assets/images/logo.png","alt":""}})])]),_c('a',{staticClass:"logo logo-dark",attrs:{"href":"index.html"}})],1),_vm._m(0),_c('vue-simplebar',{staticClass:"sidebar-menu-scroll"},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"menu-title"},[_vm._v(" Menu ")]),(_vm.onCanShow('AdminDashboard'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/resumen',"title":"Resumen"}},[_c('i',{staticClass:"uil-home-alt"}),_c('span',[_vm._v("Resumen")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'Reception',
              'Delivery',
              'Tracking',
              'CollectionRouteTrace',
              'Order' ])
          )?_c('li',{staticClass:"menu-title",on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_vm._v(" Paquetería ")]):_vm._e(),(
            _vm.canShowMultiple([
              'Reception',
              'CollectionRouteTrace' ])
          )?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/mostrador',"title":"Mostrador"}},[_c('i',{staticClass:"uil-qrcode-scan"}),_c('span',[_vm._v("Mostrador")])])],1):_vm._e(),(_vm.onCanShow('Order'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/ordenes',"title":"Ordenes"},on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('i',{staticClass:"uil-location-pin-alt"}),_c('span',[_vm._v("Ordenes")])])],1):_vm._e(),(_vm.onCanShow('Delivery'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/manifiestos',"title":"Manifiestos"}},[_c('i',{staticClass:"uil-parcel"}),_c('span',[_vm._v("Manifiestos")])])],1):_vm._e(),(_vm.onCanShow('Tracking'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/guias-generadas',"title":"Guías Generadas"},on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('i',{staticClass:"uil-crosshair"}),_c('span',[_vm._v("Guías Generadas")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'Sale',
              'ServiceDeliveryPrice',
              'PrepaidPlan' ])
          )?_c('li',{staticClass:"menu-title",on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_vm._v(" Ventas y Servicios ")]):_vm._e(),(_vm.onCanShow('Sale'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/ventas-y-carta-porte',"title":"Ventas y Carta Porte"}},[_c('i',{staticClass:"uil-shopping-cart"}),_c('span',[_vm._v("Ventas y Carta Porte")])])],1):_vm._e(),(_vm.onCanShow('ServiceDeliveryPrice'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/costos-de-servicios',"title":"Costos de Servicios"}},[_c('i',{staticClass:"uil-bill"}),_c('span',[_vm._v("Costos de Servicios")])])],1):_vm._e(),(_vm.onCanShow('PrepaidPlan'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/planes-de-prepago',"title":"Planes de Prepago"}},[_c('i',{staticClass:"uil-file-contract-dollar"}),_c('span',[_vm._v("Planes de Prepago")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'Customer',
              'CustomerSolvency' ])
          )?_c('li',{staticClass:"menu-title",on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_vm._v(" Nuestros Clientes ")]):_vm._e(),(_vm.onCanShow('Customer'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/clientes',"title":"Clientes"}},[_c('i',{staticClass:"uil-comment-notes"}),_c('span',[_vm._v("Clientes")])])],1):_vm._e(),(_vm.onCanShow('CustomerSolvency'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/creditos-y-prepago',"title":"Creditos y Prepago"}},[_c('i',{staticClass:"uil-ticket"}),_c('span',[_vm._v("Creditos y Prepago")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'Employee',
              'User',
              'Role' ])
          )?_c('li',{staticClass:"menu-title",on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_vm._v(" Autorización ")]):_vm._e(),(_vm.onCanShow('Employee'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/autorizacion/empleados',"title":"Empleados"}},[_c('i',{staticClass:"uil-head-side"}),_c('span',[_vm._v("Empleados")])])],1):_vm._e(),(_vm.onCanShow('User'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/autorizacion/usuarios',"title":"Usuarios"}},[_c('i',{staticClass:"uil-user"}),_c('span',[_vm._v("Usuarios")])])],1):_vm._e(),(_vm.onCanShow('Role'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/autorizacion/roles-y-permisos',"title":"Roles y Permisos"}},[_c('i',{staticClass:"uil-shield"}),_c('span',[_vm._v("Roles y Permisos")])])],1):_vm._e(),(_vm.onCanShow('Role'))?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/autorizacion/rastreo-gps',"title":"Rastreo GPS"}},[_c('i',{staticClass:"uil-map-marker"}),_c('span',[_vm._v("Rastreo GPS")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'CollectionRoute',
              'SctPermissionType',
              'TraceStatusReason',
              'TransportationType',
              'Vehicle',
              'VehicleType',
              'WorkRoute',
              'BillConcept',
              'BillConceptKey',
              'BillType',
              'TaxType',
              'TaxFee',
              'TaxFactorType',
              'BillUsingType',
              'BillRelation',
              'ProductType',
              'MeasureUnit',
              'DangerousMaterial',
              'PackagingType',
              'TransportMerchandiseType',
              'UnitKey',
              'BranchOffice',
              'PostalCode',
              'Neighborhood',
              'Destination',
              'Municipality',
              'District',
              'Country' ])
          )?_c('li',{staticClass:"menu-title",on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_vm._v(" Configuraciones ")]):_vm._e(),(
            _vm.canShowMultiple([
              'CollectionRoute',
              'SctPermissionType',
              'TraceStatusReason',
              'TransportationType',
              'Vehicle',
              'VehicleType',
              'WorkRoute' ])
          )?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/catalogo-de-transporte',"title":"Catálogo de Transporte"}},[_c('i',{staticClass:"mdi mdi-map-marker-path"}),_c('span',[_vm._v("Catálogo de Transporte")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'BillConcept',
              'BillConceptKey',
              'BillType',
              'TaxType',
              'TaxFee',
              'TaxFactorType',
              'BillUsingType',
              'BillRelation' ])
          )?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{staticClass:"menu--link",attrs:{"to":'/admin/catalogo-de-ventas',"title":"Catálogo de Ventas"}},[_c('i',{staticClass:"uil-chart-line"}),_c('span',[_vm._v("Catálogo de Ventas")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'ProductType',
              'MeasureUnit',
              'DangerousMaterial',
              'PackagingType',
              'TransportMerchandiseType',
              'UnitKey' ])
          )?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/catalogo-de-productos',"title":"Tipos de productos"}},[_c('i',{staticClass:"mdi mdi-bulletin-board"}),_c('span',[_vm._v("Catálogo de Productos")])])],1):_vm._e(),(
            _vm.canShowMultiple([
              'BranchOffice',
              'PostalCode',
              'Neighborhood',
              'Destination',
              'Municipality',
              'District',
              'Country' ])
          )?_c('li',{on:{"!click":function($event){return _vm.toggleSideBarNavbar()}}},[_c('router-link',{attrs:{"to":'/admin/catalogo-de-ubicaciones',"title":"Catálogo de Ubicaciones"}},[_c('i',{staticClass:"uil-map-marker"}),_c('span',[_vm._v("Catálogo de Ubicaciones")])])],1):_vm._e()])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-fw fa-bars"})])}]

export { render, staticRenderFns }