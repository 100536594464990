import BaseForm from '../infraestructure/BaseForm'
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";


export default {
    extends: BaseForm,
    data() {
        return {
            dialogVisible: false,
            viewModel: null,
            editPass: false,
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                fiscalName: [{
                    message: 'Razón Social requerido.',
                    trigger: 'blur'
                }],
                fiscalCode: [{
                    validator: this.onVerifyRFC, trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }],
                contactName: [{
                    required: true,
                    message: 'Nombre(s) de contacto requerido.',
                    trigger: 'blur'
                }],
                userName: [{
                    trigger: 'blur',
                    validator: this.onValidateUserName
                }],
                email: [{
                    trigger: 'blur',
                    validator: this.onValidateEmail
                },
                {
                    type: 'email',
                    message: 'Ingrese una dirección de correo electrónico correcta',
                    trigger: ['blur', 'change']
                }],
                password: [{
                    validator: this.onValidatePassword, trigger: 'blur'
                }],
                passwordConfirmation: [{
                    validator: this.onValidatePasswordConfirmation, trigger: 'blur'
                }],
                descriptionLocation: [{
                    required: true,
                    message: 'Descripción/Alias de Ubicación requerido.',
                    trigger: 'blur'
                }],
                mainStreet: [{
                    required: true,
                    message: 'Calle Principal requerido.',
                    trigger: 'blur'
                }],
                secondaryStreet: [{
                    required: true,
                    message: 'Entre Calles requerido.',
                    trigger: 'blur'
                }],
                extNumber: [{
                    required: true,
                    message: 'Numero requerido.',
                    trigger: 'blur'
                }],
                city: [{
                    required: true,
                    message: 'Ciudad/Destino requerido.',
                    trigger: 'blur'
                }],
                neighborhood: [{
                    required: true,
                    message: 'Colonia requerido.',
                    trigger: 'blur'
                }],
                postalCode: [{
                    required: true,
                    message: 'Código Postal requerido.',
                    trigger: 'blur'
                }],
                destination: [{
                    required: true,
                    message: 'La ciudad destino requerido.',
                    trigger: 'blur'
                }],
            },
            customerTypes: [{
                id: 1,
                name: "Prepago"
            }, {
                id: 2,
                name: "Crédito"
            }],
            destinations:[],
            neighborhoods: [],
            activeName: 'first'
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CustomerReceptionsViewModel(this)
        this.onActive()
        
    },

    methods: {
        onActive() {
            this.viewModel.bindFormReceptionCustomer(this.formData)
     
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.viewModel.saveCustomer(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-receptions-customer')
        },
        onVerifyRFC(rule, value, callback) {
            if (value) {
                //callback(new Error('El RFC es requerido.'));
                if (this.viewModel.verifyRFC(value)) {
                    callback();
                } else {
                    callback(new Error('El RFC no es valido.'));
                }
            } else {
                callback();
            }
        },
        onSaveCustomerResponse(response) {
            response.type = this.config.type
            this.onDoAfterSaveResponse(response.message, 'on-submited-receptions-customer', response)
        },
        onValidateUserName(rule, value, callback) {
            if (this.formData.isMasterAccountActive) {
                if (!value) {
                    callback(new Error('Usuario requerido.'));
                }
                callback()
            } else {
                callback();
            }
        },
        onValidateEmail(rule, value, callback) {
            if (this.formData.isMasterAccountActive) {
                if (!value) {
                    callback(new Error('Email requerido.'));
                }
                callback()
            } else {
                callback();
            }
        },
        onValidatePassword(rule, value, callback) {
            if (this.formData.isMasterAccountActive) {
                if (!value) {
                    callback(new Error('Contraseña requerida.'));
                } else {
                    if (this.formData.passwordConfirmation !== '') {
                        this.$refs.formData.validateField('passwordConfirmation');
                    }
                    callback();
                }
            } else {
                callback();
            }
            
        },
        onValidatePasswordConfirmation(rule, value, callback){
            if (this.formData.isMasterAccountActive) {
                if (!value) {
                    callback(new Error('Confirmación requerida.'));
                } else if (value !== this.formData.password) {
                    callback(new Error('Contraseña y confirmación de contraseña no coinciden'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        onFindDestinations(data){
            if(data) {
                this.viewModel.findDestinations(`Name=${data}`)
            } else {
                this.destinations = []
            }
        },
        onFindDestinationsResponse(response){
            this.destinations = response.data
        },
        onFindNeighborhoods(data) {
            this.neighborhoods = []
            if(this.formData.city && data) {
                this.viewModel.findNeighborhoods(`CityId=${this.formData.city.id}&Name=${data}`)
            } else {
                this.$refs.formData.validateField('city')
            }
        },
        onFindNeighborhoodsResponse(response) {
            this.neighborhoods = response.data
        },
        onAddNeighborhood() {
            if(this.formData.city && this.formData.neighborhoodName) {
                var neighborhood = this.viewModel.addNeighborhood({
                    name: this.formData.neighborhoodName,
                    city: this.formData.city
                })
                this.viewModel.createNeighborhood(neighborhood)
            } else {
                this.$refs.formData.validateField('city')
            }
        },
        onCreateNeighborhoodResponse(response) {
            this.formData.neighborhoodName = ''
            this.neighborhoods = [response.data]
            this.$set(this.formData, 'neighborhood', response.data)
            this.$refs.formData.clearValidate(['neighborhood']);
        }
    }
}