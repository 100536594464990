import BaseForm from '../infraestructure/BaseForm'
import PostalCodeViewModel from '../../core/features/postalCodes/PostalCodeViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'PostalCode',
            viewModel: null,
            countries: [],
            districts: [],
            municipalities: [],
            cities: [],
            neighborhoods: [],
            rules: {
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                country: [{
                    required: true,
                    message: 'Pais requerido.',
                    trigger: 'blur'
                }],
                district: [{
                    required: true,
                    message: 'Estado requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new PostalCodeViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-postal-code')
        },
        onChangeCountry(country){
            this.districts = []
            this.municipalities = []
            this.cities = []
            this.neighborhoods = []
            this.formData.district = ''
            this.formData.municipality = ''
            this.formData.city = ''
            this.formData.neighborhood = ''
            if(country) this.viewModel.findDistricts(`CountryId=${country.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindDistrictsResponse(response) {
            this.districts = response.data
        },
        onChangeDistrict(district){
            this.municipalities = []
            this.cities = []
            this.formData.municipality = ''
            this.formData.city = ''
            if(district){
                this.viewModel.findMunicipalities(`DistrictId=${district.id}&Skip=0&Limit=10000&IsActive=true`)
            }
        },
        onFindMunicipalitiesResponse(response) {
            this.municipalities = response.data
        },
        onChangeMunicipality(municipality){
            this.cities = []
            this.formData.city = ''
            if(municipality) this.viewModel.findCities(`DistrictId=${this.formData.district.id}&MunicipalityId=${municipality.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindCitiesResponse(response) {
            this.cities = response.data
        },
        onChangeCity(){
            this.$forceUpdate()
            // this.neighborhoods = []
            // if(city) this.viewModel.findNeighborhoods(`CityId=${city.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindNeighborhoodsResponse(response) {
            this.neighborhoods = response.data
        },
        onChangeNeighborhood(){
            this.$forceUpdate()
        }
    }
}