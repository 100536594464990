export default class CustomerSubAccount {
  constructor(options, state) {
    if(state){
      this.state = state
    }
    Object.assign(this, options)
  }

  getFullName(){
    return `${this.firstName}  ${this.lastName}`
  }

  getUserNameEmail() {
    return `${this.userName} | ${this.email}`
  }

  getResponsibleFullName() {
    return this.name
  }

  toSend() {
    console.log('toSendCustomerSub')
    console.log(this)
    let aux =  {
      id: this.id,
      accountName: this.accountName,
      userName: this.user.userName,
      password: this.user.password,
      passwordConfirmation: this.user.password,
      email: this.user.email,
      roleName: 'Customer',
      isActive: this.user.isActive,
    }
    console.log(aux)
    return aux;
  }
  
  toSendCustomerSubAccount() {
    return {
      userName: this.userName.trim(),
      firstName: this.firstName,
      lastName: this.lastName,
      password: this.password,
      passwordConfirmation: this.password,
      email: this.email.trim(),
      //roleName: (this.roleName) ? this.roleName : null,
      roleName: 'Customer',
      customerId: this.customerId,
      isActive: this.isActive
    }
  }
}