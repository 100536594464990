<script>
import ModalReceptionAssignedToSolvencyComponent from "./ModalReceptionAssignedToSolvencyComponent";

export default {
  extends: ModalReceptionAssignedToSolvencyComponent,
};
</script>

<template>
  <el-dialog
    width="80%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-ticket" /> Gu&iacute;as Asignadas
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-table
            :data="formData.receptions"
            style="width: 100%"
          >
            <el-table-column
              prop="trackingNumber"
              label="No. Guía"
              width="180"
            />
            <el-table-column
              prop="receptionDate"
              label="F. Recepción"
              width="180"
            >
              <template slot-scope="scope">
                <el-tag
                  type="primary"
                  effect="plain"
                >
                  <i class="el-icon-date" />
                  <span class="mx-2">
                    {{ scope.row.receptionDate | formatDate }}
                  </span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="originPlace"
              label="Origen"
              width="180"
              align="center"
            />
            <el-table-column
              prop="destinationPlace"
              label="Destino"
              width="180"
              align="center"
            />
            <el-table-column
              label="Remitente"
              align="center"
            >
              <template slot-scope="scope">
                <span class="mx-2">
                  {{ scope.row.senderName }} | {{ scope.row.senderPhone }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Destinatario"
              align="center"
            >
              <template slot-scope="scope">
                <span class="mx-2">
                  {{ scope.row.receiverName }} | {{ scope.row.receiverPhone }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Aceptar</el-button>
    </span>
  </el-dialog>
</template>
