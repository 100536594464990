import BaseList from '../infraestructure/BaseList'
import CustomersAccountViewModel from "../../core/features/customerAccount/CustomersAccountViewModel";



export default {
    extends: BaseList,
    data() {
        return {
            viewModel: null,
            customerId: '',
            searchName: '',
            searchEmail: '',
            searchRoleName: '',
            roles:[],
            currentSession: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-user-profile-customer', this.onActive);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-user-profile-customer', this.onActive);
    },
    created() {
        this.viewModel = new CustomersAccountViewModel(this)
        this.customerId = this.$store.state.admin.userCurrent.id
        this.onActive()
    },
    methods: {
        onActive() {
            this.currentSession = this.$store.getters["admin/getUserActive"]
        },
        onSelectUser() { },
        onCreate() {
            this.item = this.viewModel.create({
                customerId: this.customerId,
                isActive: true,
            })
            this.modalConfig.isVisible = true
        },
    }
}
