import _ from 'lodash'
import BaseForm from '../infraestructure/BaseForm'
import ReceptionPackageViewModel from '../../core/features/receptions/ReceptionPackageViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            weightCostConfig: null,
            rules: {
                productTypePath: [{
                    required: true,
                    message: 'Tipo de Paquete requerido.',
                    trigger: 'blur'
                }],
                measureUnit: [{
                    required: true,
                    message: 'Unidad de Medida requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                quantity: [{
                    required: true,
                    validator: this.onQuantityValid,
                    trigger: 'blur'
                }],
                weight: [{
                    required: true,
                    validator: this.onWeightValid,
                    trigger: 'blur'
                }],
                height: [{
                    required: true,
                    validator: this.onHeightValid,
                    trigger: 'blur'
                }],
                width: [{
                    required: true,
                    validator: this.onWidthValid,
                    trigger: 'blur'
                }],
                length: [{
                    required: true,
                    validator: this.onLengthValid,
                    trigger: 'blur'
                }],
                packagingType: [{
                    required: true,
                    message: 'Embalaje requerido.',
                    trigger: 'blur'
                }],
                transportMerchandiseType: [{
                    required: true,
                    message: 'Bienes Transportados requerido.',
                    trigger: 'blur'
                }],
                dangerousMaterial: [
                    {
                        validator: this.onValidateDangerousMaterial,
                        trigger: 'blur'
                    }
                ]
            },
            productTypes: [],
            measureUnits: [],
            currencies: [],
            stccTypes: [],
            dangerousMaterialTypes: [],
            packagingTypes: [],
            isMerchandiseTypeLoading: false,
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ReceptionPackageViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid, errors) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                } else {
                    this.onShowWarningList(errors)
                    return false
                }
            })
        },
        onSave(formData) {
            this.onDoAfterLocalSaveResponse(formData, 'on-submited-reception-packages')
        },
        onFindMeaseureUnitsResponse(response) {
            this.measureUnits = response.data
        },
        onFindProductTypes(data) {
            this.productTypes = []
            if (data) {
                this.viewModel.findProductTypes(`Name=${data}`)
            }
        },
        onFindProductTypesResponse(response) {
            this.productTypes = response.data
        },
        onChangeProductType(data) {
            if (!this.viewModel.isProductTypesTreeEmpty(data)) {
                let productTypeId = this.viewModel.getSelectedProductType(data)
                this.config.isLoading = true
                this.viewModel.findProductType(productTypeId)
            }
        },
        onFindProductTypeResponse(response) {
            if (response.data.measureUnit) {
                this.formData.weight = response.data.weight
                this.formData.length = response.data.length
                this.formData.width = response.data.width
                this.formData.height = response.data.height
                this.formData.productType = response.data
                this.formData.extraWeightCost = this.weightCostConfig.extraWeightCost
                this.formData.extraWeightTax = this.weightCostConfig.extraWeightTax
                this.formData.weightBase = this.weightCostConfig.weightBase
                this.measureUnits = [response.data.measureUnit]
                this.formData.measureUnit = response.data.measureUnit
                
            }
            setTimeout(() => {
                this.config.isLoading = false
            }, 400);
        },
        onCalculateWeight: _.debounce(function () {
            this.formData.volumetricWeight = this.viewModel.calculateVolumetricWeight(this.formData.quantity, this.formData.length, this.formData.height, this.formData.width)
            this.formData.finalWeight = this.viewModel.getFinalWeight(this.formData.weight, this.formData.volumetricWeight)
            this.formData.extraWeight = (this.weightCostConfig) ? this.viewModel.calculateExtraWeight(this.formData.finalWeight, this.weightCostConfig.weightBase, this.formData.quantity) : 0
            this.$forceUpdate()
        }, 400),
        onFindTransportMerchandiseTypes: _.debounce(function (query) {
            this.stccTypes = []
            this.isMerchandiseTypeLoading = true
            if(query) this.viewModel.findTransportMerchandiseTypes(`FullSearch=${query}&Skip=0&Limit=50`)
        }, 400),
        onFindTransportMerchandiseTypesResponse(response) {
            this.stccTypes = response.data
            this.isMerchandiseTypeLoading = false
        },
        onChangeTransportMerchandiseType(data) {
            this.formData.isDangerousMaterial = data.isDangerousMaterial;
        },
        onFindDangerousMaterials(query) {
            this.dangerousMaterialTypes = []
            if(query) this.viewModel.findDangerousMaterials(`FullSearch=${query}`)
        },
        onFindDangerousMaterialsResponse(response) {
            this.dangerousMaterialTypes = response.data
        },
        onFindPackagingTypes(query) {
            this.packagingTypes = []
            if(query) this.viewModel.findPackagingTypes(`FullSearch=${query}`)
        },
        onFindPackagingTypesResponse(response) {
            this.packagingTypes = response.data
        },
        onWidthValid(rule, value, callback) {
            this.viewModel.isWidthValid(value, callback)
        },
        onHeightValid(rule, value, callback) {
            this.viewModel.isHeightValid(value, callback)
        },
        onLengthValid(rule, value, callback) {
            this.viewModel.isLengthValid(value, callback)
        },
        onQuantityValid(rule, value, callback) {
            this.viewModel.isQuantityValid(value, callback)
        },
        onWeightValid(rule, value, callback) {
            this.viewModel.isWeightValid(value, callback)
        },
        filterOptions(node, keyword) {
            return node.text.toLowerCase().includes(keyword.toLowerCase())
        },
        onValidateDangerousMaterial(rule, value, callback){
            if(this.formData.isDangerousMaterial){
                if(this.formData.dangerousMaterial){
                    callback()
                } else {
                    callback(new Error("Material peligroso requerido"))
                }
            } else {
                callback()
            }
        },
        onIsDangerousMaterial() {
            this.$set(this.formData, 'dangerousMaterial', null)
        }
    }
}