export default class User {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
  
    getFullName(){
      return `${this.firstName}  ${this.lastName}`
    }

    getUserNameEmail() {
      return `${this.userName} | ${this.email}`
    }

    getResponsibleFullName() {
      return this.name
    }
    
    toSend() {
      return {
        id: this.id,
        userName: this.userName.trim(),
        password: this.password,
        passwordConfirmation: this.password,
        firstName: (this.firstName) ? this.firstName : null,
        lastName: (this.lastName) ? this.lastName: null,
        roleId: (this.role) ? this.role.id : null,
        roleName: (this.roleName) ? this.roleName : null,
        jobDescription: this.jobDescription,
        employeeCode: this.employeeCode,
        email: this.email.trim(),
        status: this.status,
        isActive: this.isActive
      }
    }
  }