import _ from 'lodash';
import BaseModule from '../infraestructure/BaseModule'
import GpsTrackingViewModel from '../../core/features/gpsTracking/GpsTrackingViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                description:'',
                operator: '',
                mapTrackingDate: '',
            },
            operators: [],
        }
    },
    created() {
        this.viewModel = new GpsTrackingViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-device-location', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onFilter();
        }, 500),
        onOperatorChange(operatorId) {
            (operatorId) ? this.addFilter('EmployeeProfileId', operatorId) : this.removeFilter('EmployeeProfileId')
            this.onFilter();
        },
        onSearchMapTrackingDate: _.debounce(function (text) {
            if (text) {
                this.addFilter('StartDate', text[0])
                this.addFilter('EndDate', text[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onFilter();
        }, 500),
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}