
import BaseForm from '../infraestructure/BaseForm'
import PlaceViewModel from '../../core/features/places/PlaceViewModel'
import _ from 'lodash'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Place',
            viewModel: null,
            countries: [],
            districts: [],
            municipalities: [],
            rules:{
                /*
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],*/
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                /* description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }], */
                country: [{
                    required: true,
                    message: 'Pais requerido.',
                    trigger: 'blur'
                }],
                district: [{
                    required: true,
                    message: 'Estado requerido.',
                    trigger: 'blur'
                }],
                /* municipality: [{
                    required: true,
                    message: 'Municipio requerido.',
                    trigger: 'blur'
                }],*/
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }],
                destinationCode: [{
                    validator: this.isDestinationRequired,
                    trigger: 'blur'
                }],
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new PlaceViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-place')
        },
        onChangeCountry(country){
            this.districts = []
            this.municipalities = []
            if(!country) {
                this.formData.district = null
                this.formData.municipality = null
            }
            if(country) this.viewModel.findDistricts(`CountryId=${country.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindDistrictsResponse(response) {
            this.districts = response.data
        },
        onChangeDistrict(district){
            this.municipalities = []
            if(!district) this.formData.municipality = null
            if(district) this.viewModel.findMunicipalities(`DistrictId=${district.id}&Skip=0&Limit=10000&IsActive=true`)
        },
        onFindMunicipalitiesResponse(response) {
            this.municipalities = response.data
        },
        isDestinationRequired(rules, value, callback) {
            if(this.formData.isDestination) {
                if(_.isEmpty(value)) {
                    callback(new Error('Codigo de Destino requerido.'))
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        onChangeIsDestination() {
            this.$set(this.formData, 'destinationCode', '')
        }
    }
}