import BaseForm from '../infraestructure/BaseForm'
import TrackingViewModel from '../../core/features/trackings/TrackingViewModel'
export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            rules: {
                traceStatus: [{
                    required: true,
                    message: 'Estatus requerido.',
                    trigger: 'blur'
                }],
            },
            receptionTrackingStatus: TrackingViewModel.receptionTrackingStatus,
            traceStatusReasons: [],
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new TrackingViewModel(this)
        // this.onActive()
    },
    methods: {
        onActive() {
            // this.viewModel.onBindFormReceptionStatus(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.config.isLoading = true
                    this.onSaveReceptionStatus(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveReceptionStatus(data) {
            this.viewModel.saveReceptionStatus(data)
        },
        onSaveReceptionStatusResponse(response) {
            this.config.isLoading = false
            this.onDoAfterSaveResponse(response.message, 'on-submited-reception-status')
        },
        onChangeTraceStatus(data) {
            this.$set(this, 'traceStatusReasons', [])
            this.$set(this.formData, 'traceStatusReason', '')
            if(data)
                this.viewModel.findTraceStatusReasons(data)
        },
        onFindTraceStatusReasonsResponse(response) {
            this.traceStatusReasons = response.data
        },
        onSaveReceptionStatusError(error) {
            this.config.isLoading = false
            this.onError(error)
        }
    }
}