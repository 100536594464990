<script>
import ListCustomerBillComponent from "./ListCustomerBillComponent";
import ModalCustomerBillView from "./ModalCustomerBillView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-customer-bill-view": ModalCustomerBillView,
    "modal-import-view": ModalImportView
  },
  extends: ListCustomerBillComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex">
                <div class="col-2">
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-6 me-2">
                  <div class="row mb-2">
                    <!-- <div class="col">
                      <el-input
                        v-model="searchCode"
                        placeholder="Buscar por código..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchCodeFilter"
                      />
                    </div>-->
                    <div class="col">
                      <el-input
                        v-model="searchDescription"
                        placeholder="Buscar por descripción..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchDescriptionFilter"
                      />
                    </div>
                    <div class="col">
                      <el-input
                        v-model="searchFiscalName"
                        placeholder="Buscar por Razón Social..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchFiscalNameFilter"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <!-- <div class="col">
                      <el-input
                        v-model="searchContactName"
                        placeholder="Buscar por Contacto..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchContactNameFilter"
                      />
                    </div>-->
                    <!-- <div class="col">
                      <el-input
                        v-model="searchContactEmail"
                        placeholder="Buscar por Correo..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchContactEmailFilter"
                      />
                    </div>-->
                    <!-- <div class="col">
                      <el-input
                        v-model="searchFiscalCode"
                        placeholder="Buscar por RFC..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchFiscalCodeFilter"
                      />
                    </div>-->
                  </div>
                </div>
                <div class="col-4" align="right">
                  <div
                    id="buttonlist"
                    class="mb-3"
                  >
                    <el-dropdown
                      size="medium"
                      class="filter-action"
                    >
                      <el-button
                        type="danger"
                        size="medium"
                      >
                        <i class="uil-filter" /> Filtrar
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <!--                         <el-dropdown-item class="my-2">
                          <el-select
                            v-model="searchCustomerType"
                            placeholder="Seleccionar Tipo de Cliente"
                            :popper-append-to-body="false"
                            size="medium"
                            clearable
                            @change="searchCustomerTypeFilter"
                          >
                            <el-option
                              v-for="item in customerTypes"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="my-2">
                          <el-select
                            v-model="defaultStatus"
                            placeholder="Seleccionar Estado"
                            :popper-append-to-body="false"
                            size="medium"
                            clearable
                            @change="searchStatusFilter"
                          >
                            <el-option
                              v-for="item in statusOptionsActive"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown
                      size="medium"
                      class="more-options-action"
                    >
                      <el-button
                        type="plain"
                        size="medium"
                      >
                        <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onShowImport"
                          >
                            <i class="el-icon-upload2" /> Importar
                          </el-button>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onExport"
                          >
                            <i class="el-icon-download" /> Exportar
                          </el-button>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-button
                      v-if="onUserPermission('Permission.CustomerBills.Create')"
                      type="primary"
                      size="medium"
                      class="main-action"
                      @click="onCreate"
                    >
                      <i class="fas fa-plus-circle" /> Crear Contacto
                    </el-button>-->
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.CustomerBills.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectCustomer"
              >
                <!-- <el-table-column
                  type="selection"
                  width="45"
                />-->
                <el-table-column
                  property="groupCode"
                  label="No. Grupo"
                  width="140"
                  align="center"
                />
                <el-table-column
                  label="Manifiesto"
                  width="140"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.shipment.shipmentNumber }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="No. Guias"
                  width="140"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.receptions.length }}
                  </template>
                </el-table-column>
                <el-table-column label="Origen">
                  <template slot-scope="scope">
                    {{ scope.row.reception.senderPlace.name }}
                  </template>
                </el-table-column>
                <el-table-column label="Destino">
                  <template slot-scope="scope">
                    {{ scope.row.reception.receiverPlace.name }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  width="140"
                >
                  <template slot-scope="scope">
                    {{ scope.row.total | formatCurrency }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Pdf"
                  width="160"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row">
                      <el-button @click="onDownloadPdf(scope.row)" size="medium" icon="el-icon-download">
                        Descargar
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Xml"
                  width="160"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row">
                      <el-button @click="onDownloadXml(scope.row)" size="medium" icon="el-icon-download">
                        Descargar
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Xlsx"
                  width="160"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row">
                      <el-button @click="onDownloadExcel(scope.row)" size="medium" icon="el-icon-download">
                        Descargar
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table :data="props.row.receptions" style="width: 100%" height="200px">
                      <el-table-column label="No. Guía" width="180">
                        <template slot-scope="item">
                          {{ item.row.reception.trackingNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Origen" width="200">
                        <template slot-scope="item">
                          {{ item.row.reception.senderPlace.name }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Destino" width="200">
                        <template slot-scope="item">
                          {{ item.row.reception.receiverPlace.name }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Remitente">
                        <template slot-scope="item">
                          {{ item.row.reception.senderName }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Destinatario">
                        <template slot-scope="item">
                          {{ item.row.reception.receiverName }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Subtotal" width="200">
                        <template slot-scope="item">
                          {{ item.row.sale.total | formatCurrency }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-customer-bill-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
  </div>
</template>
