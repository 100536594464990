import BaseList from "../infraestructure/BaseList";
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";
import moment from "moment";
export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      customerId: null,
      searchCityId: "",
      searchReceptionNumber: "",
      searchDescription: "",
      searchFullAddress: "",
      searchNeighborhood: "",
      searchPostalCode: "",
      itemSelected: null,
      searchOriginName: "",
      searchDestinyName: "",
      searchBeginingDate: "",
      searchEndingDate: "",
      origins: [],
      destinations: [],
      searchReceptionType: "",
      searchpaymentType: "",
      searchreceptionStatus: "",
      serachStatusTracking: "",
      optionSelected: "",
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR RECEPCIÓN",
        /*  onUploadImport: "on-upload-import-destinations",
        onCancelImport: "on-cancel-import-destinations", */
      },
      modalFinancialHistory: {
        isVisible: false,
        isLoading: false,
        title: "RECEPCIONES - HISTORIAL FINANCIERO",
      },
      receptionTypes: CustomerReceptionsViewModel.receptionTypes,
      paymentTypes: CustomerReceptionsViewModel.paymentTypes,
      receptionTrackingStatus:
        CustomerReceptionsViewModel.receptionTrackingStatus,
      modalSummaryOfShipments: {
        isVisible: false,
        isLoading: false,
      },
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-reception", this.onActive);
    // this.onMountedEvent("on-upload-import-customers", this.onUploadImport);
    // this.onMountedEvent("on-cancel-import-customers", this.onCancelImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-customer-reception", this.onActive);
    /* this.onBeforeDestroyEvent(
      "on-upload-import-customers",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-import-customers",
      this.onCancelImport
    ); */
  },
  created() {
    this.viewModel = new CustomerReceptionsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.onSearch();
    },
    onSelectCustomer() {},
    onCreate() {
      this.item = this.viewModel.create({
        isActive: true,
        receptionDate: new Date(),
        traces: [],
        customerId: this.customerId,
        orderFiscal: {
          fiscalLocation: null,
          selectedLocation: null,
          type: "",
        },
        sender: {
          customerLocation: null,
          selectedLocation: null,
          type: "",
        },
        receiver: {
          customerLocation: null,
          selectedLocation: null,
          type: "",
        },
      });
      this.modalConfig.isVisible = true;
    },
    searchReceptionNumberFilter() {
      this.addFilter("TrackingNumber", this.searchReceptionNumber);
      this.onSearch();
    },
    searchOriginNameFilter() {
      if (this.searchOriginName !== "") {
        this.addFilter("OriginName", this.searchOriginName.name);
      } else {
        this.removeFilter("OriginName");
      }
      this.onSearch();
    },
    searchDestinyNameFilter() {
      if (this.searchDestinyName !== "") {
        this.addFilter("DestinyName", this.searchDestinyName.name);
      } else {
        this.removeFilter("DestinyName");
      }
      this.onSearch();
    },
    searchBeginingDateFilter() {
      if (this.searchBeginingDate !== null) {
        this.addFilter(
          "BeginingDate",
          moment(this.searchBeginingDate[0]).format("YYYY-MM-DD")
        );
        this.addFilter(
          "EndingDate",
          moment(this.searchBeginingDate[1]).format("YYYY-MM-DD")
        );
      } else {
        this.removeFilter("BeginingDate");
        this.removeFilter("EndingDate");
      }
      this.onSearch();
    },
    searchReceptionTypeFilter() {
      if (this.searchReceptionType !== null) {
        this.addFilter("ReceptionType", this.searchReceptionType);
      } else {
        this.removeFilter("ReceptionType");
      }
      this.onSearch();
    },
    searchpaymentTypeFilter() {
      if (this.searchpaymentType !== null) {
        this.addFilter("PaymentType", this.searchpaymentType);
      } else {
        this.removeFilter("PaymentType");
      }
      this.onSearch();
    },
    searchreceptionStatusFilter() {
      if (this.searchreceptionStatus !== null) {
        this.addFilter("ReceptionStatus", this.searchreceptionStatus);
      } else {
        this.removeFilter("ReceptionStatus");
      }
      this.onSearch();
    },
    serachStatusTrackingFilter() {
      if (this.serachStatusTracking !== null) {
        this.addFilter("StatusTracking", this.serachStatusTracking);
      } else {
        this.removeFilter("StatusTracking");
      }
      this.onSearch();
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("IsActive", data);
      } else {
        this.removeFilter("IsActive");
      }
      this.onSearch();
    },
    onFindOrigins(data) {
      if (data) {
        this.viewModel.findOrigins(`Name=${data}`);
      } else {
        this.origins = [];
      }
    },
    onFindOriginsResponse(response) {
      this.origins = response.data;
    },
    onFindDestinations(data) {
      if (data) {
        this.viewModel.findDestinations(`Name=${data}`);
      } else {
        this.destinations = [];
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = response.data;
    },
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type);
    },
    onGetNamePaymentType(type) {
      return this.viewModel.getNamePaymentType(type);
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type);
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status);
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status);
    },
    onGetStyleTrackingStatus(status) {
      return this.viewModel.getStyleTrackingStatus(status);
    },
    onChangeOption(event) {
      this.optionSelected = event.command;
      switch (event.command) {
        case "edit":
          this.onEditCustomerReceptions(event.customerId, event.item.orderId);
          break;
        case "delete":
          this.onDeleteCustomerReceptions(event.customerId, event.item.id);
          break;
        case "show-reception":
          this.onFindReceptionOrderDetail(event.customerId, event.item.id);
          break;
        case "generate-tracking-document":
          this.onGenerateTrackingDocument(event.item.orderNumber);
          break;
        case "cancel":
          this.onCancelReceptionOrder(event.customerId, event.item.orderId);
          break;
      }
    },
    onEditCustomerReceptions(customerId, id) {
      this.viewModel.findItem(customerId, id);
    },
    onFindItemResponseCustomerReception(response, customerId) {
      this.item = this.viewModel.edit(response.data, customerId);
      this.modalConfig.isVisible = true;
    },
    onFindReceptionOrderDetail(customerId, id) {
      this.viewModel.findReceptionOrderDetail(customerId, id);
    },
    onFindReceptionOrderDetailResponse(response, customerId) {
      this.item = this.viewModel.show(response.data, customerId);
      this.modalConfig.isVisible = true;
    },
    onDeleteCustomerReceptions(customerId, id) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.delete(customerId, id);
        })
        .catch(() => {});
    },
    onDeleteCustomerReceptionResponse(response) {
      this.notifySuccess(response.message);
      this.onSearch();
    },
    onGenerateTrackingDocument(orderNumber) {
      this.isListLoading = true;
      this.viewModel.findOrderTrackingDocument(orderNumber);
    },
    onGenerateTrackingDocumentResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          response.data,
          response.fileName,
          "application/pdf",
          () => {
            this.notifySuccess("Etiquetas Generadas.");
            this.isListLoading = false;
          }
        );
      }, 400);
    },
    onGenerateTrackingDocumentError(error) {
      setTimeout(() => {
        this.isListLoading = false;
        this.onError(error);
      }, 400);
    },

    async onCancelReceptionOrder(customerId, id) {
      try {
        this.isListLoading = true;
        const response = await this.viewModel.cancelReceptionOrder(
          customerId,
          id
        );
        setTimeout(() => {
          this.notifySuccess(response.data.message);
          this.onSearch();
        }, 400);
      } catch (error) {
        this.onCancelReceptionOrderError(error);
      }
    },
    onCancelReceptionOrderError(error) {
     setTimeout(() => {
      this.isListLoading = false;
      this.onError(error);
     }, 400);
    },
    onSearch() {
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(this.customerId, this.filters);
      }, 400);
    },
    onFormatMoney(value) {
      return this.viewModel.formatMoney(value);
    },
    onSummaryOfShipments() {
      this.modalSummaryOfShipments.isVisible = true;
      this.modalSummaryOfShipments.isLoading = true;
    },
  },
};
