import BaseApi from '../../infraestructure/BaseApi'

export default class ApiProductTypes extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }


    findFilterTree(){
        return this.client.get(`${this.endPoint}/filter-tree`) 
    }
}