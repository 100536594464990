import BaseViewModel from '../../infraestructure/BaseViewModel'
import BillUsingType from './BillUsingType'

export default class BillUsingTypeViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new BillUsingType(data, 'create')
    }

    edit(data) {
        return new BillUsingType(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.billUsingTypes.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.billUsingTypes.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.billUsingTypes.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.billUsingTypes.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.billUsingTypes.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm() { }

    import(file) {
        this.api.billUsingTypes.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new BillUsingType(item))
    }

    //#endregion
}