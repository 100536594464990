<template>
  <section
    id="cta-3"
    class="cta cta-3 bg-theme"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="heading heading-2 heading-light">
            <p class="heading-subtitle">
              Distancia, ¡Acaso importa!
            </p>
            <h2 class="heading-title">
              ¡Carga express que le ahorra tiempo!
            </h2>
          </div>
        </div>
        <!--End .col-lg-5-->
        <div class="col-12 col-lg-6 offset-lg-1">
          <div class="prief-set">
            <p>
              Estamos orgullosos de ofrecer los mejores servicios de carga y
              envío. Nuestro personal calificado, que utiliza software de
              comunicaciones, seguimiento y procesamiento, combinado con décadas
              de experiencia. Mediante soluciones integradas de cadena de
              suministro, RAM impulsa ventajas competitivas sostenibles para las
              empresas.
            </p>
            <ul class="advantages-list">
              <li>
                <i class="fas fa-check-circle" /> Sistema de control de calidad
              </li>
              <li>
                <i class="fas fa-check-circle" /> Mano de obra incomparable
              </li>
              <li>
                <i class="fas fa-check-circle" /> Garantía de satisfacción del
                100%
              </li>
              <li>
                <i class="fas fa-check-circle" /> Procesos de prueba precisos
              </li>
              <li>
                <i class="fas fa-check-circle" /> Personal altamente profesional
              </li>
              <li>
                <i class="fas fa-check-circle" /> Profesional y calificado
              </li>
            </ul>
          </div>
        </div>
        <!--End .col-lg-6-->
      </div>
      <!-- End .row-->
      <div class="action-panels">
        <div class="row no-gutters">
          <div class="col-12 col-lg-6">
            <div class="action-panel">
              <div class="action-panel-img">
                <div class="bg-section">
                  <img
                    src="/website/images/cta/2.jpg"
                    alt="image"
                  >
                </div>
              </div>
              <div class="action-panel-content">
                <div class="panel-icon">
                  <i class="flaticon-014-box-3" />
                </div>
                <div class="panel-heading">
                  <h3>Entrega rápida y eficiente</h3>
                </div>
                <a href="javascript:void(0)"><i class="icon-arrow-right" /></a>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="action-panel">
              <div class="action-panel-img">
                <div class="bg-section">
                  <img
                    src="/website/images/cta/3.jpg"
                    alt="Image"
                  >
                </div>
              </div>
              <div class="action-panel-content inverted">
                <div class="panel-icon">
                  <i class="flaticon-017-pallet" />
                </div>
                <div class="panel-heading">
                  <h3>Excelente infraestructura</h3>
                </div>
                <a href="javascript:void(0)"><i class="icon-arrow-right" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End .row-->
    </div>
    <!-- End .container-->
  </section>
</template>

<script>
export default {};
</script>

<style></style>
