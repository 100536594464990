import _ from "lodash";
import BaseForm from "../infraestructure/BaseForm";
import OrderViewModel from "../../core/features/orders/OrderViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: "Order",
      viewModel: null,
      shipmentTabActive: "1",
      tabActive: "reception-details",
      stepActive: 0,
      activeName: "second",
      isSenderBoxLoading: false,
      customers: [],
      serviceCosts: [],
      postalCodes: [],
      taxRegimes: [],
      billUsingTypes: [],
      paymentMethods: [],
      isFiscalLoading: false,
      payerResponsibles: [
        {
          id: "sender",
          name: "Remitente",
          icon: "uil-sign-out-alt",
        },
        {
          id: "receiver",
          name: "Destinatario",
          icon: "uil-sign-in-alt",
        },
      ],
      receptionServiceTypes: OrderViewModel.receptionServiceTypes,
      rules0: {
        receptionServiceType: [
          {
            validator: this.onReceptionServiceTypeValidation,
            trigger: "blur, change",
          },
        ],
        payerResponsible: [
          {
            required: true,
            message: "Responsable de pago requerido.",
            trigger: "blur",
          },
        ],
        sender: {
          customer: [
            {
              required: true,
              validator: this.onSenderCustomerValidation,
              trigger: "blur, change",
            },
          ],
          customerLocation: [
            {
              required: true,
              validator: this.onSenderLocationValidation,
              trigger: "blur, change",
            },
          ],
          name: [
            {
              required: true,
              message: "Nombre Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          phone: [
            {
              required: true,
              message: "Teléfono Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observaciones Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código Postal Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          country: [
            {
              required: true,
              message: "Pais Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          district: [
            {
              required: true,
              message: "Estado Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          city: [
            {
              required: true,
              message: "Ciudad Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          neighborhood: [
            {
              required: true,
              message: "Colonia Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle Principal Remitente Requerido.",
              trigger: "blur, change",
            },
          ],
        },
        receiver: {
          customer: [
            {
              required: true,
              validator: this.onReceiverCustomerValidation,
              trigger: "blur, change",
            },
          ],
          customerLocation: [
            {
              required: true,
              validator: this.onReceiverLocationValidation,
              trigger: "blur, change",
            },
          ],
          name: [
            {
              required: true,
              message: "Nombre Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          phone: [
            {
              required: true,
              message: "Teléfono Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observaciones Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código Postal Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          country: [
            {
              required: true,
              message: "Pais Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          district: [
            {
              required: true,
              message: "Estado Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          city: [
            {
              required: true,
              message: "Ciudad Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          neighborhood: [
            {
              required: true,
              message: "Colonia Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle Principal Destinatario Requerido.",
              trigger: "blur, change",
            },
          ],
        },
        orderFiscal: {
          customer: [
            {
              required: true,
              message: "Cliente Requerido.",
            },
            {
              validator: this.onCustomerValidator,
              trigger: "blur, change",
            },
          ],
          fiscalName: [
            {
              required: true,
              message: "Razón Social Requerido.",
            },
          ],
          fiscalCode: [
            {
              required: true,
              message: "RFC Requerido.",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código Postal Requerido.",
            },
          ],
          fiscalRegime: [
            {
              required: true,
              message: "Regimen Fiscal Requerido.",
            },
          ],
          paymentMethod: [ {
            required: true,
            message: "Metodo de Pago Requerido.",
          }],
          billUsingType: [ {
            required: true,
            message: "Uso de CFDI Requerido.",
          }],
          type: [{
            required:true,
            message: "Opción de Facturación Requerido."
          }]
        },
      },
      rules1: {
        traces: [
          {
            validator: this.onTracesValidator,
            trigger: "blur, change",
          },
        ],
        serviceCost: [
          {
            validator: this.onServiceCostValidator,
            trigger: "blur, change",
          },
        ],
      },
      modalCustomerConfig: {
        isVisible: false,
        isLoading: false,
        type: null,
      },
      itemCustomer: null,
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new OrderViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    isFirstStep() {
      return this.stepActive === 0;
    },
    onPrevStep() {
      this.stepActive -= 1;
    },
    onNextStep() {
      this.$forceUpdate();
      if (this.stepActive < 2) {
        this.isStepValid(this.stepActive, (isValid) => {
          if (isValid) {
            this.config.isLoading = true;
            setTimeout(async () => {
              this.stepActive += 1;
              if (this.stepActive === 1) {
                await this.viewModel.bindStepTwoFormView();
              }
              if (this.stepActive == 2) {
                this.viewModel.save(this.formData);
              }
              this.isLoading = false;
            }, 400);
          }
        });
      }
    },
    isStepValid(stepActive, callback) {
      this.$refs[`formDataStep${stepActive}`].validate((isValid, errors) => {
        if (!isValid) {
          this.onShowWarningList(errors);
        }
        callback(isValid);
      });
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid, errors) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        } else {
          this.onShowWarningList(errors);
          return false;
        }
      });
    },
    onSaveResponse(response) {
      this.config.isLoading = false;
      this.onDoAfterSaveResponse(response.message, "on-submited-order");
    },
    onSaveResponseError(error) {
      this.onPrevStep();
      this.config.isLoading = false;
      this.onError(error);
    },
    onVerifyRFC(rule, value, callback) {
      if (value) {
        _.debounce(() => {
          if (this.formData.customer.fiscalCode) {
            this.config.isLoading = true;
            this.config.isLoadingText = "Verificando RFC...";
            this.viewModel.verifyRFC(
              this.formData.customer.fiscalCode,
              callback
            );
          } else {
            callback();
          }
        }, 500)();
      } else {
        callback();
      }
    },
    async onChangeCustomer(customer) {
      this.isFiscalLoading = true;
      this.postalCodes = [];
      if (customer) {
        const currentCustomer = await this.viewModel.findCustomerAsync(
          customer.id
        );

        console.log(currentCustomer)
        console.log(this.taxRegimes)

        if(currentCustomer.taxRegime) {
          await this.viewModel.findTaxRegimeAsync(currentCustomer.taxRegime.id)
        }
        
        this.viewModel.setPaymentMethods(this.formData, this.formData.receptionServiceType, this.paymentMethods)
        this.viewModel.setBillUsingTypeGeneralPublic(this.formData, this.billUsingTypes)

        this.formData.orderFiscal.fiscalName = currentCustomer?.fiscalName;
        this.formData.orderFiscal.fiscalCode = currentCustomer?.fiscalCode;
        this.formData.orderFiscal.fiscalEmail = currentCustomer?.contactEmail;
        this.formData.orderFiscal.postalCode = currentCustomer?.fiscalPostalCode;
        this.formData.orderFiscal.fiscalRegime = currentCustomer?.taxRegime;
        if (customer?.fiscalPostalCode) {
          this.postalCodes = await this.viewModel.findPostalCodesAsync(
            `Code=${customer.fiscalPostalCode}&Skip=0&Limit=100&IsActive=true`
          );
          this.formData.postalCode = this.postalCodes[0];
        } else {
          this.formData.postalCode = null;
        }
      } else {
        this.formData.orderFiscal.fiscalName = "";
        this.formData.orderFiscal.fiscalCode = "";
        this.formData.orderFiscal.fiscalEmail = "";
        this.formData.orderFiscal.fiscalRegime = null;
        this.formData.orderFiscal.postalCode = null;
        this.formData.orderFiscal.billUsingType = null;
        this.formData.orderFiscal.paymentMethod = null;
        this.billUsingTypes = []
      }

      setTimeout(() => {
        this.isFiscalLoading = false;
      }, 400);
    },
    async onFindCustomers(query) {
      this.customers = [];
      if (query) {
        this.customers = await this.viewModel.findCustomers(
          `FullSearch=${query}&Skip=0&Limit=100&IsActive=true`
        );
      }
    },
    onDeletePackage(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.deletePackage(index, this.formData.traces);
        })
        .catch(() => {});
    },
    onAddPackage() {
      this.formData.traces.push({
        quantity: 0,
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
      });
    },
    onRegisterCustomer() {
      this.itemCustomer = this.viewModel.createCustomer({
        isActive: true,
        address: {},
      });

      this.modalCustomerConfig.isVisible = true;
    },
    /**
     * Metodo para el evento cuando el tipo de servicio (Contado/Credito/Prepago)
     */
    onChangeReceptionServiceType(data) {
      this.formData.freightPayable = data === 3;
      this.onResetCustomerForm("sender", ["sender.name", "sender.phone"]);
      this.onResetCustomerForm("receiver", ["receiver.name", "receiver.phone"]);
    },
    /**
     * Metodo para el evento cuando el responsable de pago cambia (Remitente /Destinatario)
     */
    onChangePayerResponsible(data) {
      this.formData.freightPayable =
        this.formData.receptionServiceType === 3 && data === "sender";
      this.onResetCustomerForm("sender", ["sender.name", "sender.phone"]);
      this.onResetCustomerForm("receiver", ["receiver.name", "receiver.phone"]);
    },
    onResetCustomerForm(customerType, properties) {
      this.viewModel.resetCustomer(
        this.formData[customerType],
        this.$refs["formDataStep0"],
        properties
      );
    },
    onChangeServiceCost(serviceCost) {
      if (serviceCost) {
        let serviceDeliveryPrice = this.viewModel.getServiceDeliveryPrice(
          this.formData,
          serviceCost.amount
        );
        this.$set(this.formData, "serviceDeliveryPrice", serviceDeliveryPrice);
        // this.onCalculatePackages()
      } else {
        this.formData.serviceDeliveryPrice = 0;
      }
    },
    onFormatServiceCost(serviceCost) {
      return this.viewModel.formatServiceCost(
        serviceCost,
        this.formData.receptionServiceType,
        this.formData.serviceDeliveryPrice
      );
    },
    onReceptionServiceTypeValidation(rule, value, callback) {
      if (!value) {
        callback("Tipo de servicio requerido");
      } else {
        callback();
      }
    },
    onSenderCustomerValidation(rule, value, callback) {
      if (!value) {
        callback("Cliente Remitente requerido");
      } else if (
        this.formData.payerResponsible === "sender" &&
        this.formData.receptionServiceType !== 3 &&
        !this.formData.customerSolvencyId
      ) {
        callback(this.formData.solvencyMessage);
      } else {
        callback();
      }
    },
    onReceiverCustomerValidation(rule, value, callback) {
      if (!value) {
        callback("Cliente Destinatario requerido");
      } else if (
        this.formData.payerResponsible === "receiver" &&
        this.formData.receptionServiceType !== 3 &&
        !this.formData.customerSolvencyId
      ) {
        callback(this.formData.solvencyMessage);
      } else {
        callback();
      }
    },
    onSenderLocationValidation(rule, value, callback) {
      if (this.formData.sender.customer) {
        if (!value) {
          callback("Dirección Remitente requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    onReceiverLocationValidation(rule, value, callback) {
      if (this.formData.receiver.customer) {
        if (!value) {
          callback("Dirección Destinatario requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    /**
     * Metodo para el evento cuando se selecciona el cliente y verificar si tiene solvencia (Credito/Prepago)
     * @param {*} data
     */
    onFindAvailableCustomerSolvency(data) {
      if (data) {
        this.viewModel.findAvailableCustomerSolvency(
          data.id,
          this.formData.receptionServiceType
        );
      } else {
        this.viewModel.setCustomerSolvency(this.formData, null);
      }
    },
    /**
     * Metodo de respuesta para cuando se busca si el cliente responsable de pago
     * @param {*} response
     */
    onFindByAilableCustomerSolvencyResponse(response) {
      this.viewModel.setCustomerSolvency(this.formData, response);
    },
    onTracesValidator(rule, value, callback) {
      if (!value || value.length === 0) {
        callback("Al menos un paquete requerido.");
      } else {
        callback();
      }
    },
    onServiceCostValidator(rule, value, callback) {
      if (!value) {
        callback("Flete requerido.");
      } else {
        callback();
      }
    },
    onCustomerValidator(rule, value, callback) {
      if (!value) {
        callback("Cliente de Datos Fiscales Requerido.");
      } else {
        callback();
      }
    },
    onPackageQuantityValidator(rule, value, callback) {
      if (!value) {
        callback("Cantidad debe ser mayor a 1.");
      } else {
        callback();
      }
    },
    onCreate() {
      return this.formData.state === "create";
    },
    onEdit() {
      return this.formData.state === "update";
    },
    onCalculateTotalVolume(receptionPackage) {
      return this.viewModel.calculateTotalVolume(
        OrderViewModel.BoxType,
        receptionPackage.quantity || 1,
        receptionPackage.length,
        receptionPackage.height,
        receptionPackage.width
      );
    },
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type);
    },
    onGetNamePaymentType(type) {
      return this.viewModel.getNamePaymentType(type);
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type);
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status);
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status);
    },
    hasSenderCustomer(formData) {
      return formData.senderLocation && formData.senderLocation.customer;
    },
    hasSenderCustomerLocation(formData) {
      return formData.senderLocation && formData.senderLocation.mainStreet;
    },
    hasReceiverCustomer(formData) {
      return formData.receiverLocation && formData.receiverLocation.customer;
    },
    hasReceiverCustomerLocation(formData) {
      return formData.receiverLocation && formData.receiverLocation.mainStreet;
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    getTotalService() {
      if (this.formData.discountPercentage === 0)
        return this.formData.shippingQuote;
      var discount =
        (this.formData.discountPercentage * this.formData.shippingQuote) / 100;
      return this.formData.shippingQuote - discount;
    },
    async onFiscalOptionChange(option) {
      this.isFiscalLoading = true;
      if (option === "none") {
        await this.viewModel.findFiscalGeneralPublic();
        this.viewModel.setBillUsingTypeGeneralPublic(this.formData, this.billUsingTypes)
        this.viewModel.setPaymentMethods(this.formData, this.formData.receptionServiceType, this.paymentMethods)
      } else {
        this.formData.orderFiscal.customer = null;
        this.formData.orderFiscal.fiscalName = null;
        this.formData.orderFiscal.fiscalCode = null;
        this.formData.orderFiscal.fiscalEmail = null;
        this.formData.orderFiscal.postalCode = null;
        this.formData.orderFiscal.fiscalRegime = null;
        this.formData.orderFiscal.billUsingType = null;
        this.formData.orderFiscal.paymentMethod = null;
        this.customers = []
        // this.taxRegimes = []
        this.billUsingTypes = []
      }
      setTimeout(() => {
        this.isFiscalLoading = false;
      }, 400);
    },
  },
};
