import BaseApi from '../../infraestructure/BaseApi'

export default class ApiServiceCost extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    export() {
        return this.client.get(`${this.endPoint}/export`)
    }
}