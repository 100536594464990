<script>
import WebsiteHeaderComponent from "./WebsiteHeaderComponent";
export default {
  extends: WebsiteHeaderComponent,
};
</script>

<template>
  <header
    id="navbar-spy"
    class="header header-1 header-light header-topbar"
  >
    <div class="container">
      <!-- Start .top-bar-->
      <div class="top-bar">
        <div class="block-left">
          <a
            :href="$router.resolve({ name: 'index' }).href"
            title="Inicio"
            class="navbar-brand"
          >
            <img
              class="logo logo-light"
              src="@/assets/website/images/logo/logo.svg"
              alt="Equita Logo"
            ><img
              class="logo logo-dark"
              src="@/assets/website/images/logo/logo.svg"
              alt="Equita Logo"
            >
          </a>
          <!--         <router-link
            :to="{name: 'index'}"
            title="Inicio"
            class="navbar-brand"
          >
            <img
              class="logo logo-light"
                src="@/assets/website/images/logo/logo.svg"
              alt="Equita Logo"
            ><img
              class="logo logo-dark"
                src="@/assets/website/images/logo/logo.svg"
              alt="Equita Logo"
            >
          </router-link> -->
          <button
            class="navbar-toggler"
            :class="{ collapsed: !navbarOpen }"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            @click.stop="toggleNavbar()"
          >
            <span class="navbar-toggler-icon" />
          </button>
        </div>
        <div class="block-right">
          <div class="top-contact">
            <div class="contact-infos">
              <i class="fas fa-phone-alt" />
              <div class="contact-body">
                <span>Llamanos:</span><a href="tel:(612) 125-1780">(612) 125-1780</a>
              </div>
            </div>
            <div class="contact-infos">
              <i class="fas fa-envelope" />
              <div class="contact-body">
                <span>Envianos un Email:</span><a
                  href="mailto:info@ram-logistica.com"
                >info@ram-logistica.com</a>
              </div>
            </div>
            <div class="contact-infos">
              <i class="fas fa-clock" />
              <div class="contact-body">
                <span>Horario de Trabajo:</span>
                <p>Lun-Vie: 9am – 8pm</p>
                <p>Sab: 9am – 14:30pm</p>
              </div>
            </div>
          </div>
          <div class="module">
            <!-- Start .module-contact-->
            <div class="module-contact">
              <a
                class="btn btn--primary"
                :href="$router.resolve({ name: 'request-quote-service' }).href"
              >Cotizaci&oacute;n</a>
            </div>
          </div>
        </div>
      </div>
      <!-- End .top-bar-->
    </div>
    <nav
      id="primary-menu"
      class="navbar navbar-expand-lg navbar-dark"
      :class="{ navbarOpen: navbarOpen }"
    >
      <div class="container">
        <div
          id="navbarContent"
          class="collapse navbar-collapse"
          :class="{ show: navbarOpen }"
        >
          <ul class="navbar-nav ">
            <li class="nav-item">
              <a
                :href="$router.resolve({ name: 'index' }).href"
                title="Inicio"
              ><span>Inicio</span></a>
            </li>
            <li
              id="menuempresa"
              class="nav-item has-dropdown"
              @click.capture="toggleSubMenu('menuempresa')"
            >
              <a
                class="dropdown-toggle"
                href="#"
                data-toggle="dropdown"
              ><span>Empresa</span></a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    :href="$router.resolve({name: 'about-company'}).href"
                    title="Acerca de nosotros"
                  >  <span>Acerca de Nosotros</span> </a> 
                </li>
                <li class="nav-item">
                  <a
                    :href="$router.resolve({name: 'faqs-company'}).href"
                    title="Preguntas frecuentes"
                  > 
                    <span>Preguntas Frecuentes</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a :href="$router.resolve({name: 'login'}).href">
                    <span>Login</span>
                  </a>
                </li>
              </ul>
            </li>
            <li
              id="menuservicios"
              class="nav-item has-dropdown"
              @click.capture="toggleSubMenu('menuservicios')"
            >
              <a
                class="dropdown-toggle"
                href="#"
                data-toggle="dropdown"
              ><span>Servicios</span></a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a :href="$router.resolve({name: 'transport-service'}).href"> 
                    <span>Transporte</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :href="$router.resolve({name: 'request-quote-service'}).href"
                    title="Solicitar Recolección"
                  > 
                    <span>Solicitar Recolecci&oacute;n</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :href="$router.resolve({name: 'tracking-delivery-service'}).href"
                    title="Rastrear Envíos"
                  > 
                    <span>Rastrear Env&iacute;o</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                :href="$router.resolve({name: 'contact'}).href"
                title="Contacto"
              >
                <span>Contacto</span>
              </a>
            </li>
          </ul>
          <div class="module-container">
            <!-- Start .module-social-->
            <div class="module module-social module-social-2">
              <a
                class="share-facebook"
                href="javascript:void(0)"
              ><i class="fab fa-facebook-f" /></a><a
                class="share-instagram"
                href="javascript:void(0)"
              ><i class="fab fa-instagram" /></a><a
                class="share-twitter"
                href="javascript:void(0)"
              ><i
                class="fab fa-twitter"
              /></a>
            </div>
            <!-- End .module-social-->
            <!--  Start Module Search  -->
          </div>
          <!--  End .module-container-->
        </div>
        <!--  End .navbar-collapse-->
      </div>
      <!--  End .container-->
    </nav>
  </header>
</template>
