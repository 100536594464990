import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import CustomersViewModel from "../../core/features/customers/CustomersViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Customer',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                description: '',
                customerType: '',
                fiscalName: '',
                fiscalCode: '',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR CLIENTES",
                onUploadImport: "on-upload-import-customers",
                onCancelImport: "on-cancel-import-customers",
            },
            customerTypes: [
                {
                    id: 1,
                    name: "Prepago",
                },
                {
                    id: 2,
                    name: "Crédito",
                },
            ],
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-customers", this.onSearch);
        this.onMountedEvent("on-upload-import-customers", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-customers", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-customers", this.onSearch);
        this.onBeforeDestroyEvent(
            "on-upload-import-customers",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-customers",
            this.onCancelImport
        );
    },
    created() {
        this.viewModel = new CustomersViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
        },
        onSelectCustomer() { },
        onCreate() {
            this.item = this.viewModel.create({
                customerSubAccounts: [],
                allowSubAccounts: false,
                customerLocations: [],
                discountPercentage: 0,
                masterAccount: {},
                isActive: true
            });
            this.modalConfig.isVisible = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        onSearchByFiscalName: _.debounce(function (text) {
            if (text) {
                this.addFilter('FiscalName', text)
            } else {
                this.removeFilter('FiscalName')
            }
            this.onSearch();
        }, 500),
        onSearchByFiscalCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('FiscalCode', text)
            } else {
                this.removeFilter('FiscalCode')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        searchCustomerTypeFilter(data) {
            if (data !== null) {
                this.addFilter("CustomerType", data);
            } else {
                this.removeFilter("CustomerType");
            }
            this.onSearch();
        },
        onRestructureLocations(){
            this.isLoading = true
            this.viewModel.updateRestructureLocations()
        },
        onRestructureLocationsResponse(response){
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.isLoading = false
            }, 400);
        }
    },
};
