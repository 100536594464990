export default class MeasureUnit {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  toSend() {
    return {
      measureUnitId: this.id,
      code: this.code.trim(),
      name: this.name.trim(),
      isActive: this.isActive,
      description: this.description,
    };
  }

  toCatalog() {
    return `${this.code} - ${this.name}`;
  }
}
