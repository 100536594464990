<script>
import RequestQuotePageComponent from "./RequestQuotePageComponent";
import HeroSection from "../components/HeroSection";
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView";
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView";
import CustomerBoxView from "../components/customerBox/CustomerBoxView";
import ShipmentBoxView from "../components/shipmentBox/ShipmentBoxView";
import PackageBoxView from "../components/packagesBox/PackageBoxView";
import AcceptTermsBoxView from "../components/acceptTermsBox/AcceptTermsBoxView";
import OrderVerificationBoxView from "../components/orderVerificationBox/OrderVerificationBoxView";
import OrderDetailBoxView from "../components/orderDetailBox/OrderDetailBoxView";

export default {
  name: "RequestQuotePageView",
  components: {
    "hero-section": HeroSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
    CustomerBoxView,
    ShipmentBoxView,
    PackageBoxView,
    OrderVerificationBoxView,
    AcceptTermsBoxView,
    OrderDetailBoxView
  },
  extends: RequestQuotePageComponent,
};
</script>
<template>
  <div>
    <!-- Start .header-1-->
    <website-header-view />
    <!-- Start #page-title-->
    <hero-section
      :is-home="false"
      url="/website/images/sliders/cotizacion.jpg"
      title-sub-heading="Envianos tus requerimientos"
      title-heading="Solicita
    una recolección a nuestro equipo de profesionales"
      :breadcrumb-item="[
        {
          href: '/',
          name: 'Inicio',
        },
        {
          href: '',
          name: 'Servicios',
        },
        {
          href: '',
          name: 'Solicitar cotización',
        },
      ]"
    />
    <!-- End #page-title-->
    <!--
      ============================
      Request Quote Section
      ============================
      -->
    <section
      id="request-quote"
      class="request-quote contact-card"
    >
      <div class="container">
        <div class="row">
          <!-- 
            ============================
            Sidebar Area
            ============================
            -->
          <div class="col-sm-12 col-md-12 col-lg-4 order-2 order-lg-0">
            <div class="sidebar sidebar-case-study">
              <!-- Start .widget-categories-->
              <div class="widget widget-categories">
                <div class="widget-title">
                  <h5>Orden de Recolecci&oacute;n</h5>
                  <p>
                    Sigue estos sencillos pasos para solicitar una
                    recolecci&oacute;n.
                  </p>
                </div>
                <div class="widget-content">
                  <ul class="list-unstyled">
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/datospersonales.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Generar C&oacute;digo
                          </h6>
                          <p>
                            Generar Código de Verificación.
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/costos.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Datos De Cliente
                          </h6>
                          <p>
                            Ingresar Datos de Cliente.
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/comprar.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Datos de Env&iacute;o
                          </h6>
                          <p>
                            Ingresar información de recolección y entrega.
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/finalizar.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Finalizo
                          </h6>
                          <p>
                            Se genera una nueva recolección y se notifica a
                            nuestros recolectores.
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <!-- End .widget-categories -->
              <div class="widget widget-reservation">
                <img
                  src="assets/images/blog/sidebar/reservation.jpg"
                  alt="Background Image"
                >
                <div class="widget-content">
                  <h5>¿Problemas al generar una recolecci&oacute;n?</h5>
                  <p>
                    Si tienes problemas para realizar una recolecci&oacute;n, o
                    presenta algún inconveniente ponte en contacto con nosotros.
                    Estamos para asesorarte y ayudarte.
                  </p>
                  <a
                    class="btn btn--transparent btn--inverse btn--block"
                    href="/contacto"
                  >Contacto</a>
                </div>
              </div>
            </div>
          </div>
          <!-- End .col-lg-4 -->
          <div class="col-12 col-lg-8">
            <div class="quote-form contact-body">
              <div class="form-status d-none">
                <p class="status">
                  Your form has been successfully submitted
                </p>
                <p>
                  Need dependable, cost effective transportation of your
                  commodities? Fill out our easy Quote Request Form below to get
                  a fast quote on your job.
                </p>
              </div>
              <div v-if="!isOrderVerificationAvailable">
                <order-verification-box-view />
              </div>
              <div v-if="isOrderVerificationAvailable">
                <el-row
                  v-if="formData.order.status === 1"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col>
                    <el-steps
                      :active="step"
                      align-center
                    >
                      <el-step title="Cliente" />
                      <el-step title="Mercancia" />
                      <el-step title="Envío" />
                      <el-step title="Terminos" />
                      <el-step title="Finalizado" />
                    </el-steps>
                    <el-row
                      type="flex"
                      class="row-bg"
                      :gutter="20"
                      style="margin-top:20px;"
                    >
                      <el-col
                        :span="24"
                        align="center"
                      >
                        <el-button-group>
                          <el-button
                            v-if="step > 0 && step < 4"
                            type="primary"
                            icon="el-icon-arrow-left"
                            size="medium"
                            @click="onBack"
                          >
                            Atras
                          </el-button>
                          <el-button
                            v-if="step < 3"
                            id="next-step"
                            type="primary"
                            icon="el-icon-arrow-right"
                            size="medium"
                            @click="onNext"
                          >
                            Siguiente
                          </el-button>
                          <el-button
                            v-if="step == 3"
                            type="primary"
                            icon="el-icon-circle-check"
                            size="medium"
                            @click="onFinish('formData')"
                          >
                            Finalizar
                          </el-button>
                        </el-button-group>
                      </el-col>
                    </el-row>
                    <el-form
                      v-if="step == 0"
                      ref="formData0"
                      v-loading="isFormDataLoading"
                      :model="formData"
                      :rules="rulesFormData0"
                      :label-position="'top'"
                    >
                      <customer-box-view
                        :form-data="formData"
                        :is-postal-code-loading="isPostalCodeLoading"
                      />
                    </el-form>
                    <el-form
                      v-if="step == 1"
                      ref="formData1"
                      v-loading="isFormDataLoading"
                      :model="formData"
                      :rules="rulesFormData1"
                      :label-position="'top'"
                    >
                      <package-box-view
                        :form-data="formData"
                      />
                    </el-form>
                    <el-form
                      v-if="step == 2"
                      ref="formData2"
                      v-loading="isFormDataLoading"
                      :model="formData"
                      :rules="rulesFormData2"
                      :label-position="'top'"
                    >
                      <shipment-box-view
                        :form-data="formData"
                        :is-postal-code-loading="isPostalCodeLoading"
                      />
                    </el-form>
                    <el-form
                      v-if="step == 3"
                      ref="formData3"
                      v-loading="isFormDataLoading"
                      :model="formData"
                      :rules="rulesFormData3"
                      :label-position="'top'"
                    >
                      <accept-terms-box-view
                        :form-data="formData"
                      />
                    </el-form>
                    <el-col
                      v-if="step == 4"
                      v-loading="isFormDataLoading"
                      :xs="24"
                      :sm="24"
                      class="finished_step"
                      align="center"
                    >
                      <order-detail-box-view :order-number="formData.order.orderNumber" />
                    </el-col>
                  </el-col>
                </el-row>
                <el-row
                  v-if="formData.order.status === 2 || formData.order.status === 3 || formData.order.status === 4"
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <order-detail-box-view :order-number="formData.order.orderNumber" />
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .container-->
    </section>
    <!--
      ============================
      Footer #1
      ============================
    -->
    <website-footer-view />
  </div>
</template>
