import BaseModule from '../infraestructure/BaseModule'
import CustomersViewModel from '../../core/features/customers/CustomersViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomersViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}
