import BasePage from "../../infraestructure/BasePage";
import RequestQuoteViewModel from "./RequestQuoteViewModel";

export default {
  extends: BasePage,
  data() {
    return {
      step: 0,
      viewModel: null,
      isOrderVerificationAvailable: false,
      isFormDataLoading: false,
      isPostalCodeLoading: false,
      formData: {
        description: "",
        address: {},
        senderType: "customer",
        receiverType: "contacts",
        sender: {
          customerLocation: {},
        },
        receiver: {
          customerLocation: {},
        },
        orderFiscal: {
          type: "none",
          location: {},
        },
        customer: {
          customerLocation: null,
        },
        packages: [],
        terms: {},
      },
      rulesFormData0: {
        customer: {
          contactName: [
            { required: true, message: "Nombre requerido.", trigger: "blur" },
          ],
          contactPhone: [
            { required: true, message: "Teléfono requerido.", trigger: "blur" },
          ],
          contactEmail: [
            { required: true, message: "Email requerido.", trigger: "blur" },
            {
              type: "email",
              message: "Formato de correo incorrecto",
              trigger: ["blur", "change"],
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación requerido.",
              trigger: "blur",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio requerido.",
              trigger: "blur",
            },
          ],
          city: [
            { required: true, message: "Ciudad requerido.", trigger: "blur" },
          ],
          neighborhood: [
            { required: true, message: "Colonia requerido.", trigger: "blur" },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle Principal requerido.",
              trigger: "blur",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código Postal requerido.",
              trigger: "blur",
            },
          ],
          fiscalCode: [
            {
              validator: this.onVerifyRFC,
              trigger: ["blur"],
            },
          ],
          customerLocation: [
            {
              required: true,
              message: "Ubicación requerido",
              trigger: "blur,change",
            },
          ],
        },
      },
      rulesFormData1: {
        packages: [{ validator: this.isPackageValid, trigger: "blur" }],
      },
      rulesFormData2: {
        serviceCost: [
          { required: true, message: "Servicio requerido", trigger: "blur" },
        ],
        payerResponsible: [
          { required: true, message: "Responsable de pago requerido", trigger: "blur" },
        ],
        sender: {
          contactName: [
            {
              required: true,
              message: "Nombre Remitente requerido.",
              trigger: "blur",
            },
          ],
          contactPhone: [
            {
              required: true,
              message: "Teléfono Remitente requerido.",
              trigger: "blur",
            },
          ],
          contactEmail: [
            {
              required: true,
              message: "Email Remitente requerido.",
              trigger: "blur",
            },
            {
              type: "email",
              message: "Formato de correo incorrecto",
              trigger: ["blur", "change"],
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.country": [
            {
              required: true,
              message: "Pais Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.district": [
            {
              required: true,
              message: "Estado Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.municipality": [
            {
              required: true,
              message: "Municipio Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.city": [
            {
              required: true,
              message: "Ciudad Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.neighborhood": [
            {
              required: true,
              message: "Colonia Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.mainStreet": [
            {
              required: true,
              message: "Calle Principal Remitente requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.postalCode": [
            {
              required: true,
              message: "Código Postal Remitente requerido.",
              trigger: "blur",
            },
          ],
          selectedContact: [
            {
              validator: this.onSenderSelectedContact,
              trigger: "blur, change",
            },
          ],
          selectedLocation: [
            {
              validator: this.onSenderSelectedLocation,
              trigger: "blur, change",
            },
          ],
        },
        receiver: {
          contactName: [
            {
              required: true,
              message: "Nombre Destinatario requerido.",
              trigger: "blur",
            },
          ],
          contactPhone: [
            {
              required: true,
              message: "Teléfono Destinatario requerido.",
              trigger: "blur",
            },
          ],
          contactEmail: [
            {
              required: true,
              message: "Email Destinatario requerido.",
              trigger: "blur",
            },
            {
              type: "email",
              message: "Formato de correo incorrecto",
              trigger: ["blur", "change"],
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.country": [
            {
              required: true,
              message: "Pais Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.district": [
            {
              required: true,
              message: "Estado Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.municipality": [
            {
              required: true,
              message: "Municipio Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.city": [
            {
              required: true,
              message: "Ciudad Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.neighborhood": [
            {
              required: true,
              message: "Colonia Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.mainStreet": [
            {
              required: true,
              message: "Calle Principal Destinatario requerido.",
              trigger: "blur",
            },
          ],
          "customerLocation.postalCode": [
            {
              required: true,
              message: "Código Postal Destinatario requerido.",
              trigger: "blur",
            },
          ],
          selectedContact: [
            {
              validator: this.onReceiverSelectedContact,
              trigger: "blur, change",
            },
          ],
          selectedLocation: [
            {
              validator: this.onReceiverSelectedLocation,
              trigger: "blur, change",
            },
          ],
          
        },
        orderFiscal: {
          fiscalCode: [
            { required: true, message: "R.F.C requerido.", trigger: "blur" },
            {
              validator: this.onVerifyRFC,
              trigger: ["blur"],
            },
          ],
          fiscalName: [
            {
              required: true,
              message: "Razón Social Requerido",
              trigger: "blur",
            },
          ],
          fiscalEmail: [
            { validator: this.orderFiscalEmailvalidator, trigger: "blur" },
            {
              type: "email",
              message: "Formato de correo incorrecto",
              trigger: ["blur", "change"],
            },
          ],
        },
      },
      rulesFormData3: {
        terms: {
          acceptTermsAndConditions: [
            {
              validator: this.isTermsValid,
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.onMountedEvent("is-order-verified", this.isOrderVerified);
    this.onMountedEvent("is-form-loading", this.isFormLoading);
    this.onMountedEvent("reset-sender-form", this.onResetSenderForm);
    this.onMountedEvent("reset-receiver-form", this.onResetReceiverForm);

    this.viewModel = new RequestQuoteViewModel(this);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("is-order-verified", this.isOrderVerified);
    this.onBeforeDestroyEvent("is-form-loading", this.isFormLoading);
    this.onBeforeDestroyEvent("reset-sender-form", this.onResetSenderForm);
    this.onBeforeDestroyEvent("reset-receiver-form", this.onResetReceiverForm);
  },
  created() {},
  methods: {
    onBack() {
      this.step = this.step - 1;
    },
    onNext() {
      this.$refs[`formData${this.step}`].validate((isValid, errors) => {
        if (isValid) {
          this.isFormDataLoading = true;
          this.step = this.step + 1;
          return true;
        } else {
          this.onShowWarningList(errors);
          return false;
        }
      });
    },
    onFinish(formName) {
      this.$refs[`${formName}${this.step}`].validate((isValid, errors) => {
        if (isValid) {
          this.isFormDataLoading = true;
          this.viewModel.requestPicking(this.formData);
          return true;
        } else {
          this.onShowWarningList(errors);
          return false;
        }
      });
    },
    onRequestPickingResponse() {
      setTimeout(() => {
        this.step = this.step + 1;
        this.isFormDataLoading = false;
      }, 400);
    },
    onRequestPickingError(error) {
      setTimeout(() => {
        this.onError(error);
        this.isFormDataLoading = false;
      }, 400);
    },
    isOrderVerified(orderVerification) {
      this.formData.order = orderVerification.data;
      this.isOrderVerificationAvailable = true;
    },
    onFindCustomerResponse(response) {
      this.formData.customer = response.data;
    },
    isFormLoading(isLoading) {
      this.isFormDataLoading = isLoading;
    },
    isPackageValid(rules, value, callback) {
      if (this.viewModel.isPackageValid(value)) {
        callback();
      } else {
        callback(new Error("Requiere al menos 1 paquete."));
      }
    },
    isTermsValid(rules, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("Confirmación de Lectura requerido."));
      }
    },
    onResetSenderForm() {
      // this.$refs['formData2'].resetFields();
      this.$set(this.formData, "sender", { customerLocation: {} });
      this.$forceUpdate();
    },
    onResetReceiverForm() {
      // this.$refs['formData2'].resetFields();
      this.$set(this.formData, "receiver", { customerLocation: {} });
      this.$forceUpdate();
    },
    onVerifyRFC(rule, value, callback) {
      if (value) {
        this.isPostalCodeLoading = true;
        this.viewModel.verifyRFC(value, callback);
      } else {
        callback();
      }
    },
    orderFiscalEmailvalidator(rule, value, callback) {
      if (this.formData.orderFiscal.type === "none") {
        callback();
      } else {
        if (!value) {
          callback(new Error("Correo para facturación requerido"));
        } else {
          callback();
        }
      }
    },
    onSenderSelectedContact(rule, value, callback) {
        if(this.formData.senderType === 'contacts') {
            if (!value) callback(new Error("Contacto requerido."));
            else callback();
        } else callback();
      
    },
    onReceiverSelectedContact(rule, value, callback) {
        if (this.formData.receiverType === 'contacts') {
            if (!value) callback(new Error("Ubicación requerido."));
            else callback();
        } else callback();
      
    },
    onSenderSelectedLocation(rule, value, callback) {
        if(this.formData.senderType === 'contacts') {
            if (!value) callback(new Error("Contacto requerido."));
            else callback();
        } else callback();
        
    },
    onReceiverSelectedLocation(rule, value, callback) {
        if (this.formData.receiverType === 'contacts') {
            if (!value) callback(new Error("Ubicación requerido."));
            else callback();
        } else callback();
    },
  },
};
