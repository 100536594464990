import BaseModule from '../infraestructure/BaseModule'
import WorkRoutesViewModel from '../../core/features/workRoutes/WorkRoutesViewModel'
export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            tabActive: 'collection-routes'
        }
    },
    created() {
        this.viewModel = new WorkRoutesViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}