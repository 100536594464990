import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      customers: [],
      isLoading: false,
      itemCustomer: null,
      modalCustomerConfig: {
        isVisible: false,
        isLoading: false,
        type: null,
      },
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    reception: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.onMountedEvent(`on-create-${this.propName}-response`, this.onCreateCustomerResponse);
  },
  beforeDestroy(){
    this.onBeforeDestroyEvent(`on-create-${this.propName}-response`, this.onCreateCustomerResponse);
  },
  methods: {
    async onActive() {
      await this.viewModel.bindCustomerFormView(this.formData);
    },
    onFindCustomers(query) {
      if (query) this.viewModel.findCustomers(`FullSearch=${query}`);
    },
    onFindCustomersResponse(response) {
      this.customers = response.data;
    },
    onChangeCustomer(customer) {
      this.isLoading = true;
      if(this.isServiceTypeAndPayerResponsibleSelected()) {
        if (customer) {
          this.setCustomer(customer);
          this.viewModel.findCustomerLocations(customer.id);
          if(this.viewModel.verifyReceptionServiceIsAnContract(this.reception.receptionServiceType) && this.isCustomerPayerResponsible()){
            this.onFindAvailableCustomerSolvency(customer);
          }
        } else {
          setTimeout(() => {
            this.setCustomer(null);
            this.isLoading = false;
          }, 400);
        }
      } else {
        setTimeout(() => {
          this.setCustomer(null);
          this.isLoading = false;
          this.onShowWarning('Seleccione un tipo de servicio y un responsable de pago');
        }, 400);
      }
    },
    onFindCustomerLocationsResponse(response) {
      setTimeout(() => {
        this.$set(this.formData, "mainStreet", '')
        this.$set(this.formData, "secondaryStreet", '')
        this.$set(this.formData, "extNumber", '')
        this.$set(this.formData, "intNumber", '')
        this.$set(this.formData, "postalCode", '')
        this.$set(this.formData, "neighborhood", '')
        this.$set(this.formData, "city", '')
        this.$set(this.formData, "municipality", '')
        this.$set(this.formData, "district", '')
        this.$set(this.formData, "country", '')
        this.$set(this.formData, "customerLocation", '')
        this.$set(this.formData,"customerLocations", this.viewModel.mapCustomerLocations(response.data.customerLocations));
        this.isLoading = false;
      }, 400);
    },
    setCustomer(customer) {
      this.$set(this.formData, "customer", customer ? customer : '');
      this.$set(this.formData, "name", customer ? customer.contactName : "" || null);
      this.$set(this.formData, "phone", customer ? customer.contactPhone : "" || null);
      this.$set(this.formData, "email", customer ? customer.contactEmail : "" || null);
      this.$forceUpdate()
    },
    onFindAvailableCustomerSolvency(data) {
      if (data) {
        this.viewModel.findAvailableCustomerSolvency(data.id, this.reception.receptionServiceType);
      } else {
        this.onFindByAilableCustomerSolvencyResponse(null)
      }
    },
    onFindByAilableCustomerSolvencyResponse(response) {
      this.bindReceptionContractService(response)
    },
    isCustomerEditable() {      
      return this.reception && this.isServiceTypeAndPayerResponsibleSelected()
    },
    onRegisterCustomer() {
      if(this.isServiceTypeAndPayerResponsibleSelected()) {
        this.itemCustomer = { address: {} }
        this.modalCustomerConfig.isVisible = true;
      } else {
        this.onShowWarning('Seleccione un tipo de servicio y un responsable de pago');
      }
    },
    onCreateCustomerResponse(response) {
      setTimeout(() => {
        this.bindCustomer(response)
        this.modalCustomerConfig.isVisible = false;
      }, 400)
    },
    bindCustomer(customer) {
      this.isLoading = true;
      this.customers = [customer]
      this.$set(this.formData, "customer", customer);
      this.$set(this.formData, "name", customer.contactName);
      this.$set(this.formData, "phone", customer.contactPhone);
      this.$set(this.formData, "email", customer.contactEmail);
      this.$set(this.formData,"customerLocations", this.viewModel.mapCustomerLocations(customer.customerLocations));
      setTimeout(() => {
        this.$forceUpdate()
        this.isLoading = false;
      }, 400);
    },
    bindReceptionContractService(customerSolvencyContract) {
      this.$set(this.reception, "customerSolvencyId", (customerSolvencyContract) ? customerSolvencyContract.customerSolvencyId : null);
      this.$set(this.reception, "paymentType", (customerSolvencyContract) ? customerSolvencyContract.paymentType : null);
      this.$set(this.reception, "serviceDeliveryPrice", (customerSolvencyContract) ? customerSolvencyContract.serviceDeliveryPrice : null);
      this.$set(this.reception, "solvencyType", (customerSolvencyContract) ? customerSolvencyContract.solvencyType : null);
      this.$set(this.reception, "isAvailable", (customerSolvencyContract) ? customerSolvencyContract.isAvailable : null);
      this.$set(this.reception, "solvencyMessage", (customerSolvencyContract) ? customerSolvencyContract.message : null);
      this.$set(this.reception, "freightPayable", this.viewModel.verifyServicePayed(customerSolvencyContract?.solvencyType));
      this.$forceUpdate();
    },
    isCustomerPayerResponsible() {
      return this.propName === this.reception.payerResponsible
    },
    isServiceTypeAndPayerResponsibleSelected() {
      return this.reception.receptionServiceType && this.reception.payerResponsible
    }
  }
};
