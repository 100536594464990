import BaseList from "../infraestructure/BaseList";
import CustomerContactViewModel from "../../core/features/customerBill/CustomerBillViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      customerId: null,
      searchFiscalName: "",
      searchCode: "",
      searchDescription: "",
      searchContactName: "",
      searchContactEmail: "",
      searchFiscalCode: "",
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR CONTACTOS",
        onUploadImport: "on-upload-import-customers",
        onCancelImport: "on-cancel-import-customers",
      },
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-contacts", this.onActive);
    this.onMountedEvent("on-upload-import-customers", this.onUploadImport);
    this.onMountedEvent("on-cancel-import-customers", this.onCancelImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-customer-contacts", this.onSearch);
    this.onBeforeDestroyEvent(
      "on-upload-import-customers",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-import-customers",
      this.onCancelImport
    );
  },
  created() {
    this.viewModel = new CustomerContactViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.onSearch(this.customerId);
    },
    onSelectCustomer() {},
    onCreate() {
      this.item = this.viewModel.create({
        customerId: this.customerId,
        isActive: true,
      });
      this.modalConfig.isVisible = true;
    },
    searchCodeFilter() {
      this.addFilter("Code", this.searchCode);
      this.onSearch(this.customerId);
    },
    searchDescriptionFilter() {
      this.addFilter("Description", this.searchDescription);
      this.onSearch(this.customerId);
    },
    searchFiscalNameFilter() {
      this.addFilter("FiscalName", this.searchFiscalName);
      this.onSearch(this.customerId);
    },
    searchContactNameFilter() {
      this.addFilter("ContactName", this.searchContactName);
      this.onSearch(this.customerId);
    },
    searchContactEmailFilter() {
      this.addFilter("ContactEmail", this.searchContactEmail);
      this.onSearch(this.customerId);
    },
    searchFiscalCodeFilter() {
      this.addFilter("FiscalCode", this.searchFiscalCode);
      this.onSearch(this.customerId);
    },
    onSearch(customerId) {
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(customerId, this.filters);
      }, 400);
    },
    onChangeEntries(data) {
      this.limit = data;
      this.onSearch(this.customerId);
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("isActive", data);
      } else {
        this.removeFilter("isActive");
      }
      this.onSearch(this.customerId);
    },
    onFindDestinations(data) {
      this.destinations = [];
      if (data) {
        this.viewModel.findDestinations(`Name=${data}`);
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = response.data;
    },
    onChangeOptionCustomerContacts(event) {
      if (event.command === "edit")
        this.onEditCustomerContact(event.customerId, event.item.id);
      if (event.command === "delete")
        this.onDeleteCustomerContact(event.customerId, event.item.id);
    },
    onEditCustomerContact(customerId, id) {
      this.viewModel.findItem(customerId, id);
    },
    onFindItemResponseCustomerContact(response, customerId) {
      this.item = this.viewModel.edit(response.data, customerId);
      this.modalConfig.isVisible = true;
    },
    onDeleteCustomerContact(customerId, id) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.delete(customerId, id);
        })
        .catch(() => {});
    },
    onDeleteResponseCustomerContact(response, customerId) {
      this.notifySuccess(response.message);
      this.onSearch(customerId);
    },
    isBillActive(saleBill) {
      return saleBill?.status === 1;
    },
    onChangeOption(event) {
      if (event.command === "edit") this.onEdit(event.item.id);
      if (event.command === "delete") this.onDelete(event.item.id);
      if (event.command === "do-bill") this.onDoBill(event.item.id);
      if (event.command === "do-bill-cancellation")
        this.onDoBillCancellation(event.item.id);
      if (event.command === "download-pdf") this.onDownloadPdf(event.item);
      if (event.command === "download-xml") this.onDownloadXml(event.item);
    },
    onDownloadPdf(item) {
      this.isListLoading = true;
      setTimeout(() => {
        const blobPdf = this.viewModel.dataURItoBlob(
          item.saleBill.billPdfFile,
          "application/pdf"
        );
        const urlPdf = URL.createObjectURL(blobPdf);
        const link = document.createElement("a");
        link.href = urlPdf;
        link.target = "_blank";
        link.setAttribute(
          "download",
          `factura_folio_${item.saleBill.folNumber}.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      }, 200);
      setTimeout(() => {
        this.isListLoading = false;
      }, 400);
    },
    onDownloadXml(item) {
      this.isListLoading = true;
      const blobXml = this.viewModel.dataURItoBlob(
        item.saleBill.billXmlFile,
        "application/xml"
      );
      const urlPdf = URL.createObjectURL(blobXml);
      const link = document.createElement("a");
      link.href = urlPdf;
      link.target = "_blank";
      link.setAttribute(
        "download",
        `factura_folio_${item.saleBill.folNumber}.xml`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        this.isListLoading = false;
      }, 400);
    },
    async onDownloadExcel(item) {
      this.isListLoading = true;
      const response = await this.viewModel.generateExcelSalesBySaleGroup(this.customerId, {
        groupCode: item.groupCode,
      });

      this.viewModel.addDownloadEvent(
        response.fileStream,
        response.fileName,
        "application/vnd.ms-excel",
        () => {
          setTimeout(() => {
            this.notifySuccess(response.message);
            this.isListLoading = false;
          }, 400);
        }
      );
    },
  },
};
