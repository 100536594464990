import BaseModule from '../infraestructure/BaseModule'
import BillConceptViewModel from '../../core/features/billConcepts/BillConceptViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            tabActive: 'bill-concepts',
        }
    },
    created() {
        this.viewModel = new BillConceptViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}