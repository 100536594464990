<script>
import ModalUserComponent from "./ModalUserComponent";

export default {
  extends: ModalUserComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-user"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-user" /> Usuario
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="8">
          <el-form-item
            label="Rol"
            prop="roleName"
          >
            <el-select
              v-model="formData.roleName"
              placeholder="Seleccionar Rol"
              filterable
              clearable
              size="medium"
            >
              <el-option
                v-for="item in roles"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Usuario"
            prop="userName"
          >
            <el-input
              v-model="formData.userName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="8"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            type="email"
            label="Correo electrónico"
            prop="email"
          >
            <el-input
              v-model="formData.email"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="formData.state === 'update'">
        <el-col :span="24">
          <el-form-item prop="editPass">
            <el-checkbox
              v-model="editPass"
              border
              @change="onClearFieldForm('formData')"
            >
              Editar Contraseña
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="Contraseña"
            prop="password"
          >
            <el-input
              v-model="formData.password"
              autocomplete="off"
              size="medium"
              show-password
              name="password"
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Verificar Contraseña"
            prop="passwordConfirmation"
          >
            <el-input
              v-model="formData.passwordConfirmation"
              autocomplete="off"
              name="passwordConfirmation"
              size="medium"
              show-password
              :disabled="!editPass && formData.state === 'update'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
