<script>
import ReceptionCreateComponent from "./ReceptionCreateComponent";

import StepOneView from './steps/StepOneView';
import StepTwoView from './steps/StepTwoView';
import StepThreeView from './steps/StepThreeView';
import StepFourView from './steps/StepFourView';

export default {
  components: {
    'step-one-view': StepOneView,
    'step-two-view': StepTwoView,
    'step-three-view': StepThreeView,
    'step-four-view': StepFourView
  },
  extends: ReceptionCreateComponent,
};
</script>
<template>
  <div>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col>
        <el-steps
          :active="stepActive"
          align-center
        >
          <el-step
            title="Cliente"
            description="Remitente y Destinatario"
          />
          <el-step
            title="Paquetes"
            description="Registro de Envío"
          />
          <el-step
            title="Pagos y Facturación"
            description="Pago, Factura y Carta"
          />
          <el-step
            title="Finalizar"
            description="Guía de Envío"
          />
        </el-steps>
      </el-col>
    </el-row>
    <el-form
      ref="formDataStep0"
      :model="formData"
      :rules="rulesStep0"
      :label-position="'top'"
    >
      <step-one-view 
        v-if="stepActive === 0 && weightCostConfig"
        :form-data="formData"
        :weight-cost-config="weightCostConfig"
        :config="config"
      />
    </el-form>

    <el-form
      ref="formDataStep1"
      :model="formData"
      :rules="rulesStep1"
      :label-position="'top'"
    >
      <step-two-view 
        v-if="stepActive === 1 && weightCostConfig"
        :form-data="formData"
        :config="config"
      />
    </el-form>
    <!-- EL FORM STEP 2 -->
    <el-form
      ref="formDataStep2"
      :model="formData"
      :rules="rulesStep2"
      :label-position="'top'"
    >
      <step-three-view 
        v-if="stepActive === 2 && weightCostConfig"
        :form-data="formData"
        :config="config" 
        :weight-cost-config="weightCostConfig"
      />
    </el-form>

    <step-four-view 
      v-if="stepActive === 3 && receptionData && weightCostConfig"
      :form-data="receptionData"
      :config="config"
    />

    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        align="center"
        class="reception-steps-navigation"
      >
        <el-button
          v-if="!isFirstStep() && stepActive !== 3"
          type="primary"
          size="small"
          @click="onPrevStep"
        >
          Anterior
        </el-button>
        <el-button
          v-if="stepActive !== 3"
          type="primary"
          size="small"
          @click="onNextStep"
        >
          Siguiente
        </el-button>
        <el-button
          v-if="receptionData && stepActive === 3"
          type="primary"
          icon="el-icon-printer"
          size="small"
          @click="onPrint"
        >
          Imprimir
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
