import BaseViewModel from '../../infraestructure/BaseViewModel'
import BillConcept from './BillConcept'
import UnitKey from '../unitKeys/UnitKey'
import BillConceptKey from '../billConceptKeys/BillConceptKey'
import MeasureUnit from '../measureUnits/MeasureUnit'

export default class BillConceptViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    create(data) {
        return new BillConcept(data, 'create')
    }

    edit(data) {
        return new BillConcept(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.billConcepts.create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        } else {
            this.api.billConcepts.update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.billConcepts.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(filters) {
        this.api.billConcepts.find(this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.billConcepts.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm(formData) { 
        if(formData.state === 'update') {
            this.view.unitKeys = (formData.unitKey) ? [new UnitKey(formData.unitKey)] : []
            this.view.measureUnits = (formData.measureUnit) ? [new MeasureUnit(formData.measureUnit)] : []
            this.view.billConceptKeys = (formData.billConceptKey) ? [new BillConceptKey(formData.billConceptKey)] : []
        }
    }

    import(file) {
        this.api.billConcepts.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            name: '',
            billConceptKey: '',
            measureUnit:'',
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Name');
        this.view.removeFilter('BillConceptKeyId');
        this.view.removeFilter('MeasureUnitId');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }

    //#region 

    mapCollection(collection) {
        return collection.map((item) => new BillConcept(item))
    }

    //#endregion

    findUnitKeys(query) {
        this.api.unitKeys.find(query)
            .then((response) => this.view.onUnitKeysResponse(response.data))
            .catch(this.view.onError)
    }

    findMeasureUnits(query) {
        this.api.measureUnits.find(query)
            .then((response) => this.view.onMeasureUnitsResponse(response.data))
            .catch(this.view.onError)
    }

    findBillConceptKeys(query) {
        this.api.billConceptKeys.find(query)
            .then((response) => this.view.onBillConceptKeysResponse(response.data))
            .catch(this.view.onError)
    }

    mapUnitKeys(collection) {
        return collection.map((item) => new UnitKey(item))
    }

    mapBillConceptKeys(collection) {
        return collection.map((item) => new BillConceptKey(item))
    }

    mapMeasureUnits(collection) {
        return collection.map((item) => new MeasureUnit(item))
    }
}