import BaseViewModel from "../../infraestructure/BaseViewModel";
import User from "./User";

export default class UsersViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  create(data) {
    return new User(data, "create");
  }

  edit(data) {
    return new User(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.users
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.users
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(id) {
    this.api.users
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.users
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.users
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  import(file) {
    this.api.users.import(file)
        .then((response) => this.view.onImportResponse(response.data))
        .catch(this.view.onError);
  }

  getUserCurrent() {
     this.api.users.getUserCurrentApi()
      .then((response) => this.view.onUserCurrentResponse(response.data))
      .catch(this.view.onError);
  } 

  allClearFilters() {
    this.view.filtersSelected = {
        userName: '',
        email: '',
        roleName: '',
        userType: '',
        status: '',
    };

    this.view.removeFilter('UserName');
    this.view.removeFilter('Email');
    this.view.removeFilter('RoleName');
    this.view.removeFilter('UserType');
    this.view.removeFilter('IsActive');
    this.view.onSearch();
  }



  bindForm(formData) {
    if (formData.state === "update") {
      this.view.roles = formData.roles ? [formData.roles] : [];
    }
    /*   this.api.roles.find()
            .then((response) => {
                if (formData.state === 'update') {
                     this.view.roles = (formData.roleName) ? [formData.origin] : []
                }
            })
            .catch(this.view.onError) */
  }

  //#region

  mapCollection(collection) {
    return collection.map((item) => new User(item));
  }

  //#endregion

  //#region QUERIES

  findRole() {
    this.api.roles
      .find()
      .then((response) => this.view.onFindRoleResponse(response.data))
      .catch(this.view.onError);
  }

  findRoles(criteria) {
    this.api.roles.find(criteria)
        .then((response) => this.view.onFindRoleResponse(response.data))
        .catch(this.view.onError)
}

  //#endregion QUERIES
}
