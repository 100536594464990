import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomerVerificationCode extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }
 
    create(customerId, data) {
        return this.client.post(`${this.endPoint}/${customerId}/verification-codes`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/verification-codes/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/verification-codes/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/verification-codes${parameters}`)
    }

    findOne(customerId, id) {
          return this.client.get(`${this.endPoint}/${customerId}/verification-codes/${id}`)
    }

    generateVerificationCode(customerId) {
        return this.client.get(`${this.endPoint}/${customerId}/verification-codes/generate`)
    }

    getVerificationCodeQr(customerId, id) {
        return this.client.get(`${this.endPoint}/${customerId}/verification-codes/${id}/generate-qr`)
    }

    findVerificationCode(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/verification-codes-by-customer${parameters}`)
    }

}