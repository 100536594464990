import BaseList from "../infraestructure/BaseList";
import CustomerSolvencyViewModel from "../../core/features/customerSolvencies/CustomerSolvencyViewModel";
import moment from "moment";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      searchCode: '',
      searchName: '',
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR CRÉDITOS Y PAGOS",
        onUploadImport: "on-upload-import-customer-solvencies",
        onCancelImport: "on-cancel-import-customer-solvencies",
      },
      solvencyReceptions: {
        receptions: []
      },
      modalSolvencyReceptions: {
        isVisible: false,
        isLoading: false
      },
      customerId: null,
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-solvency", this.onSearch);
    this.onMountedEvent("on-upload-import-customer-solvencies", this.onUploadImport);
    this.onMountedEvent("on-cancel-import-customer-solvencies", this.onCancelImport);
    this.onMountedEvent("on-export-customer-solvencies-excel", this.onExport);
    this.onMountedEvent("on-filters-customer-solvencies", this.onFiltersSolvencyReceptions);
    
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-solvency", this.onSearch);
    this.onBeforeDestroyEvent(
      "on-upload-customer-solvencies",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-customer-solvencies",
      this.onCancelImport
    );
    this.onBeforeDestroyEvent("on-export-customer-solvencies-excel", this.onExport);
    this.onBeforeDestroyEvent("on-filters-customer-solvencies", this.onFiltersSolvencyReceptions);
  },
  created() {
    this.viewModel = new CustomerSolvencyViewModel(this);
    this.onActive();
  },
  methods: {
    onExport(idSolvency){
      this.isListLoading = true;
      this.viewModel.export(idSolvency,this.filters);
    },
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.onSearch();
    },
    onSelectSolvency() { },
    onSearch(){
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(this.customerId, this.filters);
      }, 400);
    },
    onChangeOption(event) {
      if (event.command === "show-receptions") this.onShowReceptionsBySolvency(event.item.id);
    },
    onShowReceptionsBySolvency(solvencyId) {
      this.solvencyIdSelected = solvencyId
      this.viewModel.findReceptionsBySolvency(this.customerId, solvencyId)
    },
    onFindReceptionsBySolvencyResponse(response,solvencyId,headers) {
      this.modalSolvencyReceptions.isVisible = true
      this.solvencyReceptions.pagination = this.getPagination(headers);
      this.solvencyReceptions.pagination.limit = response.limit;
      this.solvencyReceptions.idSelected = solvencyId;
      this.solvencyReceptions.receptions = response.data
    },
    searchCodeFilter() {
      this.addFilter("Code", this.searchCode);
      this.onSearch();
    },
    searchNameFilter() {
      this.addFilter("CustomerName", this.searchName);
      this.onSearch();
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("IsActive", data);
      } else {
        this.removeFilter("IsActive");
      }
      this.onSearch();
    },
    searchCustomerTypeFilter(data) {
      if (data !== null) {
        this.addFilter("CustomerType", data);
      } else {
        this.removeFilter("CustomerType");
      }
      this.onSearch();
    },
    onChangeSolvencyType(data) {
      if (data !== null) {
        this.addFilter("type", data);
      } else {
        this.removeFilter("type");
      }
      this.onSearch();
    },

    onSearchsSolvencyReceptions(){
      this.defaultFilters();
      this.modalSolvencyReceptions.isLoading = true;
      setTimeout(() => {
        this.viewModel.findCollectionReceptionsBySolvency(this.customerId, this.solvencyReceptions.idSelected,this.filters);
      }, 400);
    },

    onSearchResponseReceptionsBySolvency(response, headers) {
      // console.log(headers);
      this.modalSolvencyReceptions.isLoading = false;
      this.solvencyReceptions.pagination = this.getPagination(headers);
      this.solvencyReceptions.pagination.limit = response.limit;
      this.solvencyReceptions.receptions = response.data
    },

    onFiltersSolvencyReceptions(dataFilters){
      if (dataFilters.nameFilter === "searchReceptionNumberFilter") {
        this.searchReceptionNumberFilter(dataFilters.text);
      }
      if (dataFilters.nameFilter === "searchOriginNameFilter") {
        this.searchOriginNameFilter(dataFilters.text)
      }
      if (dataFilters.nameFilter === "searchDestinyNameFilter") {
        this.searchDestinyNameFilter(dataFilters.text)
      }
      if (dataFilters.nameFilter === "searchSenderPlaceFilter") {
        this.searchSenderPlaceFilter(dataFilters.text)
      }
      if (dataFilters.nameFilter === "searchReceiverPlaceFilter"){
        this.searchReceiverPlaceFilter(dataFilters.text)
      }
      if (dataFilters.nameFilter === "searchBeginingDateFilter") {
        this.searchBeginingDateFilter(dataFilters.text)
      }
    },
    searchReceptionNumberFilter(text){
      if (text !== null) {
        this.addFilter("TrackingNumber", text);
      } else {
        this.removeFilter("TrackingNumber");
      }
      this.onSearchsSolvencyReceptions();
    },

    searchOriginNameFilter(text){
      if (text !== null) {
        this.addFilter("SenderName", text);
      } else {
        this.removeFilter("SenderName");
      }
      this.onSearchsSolvencyReceptions();
    },

    searchDestinyNameFilter(text){
      if (text !== null) {
        this.addFilter("ReceiverName", text);
      } else {
        this.removeFilter("ReceiverName");
      }
      this.onSearchsSolvencyReceptions();
    },
    searchSenderPlaceFilter(text){
      if (text !== null) {
        this.addFilter("SenderPlace", text);
      } else {
        this.removeFilter("SenderPlace");
      }
      this.onSearchsSolvencyReceptions();
    },
    searchReceiverPlaceFilter(text){
      if (text !== null) {
        this.addFilter("ReceiverPlace", text);
      } else {
        this.removeFilter("ReceiverPlace");
      }
      this.onSearchsSolvencyReceptions();
    },

    searchBeginingDateFilter(text) {
      if (text !== null) {
        this.addFilter(
          "BeginingDate",
          moment(text[0]).format("YYYY-MM-DD")
        );
        this.addFilter(
          "EndingDate",
          moment(text[1]).format("YYYY-MM-DD")
        );
      } else {
        this.removeFilter("BeginingDate");
        this.removeFilter("EndingDate");
      }
      this.onSearchsSolvencyReceptions();
    },
  },
};
