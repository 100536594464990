<script>
import OrderVerificationBoxComponent from "./OrderVerificationBoxComponent";

export default {
  components: {},
  extends: OrderVerificationBoxComponent,
};
</script>
<template>
  <div class="request-code__order__verification">
    <el-form
      ref="formDataValidation"
      v-loading="isFormVerificationLoading"
      :model="formDataValidation"
      :rules="rulesValidation"
      :label-position="'top'"
    >
      <el-row>
        <el-col>
          <el-form-item
            label="Numero de Orden"
            prop="verificationCode"
          >
            <el-input
              v-model="formDataValidation.verificationCode"
              size="big"
              placeholder="Ingresar código de cliente"
              class="form-control"
              style="height:65px;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button
            type="primary"
            size="big"
            style="width:100%;"
            @click="onVerifyForm('formDataValidation')"
          >
            Verificar Código
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
