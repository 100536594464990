export default class Tracking {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
   
    toSend() {
      return {
        trackingId: this.id,
      
      }
    }
  }