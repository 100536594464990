import BaseModule from '../infraestructure/BaseModule'
import OrderViewModel from '../../core/features/orders/OrderViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new OrderViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}