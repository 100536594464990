import BaseForm from '../../infraestructure/BaseForm';
import SaleViewModel from "../../../core/features/sales/SaleViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            vehicles: [],
            viewModel: null,
            sctPermissionTypes: [],
            vehicleSelectedIndex: null,
            isAutoTransportTableLoading: false,
        }
    },
    created() {
        this.viewModel = new SaleViewModel(this)
        this.onActive()
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        propName: {
            type: String,
            required: true
        }
    },
    methods: {
        onActive() {
            this.viewModel.api.vehicles.find(`skip=0&limit=50000&IsActive=true`)
                .then((response) => {
                    this.vehicles = this.viewModel.mapVehicles(response.data.data)
                    return this.viewModel.api.sctPermissionTypes.find(`skip=0&limit=50000&IsActive=true`)
                })
                .then((response) => this.onFindSctPermissionTypesResponse(response.data))
                .catch((error) => this.onError(error))
        },
        onFindSctPermissionTypesResponse(response) {
            this.sctPermissionTypes = response.data
            this.formData.sctPermissionType = (this.formData.sctPermissionType) ? this.formData.sctPermissionType : response.data.find(item => item.isDefault)
        },
        onChangeTransportVehicle(index, vehicle) {
            this.vehicleSelectedIndex = index
            if (vehicle) {
                this.isAutoTransportTableLoading = true
                this.viewModel.findVehicle(vehicle.id)
            }
        },
        onFindVehicleResponse(response) {
            // this.formData.autoTransports[this.vehicleSelectedIndex].vehicleType = response.vehicleType
            this.$set(this.formData.getAutotransportations()[this.vehicleSelectedIndex], "vehicleType", response.data.vehicleType)
            this.$set(this.formData.getAutotransportations()[this.vehicleSelectedIndex], "plateNumber", response.data.plateNumber)
            this.$set(this.formData.getAutotransportations()[this.vehicleSelectedIndex], "insuranceCarrier", response.data.insuranceCarrier)
            this.$set(this.formData.getAutotransportations()[this.vehicleSelectedIndex], "policyNumber", response.data.policyNumber)
            this.vehicleSelectedIndex = null
            setTimeout(() => {
                this.isAutoTransportTableLoading = false
            }, 400);
        },
        onAddAutotransportation() {
            this.isAutoTransportTableLoading = true
            setTimeout(() => {
                this.formData.addAutotransportation()
                this.isAutoTransportTableLoading = false
            }, 400);
        },
        onEditAutotransportation() {

        },
        onDeleteAutotransportation() {
            this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.isAutoTransportTableLoading = true
                    setTimeout(() => {
                        this.formData.deleteAutotransportation(this.vehicleSelectedIndex);
                        this.isAutoTransportTableLoading = false
                    }, 400);
                })
                .catch(() => {
                    this.vehicleSelectedIndex = null
                });
        },
        onChangeOption(option) {
            this.vehicleSelectedIndex = option.index
            if (option.command === 'edit') this.onEditAutotransportation(option.item)
            if (option.command === 'delete') this.onDeleteAutotransportation(option.item)
        },

    }
}