<script>
import ModalCustomerVerificationCodeComponent from "./ModalCustomerVerificationCodeComponent";

export default {
  components: { },
  extends: ModalCustomerVerificationCodeComponent
};
</script>

<template>
  <el-dialog
    width="30%"
    custom-class="dialog-customer-verification-code"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-comment-notes" /> Qr de Verificación
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :span="24"
        >
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
              placeholder="Ingresar una descripción de responsable."
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="16"
        >
          <el-form-item prop="verificationCode">
            <el-input
              v-model="formData.verificationCode"
              placeholder="Generar código"
              autocomplete="off"
              size="medium"
              disabled
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                type="primary"
                class="btn-generate"
                @click="onGenerateVerificationCode"
              >
                Generar Codigo
              </el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col
          :span="8"
        >
          <el-form-item
            label=""
            prop="isActive"
          >
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
        >
          <el-form-item prop="accountResponsible">
            <el-select
              v-model="formData.accountResponsible"
              placeholder="Seleccionar Cuenta Responsable"
              filterable
              clearable
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindResponsible"
            >
              <el-option
                v-for="item in responsibles"
                :key="item.id"
                :label="item.getAccountName()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
