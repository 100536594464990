import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import MeasureUnitViewModel from '../../core/features/measureUnits/MeasureUnitViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'MeasureUnit',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                description:'',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR UNIDADES DE MEDIDA',
                onUploadImport: 'on-upload-import-measure-units',
                onCancelImport: 'on-cancel-import-measure-units'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-measure-unit', this.onSearch)
        this.onMountedEvent('on-upload-import-measure-units', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-measure-units', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-measure-unit', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-measure-units', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-measure-units', this.onCancelImport);
    },
    created() {
        this.viewModel = new MeasureUnitViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
           this.onSearch() 
        },
        onSelectMeasureUnit() { },
        onCreate(){
            this.item = this.viewModel.create({
                isActive: true,
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
     
    }
}