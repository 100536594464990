export default class ProductTypes {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        id: this.id,
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description,
        isActive: this.isActive,
        width: this.width,
        height: this.height,
        length: this.length,
        weight: this.weight,
        parentProductTypeId : (this.parentProductType) ? this.parentProductType.id : null,
        measureUnitId: (this.measureUnit) ? this.measureUnit.id : null,
        transportMerchandiseTypeId: (this.transportMerchandiseType) ? this.transportMerchandiseType.id : null,
      }
    }
  }