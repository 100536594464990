var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request-quote__packages"},[_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20}},[_c('el-col',{staticClass:"control",attrs:{"align":"right"}},[_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.onAddPackage}},[_vm._v(" Agregar Paquete ")])],1)],1),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"packages","align":"center"}},[_c('el-table',{staticClass:"table__request_quote",attrs:{"data":_vm.formData.packages}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20}},[_c('el-col',{attrs:{"xs":12,"sm":6,"align":"center"}},[_c('el-form-item',{attrs:{"label":"Cantidad","prop":'packages.' + props.$index + '.quantity',"rules":[
                      {
                        required: true,
                        message: 'Cantidad requerido.',
                        trigger: 'blur',
                      } ]}},[_c('el-input-number',{attrs:{"size":"small","min":1,"precision":3},model:{value:(props.row.quantity),callback:function ($$v) {_vm.$set(props.row, "quantity", $$v)},expression:"props.row.quantity"}})],1)],1),_c('el-col',{attrs:{"xs":12,"sm":6,"align":"center"}},[_c('el-form-item',{attrs:{"label":"Largo (cm.)","prop":'packages.' + props.$index + '.length',"rules":[
                      {
                        required: true,
                        message: 'Largo requerido.',
                        trigger: 'blur',
                      } ]}},[_c('el-input-number',{attrs:{"size":"small","min":0,"precision":3},model:{value:(props.row.length),callback:function ($$v) {_vm.$set(props.row, "length", $$v)},expression:"props.row.length"}})],1)],1),_c('el-col',{attrs:{"xs":12,"sm":6,"align":"center"}},[_c('el-form-item',{attrs:{"label":"Alto (cm.)","prop":'packages.' + props.$index + '.height',"rules":[
                      {
                        required: true,
                        message: 'Alto requerido.',
                        trigger: 'blur',
                      } ]}},[_c('el-input-number',{attrs:{"size":"small","min":0,"precision":3},model:{value:(props.row.height),callback:function ($$v) {_vm.$set(props.row, "height", $$v)},expression:"props.row.height"}})],1)],1),_c('el-col',{attrs:{"xs":12,"sm":6,"align":"center"}},[_c('el-form-item',{attrs:{"label":"Ancho (cm.)","prop":'packages.' + props.$index + '.width',"rules":[
                      {
                        required: true,
                        message: 'Ancho requerido.',
                        trigger: 'blur',
                      } ]}},[_c('el-input-number',{attrs:{"size":"small","min":0,"precision":3},model:{value:(props.row.width),callback:function ($$v) {_vm.$set(props.row, "width", $$v)},expression:"props.row.width"}})],1)],1),_c('el-col',{attrs:{"xs":12,"sm":6,"align":"center"}},[_c('el-form-item',{attrs:{"label":"Peso Total (kg.)","prop":'packages.' + props.$index + '.weight',"rules":[
                      {
                        required: true,
                        message: 'Ancho requerido.',
                        trigger: 'blur',
                      } ]}},[_c('el-input-number',{attrs:{"size":"small","min":0,"precision":3},model:{value:(props.row.weight),callback:function ($$v) {_vm.$set(props.row, "weight", $$v)},expression:"props.row.weight"}})],1)],1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"description","label":"Descripción"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('small',{staticClass:"table-package-especification-warning"},[_vm._v("Seleccionar el icono de +/- para ocultar o mostrar especificaciones")]),_c('el-form-item',{attrs:{"prop":'packages.' + scope.$index + '.description',"rules":[
                  {
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"size":"small","type":"textarea"},model:{value:(scope.row.description),callback:function ($$v) {_vm.$set(scope.row, "description", $$v)},expression:"scope.row.description"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"Opciones","width":"85","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"default","icon":"el-icon-delete-solid","size":"small"},on:{"click":function($event){return _vm.onDeletePackage(scope.$index)}}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }