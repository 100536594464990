<script>
import StepOneComponent from "./StepOneComponent";

import CustomerBoxView from "../boxes/CustomerBoxView";

export default {
  components: {
    "customer-box-view": CustomerBoxView,
  },
  extends: StepOneComponent,
};
</script>
<template>
  <el-row
    type="flex"
    class="row-bg reception-steps-wrapper"
    :gutter="20"
  >
    <el-col :span="24">
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="12">
          <h4>Tipo de Servicio</h4>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="24"
              align="center"
            >
              <el-form-item
                prop="receptionServiceType"
                class="field-client-type"
              >
                <el-radio-group
                  v-model="formData.receptionServiceType"
                  @change="onChangeReceptionServiceType"
                >
                  <el-radio
                    v-for="item in receptionServiceTypes"
                    :key="item.id"
                    :label="item.id"
                  >
                    <i :class="item.icon" /> {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <h4>Responsable de Pago</h4>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="24"
              align="center"
            >
              <el-form-item
                prop="payerResponsible"
                class="field-client-type"
              >
                <el-radio-group
                  v-model="formData.payerResponsible"
                  @change="onChangePayerResponsible"
                >
                  <el-radio
                    v-for="item in payerResponsibles"
                    :key="item.id"
                    :label="item.id"
                  >
                    <i :class="item.icon" /> {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <h4>Datos de Envío</h4>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="12">
              <customer-box-view
                :form-data="formData.sender"
                prop-name="sender"
                title="Remitente"
                :reception="formData"
              />
            </el-col>
            <el-col :span="12">
              <customer-box-view
                :form-data="formData.receiver"
                prop-name="receiver"
                title="Destinatario"
                :reception="formData"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
