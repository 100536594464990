<script>
import AdminMenuView from "../shared/menus/AdminMenuView.vue";
import AdminHeaderView from "../shared/headers/AdminHeaderView.vue";
import AdminFooterView from "../shared/footers/AdminFooterView";
import ModuleWorkRoutesComponent from "./ModuleWorkRoutesComponent";
import ListWorkRoutesView from "./ListWorkRoutesView";
import ListCollectionRoutesView from "../collectionRoutes/ListCollectionRoutesView";
import ListVehiclesView from "../vehicles/ListVehiclessView";
import ListVehicleTypeView from "../vehicleTypes/ListVehicleTypeView";
import ListTraceStatusReasonView from "../traceStatusReasons/ListTraceStatusReasonView";
import ListTransportationTypeView from "../transportationTypes/ListTransportationTypeView";
import ListSctPermissionTypeView from "../sctPermissionTypes/ListSctPermissionTypeView";

export default {
  components: {
    "admin-menu-view": AdminMenuView,
    "admin-header-view": AdminHeaderView,
    "admin-footer-view": AdminFooterView,
    "list-work-routes-view": ListWorkRoutesView,
    "list-collection-routes-view": ListCollectionRoutesView,
    "list-vehicles-view": ListVehiclesView,
    "list-trace-status-reason-view": ListTraceStatusReasonView,
    "list-vehicle-type-view": ListVehicleTypeView,
    "list-transportation-type-view": ListTransportationTypeView,
    "list-sct-permission-type-view": ListSctPermissionTypeView,
  },
  extends: ModuleWorkRoutesComponent,
};
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div
                class="page-title-box d-flex align-items-center justify-content-between"
              >
                <h4 class="mb-0">
                  <i class="mdi mdi-map-marker-path module-header-icon" />
                  Catálogo de Transporte
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="tabActive">
                    <el-tab-pane
                      label="Rutas de Recolección"
                      name="collection-routes"
                    >
                      <span slot="label"><i class="el-icon-map-location me-1" />Rutas de Recolección</span>
                      <list-collection-routes-view v-if="tabActive === 'collection-routes'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Rutas de Trabajo"
                      name="work-routes"
                    >
                      <span slot="label"><i class="uil-map-pin-alt me-1" />Rutas de Trabajo</span>
                      <list-work-routes-view v-if="tabActive === 'work-routes'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Flotilla de Vehículos"
                      name="vehicles"
                    >
                      <span slot="label"><i class="uil-truck me-1" />Flotilla de Vehículo</span>
                      <list-vehicles-view v-if="tabActive === 'vehicles'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipos de Vehículos"
                      name="vehicle-types"
                    >
                      <span slot="label"><i class="uil-truck me-1" />Tipos de Vehículo</span>
                      <list-vehicle-type-view v-if="tabActive === 'vehicle-types'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Motivos de Estatus"
                      name="trace-status-reason"
                    >
                      <span slot="label"><i class="uil-layer-group me-1" />Motivos de Estatus</span>
                      <list-trace-status-reason-view v-if="tabActive === 'trace-status-reason'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipo de Transportación"
                      name="transportation-type"
                    >
                      <span slot="label"><i class="uil-layer-group me-1" />Tipo de Transportación</span>
                      <list-transportation-type-view v-if="tabActive === 'transportation-type'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Permisos SCT"
                      name="sct-permission-type"
                    >
                      <span slot="label"><i class="uil-layer-group me-1" />Permisos SCT</span>
                      <list-sct-permission-type-view v-if="tabActive === 'sct-permission-type'" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>
