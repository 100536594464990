// import _ from "lodash";
// import moment from "moment";
import { MXN } from "../../helpers/MoneyHelper";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import CustomerReception from "./CustomerReception";

export default class CustomerReceptionSummaryOfShipmentsViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  findCollection(customerId, filters) {
    this.api.customerReceptions
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  async bindFormAsync(formData, currentSession) {
    try {
      console.log(formData, currentSession);
      //   if (formData.state === "create") {
      //     // Obteiene las ubicaciones del cliente principal
      //     // const customerResponse = await this.api.customers.getByUserAccount(`Account=${currentSession.email}`)
      //     // this.view.customerId = customerResponse.data.data

      //     const customerLocationsResponse = await this.api.customerLocation.find(
      //       formData.customerId,
      //       `Skip=0&limit=100000&IsActive=true`
      //     );
      //     this.view.senderCustomerLocations = this.mapCustomerLocations(
      //       customerLocationsResponse.data.data
      //     );

      //     // obtiene la información de los costos de servicios
      //     const serviceCostResponse = await this.api.serviceCosts.find(
      //       `Skip=0&limit=100000&IsActive=true`
      //     );
      //     this.view.serviceCosts = this.mapServiceCosts(
      //       serviceCostResponse.data.data
      //     );

      //     // obtiene la información de los contactos por default
      //     const customerContactsResponse = await this.api.customersContact.find(
      //       formData.customerId,
      //       `Skip=0&Limit=10000`
      //     );
      //     this.view.onFindReceiverCustomerContactsResponse(
      //       customerContactsResponse.data
      //     );

      //     // asigna los valores de default del cliente
      //     this.bindCurrentSession(currentSession);
      //   }
    } catch (error) {
      this.view.onError(error);
    }
  }

  import(file) {
    this.api.customerReception
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new CustomerReception(item));
  }

  export(filters) {
    this.api.customerReceptions
      .exportToExcel(
        this.view.$store.getters["admin/getUserActive"].id,
        this.getQueryParameters(filters)
      )
      .then((response) => this.view.onExportExcelResponse(response.data))
      .catch(this.view.onError);
  }

  formatMoney = (value) => `${MXN(value).format()} MXN`;
}
