<template>
  <section
    id="page-title"
    class="page-title page-title-4 bg-overlay bg-overlay-dark bg-parallax"
  >
    <div class="bg-section">
      <img
        :src="url"
        alt="Background"
      >
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="title text-lg-left">
            <div class="title-sub-heading">
              <p>{{ titleSubHeading }}</p>
            </div>
            <div class="title-heading">
              <h1>{{ titleHeading }}</h1>
            </div>
            <div class="clearfix" />
            <ol class="breadcrumb justify-content-lg-start">
              <li
                v-for="(item, index) in breadcrumbItem"
                :key="index"
                class="breadcrumb-item"
                :class="index === breadcrumbItem.length - 1 ? 'active' : ''"
              >
                <a :href="item.href">{{ item.name }}</a>
              </li>
              <!-- <li class="breadcrumb-item">
                <a href="index.html">{{breadcrumbItem[0]}}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="">Servicios</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Solicitar cotización
              </li> -->
            </ol>
          </div>
          <!-- End .title -->
        </div>
        <!-- End .col-lg-8 -->
      </div>
      <!-- End .row-->
    </div>
    <!--  End .container-fluid-->
  </section>
</template>
<script>
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    titleSubHeading: {
      type: String,
      required: true,
    },
    titleHeading: {
      type: String,
      required: true,
    },
    breadcrumbItem: {
      type: Array,
      default: () => [{}],
      required: true,
    },
  },
};
</script>
