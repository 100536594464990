<script>
import FormComponent from "../../../infraestructure/BaseForm";
import PaymentComplementViewModel from "./PaymentComplementViewModel";
import PaymentDocumentListView from "./PaymentDocumentListView";

export default {
  components: {
    "payment-document-list-view": PaymentDocumentListView,
  },
  extends: FormComponent,
  props: {
    formData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    onRemove: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      viewModel: null,
      paymentDate: null,
      bankAccount: null,
      paymentType: null,
      currency: null,
      operationNumber: null,
      exchangeRate: 1,
      amount: 0,
      currencies: [],
      paymentTypes: [],
      bankAccounts: [],
    };
  },
  watch: {
    paymentDate(newData) {
      this.formData.setPaymentDate(newData);
    },
    bankAccount(newData) {
      this.formData.setBankAccount(newData);
    },
    paymentType(newData) {
      this.formData.setPaymentType(newData);
    },
    currency(newData) {
      this.formData.setCurrency(newData);
    },
    amount(newData) {
      this.formData.setAmount(newData);
    },
    exchangeRate(newData) {
      this.formData.setExchangeRate(newData);
    },
    operationNumber(newData){
      this.formData.setOperationNumber(newData);
    }
  },
  created() {
    this.viewModel = new PaymentComplementViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindPaymentForm();
    },
    onBindPaymentFormSuccess(response) {
      this.currencies = response.currencies;
      this.paymentTypes = response.paymentTypes;
      this.bankAccounts = response.bankAccounts;
      
      this.bankAccount = this.formData.bankAccount
      this.paymentType = this.formData.paymentType
      this.operationNumber = this.formData.operationNumber
      this.paymentDate = this.formData.paymentDate
      this.amount = this.formData.amount
      this.exchangeRate = this.formData.exchangeRate
      this.currency = this.formData.currency
    },
    onBindPaymentFormError(error) {
      setTimeout(() => {
        this.onError(error);
      }, 400);
    },
    getTitle() {
      return this.viewModel.getPaymentFormTitle({
        bankAccount: this.bankAccount,
        operationNumber: this.operationNumber,
        paymentDate: this.paymentDate,
      });
    },
    onRemoveItem(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.onRemove(index);
        })
        .catch(() => {});
    },
    onFocus() {
      this.formData.setEditView()
    }
  },
};
</script>
<template>
  <el-collapse-item :name="currentIndex">
    <template slot="title">
      <el-col :span="21">
        {{ getTitle() }}
      </el-col>
      <el-col
        :span="3"
        align="right"
      >
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-delete"
          @click="onRemoveItem(currentIndex)"
        />
      </el-col>
    </template>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="4">
        <el-form-item
          label="Fecha de Pago"
          required
        >
          <el-date-picker
            v-model="paymentDate"
            type="date"
            placeholder="Seleccionar fecha"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          label="Cuenta de Banco"
          required
        >
          <el-select
            v-model="bankAccount"
            placeholder="Seleccionar cuenta de banco"
            value-key="id"
            size="medium"
            filterable
            @focus="onFocus"
          >
            <el-option
              v-for="item in bankAccounts"
              :key="item.id"
              :label="item.getFullDescription()"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          label="Numero de Operación"
          required
        >
          <el-input
            v-model="operationNumber"
            type="text"
            placeholder="Ingresar Numero de Operación"
            size="medium"
            @focus="onFocus"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="Monto"
          required
        >
          <el-input-number
            v-model="amount"
            size="medium" 
            @focus="onFocus"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="Tipo de Cambio"
          required
        >
          <el-input-number
            v-model="exchangeRate"
            size="medium" 
            @focus="onFocus"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="5">
        <el-form-item
          label="Tipo de Pago"
          required
        >
          <el-select
            v-model="paymentType"
            placeholder="Seleccionar cuenta de banco"
            value-key="id"
            size="medium"
            filterable
            @focus="onFocus"
          >
            <el-option
              v-for="item in paymentTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="Moneda"
          required
        >
          <el-select
            v-model="currency"
            placeholder="Seleccionar cuenta de banco"
            value-key="id"
            size="medium"
            filterable
            @focus="onFocus"
          >
            <el-option
              v-for="item in currencies"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <payment-document-list-view
          :form-data="formData"
          prop-name="saleBillPaymentRelateds"
          :on-focus="onFocus"
          :total-amount="amount"
        />
      </el-col>
    </el-row>
  </el-collapse-item>
</template>
