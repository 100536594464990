<script>
import ModalWeightCostComponent from './ModalWeightCostComponent'

export default {
    extends: ModalWeightCostComponent,
}
</script>

<template>
  <el-dialog
    custom-class="dialog-weight-cost"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-cog" /> Configuración de Peso
    </span>
    
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Peso Base (KG)"
            prop="weightBase"
          >
            <el-input-number
              ref="weightBase"
              v-model="formData.weightBase"
              autocomplete="off"
              autofocus="true"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Costo Extra Peso"
            prop="extraWeightCost"
          >
            <el-input-number
              v-model="formData.extraWeightCost"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="I.V.A. Extra Peso"
            prop="extraWeightTax"
          >
            <el-input-number
              v-model="formData.extraWeightTax"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>