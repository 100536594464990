import BaseForm from "../infraestructure/BaseForm";
import SolvencyViewModel from "../../core/features/solvencies/SolvencyViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: 'CustomerSolvency',
      viewModel: null,
      rules: {
        code: [
          {
            required: true,
            message: "Código(s) requerido.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Descripción requerido.",
            trigger: "blur",
          },
        ],
        quantity: [
          {
            required: true,
            message: "Cantidad de guías requerido.",
            trigger: "blur",
          },
        ],
        customer: [
          {
            required: true,
            message: "Cliente requerido.",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Tipo requerido.",
            trigger: "blur",
          },
        ],
      },
      solvencyTypes: SolvencyViewModel.solvencyTypes,
      paymentTypes: SolvencyViewModel.paymentTypes,
      customers: [],
      prepaidPlans: [],
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new SolvencyViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-solvency");
    },
    onFindCustomers(data) {
      this.customers = [];
      if (data) {
        this.viewModel.findCustomers(`Description=${data}&IsActive=true`);
      }
    },
    onFindCustomersResponse(response) {
      this.customers = this.viewModel.mapCustomers(response.data);
    },
    onFindPrepaidPlans(data) {
      this.prepaidPlans = []
      if (data) {
        this.viewModel.findPrepaidPlans(`Description${data}&IsActive=true`)
      }
    },
    onFindPrepaidPlansResponse(response) {
      this.prepaidPlans = this.viewModel.mapPrepaidPlans(response.data)
    },
    onChangePrepaidPlan(data) {
      this.formData.quantity = 0
      if(data) {
        this.formData.quantity = data.quantity
      }
    },
    isPrepaid() {
      return this.viewModel.isPrepaid(this.formData.type)
    }
  },
};
