<script>
import AcceptTermsBoxComponent from "./AcceptTermsBoxComponent";

export default {
  extends: AcceptTermsBoxComponent,
};
</script>

<template>
  <div
    style="margin-top:20px; height:500px;overflow-x: hidden;
                overflow-y: auto; padding:10px;"
    class="request-quote__shipment"
  >
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      />
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        Contrato de adhesión para la prestación de servicios de paquetería y mensajería que celebran por una parte la empresa denominada RAM LOGISTICA S.A DE C.V, y por la otra el cliente cuyos datos figuran al anverso de esta guía, de conformidad con los antecedentes y cláusulas siguientes:
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        Ambas partes contratantes manifiestan su conformidad en celebrar el presente contrato de acuerdo a los términos y condiciones que figuran en ambos lados del presente documento, en este contrato se denominará:
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
      >
        <strong>CLAUSULAS</strong>
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        PRIMERA.- “EL CLIENTE”, declara tener la capacidad legal para contratar el servicio de transportación de paquetería y carga objeto de este contrato.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        SEGUNDA.- “EL CLIENTE”, conviene en:
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        a) Responderá de la veracidad de lo declarado en el anverso de esta guía, y manifiesta bajo protesta de decir verdad que posee legalmente el envío.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        b) Acepta que la falta de veracidad o exactitud en cualquiera las manifestación y datos de la guía, en especial la referida al contenido de la carga, exime a RAM LOGISTIVA S.A DE C.V., de cualquier responsabilidad en el nacimiento, determinación o pago de daños y perjuicios.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        En caso de falsa declaración del contenido “EL CLIENTE”
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        Se obliga a anotar con veracidad, con letra legible y en lugar visible de los envíos, los datos generales de identificación tanto del destinatario, como el remitente, así como el valor y contenido del paquete.En él y demás bienes, así como a terceros en la relación contractual, tanto en su persona como en sus bienes, liberando a será responsable y responderá de los daños y/ u otras perdidas que causen dichos artículos al vehículo de transportación, a las personas que viaje, observancia de la presente cláusula, así como todas las obligaciones contraídas en presente contrato.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        “EL PORTEADOR” desconoce el contenido de aquellos paquetes que vienen debidamente sellados y embalados, por lo que no se hace responsable de inspecciones, decomisos o las faltas las leyes que en su caso ameriten sobre el contenido de estos envíos y que sean realizados por las autoridades Federales, Estatales o Municipales."
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        TERCERA.-  “EL CLIENTE” se obliga a cubrir a “EL PORTEADOR”, en la fecha del depósito, por los envíos la cantidad que se indica en el anverso del presente contrato, que a la vez señala la tarifa vigente, como pago por la prestación del servicio de mensajería a que se refiere este instrumento legal, lo anterior con fundamento en el artículo 57 de la Ley Federal de Protección al Consumidor.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        En caso de convenir con particulares o empresas privadas la conducción masiva del envío de paquetería y carga, los pagos deberán efectuarse en las fechas pactadas, si una vez efectuando el servicio el “EL CLIENTE” no realizara los pagos en las fechas indicadas cubrirá como interés el costo porcentual promedio de captación que publique mensualmente El Banco de México, más diez puntos, y si se tratare de cheques sin fondos.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        CUARTA.- “EL CLIENTE”, se obliga cubrir a RAM LOGISTICA S.A DE C.V., en la fecha de depósito, por los envíos la cantidad que se indica en el anverso del contrato, que a su vez señala su tarifa vigente como pago por la prestación de servicios de envíos,  a que se refiere este instrumento legal, lo anterior con fundamento en el artículo 57 de la Ley Federal de Protección al Consumidor.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        QUINTA.- “EL CLIENTE”, podrá asegurar opcionalmente y a su cargo, sus envíos a través de la aseguradora que tiene contratada “EL PORTEADOR”. En caso de pérdida imputable a “EL PORTEADOR” deberá realizar su reclamo por escrito y presentar las facturas originales que acreditan el valor declarado en el presente contrato.
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
      >
        NOTA: El deducible que aplique la compañía de seguros deberá ser cubierto por “EL CLIENTE”.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        SEXTA.- Tratándose de envíos no asegurados por “EL CLIENTE” en los términos de la cláusula anterior, ambas partes convienen en que:
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        A).-En caso de pérdida de los envíos, por causas imputables a el “PORTEADOR”, este a petición escrita de “EL CLIENTE” le cubrirá enlo estipulado en el artículo 66 fracción V de la Ley de Caminos y Puentes y Autotransportes Federal y su Reglamento, o bien lo aplicable conforme al derecho vigente a fecha del presente contrato
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        B).-En caso de deterioro de los envíos por causas imputables a “EL PORTEADOR”, éste a petición escrita del cliente cubrirá el importe del deterioro siempre y cuando no  exista alguna anotación en el recibo por parte de “EL PORTEADOR” que se le entregó el envío con alguna anomalía.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        SÉPTIMA.- “EL CLIENTE” se obliga a cubrir los derechos aduanales de sus envíos, con destino al extranjero que fijen las autoridades competentes mexicanas y el destinatario los que se originen en el país de su destino.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        OCTAVA.- “EL PORTEADOR” no responsable ante el cliente, destinatario o cualquier tercero, en los casos de pérdida total o parcial, deterioro demora de los envíos, ocasionados por:
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        a).-Causas de fuerza mayor o caso fortuito.
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
      >
        b).-Por acto de disposición de cualquier autoridad.
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
      >
        c).-Por incorrecta y por incompleta información respecto del nombre y domicilio del destinatario.
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
      >
        d).-Por utilizar un embalaje o empaque incorrecto e inadecuado.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        NOVENA.- Para efectos del presente contrato se entiende que “EL CLIENTE” es propietario de los envíos hasta el momento en que llega a su lugar de destino, posteriormente una vez que es entregada a su destinatario, este último se considera el propietario de aquella.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA.- “EL PORTEADOR” no está obligado a prestar el servicio objeto del contrato tratándose de artículos o materiales prohibido, entendiéndose por estos los corrosivos, inflamables, explosivos, armas, detonantes, gases comprimidos, sustancias tóxicas, sustancias infecciosas, sustancias radioactivas, moneda metálica, papel moneda, líquidos, joyas, títulos negociables, antigüedades, metales preciosos, bebidas embriagantes, plantas, animales, materiales obscenos ofensivos a la moral y las buenas costumbres, artículos de procedencia extranjera que no acrediten su legal estancia en el país.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA PRIMERA.- Las partes aceptan que el destinatario al recibir los envíos en el plazo, convenido estampará su firma en el anverso del presente contrato. Ambas partes convienen en que en caso de los envíos no lleguen a su destino por perdida o lleguen deteriorados, “EL CLIENTE” podrá formular su reclamación por escrito ante “EL PORTEADOR” para obtener la indemnización correspondiente, en su término que no exceda de 05 días naturales contados a partir de la fecha siguiente al que venció el plazo de la entrega, ya que ha pasado este tiempo se tendrá por recibido a satisfacción del destinatario.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA SEGUNDA.- Si transcurridos 45 días naturales contados a partir del plazo máximo fijado para la entrega del envío, el consignatario no acude a recibir la mercancía objeto del presente contrato o no se localiza en el domicilio señalado, “EL PORTEADOR” se reserva la facultad de consignar el envío en un almacén general de depósito para su destrucción total posterior a 5 días hábiles.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA TERCERA.- Tratándose de mercancía perecedera, si el consignatario no lo retira dentro del término de 72 horas a partir del plazo máximo fijado para la llegada de su destino “EL PORTEADOR” podrá disponer de ella para su destrucción únicamente, lo que se efectuará conforme a los lineamentos correspondientes de las autoridades competentes.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA CUARTA.- “EL PORTEADOR” en cumplimiento del artículo 57 de la ley general de protección al consumidor exhibirá al público las tarifas vigentes aplicables a la fecha del depósito del envío.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
      style="margin-bottom:20px"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        DÉCIMA QUINTA.- Para la interpretación y cumplimiento de las obligaciones de este contrato, las partes se someten a la jurisdicción de las autoridades y tribunales competentes de la ciudad de La Paz, Baja California Sur.
      </el-col>
    </el-row>
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
      >
        <el-form-item
          label=""
          prop="terms.acceptTermsAndConditions"
        >
          <el-checkbox
            v-model="formData.terms.acceptTermsAndConditions"
            @change="onChangeTerms"
          >
            Aceptación de Terminos y Condiciones
          </el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
