import BasePage from "../../infraestructure/BasePage";
import BaseForm from "../../infraestructure/BaseForm";
import TrackingViewModel from "../../../core/features/trackings/TrackingViewModel";

export default {
  extends: BasePage,
  BaseForm,
  data() {
    return {
      viewModel: null,
      activeName: "1",
      activeTabName: "historyTrace",
      searchResult: false,
      isMapLoading: false,
      formDataTracking: {
        trackingNumberForm: "",
      },
      rules: {
        trackingNumberForm: [
          {
            required: true,
            message: "El Código de rastreo es requerido.",
            trigger: "blur",
          },
        ],
      },
      formData: Object,
      loading: false,
    };
  },
  created() {
    this.viewModel = new TrackingViewModel(this);
    this.formData = null;
  },
  
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.viewModel.findItemTrackingNumber(
            this.formDataTracking.trackingNumberForm
          );
          this.setLoading();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.formDataTracking.trackingNumberForm = "";
      this.formData = null;
      this.searchResult = false;
      this.$refs[formName].resetFields();
    },
    onFindItemTrackingNumberResponse(response) {
      this.loading = true;
      if (response.data.length !== 0) {
        this.searchResult = true;
      } else {
        this.searchResult = false;
      }
      this.formData = response.data;
      console.log(this.viewModel)
      this.viewModel.bindForm(this.formData)
    },
    setLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },

    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status)
    },
    onGetColorTrackingStatus(status) {
      return this.viewModel.getColorTrackingStatus(status)
    },

    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status)
    },
    onCollapseChange() {
      this.activeTabName = 'historyTrace'
    },
    onTabChange(tab, item) {
      if(tab.name === 'deliveryLocation') {
        this.isMapLoading = true
        setTimeout(() => {
          this.viewModel.startMap(item)
          this.isMapLoading = false
        }, 400);
      }
    }

  },
};
/*       this.formData.forEach((element) => {
        element.historyTrace.forEach((item) => {
          console.log('historyTrace ',item.status)
          item.icon =  this.onGetIconTrackingStatus(item.status)
          item.color = this.onGetStyleTrackingStatus(item.status)
          item.nameStatus = this.onGetNameTrackingStatus(item.status)
        });
      }); */