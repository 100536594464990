<script>
import ModalShipmentSearchReceptionComponent from "./ModalShipmentSearchReceptionComponent";

export default {
  extends: ModalShipmentSearchReceptionComponent,
};
</script>

<template>
  <el-dialog
    width="30%"
    :visible.sync="config.isVisible"
    :close-on-click-modal="false"
    append-to-body
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-search" /> Buscar Guía de Envio
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :before-close="onCancel"
      :close-on-click-modal="false"
      :model="formData"
      :label-position="'top'"
      class="guide"
    >
      <el-row :gutter="10">
        <el-form-item
          label="Guía"
          prop="guide"
        >
          <el-select
            v-model="formData.reception"
            placeholder="Buscar Guía..."
            filterable
            clearable
            value-key="id"
            remote
            size="medium"
            :remote-method="onFindTrackingDocument"
          >
            <el-option
              v-for="item in trackingDocuments"
              :key="item.id"
              :label="item.trackingNumber"
              :value="item"
            />
          </el-select> 
        </el-form-item>
      </el-row>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="config.isVisible = false"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
