// import _ from 'lodash'
import BaseModel from "../../infraestructure/BaseModel"

export default class SaleBillPayment extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    setPaymentDate(data) {
        this.paymentDate = data
    }

    setBankAccount(data) {
        this.bankAccount = data
    }

    setPaymentType(data) {
        this.paymentType = data
    }

    setCurrency(data) {
        this.currency = data
    }

    setAmount(data) {
        this.amount = data
    }

    setExchangeRate(data) {
        this.exchangeRate = data
    }

    setOperationNumber(data) {
        this.operationNumber = data
    }

    setEditView() {
        if(this.id){
            this.updateState = this.UPDATE_STATUS.UPDATED
        }
    }

    deleteSaleBillPaymentDocumentRelated(currentIndex){
        var item = this.getSaleBillPaymentDocumentRelated()[currentIndex]
        if(item) {
            var index = this.saleBillPaymentRelateds.indexOf(item)
            if(index != -1) {
                if(item.id) {
                    this.saleBillPaymentRelateds[index].updateState = this.UPDATE_STATUS.REMOVED
                } else {
                    this.saleBillPaymentRelateds.splice(index, 1)
                }
            }
        }
    }

    getSaleBillPaymentDocumentRelated() {
        console.log(this.saleBillPaymentRelateds)
        return this.saleBillPaymentRelateds.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED)
    }
}