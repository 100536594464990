import _ from 'lodash'

export default class ProductTypesHelper {
    
    /**
     * Este metodo es utilizado para obtener el ultimo nodo del arbol de tipos de productos
     * @param {*} collection 
     * @returns 
     */
    static getSelectedProductType(collection) {
        return _.last(collection)
    }

    /**
     * Este metodo es utilizado para verificar si la colección de proudctos esta vacia
     * @param {Array} collection 
     * @returns {Boolean}
     */
    static isProductTypesTreeEmpty(collection) {
        return _.isEmpty(collection)
    }

    /**
     * Este metodo es utilizado para buscar y obtener de forma recursiva el tipo de producto que se va a seleccionar
     * @param {*} id 
     * @param {*} productTypes 
     * @returns 
     */
    static getSelectedTreePathProductTypes(id, productTypes) {
        var result, done = false, path = {};

        _.forEach(productTypes, (parent, index) => {
            path[index] = [];
            path[index].push(parent.value);
            getTreePath(parent, id, index)
        })

        function getTreePath(node, target, root) {
            if (node.value === target) {
                done = true;
                result = path[root];
            }
            _.forEach(node.children, (item) => {
                if (!done) {
                    if (item.value === target) {
                        //if we found our target push it to the path
                        path[root].push(target);
                        //set result to the completed path
                        result = path[root];
                        //set done to true to exit the search
                        done = true;
                        return;
                    } else {
                        //if the node does not match we need to check for children                        
                        if (!_.isEmpty(item.children)) {
                            //if node has children, push the key into our path and check the children for our target
                            path[root].push(item.value);
                            return getTreePath(item, target, root);
                        }
                        //no children means our search of this branch is over
                        return;
                    }
                }
            })
            if (!done) {
                path[root].pop();
            }
            return;
        }

        return result;
    }

    /**
     * Este metodo es utilizado para mapear el arbol de tipos de productos
     * @param {*} collection 
     * @returns 
     */
    static mapProductTypesTree(collection) {
        ProductTypesHelper.removeEmptyChildrenProductTypeTrees(collection)
        return collection
    }

    /**
     * Este metodo es utilizado para eliminar los hijos en el arbol de tipos de productos
     * @param {*} productTypes 
     */
    static removeEmptyChildrenProductTypeTrees(productTypes) {
        for (const indexItem in productTypes) {
            if (Object.prototype.hasOwnProperty.call(productTypes[indexItem], 'children')) {
                if (productTypes[indexItem].children.length == 0) {
                    delete productTypes[indexItem].children;
                } else {
                    ProductTypesHelper.removeEmptyChildrenProductTypeTrees(productTypes[indexItem].children);
                }
            }
        }
    }
}