export default class PaymentMethod {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            isActive: this.isActive
        }
    }

    getFullName() {
        return `${this.code} - ${this.name}`
    }
}