import _ from "lodash";
import BaseViewModel from "../../../core/infraestructure/BaseViewModel";

export default class RequestQuoteViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  requestPicking(formData) {
    this.api.orders
      .requestPicking(this.mappingPicking(formData))
      .then((response) => this.view.onRequestPickingResponse(response.data))
      .catch(this.view.onRequestPickingError);
  }

  mappingPicking(formData) {
    return {
      customerCode: formData.customer.code,
      orderNumber: formData.order.orderNumber,
      sender: this.mapShipmentContact(formData.sender),
      receiver: this.mapShipmentContact(formData.receiver),
      orderFiscal: this.mapOrderFiscal(formData.orderFiscal),
      serviceCostId: (formData.serviceCost) ? formData.serviceCost.id : null,
      payerResponsible: formData.payerResponsible,
      traces: formData.packages,
    }
  }

  mapShipmentContact(contact) {
    return {
      name: contact.contactName,
      email: contact.contactEmail,
      phone: contact.contactPhone,
      observation: contact.observation,
      addressId: contact.customerLocation.id,
      mainStreet: contact.customerLocation.mainStreet,
      secondaryStreet: contact.customerLocation.secondaryStreet,
      intNumber: contact.customerLocation.intNumber,
      extNumber: contact.customerLocation.extNumber,
      postalCodeId: contact.customerLocation.postalCode.id,
      neighborhoodId: contact.customerLocation.neighborhood.id
    }
  }

  mapOrderFiscal(orderFiscal) {
    return {
      fiscalName: orderFiscal.fiscalName,
      fiscalCode: orderFiscal.fiscalCode,
      fiscalEmail: orderFiscal.fiscalEmail,
      mainStreet: orderFiscal.location.mainStreet,
      secondaryStreet: orderFiscal.location.secondaryStreet,
      intNumber: orderFiscal.location.intNumber,
      extNumber: orderFiscal.location.extNumber,
      postalCodeId: (orderFiscal.location && orderFiscal.location.postalCode) ? orderFiscal.location.postalCode.id : null,
      neighborhoodId: (orderFiscal.location && orderFiscal.neighborhood) ? orderFiscal.location.neighborhood.id: null
    }
  }

  upsertCustomer(customer) {
    if (customer.id) {
      this.api.customers.update(customer.id, this.mapUpdateCustomer(customer))
        .then((response) => this.view.onUpdateCustomerResponse(response.data))
        .catch((error) => this.view.onUpdateCustomerError(error));
    } else {
      this.api.customers.register(this.mapRegisterCustomer(customer))
        .then((response) => this.view.onRegisterCustomerResponse(response.data))
        .catch((error) => this.view.onRegisterCustomerError(error));
    }
  }

  mapRegisterCustomer(customer){
    return {
      description: customer.contactName,
      fiscalName: customer.fiscalName,
      fiscalCode: customer.fiscalCode,
      contactName: customer.contactName,
      contactPhone: customer.contactPhone,
      contactEmail: customer.contactEmail,
      address: {
        description: "Principal",
        mainStreet: customer.mainStreet,
        secondaryStreet: customer.secondaryStreet,
        extNumber: customer.extNumber,
        intNumber: customer.intNumber,
        postalCodeId: customer.postalCode.id,
        neighborhoodId: customer.neighborhood.id
      },
    }
  }

  mapUpdateCustomer(customer) {
    return {
      description: customer.contactName,
      fiscalName: customer.fiscalName,
      fiscalCode: customer.fiscalCode,
      contactName: customer.contactName,
      contactPhone: customer.contactPhone,
      contactEmail: customer.contactEmail,
      customerLocations: [
        {
          id: customer.addressId,
          description: "Principal",
          mainStreet: customer.mainStreet,
          secondaryStreet: customer.secondaryStreet,
          extNumber: customer.extNumber,
          intNumber: customer.intNumber,
          postalCodeId: customer.postalCode.id,
          neighborhoodId: customer.neighborhood.id,
          updateState: 2,
        },
      ],
    }
  }

  findCustomer(id) {
    this.api.customers
      .find(id)
      .then((response) => this.view.onFindCustomerResponse(response.data))
      .catch((error) => this.view.onFindCustomerError(error));
  }

  isPackageValid(collection) {
    return !_.isNil(collection) && !_.isEmpty(collection);
  }

  verifyRFC(value, callback) {
    if (value === 'XAXX010101000') {
      this.view.isPostalCodeLoading = false
      callback();
    } else {
      this.api.bills
      .verifyRfc(value)
      .then((response) => {
        setTimeout(() => {
          if (response.data.isValid) {
            callback();
          } else {
            callback(new Error(response.data.message));
          }
        this.view.isPostalCodeLoading = false
        }, 400);
      })
      .catch(this.view.onError);
    }
    
  }
}