<script>
import OrderDetailBoxComponent from "./OrderDetailBoxComponent";

export default {
  components: {},
  extends: OrderDetailBoxComponent,
};
</script>
<template>
  <div
    v-if="order"
    v-loading="isDetailLoading"
    class="request-code__order__detail"
  >
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="center"
      >
        <h4 class="order-details">
          Detalles de Orden
        </h4>
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
      >
        <i
          type="success"
          class="el-icon-success icon__success"
        />
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
        style="margin-top:20px;"
      >
        <h3>No. Cliente: {{ order.customer.code }}</h3>
      </el-col>      
    </el-row>
    
    
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="center"
      >
        <h5 class="tracking-number">
          <i class="el-icon-circle-check" /> NO. GUÍA:
          {{ order.reception.trackingNumber }}
        </h5>
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :span="24"
        align="center"
      >
        <el-button-group align="center">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-download"
            @click="onDownloadDocument"
          >
            Descargar Documentación de Paquetes
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-download"
            @click="onDownloadVerificationCode"
          >
            Descargar Qr Verificación de Entrega
          </el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg order-places-header"
      :gutter="20"
    >
      <el-col
        :span="12"
        align="center"
      >
        <h4>Origen</h4>
      </el-col>
      <el-col
        :span="12"
        align="center"
      >
        <h4 class="order-details">
          Destino
        </h4>
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg order-places-content"
      :gutter="20"
    >
      <el-col
        :span="12"
        align="center"
      >
        <p class="place">
          {{ order.reception.origin }}
        </p>
        <p class="address">
          {{ order.reception.sender }}
        </p>
      </el-col>
      <el-col
        :span="12"
        align="center"
      >
        <p class="place">
          {{ order.reception.destination }}
        </p>
        <p class="address">
          {{ order.reception.receiver }}
        </p>
      </el-col>
    </el-row>
    <el-row
      v-if="!isFirstLoading"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="24"
        align="center"
        style="margin-top:20px;"
      >
        <small class="notification-warning">
          <i class="el-icon-warning" /> Utilize su numero de cliente para
          cargar su información la proxima vez que solicite nuestros servicios.
        </small>
      </el-col>
    </el-row>

    <!-- <el-row type="flex" class="row-bg" :gutter="20">
        <p>Guía: {{order.reception.trackingNumber}}</p>
    </el-row> -->
  </div>
</template>
