import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import WorkRoutesViewModel from '../../core/features/workRoutes/WorkRoutesViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'WorkRoute',
            viewModel: null,
            activeName: 'Lista',
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                branchOffice: '',
                status: '',
            },
            branchOffices:[],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR RUTAS DE TRABAJO',
                onUploadImport: 'on-upload-import-work-routes',
                onCancelImport: 'on-cancel-import-work-routes'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-work-route', this.onSearch)
        this.onMountedEvent('on-upload-import-work-routes', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-work-routes', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-work-route', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-work-routes', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-work-routes', this.onCancelImport);
    },
    created() {
        this.viewModel = new WorkRoutesViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
           this.onSearch() 
        },
        onSelectWorkRoute() { },
        onCreate(){
            this.item = this.viewModel.create({
                isActive: true,
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        searchbranchOfficeFilter(data){
            if(data) {
                this.addFilter('BranchOfficeId', data.id)
            } else {
                this.removeFilter('BranchOfficeId')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onFindBranchOffices(data){
            if(data) {
                this.viewModel.findbranchOffices(`Name=${data}`)
            } else {
                this.branchOffices = []
            }
        },
        onFindBranchOfficesResponse(response){
            this.branchOffices = response.data
        },
    }
}