import BaseViewModel from "../../infraestructure/BaseViewModel";
import Place from "../places/Place"
import ServiceCost from "./ServiceCost";
import WeightCost from "../weightCosts/WeightCost";

export default class ServiceCostViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    //#region MAIN FEATURE

    create(data) {
        return new ServiceCost(data, "create");
    }

    edit(data) {
        return new ServiceCost(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.serviceCosts
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.serviceCosts
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.serviceCosts
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.serviceCosts
            .find(this.getQueryParameters(filters))
            .then((response) => {
                this.view.onSearchResponse(response.data, response.headers);
                this.view.onSearchResponseMaxCost(response.data);
            })
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.serviceCosts
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    bindForm(formData) {
        if (formData.state === "update") {
            this.view.origins = formData.origin ? [formData.origin] : [];
            this.view.destinations = formData.destination
                ? [formData.destination]
                : [];
        }
    }

    import(file) {
        this.api.serviceCosts
            .import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    export() {
        this.api.serviceCosts
            .export()
            .then((response) => this.view.onExportResponse(response.data))
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            code: '',
            description: '',
            origin: '',
            destination: '',
            amountMin: '',
            amountMax: '',
            amount: [0,0],
            status: '',
        };

        this.view.removeFilter('Code');
        this.view.removeFilter('Description');
        this.view.removeFilter('OriginPlaceId');
        this.view.removeFilter('DestinationPlaceId');
        this.view.removeFilter('IsActive');
        this.view.removeFilter('AmountMin');
        this.view.removeFilter('AmountMax');
        this.view.onSearch();
    }

    bindList() {
        this.api.places.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.origins = this.mapPlaces(response.data.data)
                return this.api.destinations.find(`Skip=0&Limit=1000000&IsDestination=true&IsActive=true`);
            })
            .then((response) => {
                this.view.destinations = this.mapPlaces(response.data.data)
                setTimeout(() => {
                    this.view.isListLoading = false;
                    this.view.onSearch()
                }, 400);
            })
            .catch(this.view.onError);
    }

    mapCollection(collection) {
        return collection.map((item) => new ServiceCost(item));
    }

    mapPlaces(collection) {
        return collection.map((item) => new Place(item));
    }

    //#endregion

    //#region QUERIES

    findOrigins(criteria) {
        this.api.places
            .find(criteria)
            .then((response) => this.view.onFindOriginsResponse(response.data))
            .catch(this.view.onError);
    }

    findDestinations(criteria) {
        this.api.places
            .find(criteria)
            .then((response) => this.view.onFindDestinationsResponse(response.data))
            .catch(this.view.onError);
    }

    //#endregion QUERIES

    findWeightCosts() {
        this.api.weightCosts
            .find()
            .then((response) => this.view.onFindWeightCostsResponse(response.data))
            .catch(this.view.onError);
    }

    createWeightCost() {
        return new WeightCost({
            state: "create",
        });
    }

    editWeightCost(data) {
        var item = new WeightCost(data);
        item.state = "update";
        return item;
    }

    saveWeighCost(data) {
        if (data.state === "create") {
            this.api.weightCosts
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.weightCosts
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }
}
