import BaseList from '../../../infraestructure/BaseList'
import BoxViewModel from './BoxViewModel'
export default {
    extends: BaseList,
    data() {
        return {
            isListLoading: false,
            collection: [],
            viewModel: null,
            modal: {
                isVisible: false,
                isLoading: false,
            },
            item: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-customer', this.onResponseCustomer)
        this.onMountedEvent('on-submited-location', this.onResponseLocation)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-customer', this.onResponseCustomer)
        this.onBeforeDestroyEvent('on-submited-location', this.onResponseLocation)
    },
    created() {
        this.viewModel = new BoxViewModel(this)
        this.onActive()
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        isLocationSelected: {
            type: Boolean,
            require: true
        }
    },
    watch: {
        'formData.id'(customerId) {
            if(customerId) {
                this.viewModel.findLocationsByCustomer(customerId)
            }
        }
    },
    methods: {
        onActive() {
            if(this.formData && this.formData.id) {
                this.viewModel.findLocationsByCustomer(this.formData.id)
            }
        },
        onAddLocation() {
            if(this.viewModel.isCustomerAvailable(this.formData)){
                this.item = this.viewModel.createCustomerLocation({
                    customer: this.formData,
                })
                this.modal.isVisible = true
            } else {
                this.notifyWarning("Información de cliente requerida.")
            }
        },
        onChangeOptionCustomerLocations(event) {
            if(event.command === 'delete') this.onRemoveLocation(event.item.id)
            if(event.command === 'edit') this.onEditLocation(event.item.id)
        },
        onResponseCustomer(response) {
            this.formData.id = response.data.id
            this.formData.code = response.data.code
            this.modal.isVisible = false
            this.notifySuccess(response.message)
            this.viewModel.findLocationsByCustomer(response.data.id)
        },
        onCustomerLocationResponse(response) {
            this.modal.isVisible = false
            this.notifySuccess(response.message)
            this.viewModel.findLocationsByCustomer(this.formData.id)
        },
        onFindLocationsByCustomerResponse(response) {
            this.collection = response.data
        },
        onFindLocationsByCustomerError(error){
            this.onError(error)
        },
        onRemoveLocation(locationId){
            this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
              })
                .then(() => {
                  this.viewModel.removeLocation(this.formData.id, locationId);
                })
                .catch(() => {});
        },
        onRemoveLocationResponse(response) {
            this.notifySuccess(response.message)
            this.viewModel.findLocationsByCustomer(this.formData.id)
        },
        onRemoveLocationError(error) {
            this.onError(error)
        },
        onEditLocation(id) {
            this.viewModel.findItemLocation(this.formData.id, id)
        },
        onFindItemLocationResponse(response) {
            this.item = this.viewModel.editCustomerLocation(response.data)
            this.item.customer = this.formData
            this.modal.isVisible = true
        },
        onFindItemLocationError(error) {
            this.onError(error)
        },
        onResponseLocation(response) {
            this.notifySuccess(response.message)
            this.modal.isVisible = false
            this.viewModel.findLocationsByCustomer(this.formData.id)
        },
        onChangeCustomerLocation(item) {
            if(item) this.viewModel.findSelectLocation(this.formData.id, item.id)
            else this.$set(this.formData, 'customerLocation', null)
        },
        onFindSelectedLocationResponse(response){
            this.formData.customerLocation = response.data
        },
        onFindSelectedLocationError(error) {
            this.onError(error)
        }
    }
}