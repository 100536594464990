import BaseForm from '../infraestructure/BaseForm'
import UsersViewModel from '../../core/features/users/UsersViewModel'


export default {
    extends: BaseForm,
    data() {   
        return {
            modulePermissions: 'User',
            viewModel: null,
            editPass: false,
            roles : [],
            rules:{
                userName: [{
                    required: true,
                    message: 'Nombre de usuario requerido.',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: 'El correo electrónico es requerido.',
                    trigger: 'blur'
                },
                { type: 'email', 
                  message: 'Ingrese una dirección de correo electrónico correcta', 
                  trigger: ['blur', 'change'] 
                }],
                roleName:[{
                    required: true,
                    message: 'El rol de usuario es requerido.',
                    trigger: 'blur'
                }],
                password: [
                    {
                        required: true,
                        message: 'La contraseña es requerida.',
                        trigger: 'blur'
                    },
                    { validator: this.onValidatePassword, trigger: 'blur' }
                    ],
                passwordConfirmation: [
                    {
                        required: true,
                        message: 'La confirmación de la contraseña es requerida.',
                        trigger: 'blur'
                    },
                    { validator: this.onValidatePasswordConfirmation, trigger: 'blur' }
                ]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new UsersViewModel(this)
        this.onActive()
        this.viewModel.findRole()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            if (this.formData.state === 'update' && !this.editPass) {
                this.$refs[formName].rules['password'][0].required = false
                this.$refs[formName].rules['passwordConfirmation'][0].required = false
            }
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-user')
        },
        onFindRoles() {
            this.viewModel.findRoles(`IsActive=true`)
        },
        onFindRoleResponse(response) {
            this.roles = response.data
        },
        onClearFieldForm(formName){
            if (this.editPass){
            this.$refs[formName].rules['password'][0].required = this.editPass
            this.$refs[formName].rules['passwordConfirmation'][0].required = this.editPass
        }
            else{
                this.$refs[formName].rules['password'][0].required = !this.editPass
                this.$refs[formName].rules['passwordConfirmation'][0].required = !this.editPass
            }
            this.$refs[formName].clearValidate(['password', 'passwordConfirmation'])
        },
        onValidatePassword(rule, value, callback){
            if (value === '') {
              callback(new Error('La contraseña es requerida.'));
            } else {
              if (this.formData.passwordConfirmation !== '') {
                this.$refs.formData.validateField('passwordConfirmation');
              }
              callback();
            }
          },
          onValidatePasswordConfirmation(rule, value, callback){
            if (value === '') {
              callback(new Error('Ingrese la contraseña nuevamente'));
            } else if (value !== this.formData.password) {
              callback(new Error('La contraseña y confirmación de contraseña no coinciden'));
            } else {
              callback();
            }
        }
    }
}