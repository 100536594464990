import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Role from "./Role";


export default class RolesViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  create(data) {
    return new Role(data, "create");
  }
  edit(data) {
    return new Role(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.roles
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.roles
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }

  }

  delete(id) {
    this.api.roles
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.roles
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.roles
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  import(file) {
    this.api.roles.import(file)
        .then((response) => this.view.onImportResponse(response.data))
        .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
        name: '',
        status: '',
    };

    this.view.removeFilter('Name');
    this.view.removeFilter('IsActive');
    this.view.onSearch();
}

  bindForm(formData) {
    this.api.permissions
      .getPermissions()
      .then((response) => {
        let modules = response.data.permissionsList;
        modules.forEach((item) => {
                 item.permissions.forEach((itemPermission) => {
                  itemPermission.isActive = false;
                 })
           item.selectedPermissions = [];
        });
        if (formData.state === "create"){
          this.view.modules = modules
        }else if (formData.state === "update") {
          this.api.permissions
            .getPermissionsRoleById(formData.id)
            .then((responsePermissions) =>{
                modules.forEach((module) => {
                    module.permissions.forEach((itemPermission) => {
                        itemPermission.isActive = this.hasPermission(itemPermission.codePermission, responsePermissions.data.permissions)
                        if(itemPermission.isActive) {
                            module.selectedPermissions.push(itemPermission.codePermission)
                        }
                    })
                     
                  });
                  this.view.$set(this.view,"modules",modules)
                  this.view.$forceUpdate()
            }) 
            .catch(this.view.onError);
        }
      }) 
      .catch(this.view.onError);
  }

  mapRolePermission(roleName, modules) {
    return {
      roleName: roleName,
      parameters: this.mapModulePermission(modules),
    };
  }

  mapModulePermission(modules) {
    return modules.map((module) => {
      return {
        codeModule: module.code,
        permissions: this.mapSelectedPermission(
          module.permissions,
          module.selectedPermissions
        ),
      };
    });
  }

  mapSelectedPermission(permissions, selectedPermissions) {
    return selectedPermissions.map((permission) => {
      var item = _.find(permissions, { codePermission: permission });
      if (item) {
        return {
          code: permission,
          value: item.name,
        };
      }
    });
  } 

  saveRolePermissions(data) {
    this.api.permissions
      .savePermissions(data)
      .then((response) => {
        this.view.onSavePermissionsResponse(response.data)
      })
      .catch(this.view.onError); 
  }

   hasPermission(code,permissions){
         var item = _.find(permissions, {code: code})
         return !_.isNil(item)
   }

  mapCollection(collection) {
    return collection.map((item) => new Role(item));
  }
}
