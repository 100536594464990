import BaseModule from '../infraestructure/BaseModule'
import SolvencyViewModel from '../../core/features/solvencies/SolvencyViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new SolvencyViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}