import BaseApi from '../../infraestructure/BaseApi'

export default class ApiShipment extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    printManifest(deliveryId) {
        return this.client.get(`${this.endPoint}/generate-manifest/${deliveryId}`)
    }

    
}