import BaseForm from '../infraestructure/BaseForm'
import CustomersAccountViewModel from "../../core/features/customerAccount/CustomersAccountViewModel";


export default {
    extends: BaseForm,
    data() {
        var validatePassword = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('La contraseña es requerida.'));
            } else {
              if (this.formData.passwordConfirmation !== '') {
                this.$refs.formData.validateField('passwordConfirmation');
              }
              callback();
            }
          };
          var validatePasswordConfirmation = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('Ingrese la contraseña nuevamente'));
            } else if (value !== this.formData.password) {
              callback(new Error('La contraseña y confirmación de contraseña no coinciden'));
            } else {
              callback();
            }
        }; 
        return {
            viewModel: null,
            editPass: false,
            roles : [],
            rules:{
                firstName: [{
                    required: true,
                    message: 'Nombre(s) requerido.',
                    trigger: 'blur'
                }],
                userName: [{
                    required: true,
                    message: 'Nombre de usuario requerido.',
                    trigger: 'blur'
                }],
                lastName: [{
                    required: true,
                    message: 'Apellido(s) requerido.',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: 'El correo electrónico es requerido.',
                    trigger: 'blur'
                },
                { type: 'email', 
                  message: 'Ingrese una dirección de correo electrónico correcta', 
                  trigger: ['blur', 'change'] 
                }],
   /*              roleName:[{
                    required: true,
                    message: 'El rol de usuario es requerido.',
                    trigger: 'blur'
                }], */
                password: [
                    {
                        required: true,
                        message: 'La contraseña es requerida.',
                        trigger: 'blur'
                    },
                    { validator: validatePassword, trigger: 'blur' }
                    ],
                passwordConfirmation: [
                    {
                        required: true,
                        message: 'La confirmación de la contraseña es requerida.',
                        trigger: 'blur'
                    },
                    { validator: validatePasswordConfirmation, trigger: 'blur' }
                ]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },


   
    created() {
        this.viewModel = new CustomersAccountViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            if (this.formData.state === 'update' && !this.editPass) {
                this.$refs[formName].rules['password'][0].required = false
                this.$refs[formName].rules['passwordConfirmation'][0].required = false
            }
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-user-profile-customer')
        },            
        onClearFieldForm(formName){
            if (this.editPass){
            this.$refs[formName].rules['password'][0].required = this.editPass
            this.$refs[formName].rules['passwordConfirmation'][0].required = this.editPass
        }
            else{
                this.$refs[formName].rules['password'][0].required = !this.editPass
                this.$refs[formName].rules['passwordConfirmation'][0].required = !this.editPass
            }
            this.$refs[formName].clearValidate(['password', 'passwordConfirmation'])
        }
    }
}