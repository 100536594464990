import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import SctPermissionTypeViewModel from '../../core/features/sctPermissionTypes/SctPermissionTypeViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'SctPermissionType',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                transportKey: '',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR PERMISOS SCT',
                onUploadImport: 'on-upload-import-sct-permission-types',
                onCancelImport: 'on-cancel-import-sct-permission-types'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-sct-permission-type', this.onSearch)
        this.onMountedEvent('on-upload-import-sct-permission-types', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-sct-permission-types', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-sct-permission-type', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-sct-permission-types', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-sct-permission-types', this.onCancelImport);
    },
    created() {
        this.viewModel = new SctPermissionTypeViewModel(this)
        this.onActive()
    },

    methods: {
        onActive() {
            this.onSearch()
        },
        onSelectSctPermissionType() { },
        onCreate() {
            this.item = this.viewModel.create({ isActive: true })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByTransportKey: _.debounce(function (text) {
            if (text) {
                this.addFilter('TransportKey', text)
            } else {
                this.removeFilter('TransportKey')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}