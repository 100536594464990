import BaseForm from '../infraestructure/BaseForm'
import VehiclesViewModel from '../../core/features/vehicles/VehiclesViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Vehicle',
            viewModel: null,
            rules:{
                equipmentNumber: [{
                    required: true,
                    message: 'Número de equipo es requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                plateNumber: [{
                    required: true,
                    message: 'No. Placa requerido.',
                    trigger: 'blur'
                }],
                policyNumber: [{
                    required: true,
                    message: 'No. Poliza requerido.',
                    trigger: 'blur'
                }],
                insuranceCarrier: [{
                    required: true,
                    message: 'Aseguradora requerido.',
                    trigger: 'blur'
                }],
                branchOffice: [{
                    required: true,
                    message: 'La sucursal es requerida.',
                    trigger: 'blur'
                }],
                vehicleType: [{
                    required: true,
                    message: 'Tipo de Vehículo requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }],
                grossWeight: [{
                    required: true,
                    message: 'Peso Bruto Vehícular es requerido.',
                    trigger: 'blur'
                }]
            },
            branchOffices: [],
            vehicleTypes: [],
            sctPermissionTypes: [],
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new VehiclesViewModel(this)
        this.onActive()
    },
    methods: {
        async onActive() {
            await this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-vehicle')
        }
    }
}