export default class Neighborhood {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }

    getFullDescription() {
      return `${this.name} (${this.city})`
    }
    
    toSend() {
      return {
        id: this.id,
        name: this.name.trim(),
        postalCodeId: (this.postalCode) ? this.postalCode.id : null,
        isActive: this.isActive,
        description: this.description
      }
    }
  }