import BaseForm from "../../infraestructure/BaseForm";
import SaleViewModel from "../../../core/features/sales/SaleViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      conceptSelectedIndex: null,
      isLoadingTable: false,
      billConcepts: [],
    };
  },
  created() {
    this.viewModel = new SaleViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onActive() {
      this.viewModel.api.billConcepts
        .find(`Skip=0&Limit=1000000&IsActive=true`)
        .then((response) => {
          this.billConcepts = response.data.data;
          return this.viewModel.api.weightCosts.find();
        })
        .then((response) => {
          if (response.data.data) {
            this.weightCostConfig = response.data.data[0];
            
            this.$set(
              this.formData,
              "extraWeightTax",
              this.weightCostConfig.extraWeightTax
            );
          } else {
            throw new Error("No hay parametros de peso configurados");
          }
        })
        .catch((error) => this.onError(error));
    },
    onFindBillConceptsResponse(response) {
      this.billConcepts = response.data;
    },
    onAddConcept() {
      if(this.formData.currentReception && this.formData.currentReception.documentReference) {
        this.isLoadingTable = true;
        setTimeout(() => {
          this.formData.addConcept();
          this.isLoadingTable = false;
        }, 400);
      } else {
        this.notifyWarning("Requiere la referencia de la guía fisica")
      }
    },
    onChangeOption(option) {
      this.conceptSelectedIndex = option.index;
      // if (option.command === "edit") this.onEditConcept(option.item);
      if (option.command === "delete") this.onDeleteConcept(option.index);
    },
    // onEditConcept() {},
    onFocusConcept(index) {
      this.formData.editConcept(index)
    },
    onDeleteConcept(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.isLoadingTable = true;
          setTimeout(() => {
            this.formData.removeConcept(index);
            this.isLoadingTable = false;
          }, 400);
        })
        .catch(() => {});
    },
    isDiscountAvailable() {
      return this.formData.discount > 0;
    },
    onBillConceptChange(item) {
      this.isLoadingTable = true;
      if (item && item.billConcept) {
        if (item.billConcept.isOverWeight) {
          this.onSetOverWeightConcept(item);
        } else {
          this.onSetBillConcept(item);
        }
      } else {
        this.onClearConcept(item);
      }
    },
    onSetBillConcept(item) {
      setTimeout(() => {
        item.formatTransportation({
          template: item.billConcept.template,
          trackingNumber: this.formData.currentReception.trackingNumber,
          origin: this.formData.currentReception.senderPlace.destinationCode,
          destination: this.formData.currentReception.receiverPlace.destinationCode,
          physicalTrackingNumber: this.formData.currentReception.documentReference
        });
        item.calculateTransportation({
          qtyPackages: this.viewModel.getTotalPackages(this.formData.currentReception.receptionPackages),
          deliveryPrice: this.formData.currentReception.serviceDeliveryPrice,
        });
        this.isLoadingTable = false;
      }, 400);
    },
    onSetOverWeightConcept(item) {
      setTimeout(() => {
        item.formatOverWeight({
          template: item.billConcept.template,
          overWeight: this.formData.currentReception.totalOverWeight || 0,
        });

        item.calculateOverWeight({
          qty: 1,
          overWeight: this.formData.currentReception.totalOverWeight || 0,
          extraWeightCost: this.weightCostConfig.extraWeightCost,
        });
        
        this.isLoadingTable = false;
      }, 400);
    },
    onClearConcept(item) {
      setTimeout(() => {
        item.description = "";
        item.amount = 0;
        item.qty = 0;
        item.unitPrice = 0;
        this.isLoadingTable = false;
      }, 400);
    },
  },
};
