import _ from 'lodash'
import BaseViewModel from '../../../core/infraestructure/BaseViewModel'
import ServiceCost from '../../../core/features/serviceCosts/ServiceCost'
import CustomerSpecialPrice from '../../../core/features/customerSpecialPrices/CustomerSpecialPrice'

export default class CustomerSpecialPriceViewModel extends BaseViewModel{

    constructor(view) {
        super(view);
    }

    async bindForm(criteria) {
        try {
            const response = await this.api.customerSpecialPrices.find(criteria)
            this.view.collection = this.mapSpecialPrices(response.data.data)
        } catch (error) {
            this.view.onError(error)
        }
    }

    create(data) {
        return new CustomerSpecialPrice(data, 'create')
    }

    async import(file) {
        try {
            const responseImport = await this.api.customerSpecialPrices.import(file)
            console.log(responseImport)
            this.view.onImportSpecialPricesResponse(responseImport.data)
        } catch (error) {
            this.view.onImportSpecialPricesError(error)
        }
    }

    async findCustomerSpecialPrices(filters) {
        try {
            const criteria = this.getQueryParameters(filters)
            const response = await this.api.customerSpecialPrices.find(criteria)
            this.view.collection = this.mapSpecialPrices(response.data.data)
        } catch (error) {
            this.view.onError(error)
        }
    }

    async save(item) {
        try{
            if(!item.id) {
                const response = await this.api.customerSpecialPrices.create(item.toSend())
                this.view.onSaveSpecialPriceResponse(response.data)
            } else {
                const response = await this.api.customerSpecialPrices.update(item.id, item.toSend())
                this.view.onSaveSpecialPriceResponse(response.data)
            }
        } catch(error) {
            this.view.onError(error)
        }
    }

    async createSpecialPrice(customerId, serviceCostId, price) {
        try{
            await this.api.customerSpecialPrices.create({
                customerId,
                serviceCostId,
                price
            })
        } catch(error) {
            this.view.onError(error)
        }
    }

    async deleteSpecialPrice(id) {
        try{
            const criteria = this.getQueryParameters(this.view.filters)
            console.log(criteria)
            await this.api.customerSpecialPrices.delete(id)
            const response = await this.api.customerSpecialPrices.find(criteria)
            this.view.collection = this.mapSpecialPrices(response.data.data)
            this.view.isTableLoading = false
        } catch(error) {
            this.view.onError(error)
        }
    }

    findCustomers(query, onSuccessResponse, onFailResponse) {
        this.api.customers.find(query)
            .then((response) => onSuccessResponse(response.data))
            .catch(onFailResponse)
    }

    findServiceCosts(query, onSuccessResponse, onFailResponse) {
        this.api.serviceCosts.find(query)
            .then((response) => onSuccessResponse(response.data))
            .catch(onFailResponse)
    }


    mapServiceCosts(collection) {
        return collection.map(item => new ServiceCost(item))
    }

    mapSpecialPrices(collection) {
        return collection.map((item) => {
            return new CustomerSpecialPrice({
                id: item.id,
                customer: (item.customer) ? item.customer : null,
                customers: (item.customer) ? [item.customer] : [],
                serviceCost: (item.serviceCost) ? new ServiceCost(item.serviceCost) : null,
                serviceCosts: (item.serviceCost) ? [new ServiceCost(item.serviceCost)] : [],
                amount: item.amount,
                isActive: item.isActive
            })
        })
    }

    async removeSelected(collection) {
        try {
            const remoteRegisters = _.filter(collection, (item) => !_.isNil(item.id))
            const localRegisters =  _.filter(collection, (item) => _.isNil(item.id))

            const remoteRequests = remoteRegisters.map((item) => {
                return this.api.customerSpecialPrices.delete(item.id)
            })

            if(!_.isEmpty(remoteRequests)) {
                await Promise.all(remoteRequests)
            }
            
            const criteria = this.getQueryParameters(this.view.filters)

            const response = await this.api.customerSpecialPrices.find(criteria)
            this.view.collection = this.mapSpecialPrices(response.data.data)

            _.forEach(localRegisters, (localRegister) => {
                const currentIndex = _.indexOf(collection, localRegister)
                if(currentIndex !== -1) {
                    collection.splice(currentIndex, 1)
                }
            })

            this.view.onRemoveSelectedResponse({
                message: 'Registros seleccionados eliminados.'
            })

        } catch (error) {
            this.view.onRemoveSelectedError(error)
        }
    }
    

}