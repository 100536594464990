import _ from 'lodash'
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Customer from "../customers/Customer";
import Shipment from "../shipments/Shipment";
export default class CustomerBillViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  findCollection(customerId, filters) {
    this.api.customerBills
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  mapCollection(collection){
    let groups = _.groupBy(collection, 'groupCode');

    let result = []
    for (const itemKey in groups) {
      let group = {
        groupCode: groups[itemKey][0].groupCode,
        customer: groups[itemKey][0].customer,
        shipment: groups[itemKey][0].shipment,
        saleBill: groups[itemKey][0].saleBill,
        reception: groups[itemKey][0].reception,
        total: _.sumBy(groups[itemKey], 'sale.total'),
        receptions: groups[itemKey].map((item) => {
          return {
            reception: item.reception,
            sale: item.sale
          }
        })
      }
      result.push(group)
    }
    return result
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  mapShipments(collection) {
    return collection.map((item) => new Shipment(item));
  }

  async generateExcelSalesBySaleGroup(customerId, data){
    try {
      const response = await this.api.customerBills.generateExcelSalesBySaleGroup(customerId, data)
      return response.data
    } catch (error) {
      this.view.onError(error)
    }
  }
}
