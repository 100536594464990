<script>
import ListWorkRoutesComponent from "./ListWorkRoutesComponent";
import ModalWorkRoutesView from "./ModalWorkRoutesView";
import ModalImportView from "../shared/modals/ModalImportView";
export default {
  components: {
    "modal-work-routes-view": ModalWorkRoutesView,
    "modal-import-view": ModalImportView,
  },
  extends: ListWorkRoutesComponent,
};
</script>

<template>
  <div>
    <div class="row my-2 servicecost">
      <div class="d-flex">
        <div class="col-2">
          <div
            id="entradas"
            class="mb-3"
          >
            <span class="mx-2">Mostrar</span>
            <el-select
              v-model="defaultShowEntry"
              placeholder="Opción"
              size="small"
              @change="onChangeEntries"
            >
              <el-option
                v-for="item in showEntries"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span class="mx-2">registros </span>
          </div>
        </div>
        <div class="col-6 me-2" />
        <div class="col-4">
          <div
            id="buttonlist"
            class="mb-3"
          >
            <el-button
              type="danger"
              class="filter-btn"
              icon="fa fa-filter"
              size="medium"
              @click="onMoreFilters"
            >
              Filtrar por:
            </el-button>
            <el-dropdown
              size="medium"
              class="more-options-action"
            >
              <el-button
                type="plain"
                size="medium"
              >
                <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="my-2">
                  <el-button
                    size="medium"
                    :disabled="!onCanCreate(modulePermissions)"
                    @click="onShowImport"
                  >
                    <i class="el-icon-upload2" /> Importar
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item class="my-2">
                  <el-button
                    size="medium"
                    :disabled="!onCanRead(modulePermissions)"
                    @click="onExport"
                  >
                    <i class="el-icon-download" /> Exportar
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              v-if="onUserPermission('Permission.WorkRoute.Create')"
              type="primary"
              size="medium"
              class="main-action"
              :disabled="!onCanCreate(modulePermissions)"
              @click="onCreate"
            >
              <i class="fas fa-plus-circle" /> Crear Ruta de Trabajo
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-4">
      <el-table
        v-if="onUserPermission('Permission.WorkRoute.Read')"
        ref="multipleTable"
        v-loading="isListLoading"
        class="table table-centered table-nowrap mb-0"
        :data="collection"
        style="width: 100%"
        @selection-change="onSelectWorkRoute"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          property="code"
          label="Código"
        />

        <el-table-column
          label="Nombre"
          property="name"
          show-overflow-tooltip
        />
        <el-table-column
          property="description"
          label="Descripción"
          show-overflow-tooltip
        />
        <el-table-column
          property="branchOffice"
          label="Sucursal"
          show-overflow-tooltip
        />
        <el-table-column
          label="Estado"
          show-overflow-tooltip
          align="center"
          width="120"
        >
          <template #default="scope">
            <el-tag
              v-if="scope.row.isActive"
              type="success"
              effect="plain"
            >
              <i class="el-icon-success" /> Activo
            </el-tag>
            <el-tag
              v-if="!scope.row.isActive"
              type="danger"
              effect="plain"
            >
              <i class="el-icon-error" /> Inactivo
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            onUserPermission('Permission.WorkRoute.Update') ||
              onUserPermission('Permission.WorkRoute.Delete')
          "
          label="Opciones"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <el-dropdown @command="onChangeOption">
              <el-button size="small">
                <i class="el-icon-more" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="onUserPermission('Permission.WorkRoute.Update')"
                    :disabled="!onCanRead(modulePermissions)"
                    :command="{ command: 'edit', item: scope.row }"
                  >
                    <i class="el-icon-edit" /> Mostrar
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="onUserPermission('Permission.WorkRoute.Delete')"
                    :disabled="!onCanDelete(modulePermissions)"
                    :command="{
                      command: 'delete',
                      item: scope.row,
                    }"
                  >
                    <i class="el-icon-delete" /> Eliminar
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row mt-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0">
            Mostrar 1 a {{ pagination.limit }} de
            {{ pagination.totalItems }} registros
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-sm-end">
          <el-pagination
            :page-count="pagination.totalPages"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </div>

    <modal-import-view :conf="modalImport" />
    <modal-work-routes-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button
          class="btn-text"
          type="text"
          @click="onClearFilters"
        >
          Borrar todos los filtros
        </el-button>
      </template>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Codigo:</span>
            <el-input
              v-model="filtersSelected.code"
              type="text"
              placeholder="Buscar por código"
              clearable
              size="small"
              @input="onSearchByCode"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Nombre:</span>
            <el-input
              v-model="filtersSelected.name"
              type="text"
              placeholder="Buscar por nombre"
              clearable
              size="small"
              @input="onSearchByName"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Sucursal:</span>
            <el-select
              v-model="filtersSelected.branchOffice"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              value-key="id"
              remote
              size="medium"
              :remote-method="onFindBranchOffices"
              @change="searchbranchOfficeFilter"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              />
              <el-option
                v-for="item in branchOffices"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">¿Activo?:</span>
            <el-select
              v-model="filtersSelected.status"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              size="medium"
              clearable
              debounce
              @change="searchStatusFilter"
            >
              <el-option
                v-for="item in statusOptionsActive"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
