import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import SolvencyViewModel from "../../core/features/solvencies/SolvencyViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'CustomerSolvency',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                solvencyType: '',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR CRÉDITOS Y PAGOS",
                onUploadImport: "on-upload-import-destinations",
                onCancelImport: "on-cancel-import-destinations",
            },
            solvencyTypes: SolvencyViewModel.solvencyTypes,
            solvencyReceptions: {
                receptions: []
            },
            modalSolvencyReceptions: {
                isVisible: false,
                isLoading: false
            }
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-solvency", this.onSearch);
        this.onMountedEvent("on-upload-import-destinations", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-destinations", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-solvency", this.onSearch);
        this.onBeforeDestroyEvent(
            "on-upload-import-destinations",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-destinations",
            this.onCancelImport
        );
    },
    created() {
        this.viewModel = new SolvencyViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
        },
        onSelectSolvency() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
            });
            this.modalConfig.isVisible = true;
        },
        onChangeOption(event) {
            if (event.command === "edit") this.onEdit(event.item.id);
            if (event.command === "delete") this.onDelete(event.item.id);
            if (event.command === "show-receptions") this.onShowReceptionsAssignedToCustomerSolvency(event.item.id);
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('CustomerName', text)
            } else {
                this.removeFilter('CustomerName')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        searchSolvencyTypeFilter(data) {
            if (data !== null) {
                this.addFilter("SolvencyType", data);
            } else {
                this.removeFilter(" SolvencyType");
            }
            this.onSearch();
        },
        onShowReceptionsAssignedToCustomerSolvency(customerSolvencyId) {
            this.viewModel.findReceptionsAssignedToCustomerSolvency(customerSolvencyId)
        },
        onShowReceptionsAssignedToCustomerSolvencyResponse(response) {
            this.modalSolvencyReceptions.isVisible = true
            this.solvencyReceptions.receptions = response.data
        },
    },
};
