import BaseList from '../../../infraestructure/BaseList'
import BoxViewModel from './BoxViewModel'
export default {
    extends: BaseList,
    data() {
        return {
            contactCollection: [],
            locationCollection: [],
            viewModel: null,
            modal: {
                isVisible: false,
                isLoading: false,
            },
            item: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-contact', this.onResponseContact)
        this.onMountedEvent('on-submited-contact-location', this.onResponseLocation)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-contact', this.onResponseCustomer)
        this.onBeforeDestroyEvent('on-submited-contact-location', this.onResponseLocation)
    },
    created() {
        this.viewModel = new BoxViewModel(this)
        this.onActive()
    },
    props: {
        customer: {
            type: Object,
            require: true
        },
        formData: {
            type: Object,
            require: true
        },
        type: {
            type: String,
            require: true
        }
    },
    watch: { },
    methods: {
        onActive() {
            this.viewModel.findCustomerContacts(this.customer.id)
        },
        onFindCustomerContactResponse(response) {
            this.contactCollection = response.data
            if(this.formData.selectedContact) {
                this.viewModel.findLocationsByCustomer(this.formData.selectedContact.id)
            }
        },
        onFindCustomerContactError(error) {
            this.onError(error)
        },
        onAddContact() {
            this.item = this.viewModel.createContact({
                customer: this.customer,
                address: {}
            })
            this.modal.isVisible = true
        },
        onShowContact() {
            if(this.formData.selectedContact) this.viewModel.findCustomerContact(this.customer.id, this.formData.selectedContact.id)
        },
        onShowContactResponse(response) {
            this.item = this.viewModel.editContact(response.data)
            this.item.customer = this.customer
            this.modal.isVisible = true
        },
        onShowContactError(error) {
            this.onError(error)
        },
        onChangeOptionCustomerContact() {

        },
        onChangeCustomerContact(data) {
            this.$set(this, 'locationCollection', [])
            this.$set(this.formData, 'selectedLocation', null)
            this.onNotifyEvent(`${this.type}-contact-change`, data)
            if(data) this.viewModel.findLocationsByCustomer(this.formData.selectedContact.id)
            this.$forceUpdate()
        },
        onChangeCustomerLocation(data) {
            if(data) this.viewModel.findSelectLocation(this.formData.selectedContact.id, data.id)
            else this.onNotifyEvent(`${this.type}-contact-location-change`, null)
        },
        onResponseContact(response) {
            this.modal.isVisible = false
            this.notifySuccess(response.message)
            this.viewModel.findCustomerContacts(this.customer.id)
        },
        onResponseLocation(response) {
            this.notifySuccess(response.message)
            this.modal.isVisible = false
            this.viewModel.findCustomerContacts(this.formData.selectedContact.id)
        },
        onFindLocationsByCustomerResponse(response) {
            this.locationCollection = response.data
        },
        onFindLocationsByCustomerError(error) {
            this.onError(error)
        },
        onFindSelectedLocationResponse(response){
            this.onNotifyEvent(`${this.type}-contact-location-change`, response.data)
        },
        onFindSelectedLocationError(error) {
            this.onError(error)
        }
    }
}