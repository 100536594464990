import _ from "lodash";
// import moment from "moment";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import ReceptionHelper from "../../helpers/ReceptionHelper";
// import ReceptionPackage from "./ReceptionPackage";

export default class ReceptionPackageViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  async bindFormView(formData) {
    try {
      this.view.config.isLoading = true;

      const requests = [
        this.api.currencies.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.productTypes.findFilterTree(),
        this.api.measureUnits.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.weightCosts.find(),
      ];

      const [
        currencies,
        productTypes,
        measureUnits,
        weightCosts,
      ] = await Promise.all(requests);

      this.view.currencies = currencies.data.data;
      this.view.productTypes = this.mapProductTypesTree(productTypes.data.data);
      this.view.onFindMeaseureUnitsResponse(measureUnits.data);

      if (!_.isEmpty(weightCosts.data.data)) {
        this.view.weightCostConfig = weightCosts.data.data[0];
      } else {
        throw new Error("No hay parametros de peso configurados");
      }
      formData.currency = currencies.data.data.find((x) => x.isDefault);
      if (formData.state === "update") {
        let result = (formData.productType) ?  this.getSelectedTreePathProductTypes(formData.productType.id, this.view.productTypes) : null
        this.view.$set(this.view.formData, "productTypePath", result);
        this.view.packagingTypes = this.mapSingleFieldToCollectionItem(formData.packagingType);
        this.view.dangerousMaterialTypes = this.mapSingleFieldToCollectionItem(formData.dangerousMaterial);
        this.view.stccTypes = this.mapSingleFieldToCollectionItem(formData.transportMerchandiseType);
        this.view.$forceUpdate();
      }
      this.view.config.isLoading = false;
    } catch (error) {
      this.view.onError(error);
    }
  }

  mapSingleFieldToCollectionItem(itemData){
    return (itemData) ? [itemData] : []
  }

  findProductTypes(query) {
    this.api.productTypes
      .find(query)
      .then((response) => this.view.onFindProductTypesResponse(response.data))
      .catch(this.view.onError);
  }

  findProductType(id) {
    this.api.productTypes
      .findOne(id)
      .then((response) => this.view.onFindProductTypeResponse(response.data))
      .catch(this.view.onError);
  }

  findMeasureUnits(query) {
    this.api.measureUnits
      .find(query)
      .then((response) => this.view.onFindMeasureUnitsResponse(response.data))
      .catch(this.view.onError);
  }

  findTransportMerchandiseTypes(query) {
    this.api.transportMechandiseTypes
      .find(query)
      .then((response) =>
        this.view.onFindTransportMerchandiseTypesResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findPackagingTypes(query) {
    this.api.packagingTypes
      .find(query)
      .then((response) => this.view.onFindPackagingTypesResponse(response.data))
      .catch(this.view.onError);
  }

  findDangerousMaterials(query) {
    this.api.dangerousMaterials
      .find(query)
      .then((response) =>
        this.view.onFindDangerousMaterialsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  getSelectedTreePathProductTypes(id, productTypes) {
    var result,
      done = false,
      path = {};

    _.forEach(productTypes, (parent, index) => {
      path[index] = [];
      path[index].push(parent.value);
      getTreePath(parent, id, index);
    });

    function getTreePath(node, target, root) {
      if (node.value === target) {
        done = true;
        result = path[root];
      }
      _.forEach(node.children, (item) => {
        if (!done) {
          if (item.value === target) {
            //if we found our target push it to the path
            path[root].push(target);
            //set result to the completed path
            result = path[root];
            //set done to true to exit the search
            done = true;
            return;
          } else {
            //if the node does not match we need to check for children
            if (!_.isEmpty(item.children)) {
              //if node has children, push the key into our path and check the children for our target
              path[root].push(item.value);
              return getTreePath(item, target, root);
            }
            //no children means our search of this branch is over
            return;
          }
        }
      });
      if (!done) {
        path[root].pop();
      }
      return;
    }

    return result;
  }

  getSelectedProductType(collection) {
    return _.last(collection);
  }

  isProductTypesTreeEmpty(collection) {
    return _.isEmpty(collection);
  }

  mapProductTypesTree(collection) {
    this.removeEmptyChildrenProductTypeTrees(collection);
    return collection;
  }

  removeEmptyChildrenProductTypeTrees(productTypes) {
    for (const indexItem in productTypes) {
      if (
        Object.prototype.hasOwnProperty.call(
          productTypes[indexItem],
          "children"
        )
      ) {
        if (productTypes[indexItem].children.length == 0) {
          delete productTypes[indexItem].children;
        } else {
          this.removeEmptyChildrenProductTypeTrees(
            productTypes[indexItem].children
          );
        }
      }
    }
  }

  getFinalWeight(weight, volumetricWeight) {
    return ReceptionHelper.getFinalWeight(weight, volumetricWeight);
  }

  calculateExtraWeight(finalWeight, weightBase, quantity) {
    return this.formatPrecision(
      ReceptionHelper.calculateExtraWeight(finalWeight, weightBase, quantity)
    );
  }

  calculateSubtotalExtaWeight(receptionPackage, weightCostConfig) {
    return ReceptionHelper.calculateSubtotalExtaWeight(
      receptionPackage,
      weightCostConfig
    );
  }

  calculateDeliveryPrice(
    receptionPackage,
    deliveryPriceCost,
    weightCostConfig
  ) {
    return ReceptionHelper.calculateDeliveryPrice(
      receptionPackage,
      deliveryPriceCost,
      weightCostConfig
    );
  }

  calculateVolumetricWeight(quantity, legth, height, width) {
    return this.formatPrecision(ReceptionHelper.calculateVolumetricWeight(quantity, legth, height, width));
  }

  isWidthValid(value, callback) {
    if (!value) {
      callback(new Error("Ancho requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isHeightValid(value, callback) {
    if (!value) {
      callback(new Error("Alto requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isLengthValid(value, callback) {
    if (!value) {
      callback(new Error("Longitud requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isQuantityValid(value, callback) {
    if (!value) {
      callback(new Error("Cantidad requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isWeightValid(value, callback) {
    if (!value) {
      callback(new Error("Peso requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }
}
