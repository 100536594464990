import BaseList from '../infraestructure/BaseList'
import GpsTrackingViewModel from '../../core/features/gpsTracking/GpsTrackingViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'DeviceLocation',
            viewModel: null,
            isMapLoading: false,
            limit: 4500,
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-device-location', this.onFilter)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-device-location', this.onFilter)
        
    },
    created() {
        this.viewModel = new GpsTrackingViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.isMapLoading = true
            this.onSearch();
        },
        onFilter(filters) {
            this.filters = filters
            this.onSearch()
        },
        onBindMapSuccess(response) {
            var mapped = this.viewModel.mapGpsLocation(response.data)
            this.viewModel.drawMap(mapped)
        },
        onBindMapError(error) {
            setTimeout(() => {
                this.isMapLoading = false
                this.onError(error)
            }, 400);
        },
    }
}