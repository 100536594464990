export default class BankAccount {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            bankName: this.bankName,
            accountNumber: this.accountNumber,
            isDefault: this.isDefault,
            isActive: this.isActive
        }
    }

    getFullDescription() {
        return `${this.bankName} - ${this.accountNumber}`
    }
}