import BaseModule from '../infraestructure/BaseModule'
import TrackingViewModel from '../../core/features/trackings/TrackingViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new TrackingViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}