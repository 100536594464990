import BaseApi from "../../infraestructure/BaseApi";

export default class ApiUsers extends BaseApi {
  constructor(client, endPoint) {
    super(client, endPoint);
  }

  findTotalUsers(criteria) {
    const parameters = criteria ? `?${criteria}` : "";
    return this.client.get(`${this.endPoint}/total-users${parameters}`);
  }

  getUserCurrentApi() {
    return this.client.get(`${this.endPoint}/current`);
  }

  getVerificationEmail(criteria) {
    const parameters = criteria ? `?${criteria}` : "";
    return this.client.get(`${this.endPoint}/verification-email${parameters}`);
  }

  verifyAccountUser(data) {
    return this.client.post(`${this.endPoint}/verify-account-user`, data);
  }
}
