export default class TraceStatusReason {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        id: this.id,
        traceStatus: this.traceStatus,
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description,
        hasCharge: this.hasCharge,
        price: this.price,
        isActive: this.isActive,
      }
    }


  }