export default class PostalCode {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            countryId: (this.country) ? this.country.id : null,
            districtId: (this.district) ? this.district.id : null,
            municipalityId: (this.municipality) ? this.municipality.id : null,
            cityId: (this.city) ? this.city.id : null,
            isActive: this.isActive
        }
    }

    getFullDescription() {
        var result = []

        if(this.code){
            result.push(this.code)
        }

        if(this.city) {
            if(this.city.id){
                result.push(this.city.name)
            } else{
                result.push(this.city)
            }
        }

        if(this.municipality) {
            if(this.municipality.id){
                result.push(this.municipality.name)
            } else {
                result.push(this.municipality)
            }
        }
        
        if (this.district) {
            if(this.district.id){
                result.push(this.district.name)
            } else {
                result.push(this.district)
            }
        }
        if (this.country) {
            if(this.country.id){
                result.push(this.country.name)
            } else {
                result.push(this.country)
            }
        }
        
        return result.join(', ')
    }
}