import BaseForm from '../infraestructure/BaseForm'
import CustomersViewModel from '../../core/features/customers/CustomersViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            rules: {
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                mainStreet: [{
                    required: true,
                    message: 'Calle Principal requerido.',
                    trigger: 'blur'
                }],
                secondaryStreet: [{
                    required: true,
                    message: 'Entre Calles requerido.',
                    trigger: 'blur'
                }],
                extNumber: [{
                    required: true,
                    message: 'Numero requerido.',
                    trigger: 'blur'
                }],
                city: [{
                    required: true,
                    message: 'Ciudad/Destino requerido.',
                    trigger: 'blur'
                }],
                neighborhood: [{
                    required: true,
                    message: 'Colonia requerido.',
                    trigger: 'blur'
                }],
                postalCode: [{
                    required: true,
                    message: 'Código Postal requerido.',
                    trigger: 'blur'
                }],
                country: [{
                    required: true,
                    message: 'País requerido.',
                    trigger: 'blur'
                }],
                district: [{
                    required: true,
                    message: 'Estado requerido.',
                    trigger: 'blur'
                }],
                municipality: [{
                    required: true,
                    message: 'Municipio requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        customer:{
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CustomersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindFormCustomerLocation(this.customer.id, this.formData)
        },
        bindFormCustomerLocationError(error) {
            this.config.isLoading = false
            this.onError(error)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
            
        },
        onSave(formData) {
            this.onDoAfterLocalSaveResponse(formData, 'on-submited-customer-location')
        },
    }
}