import BaseViewModel from '../../infraestructure/BaseViewModel'


export default class CustomerDashboardViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }
    
    getDataResumen() {
        this.api.customerDashboard.getData()
            .then((response) => this.view.onGetDataResumeResponse(response.data))
            .catch(this.view.onError)
    }
}