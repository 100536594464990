import _ from 'lodash'
import BaseForm from '../../../../components/infraestructure/BaseForm'
import CustomerBoxViewModel from './CustomerBoxViewModel'


export default {
    extends: BaseForm,
    data() {
        return {
            dialogVisible: false,
            viewModel: null,
            editPass: false,
            rules: {
                fiscalName: [{
                    message: 'Razón Social requerido.',
                    trigger: 'blur'
                }],
                fiscalCode: [{
                    validator: this.onVerifyRFC, trigger: ['blur', 'change']
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }],
                contactName: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                contactPhone: [{
                    required: true,
                    message: 'Telefono requerido.',
                    trigger: 'blur'
                }],
                contactEmail: [{
                    trigger: 'blur',
                    validator: this.onValidateEmail
                },
                {
                    type: 'email',
                    message: 'Dirección de correo electrónico invalida.',
                    trigger: ['blur', 'change']
                }],
                address:{
                    description: [{
                        required: true,
                        message: 'Descripción/Alias de Ubicación requerido.',
                        trigger: 'blur'
                    }],
                    mainStreet: [{
                        required: true,
                        message: 'Calle Principal requerido.',
                        trigger: 'blur'
                    }],
                    secondaryStreet: [{
                        required: true,
                        message: 'Entre Calles requerido.',
                        trigger: 'blur'
                    }],
                    city: [{
                        required: true,
                        message: 'Ciudad/Destino requerido.',
                        trigger: 'blur'
                    }],
                    neighborhood: [{
                        required: true,
                        message: 'Colonia requerido.',
                        trigger: 'blur'
                    }],
                    postalCode: [{
                        required: true,
                        message: 'Código Postal requerido.',
                        trigger: 'blur'
                    }],
                    country: [{
                        required: true,
                        message: 'Pais requerido.',
                        trigger: 'blur'
                    }],
                    district: [{
                        required: true,
                        message: 'Estado requerido.',
                        trigger: 'blur'
                    }],
                    municipality: [{
                        required: true,
                        message: 'Municipio requerido.',
                        trigger: 'blur'
                    }],
                }
            },
            cities:[],
            neighborhoods: [],
            activeName: 'my-locations',
            countries: [],
            districts: [],
            municipalities: [], 
            showNeighborhoodName: false,
            isNeigborhoodLoading: false,
        }
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        isPostalCodeLoading: {
            type: Boolean,
            required: true
        },
    },
    created() {
        this.viewModel = new CustomerBoxViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() { 
            if(this.formData.customerNumber) {
                this.viewModel.findCustomerByCode(this.formData.customerNumber)
            }
        },
        onCheckRfc:_.debounce(function () {
            if (this.formData.fiscalCode) {
                this.viewModel.verifyRFC(this.formData.fiscalCode)
            } else {
                // callback();
            }
        }, 500),
        onVerifyRFC(rule, value, callback) {
            if (value) {
                _.debounce(() => {
                    if (this.formData.fiscalCode) {
                        this.viewModel.verifyRFC(this.formData.fiscalCode, callback)
                    } else {
                        callback();
                    }
                }, 500)()
            } else {
                callback();
            }
        },
        onValidateEmail(rule, value, callback) {
            if (this.formData.isMasterAccountActive) {
                if (!value) {
                    callback(new Error('Email requerido.'));
                }
                callback()
            } else {
                callback();
            }
        },
        onChangeCountry() {},
        onChangeDistrict() {},
        onChangeMunicipality() {},
        onChangeCity() {},
        onShowNeighborhoodInput() {
            this.showNeighborhoodName = true
            
        },
        onChangeNeighborhood() {},
        onAddNeighborhood() {},
        onCancelNeighborhoodInput() {
            this.showNeighborhoodName = false
            
        },
        onFindCustomerByCode(){
            if(this.formData.customerNumber) {
                this.onNotifyEvent('is-form-loading', true)
                this.viewModel.findCustomerByCode(this.formData.customerNumber)
            }
        },
        onFindCustomerByCodeResponse(response) {
            setTimeout(() => {
                this.$set(this.formData.customer, 'id', response.data.id)
                this.$set(this.formData.customer, 'code', response.data.code)
                this.$set(this.formData.customer, 'contactName', response.data.contactName)
                this.$set(this.formData.customer, 'contactEmail', response.data.contactEmail)
                this.$set(this.formData.customer, 'contactPhone', response.data.contactPhone)
                this.$set(this.formData.customer, 'fiscalCode', response.data.fiscalCode)
                this.$set(this.formData.customer, 'fiscalName', response.data.fiscalName)
                // this.$set(this.formData.customer, 'addressId', response.data.customerLocations[0].id)
                // this.$set(this.formData.customer, 'mainStreet', response.data.customerLocations[0].mainStreet)
                // this.$set(this.formData.customer, 'secondaryStreet', response.data.customerLocations[0].secondaryStreet)
                // this.$set(this.formData.customer, 'extNumber', response.data.customerLocations[0].extNumber)
                // this.$set(this.formData.customer, 'intNumber', response.data.customerLocations[0].intNumber)
                // this.$set(this.formData.customer, 'postalCode', response.data.customerLocations[0].postalCode)
                // this.$set(this.formData.customer, 'municipality', response.data.customerLocations[0].municipality)
                // this.$set(this.formData.customer, 'city', response.data.customerLocations[0].city)
                // this.$set(this.formData.customer, 'neighborhood', response.data.customerLocations[0].neighborhood)
                // this.$set(this.formData.customer, 'district', response.data.customerLocations[0].district)
                // this.$set(this.formData.customer, 'country', response.data.customerLocations[0].country)
                this.onNotifyEvent('is-form-loading', false)
            }, 400);
        },
        onFindCustomerByCodeError(error) {
            setTimeout(() => {
                this.onNotifyEvent('is-form-loading', false)
                this.onError(error)
            }, 400);
        }
    }
}