import BaseForm from "../infraestructure/BaseForm";
import ShipmentViewModel from "../../core/features/shipments/ShipmentViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      rules: {
        guide: [
          {
            required: true,
            message: "La guía del envio es requerida.",
            trigger: "blur",
          },
        ],
      },
      trackingDocuments: [],
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new ShipmentViewModel(this);
    this.onActive();
  },

  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSave(formData) {
      if (
        !this.viewModel.existReception(
          formData.currentReceptions,
          formData.reception
        )
      ) {
        this.onDoAfterLocalSaveResponse(formData, "on-submited-delivery-guide");
        return true;
      } else {
        this.notifyWarning("La guía ya se encuentra agregada.");
      }
      return false;
    },
    onFindTrackingDocument(data) {
      if (data) {
        this.viewModel.findItemReceptionNumber(
          data,
          this.formData.receptionsUpdate,
          this.formData.destinationId
        );
      } else {
        this.trackingDocuments = [];
      }
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-shipment");
    },
    onFindItemReceptionNumberResponse(response) {
      this.trackingDocuments = response.data;
    },
  },
};
