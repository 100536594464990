<template>
  <section
    id="about-4"
    class="about about-4"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="about-img about-img-left">
            <div class="about-img-warp bg-overlay">
              <div class="bg-section">
                <img
                  class="img-fluid"
                  src="/website/images/about/2.jpg"
                  alt="about Image"
                >
              </div>
            </div>
            <div class="counter">
              <div class="counter-icon">
                <i class="flaticon-018-packaging" />
              </div>
              <div class="counter-num">
                <span class="counting">9,612</span>
                <p>m</p>
              </div>
              <div class="counter-name">
                <h6>Entregas realizadas</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <div class="heading heading-3">
            <p class="heading-subtitle">
              Proporcionando una gama completa de transporte en todo el mundo.
            </p>
            <h2 class="heading-title">
              ¡Las soluciones confiables de Mensajería y paquetería que le ahorran tiempo!
            </h2>
          </div>
          <div class="about-block"> 
            <div class="row">
              <div class="col-12 col-lg-7">
                <div class="block-left"> 
                  <p>Ram Logistica es un operador logístico representativo que ofrece una gama completa de servicios de Mensajería, Paquetería y Carga Express en Baja California Sur.</p>
                  <p>Estamos orgullosos de ofrecer los mejores servicios de transporte y envío disponibles en todo el estado. Nuestro personal calificado, utilizando las últimas comunicaciones y combinado con experiencia, hacemos el mejor equipo para brindarle la mejor solución.</p>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="block-right">
                  <div class="detail"> 
                    <h6>Calidad </h6>
                    <p>Siguiendo la calidad de nuestro servicio habiéndonos ganado la confianza de nuestros numerosos clientes.</p>
                  </div>
                  <div class="detail"> 
                    <h6>confiabilidad</h6>
                    <p>Brindamos seguridad a la carga en todas las etapas de nuestro proceso de entrega.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End .col-lg-6-->
      </div>
      <!-- End .row-->
    </div>
    <!-- End .container-->
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>