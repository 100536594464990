import BaseApi from '../../infraestructure/BaseApi'

export default class PermissionsApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }
    
    getPermissions(){
            return  this.client.get(`${this.endPoint}/permissions`)
    }
    getPermissionsRoleById(roleId){
        return this.client.get(`${this.endPoint}/permission/${roleId}`)
    }

    savePermissions(data){
        return this.client.post(`${this.endPoint}/addpermissions`,data)
    }
}
