import BaseModule from '../infraestructure/BaseModule'
import CustomerBillViewModel from "../../core/features/customerBill/CustomerBillViewModel";


export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomerBillViewModel(this)
    },
    methods: {
        onActive() {
            
        }
    }
}