import axios from 'axios';
import TokenStorageHelper from "./TokenStorageHelper";

export default function ExecuteInterceptor() {
    axios.interceptors.request.use(function(config) {
        const token = TokenStorageHelper.Token;
        if(token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });
}
