import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import CollectionRouteViewModel from '../../core/features/collectionRoutes/CollectionRouteViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'CollectionRoute',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                employeeProfile: '',
                status: '',
            },
            employees: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR RUTAS DE RECOLECCIÓN',
                onUploadImport: 'on-upload-import-collection-routes',
                onCancelImport: 'on-cancel-import-collection-routes'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-collection-route', this.onSearch)
        this.onMountedEvent('on-upload-import-collection-routes', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-collection-routes', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-collection-route', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-collection-routes', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-collection-routes', this.onCancelImport);
    },
    created() {
        this.viewModel = new CollectionRouteViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectCollectionRoute() {},
        onCreate(){
            this.item = this.viewModel.create({
                isActive: true,
                workRoutes: []
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchEmployeeFilter(data) {
            if (data !== null) {
                this.addFilter('EmployeeProfileId', data)
            } else {
                this.removeFilter('EmployeeProfileId')
            }
            this.onSearch()
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
     
    }
}