<script>
import BoxComponent from "./BoxComponent";
import ModalView from "./ModalView";

export default {
  components: {
    ModalView,
  },
  extends: BoxComponent,
};
</script>
<template>
  <div>
    <el-row
      class="row-bg"
      :gutter="20"
    >
      <el-col
        :xs="24"
        :sm="16"
      >
        <el-form-item
          :prop="`${type}.selectedContact`"
        >
          <el-select
            v-model="formData.selectedContact"
            placeholder="Seleccionar contacto"
            filterable
            clearable
            debounce
            value-key="id"
            size="small"
            @change="onChangeCustomerContact"
          >
            <el-option
              v-for="item in contactCollection"
              :key="item.id"
              :label="item.description"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="8"
        align="right"
      >
        <el-button-group>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus"
            @click="onAddContact"
          >
            Agregar
          </el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus"
            @click="onShowContact"
          >
            Mostrar
          </el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-row
      v-if="formData.selectedContact"
      class="row-bg"
      :gutter="20"
      type="flex"
    >
      <el-col
        :xs="24"
        :sm="24"
      >
        <el-form-item
          :prop="`${type}.selectedLocation`"
        >
          <el-select
            v-model="formData.selectedLocation"
            placeholder="Seleccionar ubicación"
            filterable
            clearable
            debounce
            value-key="id"
            size="small"
            @change="onChangeCustomerLocation"
          >
            <el-option
              v-for="item in locationCollection"
              :key="item.id"
              :label="`${item.description} | ${item.address}`"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <!-- <el-row class="row-bg" :gutter="20" type="flex">
      <el-table
        ref="multipleTable"
        v-loading="isListLoading"
        class="table table-centered table-nowrap mb-0"
        :data="collection"
        style="width: 100%; margin-top:20px;"
      >
        <el-table-column label="Dirección" show-overflow-tooltip width="150">
          <template #default="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>

        <el-table-column label="Ubicación" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>

        <el-table-column width="120" align="center">
          <template #default="scope">
            <el-dropdown @command="onChangeOptionCustomerContact">
              <el-button size="small">
                <i class="el-icon-more" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="{
                      command: 'edit',
                      item: scope.row,
                    }"
                  >
                    <i class="el-icon-edit" /> Mostrar
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      command: 'delete',
                      item: scope.row,
                    }"
                  >
                    <i class="el-icon-delete" /> Eliminar
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-row> -->
    <modal-view
      v-if="modal.isVisible"
      :config="modal"
      :form-data="item"
    />
  </div>
</template>
