import BaseModule from '../infraestructure/BaseModule'
import PlaceViewModel from '../../core/features/places/PlaceViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            tabActive: 'branch-offices',
        }
    },
    created() {
        this.viewModel = new PlaceViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}