<script>
import ModalReceptionsBySolvencyComponent from "./ModalReceptionsBySolvencyComponent";

export default {
  extends: ModalReceptionsBySolvencyComponent,
};
</script>

<template>
  <el-dialog
    width="80%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-ticket" /> Gu&iacute;as Asignadas
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
        justify="end"
      >
        <div class="d-flex justify-content-around">
          <div class="mx-2">
            <el-input
              v-model="searchReceptionNumber"
              placeholder="Buscar Guía..."
              prefix-icon="el-icon-search"
              debounce
              clearable
              size="medium"
              @input="searchReceptionNumberFilter"
            />
          </div>
          <div class="mx-2">
            <el-input
              v-model="searchOriginName"
              placeholder="Buscar Remitente..."
              prefix-icon="el-icon-search"
              debounce
              clearable
              size="medium"
              @input="searchOriginNameFilter"
            />
          </div>
           
          <div class="mx-2">
            <el-input
              v-model="searchDestinyName"
              placeholder="Buscar Destinatario..."
              prefix-icon="el-icon-search"
              debounce
              clearable
              size="medium"
              @input="searchDestinyNameFilter"
            />
          </div>

          <div class="mx-2">
            <el-input
              v-model="searchSenderPlace"
              placeholder="Buscar Origen..."
              prefix-icon="el-icon-search"
              debounce
              clearable
              size="medium"
              @input="searchSenderPlaceFilter"
            />
          </div>
          <div class="mx-2">
            <el-input
              v-model="searchReceiverPlace"
              placeholder="Buscar Destino..."
              prefix-icon="el-icon-search"
              debounce
              clearable
              size="medium"
              @input="searchReceiverPlaceFilter"
            />
          </div>
          <div class="mx-2">
            <div class="searchreceptiondate">
              <el-date-picker
                v-model="searchBeginingDate"
                size="medium"
                type="daterange"
                clearable
                range-separator="a"
                start-placeholder="Fecha Inicio"
                end-placeholder="Fecha Final"
                @change="searchBeginingDateFilter"
              />
            </div>
          </div>
        </div>
        <el-col
          :span="3"
          align="right"
        >
          <el-button
            size="medium"
            @click="onExport"
          >
            <i class="el-icon-download" /> Exportar
          </el-button>
        </el-col>
      </el-row>
      <br>
      <el-row
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <div
            id="entradas"
            class="mb-3 w-25"
          >
            <span class="mx-2">Mostrar</span>
            <el-select
              v-model="defaultShowEntry"
              placeholder="Opción"
              size="small"
              @change="onChangeEntries"
            >
              <el-option
                v-for="item in showEntries"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span class="mx-2">registros </span>
          </div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-table
            :data="formData.receptions"
            style="width: 100%"
            height="400"
          >
            <el-table-column
              prop="trackingNumber"
              label="No. Guía"
              width="180"
            />
            <el-table-column
              prop="receptionDate"
              label="F. Solicitud"
              width="180"
            >
              <template slot-scope="scope">
                <el-tag
                  type="primary"
                  effect="plain"
                >
                  <i class="el-icon-date" />
                  <span class="mx-2">
                    {{ scope.row.receptionDate | formatDate }}
                  </span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="originPlace"
              label="Origen"
              width="180"
              align="center"
            />
            <el-table-column
              prop="destinationPlace"
              label="Destino"
              width="180"
              align="center"
            />
            <el-table-column
              label="Remitente"
              align="center"
            >
              <template slot-scope="scope">
                <span class="mx-2">
                  {{ scope.row.senderName }} | {{ scope.row.senderPhone }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Destinatario"
              align="center"
            >
              <template slot-scope="scope">
                <span class="mx-2">
                  {{ scope.row.receiverName }} | {{ scope.row.receiverPhone }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="row mt-4">
        <div class="col-sm-6">
          <div>
            <p class="mb-sm-0">
              Mostrar 1 a {{ formData.pagination.limit }} de
              {{ formData.pagination.totalItems }} registros
            </p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="float-sm-end">
            <el-pagination
              :page-count="formData.pagination.totalPages"
              :current-page.sync="currentPage"
              layout="prev, pager, next"
              @current-change="onPageChange"
            />
          </div>
        </div>
      </div>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Aceptar</el-button>
    </span>
  </el-dialog>
</template>
