import BaseApi from '../../infraestructure/BaseApi'

export default class ApiCustomerAccount extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }
 
    create(data) {
        return this.client.post(`${this.endPoint}/${data.customerId}/accounts`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/accounts/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/accounts/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/accounts${parameters}`)
    }

    findOne(customerId, id) {
        return this.client.get(`${this.endPoint}/${customerId}/accounts/${id}`)
    }

    findSubAccount(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/accounts-by-customer${parameters}`)
    }

    verifyCustomerAccount(data){
        return this.client.post(`${this.endPoint}/verify-customer-account`, data)
    }

}