import BaseModule from '../infraestructure/BaseModule'
import CustomerVerificationCodeViewModel from "../../core/features/customerVerificationCodes/CustomerVerificationCodeViewModel";


export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomerVerificationCodeViewModel(this)
    },
    methods: {
        onActive() {
            
        }
    }
}