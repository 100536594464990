import $ from "jquery";
window.jQuery = window.$ = $;
import GpsTrackingHelper from "../../helpers/GpsTrackingHelper";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import TokenStorageHelper from "../../helpers/TokenStorageHelper";
import ReceptionHelper from "../../helpers/ReceptionHelper";
import _ from "lodash";
import moment from "moment";


$.fn.zyCounter = function(options) {
  var settings = $.extend(
    {
      duration: 40,
      easing: "swing",
    },
    options
  );
  return this.each(function() {
    var $this = $(this);

    var startCounter = function() {
      var numbers = [];
      // var length = $this.length;
      var number = $this.text();
      var isComma = /[,\-]/.test(number); // eslint-disable-line
      var isFloat = /[,\-]/.test(number); // eslint-disable-line
      number = number.replace(/,/g, "");
      var divisions = settings.duration;
      var decimalPlaces = isFloat ? (number.split(".")[1] || []).length : 0;

      // make number string to array for displaying counterup
      for (var rcn = divisions; rcn >= 1; rcn--) {
        var newNum = parseInt((number / divisions) * rcn);
        if (isFloat) {
          newNum = parseFloat((number / divisions) * rcn).toFixed(
            decimalPlaces
          );
        }
        if (isComma) {
          while (/(\d+)(\d{3})/.test(newNum.toString())) {
            newNum = newNum
              .toString()
              .replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
          }
        }

        numbers.unshift(newNum);
      }
      var counterUpDisplay = function() {
        $this.text(numbers.shift());
        setTimeout(counterUpDisplay, settings.duration);
      };
      setTimeout(counterUpDisplay, settings.duration);
    }; // end function

    //bind with waypoints
    $this.waypoint(startCounter, {
      offset: "100%",
      triggerOnce: true,
    });
  });
};
export default class PagesViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }


  initBackgroundInsert() {
    var $bgSection = $(".bg-section");
    var $bgPattern = $(".bg-pattern");
    var $colBg = $(".col-bg");

    $bgSection.each(function() {
      var bgSrc = $(this)
        .children("img")
        .attr("src");
      var bgUrl = "url(" + bgSrc + ")";
      $(this)
        .parent()
        .css("backgroundImage", bgUrl);
      $(this)
        .parent()
        .addClass("bg-section");
      $(this).remove();
    });

    $bgPattern.each(function() {
      var bgSrc = $(this)
        .children("img")
        .attr("src");
      var bgUrl = "url(" + bgSrc + ")";
      $(this)
        .parent()
        .css("backgroundImage", bgUrl);
      $(this)
        .parent()
        .addClass("bg-pattern");
      $(this).remove();
    });

    $colBg.each(function() {
      var bgSrc = $(this)
        .children("img")
        .attr("src");
      var bgUrl = "url(" + bgSrc + ")";
      $(this)
        .parent()
        .css("backgroundImage", bgUrl);
      $(this)
        .parent()
        .addClass("col-bg");
      $(this).remove();
    });
  }

  initNavModule() {
  
    /* ------------------  MOBILE MENU ------------------ */

    var $dropToggle = $('[data-toggle="dropdown"]');
    $dropToggle.on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        $(this).parent().siblings().removeClass("show");
        $(this).parent().toggleClass("show");
    });

    /*POPUP HEADER */
    $(".toggle-icon").on('click', function() {
        $('.popup-menu').addClass("active");
    });
    $(".popup-close i").on('click',function() {
        $('.popup-menu').removeClass("active");
    });
    $(document).on('keyup',function(e) {
        // ESCAPE key pressed
        if (e.key  == 27) {
            if ($('.popup-menu').hasClass('active')) {
                $(".popup-close i").on('click');
            }
        }
    });
  }

  initCounterUp() {
    //  $('.counting').zyCounter();
  }

  findItemTrackingNumber(trackingNumber) {
    this.api.trackings
      .findMultipleTrackingNumber(trackingNumber)
      .then((response) =>
        this.view.onFindItemTrackingNumberResponse(response.data)
      )
      .catch(this.view.onError);
  }

  doLogin(account) {
    this.api.authorization
      .doLogin(account)
      .then((response) => this.view.onLoginResponse(response.data))
      .catch(this.view.onLoginError);
  }

  
    sendEmailRecovery(email) {
     this.api.authorization
      .sendEmailRecovery(email)
      .then((response) => this.view.onSendRecoveryResponse(response.data))
      .catch(this.view.onLoginError);
  }

  passwordRecoveryToken(email) {
    this.api.authorization
     .passwordRecoveryToken(email)
     .then((response) => this.view.onSendPasswordRecoveryResponse(response.data))
     .catch(this.view.onLoginError);
 }



  setToken(token) {
    TokenStorageHelper.storeToken = token;
  }

  findPermissionsByRole() {
    this.api.authorization
      .findPermissionsByRole(TokenStorageHelper.getUserRole().toString())
      .then((response) =>
        this.view.onFindPermissionsByRoleResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findCurrentUser() {
    this.api.users
      .getUserCurrentApi()
      .then((response) => this.view.onFindCurrentUserResponse(response.data))
      .catch(this.view.onError);
  }

  getIconTrackingStatus(status) {
    return ReceptionHelper.getIconTrackingStatus(status);
  }

  getStyleTrackingStatus(status) {
    return ReceptionHelper.getStyleTrackingStatus(status);
  }
  getColorTrackingStatus(status) {
    return ReceptionHelper.getColorTrackingStatus(status);
  }

  getNameTrackingStatus(status) {
    return ReceptionHelper.getNameTrackingStatus(status);
  }

  bindForm(collection) {
    _.forEach(collection, (item) => {
      item.isDelivered = item.deliveryLat && item.deliveryLng;
    })
  }

  startMap(formData) {
    // Paso 1: Ordenar los registros por fecha de forma descendente
    const sortedRecords = _.orderBy(formData.historyTrace, ["date"], ["desc"]);

    // Paso 2: Filtrar los registros con estatus 5
    const filteredRecords = _.filter(sortedRecords, { status: 5 });

    // Paso 3: Obtener el primer registro con estatus 5
    const firstRecordWithDeliveredStatus = _.head(filteredRecords);

    this.drawMap([
      {
        lat: formData.deliveryLat,
        lng: formData.deliveryLng,
        getMarkerContent: () => {
          return `
                          <strong>Estado:</strong><br />
                          ${firstRecordWithDeliveredStatus.traceStatusName.toUpperCase()} <br /><br /> 
                          <strong>COMENTARIOS:</strong><br />
                          ${firstRecordWithDeliveredStatus.comments}<br /><br />
                          <strong>FECHA Y HORA DE ENTREGA:</strong><br />
                          ${moment(firstRecordWithDeliveredStatus.date).format(
                            "DD MMM YYYY hh:mm:ss a"
                          )}<br />
                      `;
        },
      },
    ]);
  }

  drawMap(collection) {
    if (this.map) {
        GpsTrackingHelper.removeMap(this.map);
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      } else {
        this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
      }

      GpsTrackingHelper.loadCopyRights(this.map);
      var customIcon = GpsTrackingHelper.createCustomIcon();
      collection.forEach((deviceLocation) => {
        GpsTrackingHelper.createMarker(
          this.map,
          customIcon,
          [deviceLocation.lat, deviceLocation.lng],
          deviceLocation.getMarkerContent()
        );
      });
      this.map.closePopup();
      this.view.isMapLoading = false;
  }
}
