import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import VehiclesViewModel from '../../core/features/vehicles/VehiclesViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Vehicle',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                equipmentNumber: '',
                plateNumber: '',
                description: '',
                branchOfficeName: '',
                vehicleType: '',
                status: '',
            },
            vehicleTypes: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR VEHÍCULOS',
                onUploadImport: 'on-upload-import-vehicles',
                onCancelImport: 'on-cancel-import-vehicles'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-vehicle', this.onActive)
        this.onMountedEvent('on-upload-import-vehicles', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-vehicles', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-vehicle', this.onActive)
        this.onBeforeDestroyEvent('on-upload-import-vehicles', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-vehicles', this.onCancelImport);
    },
    created() {
        this.viewModel = new VehiclesViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectVehicle() { },
        onCreate(){
            this.item = this.viewModel.create({
                isActive: true
            })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByEquipmentNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('EquipmentNumber', text)
            } else {
                this.removeFilter('EquipmentNumber')
            }
            this.onSearch();
        }, 500),
        onSearchByPlateNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('PlateNumber', text)
            } else {
                this.removeFilter('PlateNumber')
            }
            this.onSearch();
        }, 500),
        onSearchByDescription: _.debounce(function (text) {
            if (text) {
                this.addFilter('Description', text)
            } else {
                this.removeFilter('Description')
            }
            this.onSearch();
        }, 500),
        onSearchByBranchOfficeName: _.debounce(function (text) {
            if (text) {
                this.addFilter('BranchOfficeName', text)
            } else {
                this.removeFilter('BranchOfficeName')
            }
            this.onSearch();
        }, 500),
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        searchVehicleTypeFilter(data) {
            if (data !== null) {
                this.addFilter('VehicleTypeId', data)
            } else {
                this.removeFilter('VehicleTypeId')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },


    }
}