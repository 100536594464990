export default class Role {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
      
    toSend() {
      return {
        id: this.id,
        code: this.code,
        name: this.name.trim(),
        isActive: this.isActive
      }
    }
  }