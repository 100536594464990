<script>
import ModalReceptionComponent from './ModalReceptionComponent'
import ReceptionCreateView from './ReceptionCreateView'
import ReceptionEditView from './ReceptionEditView'


export default {
    components: {
      'reception-create-view': ReceptionCreateView,
      'reception-edit-view': ReceptionEditView
      },
    extends: ModalReceptionComponent
}
</script>

<template>
  <div>
    <el-dialog
      custom-class="dialog-reception dialog"
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-box" /> Recepción de Paquetes
      </span>
      <reception-create-view
        v-if="isCreateState()"
        v-loading="config.isLoading"
        :form-data="formData"
        :config="config"
      />
      <el-form
        v-if="isEditState()"
        ref="formData"
        v-loading="config.isLoading"
        :model="formData"
        :rules="rules"
        :label-position="'top'"
      >
        <reception-edit-view
          v-if="isEditState()"
          :form-data="formData"
        />
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="formData.state != 'create'"
          size="small"
          @click="onCancel"
        >Cancelar</el-button>
        <el-button
          v-if="formData.state != 'create'"
          type="primary"
          size="small"
          :disabled="!onCanSaveOrUpdate()"
          @click="onSubmitForm('formData')"
        >Guardar</el-button>
      </span>
    </el-dialog>
  </div>
</template>