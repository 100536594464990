import BaseModule from '../infraestructure/BaseModule'
import CustomerContactViewModel from "../../core/features/customerContact/CustomerContactViewModel";


export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new CustomerContactViewModel(this)
    },
    methods: {
        onActive() {
            
        }
    }
}